import React, { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import { useSearchParams } from "react-router-dom";

import "./hireFreelancer.scss";
import { GetFreelancerDataById } from "../../../apiCall";
import { domainName } from "../../../Constants";
import FreelancerProfile from "./Components/FreelancerProfile";
import HireForm from "./Components/HireForm";
import { useStateValue } from "../../../StateProvider";

const HireFreelancer = () => {
    const [profileData, setProfileData] = useState({});
    const [, dispatch] = useStateValue();
    
    const [searchParams] = useSearchParams();
    const id = searchParams.get("freelancer_id");
    const job_id = searchParams.get("job_id");
    const hire = searchParams.get("hire");
    const [showUserProfile, setShowUserProfile] = useState(hire === 'true' ? false : true);

    useEffect(() => {
        // fetch profile details and set it to setProfile Data.
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await GetFreelancerDataById(id, job_id);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("GET_FREELANCER_DETAILS_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    re.data.value.portfolio = re.data.value.portfolio
                        ? domainName + "/uploads/" + re.data.value.portfolio
                        : "";
                    setProfileData(re.data.value);
                }
            }
        };
        getData();
    }, [id, job_id, dispatch]);

    return (
        <div className='freelancer-profile-main'>
            {showUserProfile ? (
                <FreelancerProfile profileData={profileData} setShowUserProfile={setShowUserProfile} freelancer_id={id}/>
            ) : (
                <HireForm
                    job_id={job_id}
                    freelancer_id={id}
                    currency_unit={profileData?.amount_format}
                    setShowUserProfile={setShowUserProfile}
                />
            )}
        </div>
    );
};

export default HireFreelancer;
