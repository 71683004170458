import React from "react";
import { Navigate } from "react-router-dom";
import { useStateValue } from "./StateProvider";

function ProtectedRoute({children}) {
    const [{hasOtherDetails, loggedInAs}] = useStateValue()
    let location = window.location;
    
    if(loggedInAs && !hasOtherDetails && location.pathname.search('/signup') === -1){
        return <Navigate to="/signup" state={{ from: location}} replace />
    }

  return children;
}

export default ProtectedRoute;
