import React from "react";
import WarningIcon from "../Assets/icons/warning.svg";
import "./deleteView.scss";
import { useStateValue } from "../StateProvider";

const DeleteView = ({ text, callback, id }) => {
    console.log(callback)
    const [,dispatch] = useStateValue()
    const hideDeleteModel = () => {
        dispatch({
            type: "SET_DELETE_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <div className='delete-view-main'>
            <img src={WarningIcon} alt="" />
            <p>{text}</p>
            <div className='buttons'>
                <button className='btn-white-outline' onClick={hideDeleteModel}>Cancel</button>
                <button className='primary-btn-blue' onClick={() => callback(id)}>Yes, Delete</button>
            </div>
        </div>
    );
};

export default DeleteView;
