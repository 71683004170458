import React, { useState, useRef } from "react";
import toast from 'react-hot-toast';
import ReactFlagsSelect from "react-flags-select";
import CountryCodes from "../../../CountryCodes.json";
import ReverseCountryCodes from "../../../ReverseCountryCodes.json";
import { useForm } from "react-hook-form";
import UserImage from "../../../Assets/icons/user-default.png";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit-white.svg";

import "./clientprofile.scss";
import { useStateValue } from "../../../StateProvider";
import { UpdateUserProfileImage, updateClientDetails } from "../../../apiCall";
import { domainName } from "../../../Constants";
import { SuccessModal } from "../../../Modals";

const ClientProfile = () => {
    const [, dispatch] = useStateValue();
    const fileRef = useRef();
    const profile_img = sessionStorage.getItem("profile_img");
    const [userImage, setUserImage] = useState(profile_img || UserImage);
    const [countryCode, setCountryCode] = useState(
        sessionStorage.getItem("country_code") ||
            localStorage.getItem("country_code")
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            phone_number:
                sessionStorage.getItem("phone_number") ||
                localStorage.getItem("phone_number"),
            email:
                sessionStorage.getItem("email") ||
                localStorage.getItem("email"),
        },
    });

    const showSuccessModal = () => {
        dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: true,
        });
    };

    const changeProfileImg = async (e) => {
        const imgData = new FormData();
        imgData.append("profile_img", e.target.files[0]);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await UpdateUserProfileImage(imgData);
        console.log(re);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);


        if (re.data?.status[0].Error === "False") {
            if (sessionStorage.getItem("token")) {
                sessionStorage.setItem(
                    "profile_img",
                    domainName + re.data.value?.profile_img
                );
                setUserImage(domainName + re.data.value?.profile_img);
            }
        } else {
            if (re.data?.status) {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const handleProfileSubmit = async (data) => {
        data.country_code = countryCode;
        console.log(data);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await updateClientDetails(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("UPDATE_CLIENT_DETAILS_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                if(re.data.status[0].ResponseMessage === "Unacceptable Parameter Value"){
                    const errorObj = re.data.status[0].Message;

                    Object.keys(errorObj).forEach((key) => {
                        setError(key, {
                            type: "pattern",
                            message: errorObj[key],
                        });
                    });
                }else{
                    toast.error(re.data.status[0].ResponseMessage);
                }
            } else {
                sessionStorage.setItem("token", re.data.access_token);
                sessionStorage.setItem("email", re.data.value?.email);
                sessionStorage.setItem("name", re.data.value?.name);
                sessionStorage.setItem(
                    "phone_number",
                    re.data.value?.phone_number
                );
                sessionStorage.setItem(
                    "country_code",
                    re.data.value?.country_code
                );
                sessionStorage.setItem(
                    "is_indian_user",
                    re.data.value?.is_indian_user
                );
                sessionStorage.setItem(
                    "profile_img",
                    domainName + "/uploads/" + re.data.value?.profile_img
                );
                sessionStorage.setItem("user_type", re.data.value?.user_type);
                showSuccessModal();
            }
        }
    };

    return (
        <div className='client-profile-main'>
            <div className='profile-container'>
                <div className='profile-image-controler'>
                    <span className='image-name-container'>
                        <span
                            className='image-container'
                            onClick={() => fileRef.current.click()}>
                            <input
                                type='file'
                                name=''
                                id=''
                                accept='image/*'
                                ref={fileRef}
                                onChange={(e) => changeProfileImg(e)}
                            />
                            <img src={userImage} alt='' />
                            <EditIcon className='edit-icon' />
                        </span>
                        <div className='personal-details'>
                            <p className='large'>
                                {localStorage.getItem("name") ||
                                    sessionStorage.getItem("name")}
                            </p>
                        </div>
                        <span className='online-tag'>Online</span>
                    </span>
                    <button
                        className='btn-blue'
                        onClick={handleSubmit(handleProfileSubmit)}>
                        Submit Profile
                    </button>
                </div>
                <div className='personal-info-container'>
                    <p className='title'>Personal Details</p>
                    <div className='input-grp'>
                        <p className='bold'>Phone Number</p>
                        <span className='phone'>
                            <ReactFlagsSelect
                                countries={Object.keys(CountryCodes)}
                                customLabels={CountryCodes}
                                placeholder=''
                                selected={ReverseCountryCodes[countryCode.split(" ").length > 1 ? countryCode.split(" ")[1] : countryCode]}
                                onSelect={(code) =>
                                    setCountryCode(CountryCodes[code])
                                }
                                searchable
                            />
                            <input
                                className='phone_input'
                                type='number'
                                placeholder='Phone number'
                                {...register("phone_number", {
                                    required: "required",
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: "Invalid phone number.",
                                    },
                                })}
                            />
                        </span>
                        {errors?.phone_number?.type === "required" && (
                            <p className='form-error'>
                                Phone number is required.
                            </p>
                        )}
                        {errors?.phone_number?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of name can not be more than 320
                            </p>
                        )}
                        {errors?.phone_number?.type === "pattern" && (
                            <p className='form-error'>
                                {errors?.phone_number?.message}
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Email</p>
                        <input
                            type='email'
                            placeholder='Email address'
                            {...register("email", {
                                required: "required",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Invalid email format.",
                                },
                            })}
                        />
                        {errors?.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                        {errors?.email?.type === "pattern" && (
                            <p className='form-error'>
                                {errors?.email?.message}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <SuccessModal text={"Your profile is updated..."} redirect_to_home={true} />
        </div>
    );
};

export default ClientProfile;
