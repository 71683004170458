import React, { useState } from "react";

import "./billing.scss";
import PayoutInfo from "./Components/PayoutInfo";
import BillingHistory from "./Components/BillingHistory";

const Billing = () => {
    const [activeTab, setActiveTab] = useState("payout-info");
    return (
        <div className='billing-name'>
            <div className='data-container'>
                <div className='heading'>
                    <span
                        className={`${
                            activeTab === "payout-info" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("payout-info")}>
                        <p>Payout info</p>
                        <span className='underline'></span>
                    </span>
                    <span
                        className={`${
                            activeTab === "billing-history" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("billing-history")}>
                        <p>Billing history</p>
                        <span className='underline'></span>
                    </span>
                </div>
                {activeTab === 'payout-info' && <PayoutInfo />}
                {activeTab === 'billing-history' && <BillingHistory />}
            </div>
        </div>
    );
};

export default Billing;
