import React, { useRef } from "react";

import "./freelancerProfile.scss";
import { ReactComponent as StarReview } from "../../../../Assets/icons/star.svg";
import { domainName } from "../../../../Constants";
import { createChatGroup } from "../../../../apiCall";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";

const FreelancerProfile = ({
    profileData,
    setShowUserProfile,
    freelancer_id,
}) => {
    const fileRef = useRef();
    const navigateTo = useNavigate()
    const [, dispatch] = useStateValue();


    const createGroup = async () => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await createChatGroup(freelancer_id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("CREATE_CHAT_GROUP_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                navigateTo('/chat?chat_group='+re.data.value)
            }
        }
    };
    console.log(profileData);
    return (
        <div className='profile-container'>
            <div className='profile-image-controler'>
                <span className='image-name-container'>
                    <span
                        className='image-container'
                        onClick={() => fileRef.current.click()}>
                        <img
                            src={
                                domainName +
                                "/uploads/" +
                                profileData?.profile_img
                            }
                            alt=''
                        />
                    </span>
                    <div className='personal-details'>
                        <p className='large'>{profileData?.name}</p>
                        <p>
                            {profileData?.gender} | {profileData?.country} |{" "}
                            {profileData?.language}
                        </p>
                    </div>
                </span>
                <div className='buttons'>
                    {!profileData?.hired_freelancer && (
                        <button className='btn-blue' onClick={() => setShowUserProfile(false)}>Hire Freelancer</button>
                    )}
                    <button className='btn-white-outline btn-blue' onClick={createGroup}>Chat</button>
                </div>
            </div>
            <div className='info-container'>
                <p className='info-title'>
                    {profileData?.service_category} | {profileData?.role}
                </p>
                <p className='info'>{profileData?.bio}</p>
                <div className='grp'>
                    <p className='bold'>Skills</p>
                    <div className='skills-container info'>
                        {profileData?.skills?.map((skill) => (
                            <span
                                className='skill'
                                key={"profile-skill" + skill.id}>
                                {skill.name}
                            </span>
                        ))}
                    </div>
                </div>
                <div className='grp'>
                    <p className='bold'>Experience</p>
                    <div className='experience-container info'>
                        {profileData?.work_experience?.map((exp, indx) => {
                            return (
                                <div
                                    className='experience'
                                    key={"work-experience-" + indx}>
                                    <div className='details'>
                                        <div className='heading'>
                                            <p>{exp.title}</p>
                                        </div>
                                        <p>
                                            {exp.company} | {exp.start_month}{" "}
                                            {exp.start_year}{" "}
                                            {exp.currently_working ? "" : "-"}{" "}
                                            {exp.end_month} {exp.end_year}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='grp'>
                    <p className='bold'>Education</p>
                    <div className='education-container info'>
                        {profileData?.education?.map((exp, indx) => {
                            return (
                                <div
                                    className='education'
                                    key={"education-" + indx}>
                                    <div className='details'>
                                        <div className='heading'>
                                            <p>{exp.college}</p>
                                        </div>
                                        <p>
                                            {exp.degree} {exp.start_year} -{" "}
                                            {exp.end_year}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {/* <div className='info-container'>
                <p className='info-title'>Proposal</p>
                <p className='info'>{profileData?.proposal}</p>
                <p className='info'>
                    Post will cost:{" "}
                    <span className='bold'>
                        {profileData?.currency_unit === "INR" ? "₹" : "$"}
                        {profileData?.amount}
                    </span>{" "}
                    and completes in:{" "}
                    <span className='bold'>{profileData?.duration}</span>
                </p>
            </div> */}
            {profileData?.reviews?.length > 0 && (
                <div className='info-container'>
                    <div className='text-container'>
                        <p className='bold'>
                            Freelancer's Recent History (
                            {profileData?.reviews?.length})
                        </p>
                        {profileData?.reviews?.map((review) => (
                            <div
                                className='user-review'
                                key={"user-review-" + review.id}>
                                <div className='ratings-container'>
                                    <p className='name'>{review.given_for}</p>
                                    <span className='ratings'>
                                        {Array(5)
                                            .fill(0)
                                            .map((ele, indx) => (
                                                <StarReview
                                                    key={
                                                        "user-review-ratings-" +
                                                        review.id +
                                                        "-" +
                                                        indx
                                                    }
                                                    className={`${
                                                        review.ratings > indx
                                                            ? "filled"
                                                            : ""
                                                    }`}
                                                />
                                            ))}
                                    </span>
                                </div>
                                <p className='review'>{review.review}</p>
                                <p className='client-name'>
                                    From Client:{" "}
                                    <span>{review.given_from__name}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FreelancerProfile;
