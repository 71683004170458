import React from "react";

import { ReactComponent as CheckedIcon } from "../../../../../Assets/icons/check-mark.svg";
import "./desktopTable.scss";
import { useStateValue } from "../../../../../StateProvider";

const DesktopTable = ({ formData, setFormData, showColumn, error }) => {
    const [{ currency_symbol }] = useStateValue();
    return (
        <div className='table-main'>
            <table>
                {/* *************Providing Services****************** */}
                <tr>
                    <td className='title'>Providing services</td>
                    {(showColumn === "all" || showColumn === "basic") && (
                        <td>
                            <span className='head'>BASIC</span>
                            {/* <div className='input-container'>
                                <input
                                    type='text'
                                    placeholder='Write title of your service'
                                    value={formData?.services?.basic?.title}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        title: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div> */}
                            <div className='input-container'>
                                <textarea
                                    type='text'
                                    placeholder='description'
                                    value={
                                        formData?.services?.basic?.description
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        description:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "standard") && (
                        <td>
                            <span className='head'>STANDARD</span>
                            {/* <div className='input-container'>
                                <input
                                    type='text'
                                    placeholder='Write title of your service'
                                    value={formData?.services?.standard?.title}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        title: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div> */}
                            <div className='input-container'>
                                <textarea
                                    type='text'
                                    placeholder='description'
                                    value={
                                        formData?.services?.standard
                                            ?.description
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        description:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "premium") && (
                        <td>
                            <span className='head'>PREMIIUM</span>
                            {/* <div className='input-container'>
                                <input
                                    type='text'
                                    placeholder='Write title of your service'
                                    value={formData?.services?.premium?.title}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        title: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div> */}
                            <div className='input-container'>
                                <textarea
                                    type='text'
                                    placeholder='description'
                                    value={
                                        formData?.services?.premium?.description
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        description:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                </tr>
                {/* *************Delivery times****************** */}
                <tr>
                    <td className='title'>Delivery times (In Days)</td>
                    {(showColumn === "all" || showColumn === "basic") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Delivery time'
                                    value={
                                        formData?.services?.basic?.delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        delivery_time:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "standard") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Delivery time'
                                    value={
                                        formData?.services?.standard
                                            ?.delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        delivery_time:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "premium") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Delivery time'
                                    value={
                                        formData?.services?.premium
                                            ?.delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        delivery_time:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                </tr>
                {/* *************Number of revisions****************** */}
                <tr>
                    <td className='title'>Number of revisions</td>
                    {(showColumn === "all" || showColumn === "basic") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Number of revisions'
                                    value={
                                        formData?.services?.basic
                                            ?.number_of_revisions
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        number_of_revisions:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "standard") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    placeholder='Number of revisions'
                                    min={0}
                                    value={
                                        formData?.services?.standard
                                            ?.number_of_revisions
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        number_of_revisions:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "premium") && (
                        <td>
                            <div className='input-container'>
                                <input
                                    type='number'
                                    placeholder='Number of revisions'
                                    min={0}
                                    value={
                                        formData?.services?.premium
                                            ?.number_of_revisions
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        number_of_revisions:
                                                            e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                </tr>
                {/* *************Price****************** */}
                <tr>
                    <td className='title'>Price</td>
                    {(showColumn === "all" || showColumn === "basic") && (
                        <td>
                            <div className='input-container'>
                                {currency_symbol}
                                <input
                                    type='number'
                                    min={0}
                                    value={formData?.services?.basic?.price}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        price: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "standard") && (
                        <td>
                            <div className='input-container'>
                                {currency_symbol}
                                <input
                                    type='number'
                                    min={0}
                                    value={formData?.services?.standard?.price}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        price: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                    {(showColumn === "all" || showColumn === "premium") && (
                        <td>
                            <div className='input-container'>
                                {currency_symbol}
                                <input
                                    type='number'
                                    min={0}
                                    value={formData?.services?.premium?.price}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        price: e.target.value,
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                            </div>
                        </td>
                    )}
                </tr>
                <p className='form-error'>{error}</p>
            </table>
            <p className='bold'>Extra Service</p>
            <div className='check-box-container'>
                <span
                    className={`check-box ${
                        formData.has_extra_fast_delivery ? "checked" : ""
                    }`}
                    onClick={() =>
                        setFormData((pre) => {
                            return {
                                ...pre,
                                has_extra_fast_delivery:
                                    !pre.has_extra_fast_delivery,
                            };
                        })
                    }>
                    <CheckedIcon />
                </span>
                <p>Extra fast delivery</p>
            </div>
            {formData.has_extra_fast_delivery && (
                <div className='fast-delivery'>
                    <div className='plan-details'>
                        <p className='plan-name'>Basic</p>
                        <div className='details'>
                            <div className='input-container'>
                                <span>I will deliver in only</span>
                                <input
                                    type='number'
                                    min={0}
                                    value={
                                        formData?.services?.basic?.extra_service
                                            ?.special_delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    basic: {
                                                        ...pre.services.basic,
                                                        extra_service: {
                                                            ...pre.services
                                                                .basic
                                                                .extra_service,
                                                            special_delivery_time:
                                                                e.target.value,
                                                        },
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                                <span>days</span>
                            </div>
                            <div className='input-container'>
                                <span>For an extra</span>
                                <span className='price-container'>
                                    <input
                                        type='number'
                                        min={0}
                                        value={
                                            formData?.services?.basic
                                                ?.extra_service?.extra_charge
                                        }
                                        onChange={(e) =>
                                            setFormData((pre) => {
                                                return {
                                                    ...pre,
                                                    services: {
                                                        ...pre.services,
                                                        basic: {
                                                            ...pre.services
                                                                .basic,
                                                            extra_service: {
                                                                ...pre.services
                                                                    .basic
                                                                    .extra_service,
                                                                extra_charge:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    },
                                                };
                                            })
                                        }
                                    />
                                    {currency_symbol}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='plan-details'>
                        <p className='plan-name'>Standard</p>
                        <div className='details'>
                            <div className='input-container'>
                                <span>I will deliver in only</span>
                                <input
                                    type='number'
                                    min={0}
                                    value={
                                        formData?.services?.standard
                                            ?.extra_service
                                            ?.special_delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    standard: {
                                                        ...pre.services
                                                            .standard,
                                                        extra_service: {
                                                            ...pre.services
                                                                .standard
                                                                .extra_service,
                                                            special_delivery_time:
                                                                e.target.value,
                                                        },
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                                <span>days</span>
                            </div>
                            <div className='input-container'>
                                <span>For an extra</span>
                                <span className='price-container'>
                                    <input
                                        type='number'
                                        min={0}
                                        value={
                                            formData?.services?.standard
                                                ?.extra_service?.extra_charge
                                        }
                                        onChange={(e) =>
                                            setFormData((pre) => {
                                                return {
                                                    ...pre,
                                                    services: {
                                                        ...pre.services,
                                                        standard: {
                                                            ...pre.services
                                                                .standard,
                                                            extra_service: {
                                                                ...pre.services
                                                                    .standard
                                                                    .extra_service,
                                                                extra_charge:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    },
                                                };
                                            })
                                        }
                                    />
                                    {currency_symbol}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='plan-details'>
                        <p className='plan-name'>Premium</p>
                        <div className='details'>
                            <div className='input-container'>
                                <span>I will deliver in only</span>
                                <input
                                    type='number'
                                    min={0}
                                    value={
                                        formData?.services?.premium
                                            ?.extra_service
                                            ?.special_delivery_time
                                    }
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                services: {
                                                    ...pre.services,
                                                    premium: {
                                                        ...pre.services.premium,
                                                        extra_service: {
                                                            ...pre.services
                                                                .premium
                                                                .extra_service,
                                                            special_delivery_time:
                                                                e.target.value,
                                                        },
                                                    },
                                                },
                                            };
                                        })
                                    }
                                />
                                <span>days</span>
                            </div>
                            <div className='input-container'>
                                <span>For an extra</span>
                                <span className='price-container'>
                                    <input
                                        type='number'
                                        min={0}
                                        value={
                                            formData?.services?.premium
                                                ?.extra_service?.extra_charge
                                        }
                                        onChange={(e) =>
                                            setFormData((pre) => {
                                                return {
                                                    ...pre,
                                                    services: {
                                                        ...pre.services,
                                                        premium: {
                                                            ...pre.services
                                                                .premium,
                                                            extra_service: {
                                                                ...pre.services
                                                                    .premium
                                                                    .extra_service,
                                                                extra_charge:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        },
                                                    },
                                                };
                                            })
                                        }
                                    />
                                    {currency_symbol}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DesktopTable;
