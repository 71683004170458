import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

import UserImage from "../../../Assets/icons/user-default.png";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit-white.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/icons/delete.svg";
import { ReactComponent as ExpandIcon } from "../../../Assets/icons/expand.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close.svg";
import PdfIcon from "../../../Assets/icons/pdf.svg";

import "./profile.scss";
import {
    BioEditModal,
    EducationModal,
    OriginEditModal,
    PortfolioEditModal,
    RateEditModal,
    RoleEditModal,
    SkillsEditModal,
    SuccessModal,
    WorkExperienceModal,
} from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import {
    GetFreelancerData,
    GetSkillsList,
    UpdateFreelancerData,
    UpdateUserProfileImage,
} from "../../../apiCall";
import { CACHE_TIME, domainName } from "../../../Constants";

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];
const VIDEO_EXTENSIONS = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "3gp",
    "ogv",
    "rmvb",
    "m4v",
    "ts",
    "mxf",
    "divx",
];
const Profile = ({ formData, setFormData, is_profile_review = false }) => {
    const [{ currency_symbol }, dispatch] = useStateValue();
    const fileRef = useRef();
    const [profileData, setProfileData] = useState(formData);
    const profile_img = sessionStorage.getItem("profile_img");
    const [userImage, setUserImage] = useState(profile_img || UserImage);
    const [experience_index, setExperienceIndex] = useState(-1);
    const [education_index, setEducationIndex] = useState(-1);
    // const [uploadedVideo, setUploadedVideo] = useState();
    const [isEducationExpanded, setIsEducationExpanded] = useState(false);
    const [isWorkExpanded, setIsWorkExpanded] = useState(false);
    const [skillsList, setSkillsList] = useState([]);

    useQuery(["get-skills"], () => GetSkillsList(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.status[0].Error === "False") {
                setSkillsList(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    const setRoleEditModalShow = () => {
        dispatch({
            type: "SET_ROLE_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const setSkillsEditModalShow = () => {
        dispatch({
            type: "SET_SKILLS_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const setBioEditModalShow = () => {
        dispatch({
            type: "SET_BIO_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const setRateEditModalShow = () => {
        dispatch({
            type: "SET_RATE_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const showWorkExperienceModal = () => {
        dispatch({
            type: "SET_WORK_EXPERIENCE_SHOW",
            status: true,
        });
    };
    const showEducationModal = () => {
        dispatch({
            type: "SET_EDUCATION_MODAL_SHOW",
            status: true,
        });
    };
    const showOriginEditModal = () => {
        dispatch({
            type: "SET_ORIGIN_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const showProfileEditModal = () => {
        dispatch({
            type: "SET_PORTFOLIO_EDIT_MODAL_SHOW",
            status: true,
        });
    };
    const showSuccessModal = () => {
        dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: true,
        });
    };

    const changeProfileImg = async (e) => {
        const imgData = new FormData();
        imgData.append("profile_img", e.target.files[0]);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const Re = await UpdateUserProfileImage(imgData);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            if (sessionStorage.getItem("token")) {
                sessionStorage.setItem(
                    "profile_img",
                    domainName + Re.data.value?.profile_img
                );
                setUserImage(domainName + Re.data.value?.profile_img);
            }
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const handleProfileSubmit = async () => {
        const data = {
            role: profileData?.role,
            skills: profileData?.skills,
            experience: profileData?.work_experience,
            education: profileData?.education,
            service_category: profileData?.service_category,
            services: profileData?.services,
            bio: profileData?.bio,
            gender: profileData?.gender,
            country: profileData?.country,
            languages: profileData?.language,
            hourly_rate: profileData?.hourly_rate,
            portfolio_url: profileData?.yt_video_link,
        };
        console.log(data);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await UpdateFreelancerData(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("UPDATE_FREELANCER_DETAILS_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                dispatch({
                    type: "SET_HAS_OTHER_DETAILS",
                    status: true,
                });
                sessionStorage.setItem("have_other_details", true);
                showSuccessModal();
            }
        }
    };

    useEffect(() => {
        if (is_profile_review) {
            setProfileData(formData);
        } else {
            // fetch profile details and set it to setProfile Data.
            const getData = async () => {
                dispatch({ type: "SET_IS_LOADING", status: true });

                const re = await GetFreelancerData();
                setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                console.log("GET_FREELANCER_DETAILS_RESPONSE", re, re.status);
                if (re.status !== 200) {
                    toast.error(
                        "Something went wrong, Please reload the page."
                    );
                } else {
                    if (re.data.status[0].Error === "True") {
                        toast.error(re.data.status[0].ResponseMessage);
                    } else {
                        console.log(re.data.value);
                        re.data.value.portfolio = re.data.value.portfolio
                            ? domainName + "/uploads/" + re.data.value.portfolio
                            : "";
                        console.log(re.data.value);
                        setProfileData(re.data.value);
                    }
                }
            };
            getData();
        }
    }, [formData, is_profile_review, dispatch]);

    function getYouTubeVideoId(url) {
        // Regular expression to match YouTube video ID in various URL formats
        var regExp =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Extract the video ID using the regular expression
        var match = url?.match(regExp);

        // If a match is found, return the video ID; otherwise, return null
        return match ? match[1] : null;
    }
    return (
        <div className='profile-main'>
            <div className='profile-container'>
                <div className='profile-image-controler'>
                    <span className='image-name-container'>
                        <span
                            className='image-container'
                            onClick={() => fileRef.current.click()}>
                            <input
                                type='file'
                                name=''
                                id=''
                                accept='image/*'
                                ref={fileRef}
                                onChange={(e) => changeProfileImg(e)}
                            />
                            <img src={userImage} alt='' />
                            <EditIcon className='edit-icon' />
                        </span>
                        <div className='personal-details'>
                            <p className='large'>
                                {localStorage.getItem("name") ||
                                    sessionStorage.getItem("name")}
                            </p>
                            <p>
                                {localStorage.getItem("email") ||
                                    sessionStorage.getItem("email")}
                            </p>
                            <p>
                                {localStorage.getItem("country_code") ||
                                    sessionStorage.getItem("country_code")}
                                {localStorage.getItem("phone_number") ||
                                    sessionStorage.getItem("phone_number")}
                            </p>
                        </div>
                        <span className='online-tag'>Online</span>
                    </span>
                    <button className='btn-blue' onClick={handleProfileSubmit}>
                        Submit Profile
                    </button>
                </div>
                <div className='info-container role'>
                    <div className='info-head'>
                        <p className='info-title'>{profileData?.role}</p>
                        <EditIcon
                            className='edit-icon'
                            onClick={setRoleEditModalShow}
                        />
                    </div>
                </div>
                <div className='info-container skills'>
                    <div className='info-head'>
                        <p className='info-title'>Skills</p>
                        <EditIcon
                            className='edit-icon'
                            onClick={setSkillsEditModalShow}
                        />
                    </div>
                    <div className='skills-container info'>
                        {profileData?.skills.map((skill) => (
                            <span
                                className='skill'
                                key={"profile-skill" + skill.id}>
                                <Close />
                                {skill.name}
                            </span>
                        ))}
                    </div>
                </div>
                <div className='info-container bio'>
                    <div className='info-head'>
                        <p className='info-title'>Bio</p>
                        <EditIcon
                            className='edit-icon'
                            onClick={setBioEditModalShow}
                        />
                    </div>
                    <p className='info'>{profileData?.bio}</p>
                </div>
                <div className='info-container rates'>
                    <div className='info-head'>
                        <p className='info-title'>
                            {currency_symbol}
                            {profileData?.hourly_rate}/hr
                        </p>
                        <EditIcon
                            className='edit-icon'
                            onClick={setRateEditModalShow}
                        />
                    </div>
                </div>
                <div className='info-container work-history'>
                    <div
                        className='info-head'
                        onClick={() => setIsWorkExpanded((pre) => !pre)}>
                        <p className='info-title'>Work History</p>
                        {/* <EditIcon className='edit-icon' /> */}
                        <ExpandIcon
                            className={`expand-icon ${
                                isWorkExpanded ? "expanded" : ""
                            }`}
                        />
                    </div>
                    {isWorkExpanded && (
                        <div className='experience-container info'>
                            {profileData?.work_experience.map((exp, indx) => {
                                return (
                                    <div
                                        className='experience'
                                        key={"work-experience-" + indx}>
                                        <div className='details'>
                                            <div className='heading'>
                                                <p>{exp.title}</p>
                                            </div>
                                            <p>
                                                {exp.company} |{" "}
                                                {exp.start_month}{" "}
                                                {exp.start_year}{" "}
                                                {exp.currently_working
                                                    ? ""
                                                    : "-"}{" "}
                                                {exp.end_month} {exp.end_year}
                                            </p>
                                        </div>
                                        <div className='icons'>
                                            <EditIcon
                                                onClick={() => {
                                                    setExperienceIndex(indx);
                                                    showWorkExperienceModal();
                                                }}
                                            />
                                            <DeleteIcon
                                                onClick={() => {
                                                    setProfileData((pre) => {
                                                        console.log(
                                                            "HII",
                                                            pre.work_experience,
                                                            indx
                                                        );
                                                        const new_work_experience =
                                                            pre.work_experience.filter(
                                                                (
                                                                    exp,
                                                                    exp_indx
                                                                ) =>
                                                                    exp_indx !==
                                                                    indx
                                                            );
                                                        console.log(
                                                            new_work_experience
                                                        );
                                                        return {
                                                            ...pre,
                                                            work_experience:
                                                                new_work_experience,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            <div
                                className='add-experience'
                                onClick={() => {
                                    setExperienceIndex(-1);
                                    showWorkExperienceModal();
                                }}>
                                <Close />
                            </div>
                        </div>
                    )}
                </div>
                <div className='info-container education-history'>
                    <div
                        className='info-head'
                        onClick={() => setIsEducationExpanded((pre) => !pre)}>
                        <p className='info-title'>Education</p>
                        {/* <EditIcon className='edit-icon' /> */}
                        <ExpandIcon
                            className={`expand-icon ${
                                isEducationExpanded ? "expanded" : ""
                            }`}
                        />
                    </div>
                    {isEducationExpanded && (
                        <div className='education-container info'>
                            {console.log(profileData)}
                            {profileData?.education.map((exp, indx) => {
                                return (
                                    <div
                                        className='education'
                                        key={"education-" + indx}>
                                        <div className='details'>
                                            <div className='heading'>
                                                <p>{exp.college}</p>
                                            </div>
                                            <p>
                                                {exp.degree === 'Other' ? exp.otherCertificate : exp.degree} {exp.start_year} -{" "}
                                                {exp.end_year}
                                            </p>
                                            {exp.certificate_url && <a href={exp.certificate_url} target="__blank">View Certificate</a>}
                                        </div>
                                        <div className='icons'>
                                            <EditIcon
                                                onClick={() => {
                                                    setEducationIndex(indx);
                                                    showEducationModal();
                                                }}
                                            />
                                            <DeleteIcon
                                                onClick={() => {
                                                    setProfileData((pre) => {
                                                        console.log(
                                                            "HII",
                                                            pre.education,
                                                            indx
                                                        );
                                                        const new_education =
                                                            pre.education.filter(
                                                                (
                                                                    exp,
                                                                    exp_indx
                                                                ) =>
                                                                    exp_indx !==
                                                                    indx
                                                            );
                                                        console.log(
                                                            new_education
                                                        );
                                                        return {
                                                            ...pre,
                                                            education:
                                                                new_education,
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                            <div
                                className='add-education'
                                onClick={() => {
                                    setEducationIndex(-1);
                                    showEducationModal();
                                }}>
                                <Close />
                            </div>
                        </div>
                    )}
                </div>
                <div className='info-container origin-details'>
                    <div className='info-head'>
                        <p className='info-title'>Original details</p>
                        <EditIcon
                            className='edit-icon'
                            onClick={showOriginEditModal}
                        />
                    </div>
                    <div className='details info'>
                        <p>
                            Gender: <span>{profileData?.gender}</span>
                        </p>
                        <p>
                            Country: <span>{profileData?.country}</span>
                        </p>
                        <p>
                            Language Proficiency:{" "}
                            <span>{profileData?.language}</span>
                        </p>
                    </div>
                </div>
                <div className='info-container portfolio'>
                    <div className='info-head'>
                        <p className='info-title'>Portfolio</p>
                        <EditIcon
                            className='edit-icon'
                            onClick={showProfileEditModal}
                        />
                    </div>
                    {(profileData?.portfolio || profileData?.yt_video_link) && (
                        <div className='info'>
                            {profileData?.portfolio === "" ? (
                                <iframe
                                    width='560'
                                    height='315'
                                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                                        profileData?.yt_video_link
                                    )}?si=KP7ZYISL4EKewGQV&amp;controls=0`}
                                    title='YouTube video player'
                                    frameborder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowfullscreen></iframe>
                            ) : VIDEO_EXTENSIONS.includes(
                                  profileData?.portfolio?.split(".").at(-1)
                              ) ? (
                                <video width='400' controls>
                                    <source
                                        src={profileData.portfolio}
                                        type='video/mp4'
                                    />
                                    Your browser does not support HTML video.
                                </video>
                            ) : IMAGE_EXTENSIONS.includes(
                                  profileData?.portfolio?.split(".").at(-1)
                              ) ? (
                                <img src={profileData.portfolio} className="portfolio-img" alt='' />
                            ) : (
                                <span
                                    className='attachment'
                                    onClick={() =>
                                        window.open(
                                            profileData?.portfolio,
                                            "_blank"
                                        )
                                    }>
                                    <img src={PdfIcon} alt='' />
                                    <p>
                                        {profileData?.portfolio?.split("/").at(-1)}
                                    </p>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <RoleEditModal
                formData={profileData}
                setFormData={setProfileData}
            />
            <SkillsEditModal
                formData={profileData}
                skillsList={skillsList}
                setFormData={setProfileData}
            />
            <BioEditModal formData={profileData} setFormData={setProfileData} />
            <RateEditModal
                formData={profileData}
                setFormData={setProfileData}
            />
            <WorkExperienceModal
                formData={profileData}
                setFormData={setProfileData}
                experience_index={experience_index}
            />
            <EducationModal
                formData={profileData}
                setFormData={setProfileData}
                education_index={education_index}
            />
            <OriginEditModal
                formData={profileData}
                setFormData={setProfileData}
            />
            <PortfolioEditModal
                formData={profileData}
                setFormData={setProfileData}
            />
            <SuccessModal
                text={"Yeah! You have successfully updated your profile."}
                redirect_to_home={true}
            />
        </div>
    );
};

export default Profile;
