import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { useStateValue } from "../../../../StateProvider";
import PdfIcon from "../../../../Assets/icons/pdf.svg";

import "./approveWork.scss";
import { domainName } from "../../../../Constants";
import { reiterateJob } from "../../../../apiCall";
import toast from 'react-hot-toast';

const ApproveWork = ({ proposal_details, callback, giveOptions }) => {
    console.log(giveOptions)
    const [, dispatch] = useStateValue();
    const [isReiterate, setIsReiterate] = useState(false);
    const [date, setDate] = useState("");
    const [startMonth, setStartMonth] = useState("");
    const [year, setYear] = useState("");
    const [client_update, setClientUpdate] = useState("");
    const hideWorkApproveModal = () => {
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: false,
        });
    };
    const handleSubmit = () => {
        hideWorkApproveModal();
        dispatch({
            type: "SET_RELEASE_FUND_MODAL_SHOW",
            status: true,
        });
    };
    const handleReiterate = async () => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await reiterateJob(proposal_details?.id, {
            day: date,
            month: startMonth,
            year: year,
            description: client_update,
        });
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);


        console.log("JOB_REITERATE_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                hideWorkApproveModal();
                callback();
            }
        }
    };

    console.log(proposal_details);
    return (
        <div className='approve-work-main'>
            <div className='header'>
                <p>Evaluate work</p>
                <CloseIcon onClick={hideWorkApproveModal} />
            </div>
            <div className='content'>
                {isReiterate ? (
                    <p className='bold'>
                        Mention the changes you wants to get done.
                    </p>
                ) : (
                    <p className='bold'>Work Description</p>
                )}
                {isReiterate ? (
                    <textarea
                        cols='30'
                        value={client_update}
                        onChange={(e) =>
                            setClientUpdate(e.target.value)
                        }></textarea>
                ) : (
                    <div className='description'>
                        <p>{proposal_details?.work_description}</p>
                    </div>
                )}
                {isReiterate ? (
                    <div className='input-grp'>
                        <p className='bold'>New End Date*</p>
                        <div className='inputs'>
                            <select
                                value={date}
                                onChange={(e) => setDate(e.target.value)}>
                                <option value='' disabled>
                                    Date
                                </option>
                                {new Array(31).fill(0).map((ele, indx) => (
                                    <option value={indx+1} key={"date-" + indx}>
                                        {indx + 1}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={startMonth}
                                onChange={(e) => setStartMonth(e.target.value)}>
                                <option value='' disabled>
                                    Month
                                </option>
                                <option value={1}>January</option>
                                <option value={2}>February</option>
                                <option value={3}>March</option>
                                <option value={4}>April</option>
                                <option value={5}>May</option>
                                <option value={6}>June</option>
                                <option value={7}>July</option>
                                <option value={8}>August</option>
                                <option value={9}>September</option>
                                <option value={10}>October</option>
                                <option value={11}>November</option>
                                <option value={12}>December</option>
                            </select>
                            <select
                                value={year}
                                onChange={(e) => setYear(e.target.value)}>
                                <option value='' disabled>
                                    Year
                                </option>
                                {Array(3)
                                    .fill(0)
                                    .map((num, indx) => (
                                        <option key={"start-year-" + indx}>
                                            {new Date().getFullYear() + indx}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                ) : (
                    <div className='grp'>
                        {proposal_details?.attachments?.filter(attach => attach?.link !== "").map((attach, indx) => (
                            <span
                                className='attachment'
                                key={"attachment-" + attach.id}
                                onClick={() =>
                                    window.open(
                                        domainName + "/uploads/" + attach.link,
                                        "_blank"
                                    )
                                }>
                                <img src={PdfIcon} alt='' />
                                <p>{attach.file_name}</p>
                            </span>
                        ))}
                    </div>
                )}
            </div>
            {giveOptions && <div className='buttons'>
                {isReiterate ? (
                    <>
                        <button
                            className='primary-btn-white'
                            onClick={() => setIsReiterate(false)}>
                            Cancle
                        </button>
                        <button className='btn-blue' onClick={handleReiterate}>
                            Reiterate
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            className='primary-btn-white'
                            onClick={() => setIsReiterate(true)}>
                            Reiterate
                        </button>
                        <button className='btn-blue' onClick={handleSubmit}>
                            Approve
                        </button>
                    </>
                )}
            </div>}
        </div>
    );
};

export default ApproveWork;
