import React, { useEffect, useState } from "react";

import "./purchaseDetails.scss";
import ExpandIcon from "../../../../Assets/icons/expand.svg";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { useStateValue } from "../../../../StateProvider";

const PurchaseDetails = ({
    data,
    currency,
    jobDetail,
    appliedCoupon,
    setCurrentView,
    setAppliedCoupon,
}) => {
    const [taxCommission, setTaxCommission] = useState();
    const [
        {
            tax,
            commission_for_free_client,
            thresold_for_commission,
            commission_for_member_client,
            commission_for_member_client_with_cost_greater_than_thresold,
        },
    ] = useStateValue();

    useEffect(() => {
        setTaxCommission({
            taxPercentage: tax,
            commission:
                sessionStorage.getItem("is_premium_user") === "true"
                    ? data?.amount > thresold_for_commission
                        ? commission_for_member_client_with_cost_greater_than_thresold
                        : commission_for_member_client
                    : commission_for_free_client,
        });
    }, [
        tax,
        commission_for_free_client,
        thresold_for_commission,
        commission_for_member_client,
        commission_for_member_client_with_cost_greater_than_thresold,
        data?.amount,
    ]);
    return (
        <div className='purchase-details-main'>
            <div className='details'>
                <div className='description'>
                    <div className='text'>
                        <p className='gray-bold'>{jobDetail?.title}</p>
                        <p className='desc'>{jobDetail?.description}</p>
                    </div>
                    <p className='price'>
                        {currency}
                        {data?.amount}
                    </p>
                </div>
                <hr />
                <div className='count-manage'>
                    {/* <p className='bold'>How often do you need this order?</p>
                    <div className='item-container'>
                        <div className='single-price'>
                            <p className='black-bold'>Price</p>
                            <p className='price'>
                                {currency}
                                {data?.amount}
                            </p>
                        </div>
                    </div> */}
                    <div className='item-container'>
                        <div className='coupon-code-container'>
                            <span className='text'>
                                <p className='bold'>Apply coupons</p>
                                {appliedCoupon?.code ? (
                                    <p className='info'>
                                        <span className='code'>
                                            {appliedCoupon?.code}
                                        </span>{" "}
                                        Applied which saves you {currency}
                                        {Math.min(
                                            appliedCoupon?.maximum_discount_amount,
                                            (data?.amount *
                                                appliedCoupon?.discount) /
                                                100
                                        )}
                                    </p>
                                ) : (
                                    <p className='info'>
                                        Use coupons to get offers.
                                    </p>
                                )}
                            </span>
                            {appliedCoupon ? (
                                <span
                                    className='remove-coupon icon-container'
                                    onClick={() => setAppliedCoupon(undefined)}>
                                    <p>REMOVE</p>
                                    <CloseIcon />
                                </span>
                            ) : (
                                <span
                                    className='expand-icon-container icon-container'
                                    onClick={() =>
                                        setCurrentView("select-coupon")
                                    }>
                                    <img src={ExpandIcon} alt='' />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='payment'>
                        <p className='bold'>Payment details</p>
                        <div className='calculation'>
                            <span>
                                <p className='title'>Total MRP</p>
                                <p className='value'>
                                    {currency}
                                    {data?.amount}
                                </p>
                            </span>
                            {appliedCoupon && (
                                <span>
                                    <p className='title'>Coupon Discount</p>
                                    <p className='value'>
                                        -{currency}
                                        {Math.min(
                                            appliedCoupon?.maximum_discount_amount,
                                            (data?.amount *
                                                appliedCoupon?.discount) /
                                                100
                                        )}
                                    </p>
                                </span>
                            )}
                            <span>
                                <p className='title'>Taxes</p>
                                <p className='value'>
                                    {currency}
                                    {(
                                        (data?.amount *
                                            taxCommission?.taxPercentage) /
                                        100
                                    ).toFixed(2)}
                                </p>
                            </span>
                            <span>
                                <p className='title'>
                                    Rysovate Service Charges
                                </p>
                                <p className='value'>
                                    {currency}
                                    {(
                                        (data?.amount *
                                            taxCommission?.commission) /
                                        100
                                    ).toFixed(2)}
                                </p>
                            </span>
                        </div>
                        <hr />
                        <span>
                            <p className='title'>Total MRP</p>
                            <p className='value'>
                                {currency}
                                {data?.amount -
                                    (appliedCoupon?.discount
                                        ? Math.min(
                                              appliedCoupon?.maximum_discount_amount,
                                              (data?.amount *
                                                  appliedCoupon?.discount) /
                                                  100
                                          )
                                        : 0) +
                                    (data?.amount *
                                        taxCommission?.taxPercentage) /
                                        100 +
                                    (data?.amount * taxCommission?.commission) /
                                        100}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseDetails;
