export const googleClinetId = "382972840153-fm330pgpacmluabcfsa1ojne4c1i7i62.apps.googleusercontent.com"

export const STALE_TIME = 1800000 // Data is considered fresh for 30 minutes (in milliseconds)
export const CACHE_TIME = 7200000 // Data will be cached for 2 hour (in milliseconds)

// export const domainName = "http://localhost:8000"
export const domainName = "https://www.api.rysovate.net"
// export const domainName = "https://rysovate.onrender.com"

// export const webSocketDomain = "ws://127.0.0.1:8000"
export const webSocketDomain = "wss://api.rysovate.net"

export const api = {
  signup: '/api/signup',
  login: '/api/login',
  send_otp: '/api/otp',
  verify_otp: '/api/verify-otp',
  forgot_password: '/api/resetPassword',
  google_login: "/api/verify/google_access_token",
  add_basic_details: '/api/user/add-basic-details',
  update_client_details: '/api/user/update-client-user-info',
  get_skills: '/api/user/skills',
  get_service: '/api/user/service',
  freelancer_data: '/api/user/freelancer-details',
  freelancer_list: '/api/user',
  update_profile_img: '/api/update-user-profile-img',
  update_portfolio: '/api/user/update-freelancer-portfolio',
  update_certificate: '/api/user/update-freelancer-certificate',
  update_saved_jobs: '/api/user/update-saved-jobs',
  update_saved_freelancers: '/api/user/update-saved-freelancers',
  switch_user: '/api/user/switch_user',
  get_categories: '/api/job/categories',
  job: '/api/job',
  get_saved_jobs: '/api/job/saved_jobs',
  get_saved_freelancers: '/api/job/saved_freelancers',
  get_past_jobs: '/api/job/past_jobs',
  job_details: '/api/job/job-details',
  get_freelancer_jobs: '/api/job/freelancer-jobs',
  get_client_jobs: '/api/job/client-jobs',
  get_client_packages: '/api/job/client-packages',
  bid: '/api/job/bid',
  package_proposal: '/api/job/package-proposal',
  update_cover_img: '/api/offerpackage/update-coverpage',
  offerpackage: '/api/offerpackage/',
  reviews: '/api/user/reviews',
  job_feedback: '/api/user/job-review',
  freelancer_feedback: '/api/user/freelancer-review',
  my_offers: '/api/offerpackage/my-offers',
  offers: '/api/offerpackage/offers',
  coupon_codes_list: '/api/offerpackage/coupon-codes',
  work_documents: '/api/job/work-documents',
  work_description: '/api/job/work-description',
  reiterate_job: '/api/job/reiterate',
  hire_freelancer: '/api/job/hire_freelancer',
  send_proposal_to_freelancer: '/api/job/job-proposal-to-freelancer',
  mark_job_completed: '/api/job/mark-completed',
  update_proposal_status: '/api/job/change-proposal-status',
  create_chat_group: '/api/chat/create-group',
  get_chat_group: '/api/chat/get-chat-group',
  get_chat_messages: '/api/chat/get-messages',
  send_chat_document: '/api/chat/send-file',
  payment: "/api/payment",
  complete_payment: "/api/payment/complete-payment",
  notifications: "/api/user/notifications",
  contact: "/api/contact",
  privacy_policy: "/api/privacy-policy/",
  terms_and_conditions: "/api/terms-and-conditions/",
  faqs: "/api/faqs/",
  payoutMethod: "/api/payment/payout-method",
  membership_plans: "/api/membership-plans",
  user_stories: "/api/get-user-stories",
  aboutus: "/api/aboutus/",
  subscriptionPayment: "/api/payment/subscription",
  subscriptionPaymentStatus: "/api/payment/subscription/status",
};
