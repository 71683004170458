import React, { useState } from "react";

import "./step2.scss";
import { useStateValue } from "../../../../../StateProvider";

const Step2 = ({ formData, setFormData, setPageNo, countries }) => {
    const [{ currency_symbol }] = useStateValue();
    const [errors, setErrors] = useState({});

    const handleNext = () => {
        console.log(formData?.budget_upper_bound)
        if (!formData?.budget_lower_bound || !formData?.budget_upper_bound) {
            setErrors((pre) => {
                return { ...pre, budget: "Budget is required field." };
            });
            return;
        } else if (
            formData?.budget_lower_bound <= 0 ||
            formData?.budget_upper_bound <= 0 ||
            formData?.budget_lower_bound > formData?.budget_upper_bound
        ) {
            setErrors((pre) => {
                return {
                    ...pre,
                    budget: "Lower bound and upper bound of budget must be greater than zero and upper bound of budget must be more than lower bound..",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, budget: "" };
            });
        }

        setPageNo((pre) => pre + 1);
    };
    return (
        <>
            <div className='job-post-step-2-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 2 of 3</p>
                            <span>Job Post</span>
                        </span>
                        <p className='title'>Estimate scope of your work.</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>How long will your work take?</p>
                        <div
                            className='radio-grp'
                            onClick={() =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        duration: "More than 9 months",
                                    };
                                })
                            }>
                            <span
                                className={`my-radio ${
                                    formData.duration === "More than 9 months"
                                        ? "selected"
                                        : ""
                                }`}>
                                <span className='select-mark'></span>
                            </span>
                            <p>More than 9 months</p>
                        </div>
                        <div
                            className='radio-grp'
                            onClick={() =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        duration: "Between 6 to 9 months",
                                    };
                                })
                            }>
                            <span
                                className={`my-radio ${
                                    formData.duration ===
                                    "Between 6 to 9 months"
                                        ? "selected"
                                        : ""
                                }`}>
                                <span className='select-mark'></span>
                            </span>
                            <p>Between 6 to 9 months</p>
                        </div>
                        <div
                            className='radio-grp'
                            onClick={() =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        duration: "Between 3 to 6 months",
                                    };
                                })
                            }>
                            <span
                                className={`my-radio ${
                                    formData.duration ===
                                    "Between 3 to 6 months"
                                        ? "selected"
                                        : ""
                                }`}>
                                <span className='select-mark'></span>
                            </span>
                            <p>Between 3 to 6 months</p>
                        </div>
                        <div
                            className='radio-grp'
                            onClick={() =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        duration: "Less than 3 months",
                                    };
                                })
                            }>
                            <span
                                className={`my-radio ${
                                    formData.duration === "Less than 3 months"
                                        ? "selected"
                                        : ""
                                }`}>
                                <span className='select-mark'></span>
                            </span>
                            <p>Less than 3 months</p>
                        </div>
                    </div>
                    <div className='budget-input'>
                        <div className='heading'>
                            <h3>Tell us about Budget of project</h3>
                        </div>
                        <div className='input-grp'>
                            <div className='input-container'>
                                <span className='symbol'>
                                    {currency_symbol}
                                </span>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Min'
                                    value={formData?.budget_lower_bound}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                budget_lower_bound:
                                                    e.target.value,
                                            };
                                        })
                                    }
                                />
                            </div>
                            <hr />
                            <div className='input-container'>
                                <span className='symbol'>
                                    {currency_symbol}
                                </span>
                                <input
                                    type='number'
                                    min={0}
                                    placeholder='Max'
                                    value={formData?.budget_upper_bound}
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                budget_upper_bound:
                                                    e.target.value,
                                            };
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <p className='form-error'>{errors?.budget}</p>
                    </div>
                    <div className='input-grp'>
                        <div className='heading'>
                            <h3>Hiring mode</h3>
                            <p className='info'>
                                Physical mode is only available for premium
                                users*
                            </p>
                        </div>
                        {sessionStorage.getItem("is_premium_user") ===
                            "true" && (
                            <div
                                className='radio-grp'
                                onClick={() =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            hiring_mode: "physical",
                                        };
                                    })
                                }>
                                <span
                                    className={`my-radio ${
                                        formData.hiring_mode === "physical"
                                            ? "selected"
                                            : ""
                                    }`}>
                                    <span className='select-mark'></span>
                                </span>
                                <p>Physical</p>
                            </div>
                        )}
                        <div
                            className='radio-grp'
                            onClick={() =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        hiring_mode: "remote",
                                    };
                                })
                            }>
                            <span
                                className={`my-radio ${
                                    formData.hiring_mode === "remote"
                                        ? "selected"
                                        : ""
                                }`}>
                                <span className='select-mark'></span>
                            </span>
                            <p>Remote</p>
                        </div>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>What is your location?</p>
                        <div className='inputs'>
                            <input
                                type='text'
                                placeholder='City'
                                value={formData.city}
                                onChange={(e) =>
                                    setFormData((pre) => {
                                        return { ...pre, city: e.target.value };
                                    })
                                }
                            />
                            <input
                                type='text'
                                placeholder='State'
                                value={formData.state}
                                onChange={(e) =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            state: e.target.value,
                                        };
                                    })
                                }
                            />
                            <select
                                name=''
                                id=''
                                value={formData.country}
                                onChange={(e) =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            country: e.target.value,
                                        };
                                    })
                                }>
                                <option value=''>Country</option>
                                {countries.map((country, indx) => (
                                    <option
                                        value={country}
                                        key={"country" + indx}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={() => handleNext()}>
                    Next: Scope
                </button>
            </div>
        </>
    );
};

export default Step2;
