import React, { useState } from "react";

import Clock from "../../../../Assets/icons/clock.svg";
import "./step5.scss";

const Step5 = ({ formData, setFormData, setLoginPageNo }) => {
    const [errors, setErrors] = useState({});
    const handleNext = () => {
        if (!formData?.bio) {
            setErrors((pre) => {
                return {
                    ...pre,
                    bio: "Your bio is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, bio: "" };
            });
        }
        localStorage.setItem("formData", JSON.stringify(formData));
        setLoginPageNo((pre) => {
            localStorage.setItem("loginPageNo", pre + 1);
            return pre + 1;
        });
    };
    return (
        <>
            <div className='step-5-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 5 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>
                            Almost there! How would you like to be intro'd?
                        </p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>
                            Everyone has a story, what's yours?
                        </p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.bio}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        bio: e.target.value,
                                    };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.bio}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Sample bio</p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.bio}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleNext}>
                    Next: Personal
                </button>
            </div>
        </>
    );
};

export default Step5;
