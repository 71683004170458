import React from "react";
import { ReactComponent as FreelancerIcon } from "../../../../Assets/icons/freelancer.svg";
import { ReactComponent as FreelancerSelectedIcon } from "../../../../Assets/icons/freelancer-selected.svg";
import { ReactComponent as ClientIcon } from "../../../../Assets/icons/client.svg";
import { ReactComponent as ClientSelectedIcon } from "../../../../Assets/icons/client-selected.svg";
import "./selectRole.scss";

const SelectRole = ({formData, setFormData, setLoginPageNo}) => {

    return (
        <div className='select-role-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>Let's make work easier.</p>
                    <p className='desc'>Join as a client or freelacer</p>
                </div>
                <div className='options'>
                    <span className={`option ${formData.signup_as === 'freelancer' ? 'active' : ''}`} onClick={() => setFormData(pre => {return {...pre, signup_as: 'freelancer'}})}>
                        <span className='icon-container'>
                            {formData.signup_as === 'freelancer' ? <FreelancerSelectedIcon/> : <FreelancerIcon />}
                        </span>
                        <p>I'm freelancer looking for work.</p>
                    </span>
                    <span className={`option ${formData.signup_as === 'client' ? 'active' : ''}`} onClick={() => setFormData(pre => {return {...pre, signup_as: 'client'}})}>
                        <span className='icon-container'>
                        {formData.signup_as === 'client' ? <ClientSelectedIcon/> : <ClientIcon />}
                        </span>
                        <p>I'm client hiring for project.</p>
                    </span>
                </div>
                <div className='next-action'>
                    <button className='primary-btn-blue' onClick={() => {setLoginPageNo(pre => pre+1); setFormData(pre => {return {...pre, is_new_registrations: true}})}}>
                        Join as a {formData.signup_as === 'freelancer' ? "freelancer" : "client"}
                    </button>
                    <p>
                        Already have an account? <span onClick={() => {setLoginPageNo(pre => pre+1); setFormData(pre => {return {...pre, is_new_registrations: false}})}}>Log in</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SelectRole;
