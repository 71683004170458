import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../../../../Assets/icons/close.svg";
import "./roleEdit.scss";
import { useStateValue } from "../../../../../StateProvider";

const RoleEdit = ({ formData, setFormData }) => {
    const [role, setRole] = useState("");
    const [, dispatch] = useStateValue();

    const setRoleEditModalShow = (status) => {
        dispatch({
            type: "SET_ROLE_EDIT_MODAL_SHOW",
            status: status,
        });
    };

    useEffect(() => {
        setRole(formData.role);
    }, [formData]);
    return (
        <div className='role-edit-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>Tell us more about what you do.</p>
                    <Close onClick={() => setRoleEditModalShow(false)} />
                </div>
                <div className='input-grp'>
                    <p className='bold'>
                        Your title <span> (Your professional role)</span>
                        <span className='astrict'>*</span>
                    </p>
                    <input
                        type='text'
                        placeholder='For Ex: Voice artist, UX designer'
                        className='role-input'
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    />
                </div>
            </div>
            <button
                className='btn-blue'
                onClick={() => {
                    setFormData((pre) => {
                        return { ...pre, role: role };
                    });
                    setRoleEditModalShow(false);
                }}>
                Save
            </button>
        </div>
    );
};

export default RoleEdit;
