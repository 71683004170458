import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as StarReview } from "../../../Assets/icons/star.svg";
import { ReactComponent as Close } from "../../../Assets/icons/close.svg";
import "./freelancerDetails.scss";
import { createChatGroup, getFreelancerDetailsById } from "../../../apiCall";
import toast from 'react-hot-toast';
import { domainName } from "../../../Constants";
import { useStateValue } from "../../../StateProvider";

const FreelancerDetails = () => {
    const { id } = useParams();
    const [profileData, setProfileData] = useState();
    const navigateTo = useNavigate()
    const [, dispatch] = useStateValue();


    useEffect(() => {
        const getData = async () => {
            const Re = await getFreelancerDetailsById(id);

            console.log("FREELANCER_DETAILS_DATA", Re);

            if (Re?.data?.status[0].Error === "False") {
                setProfileData(Re?.data.value);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };
        getData();
    }, [id]);

    const createGroup = async () => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await createChatGroup(id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("CREATE_CHAT_GROUP_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                navigateTo('/chat?chat_group='+re.data.value)
            }
        }
    };

    function getYouTubeVideoId(url) {
        var regExp =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        var match = url?.match(regExp);

        return match ? match[1] : null;
    }
    return (
        <div className='freelancer-details-main'>
            <div className='details-container'>
                <div className='about-person'>
                    <div className='person-details'>
                        <div className='image-container'>
                            <img
                                src={
                                    domainName +
                                    "/uploads/" +
                                    profileData?.profile_img
                                }
                                alt=''
                            />
                            {/* <span className='online-mark'></span> */}
                        </div>
                        <div className='details'>
                            <span className='name-container'>
                                <span className='name'>
                                    {profileData?.name}
                                </span>
                                <span className='ratings'>
                                    {Array(5)
                                        .fill(0)
                                        .map((ele, indx) => (
                                            <StarReview
                                                key={
                                                    "user-review-ratings-" +
                                                    id +
                                                    "-" +
                                                    indx
                                                }
                                                className={`${
                                                    profileData?.ratings > indx
                                                        ? "filled"
                                                        : ""
                                                }`}
                                            />
                                        ))}
                                </span>
                            </span>
                            <p className='languages'>
                                {profileData?.gender} | {profileData?.country} |{" "}
                                {profileData?.language}
                            </p>
                        </div>
                    </div>
                    <div className='price-container'>
                        <p className='price'>
                            {profileData?.amount_format === "INR" ? "₹" : "$"}
                            {profileData?.hourly_rate}hr
                        </p>
                        <button className='btn-white-outline' onClick={createGroup}>Chat</button>
                    </div>
                </div>
                <button className='btn-white-outline mobile-chat-btn' onClick={createGroup}>
                    Chat
                </button>
                <div className='person-info-container'>
                    <div className='about-user'>
                        <h3 className='role'>
                            {profileData?.service_category} |{" "}
                            {profileData?.role}
                        </h3>
                        <p className='about'>{profileData?.bio}</p>
                    </div>
                    {profileData?.skills?.length > 0 && <div className='skills-container item-container'>
                        <p className='title'>Skills</p>
                        <div className='skills-container info'>
                            {profileData?.skills.map((skill) => (
                                <span
                                    className='skill'
                                    key={"profile-skill" + skill.id}>
                                    <Close />
                                    {skill.name}
                                </span>
                            ))}
                        </div>
                    </div>}
                    {profileData?.work_experience?.length > 0&& <div className='experience-container item-container'>
                        <p className='title'>Experience</p>
                        <div className='user-experiences'>
                            {profileData?.work_experience.map((exp, indx) => {
                                return (
                                    <div
                                        className='experience'
                                        key={"work-experience-" + indx}>
                                        <div className='details'>
                                            <div className='heading'>
                                                <p>{exp.title}</p>
                                            </div>
                                            <p>
                                                {exp.company} |{" "}
                                                {exp.start_month}{" "}
                                                {exp.start_year}{" "}
                                                {exp.currently_working
                                                    ? ""
                                                    : "-"}{" "}
                                                {exp.end_month} {exp.end_year}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                    {profileData?.education?.length > 0 && <div className='education-container item-container'>
                        <p className='title'>Education</p>
                        <div className='user-education info'>
                            {profileData?.education.map((exp, indx) => {
                                return (
                                    <div
                                        className='education'
                                        key={"education-" + indx}>
                                        <div className='details'>
                                            <div className='heading'>
                                                <p>{exp.college}</p>
                                            </div>
                                            <p>
                                            {exp.degree} |{" "}
                                                {exp.start_month}{" "}
                                                {exp.start_year}{" "}
                                                {exp.currently_working
                                                    ? ""
                                                    : "-"}{" "}
                                                {exp.end_month} {exp.end_year}
                                            </p>
                                            {exp.certificate_url && <a href={exp.certificate_url} target="__blank">View Certificate</a>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}
                    {(profileData?.yt_video_link || profileData?.portfolio) && <div className='portfolio item-container'>
                        <p className='title'>Portfolio</p>
                        {profileData?.portfolio === "" ? (
                            <iframe
                                width='560'
                                height='315'
                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                                    profileData?.yt_video_link
                                )}?si=KP7ZYISL4EKewGQV&amp;controls=0`}
                                title='YouTube video player'
                                frameborder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                allowfullscreen></iframe>
                        ) : profileData?.portfolio ? (
                            <video width='400' controls>
                                <source
                                    src={domainName + "/uploads/" +profileData?.portfolio}
                                    type='video/mp4'
                                />
                                Your browser does not support HTML video.
                            </video>
                        ) : (
                            ""
                        )}
                    </div>}
                </div>
                {profileData?.reviews?.length > 0 && <div className='history-container'>
                    {profileData?.reviews?.map((review) => (
                        <div
                            className='user-review'
                            key={"user-review-" + review.id}>
                            <div className='ratings-container'>
                                <p className='name'>{review.work}</p>
                                <span className='ratings'>
                                    {Array(5)
                                        .fill(0)
                                        .map((ele, indx) => (
                                            <StarReview
                                                key={
                                                    "user-review-ratings-" +
                                                    review.id +
                                                    "-" +
                                                    indx
                                                }
                                                className={`${
                                                    review.ratings > indx
                                                        ? "filled"
                                                        : ""
                                                }`}
                                            />
                                        ))}
                                </span>
                            </div>
                            <p className='review'>{review.review}</p>
                            <p className='client-name'>
                                From Client: <span>{review.client}</span>
                            </p>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    );
};

export default FreelancerDetails;
