import React from "react";
import { sendProposalToFreelancer } from "../../../../apiCall";
import toast from 'react-hot-toast';
import { useStateValue } from "../../../../StateProvider";

import './deposit.scss'
import { displayRazorpay } from "../../../../utils/Razorpay";

const Deposit = ({ data }) => {
    const [, dispatch] = useStateValue()
    const hideDepositModal = () => {
        dispatch({
            type: "SET_DEPOSIT_MODAL_SHOW",
            status: false,
        });
    }
    const handleSubmit = async () => {
        console.log(data)
        dispatch({ type: "SET_IS_LOADING", status: true });

        const Re = await sendProposalToFreelancer(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log(Re)
        if (Re.data?.status[0].Error === "False") {
            hideDepositModal()
            displayRazorpay(data.amount, 'JOB_POST',null, null, Re.data?.value, null, dispatch)
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else if(Re.response.data.detail){
                toast.error("Invalid input.")
            }else{
                toast.error("Something went wrong, Please try again.");
            }
        }
    }
    return (
        <div className='deposit-work-main'>
            <p className='description'>
                you will be depositing {data.currency_unit === "INR" ? "₹" : "$"} {(parseInt(data?.amount) + parseFloat((data?.amount * 0.1).toFixed(2)))} into escrow today. On your
                Transaction History page, you’ll see an immediate escrow invoice
                and corresponding payment from your primarily billing method
            </p>
            <p className='description'>
                When work is approved you release the funds, Rysovates escrow to
                your freelancer
            </p>
            <div className='buttons'>
                <button className='primary-btn-white' onClick={hideDepositModal}>
                    Cancel
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Yes, Deposit {data.currency_unit === "INR" ? "₹" : "$"} {(parseInt(data?.amount) + parseFloat((data?.amount * 0.1).toFixed(2)))}
                </button>
            </div>
        </div>
    );
};

export default Deposit;
