import React from 'react'
import Modal from "react-bootstrap/Modal";
import { useStateValue } from "./StateProvider";

import './modals.scss'
import WorkExperienceForm from './views/Login/Components/Step2/WorkExperienceForm';
import EducationForm from './views/Login/Components/Step3/EducationForm';
import YtLinkInput from './views/Login/Components/Step8/YtLinkInput';
// import SkillsEdit from './views/Freelancer/Profile/Components/SkillsEdit/SkillsEdit';
import BioEdit from './views/Freelancer/Profile/Components/BioEdit/BioEdit';
import RateEdit from './views/Freelancer/Profile/Components/RateEdit/RateEdit';
import OriginEdit from './views/Freelancer/Profile/Components/OriginEdit/OriginEdit';
import PortfolioEdit from './views/Freelancer/Profile/Components/PortfolioEdit/PortfolioEdit';
import RoleEdit from './views/Freelancer/Profile/Components/RoleEdit/RoleEdit';
import SubmitWorkForm from './views/Freelancer/MyJob/Components/SubmitWorkForm';
import FeedBackForm from './views/Freelancer/MyJob/Components/FeedBackForm';
import SuccessView from './Components/SuccessView';
import BrifProposal from './views/Freelancer/MyJob/Components/BrifProposal';
import BankDetailsForm from './views/Billing/Components/BankDetailsForm';
import { useNavigate } from 'react-router-dom';
import SkillsForm from './views/Login/Components/Step1/SkillsForm';
import DeleteView from './Components/DeleteView';
import ApproveWork from './views/Client/MyJobs/Components/ApproveWork';
import ReleaseFunds from './views/Client/MyJobs/Components/ReleaseFunds';
import Deposit from './views/Client/HireFreelancer/Components/Deposit';
import PackagePurchase from './views/Client/Packages/Components/PackagePurchase';
import Purchase from './views/Client/HireFreelancer/Components/Purchase';
import RequirementsReview from './views/Client/PurchasedPackages/Components/RequirementsReview';
import BillingMethodForm from './views/Billing/Components/BillingMethodForm';
import ForgotPassword from './views/Login/Components/ForgotPassword/ForgotPassword';


export function ForgotPasswordModal(props){
    const [{ showForgotPasswordModal }, dispatch] = useStateValue();
    const setForgotPasswordModalShow = () => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showForgotPasswordModal}
            onHide={() => setForgotPasswordModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ForgotPassword />
            </Modal.Body>
        </Modal>
    );
}

export function WorkExperienceModal(props){
    const [{ showWorkExperienceModal }, dispatch] = useStateValue();
    const setWorkExperienceModalShow = () => {
        dispatch({
            type: "SET_WORK_EXPERIENCE_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showWorkExperienceModal}
            onHide={() => setWorkExperienceModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <WorkExperienceForm
                    formData={props.formData}
                    setFormData={props.setFormData}
                    experience_index={props.experience_index}
                />
            </Modal.Body>
        </Modal>
    );
}

export function EducationModal(props){
    const [{ showEducationModal }, dispatch] = useStateValue();
    const setEducationModalShow = () => {
        dispatch({
            type: "SET_EDUCATION_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showEducationModal}
            onHide={() => setEducationModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <EducationForm
                    formData={props.formData}
                    setFormData={props.setFormData}
                    education_index={props.education_index}
                />
            </Modal.Body>
        </Modal>
    );
}

export function YtLinkModal(props){
    const [{ showYtLinkModal }, dispatch] = useStateValue();
    const setYtLinkModalShow = () => {
        dispatch({
            type: "SET_YT_LINK_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showYtLinkModal}
            onHide={() => setYtLinkModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <YtLinkInput
                    formData={props.formData}
                    setFormData={props.setFormData}
                    setShowVideo={props.setShowVideo}
                />
            </Modal.Body>
        </Modal>
    );
}

export function RoleEditModal(props){
    const [{ showRoleEditModal }, dispatch] = useStateValue();
    const setRoleEditModalShow = () => {
        dispatch({
            type: "SET_ROLE_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showRoleEditModal}
            onHide={() => setRoleEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <RoleEdit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />
            </Modal.Body>
        </Modal>
    );
}

export function SkillsEditModal(props){
    const [{ showSkillsEditModal }, dispatch] = useStateValue();
    const setSkillsEditModalShow = () => {
        dispatch({
            type: "SET_SKILLS_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showSkillsEditModal}
            onHide={() => setSkillsEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <SkillsForm
                    formData={props.formData}
                    skillsList={props.skillsList}
                    setFormData={props.setFormData}
                    is_from_edit_profile={true}
                />
            </Modal.Body>
        </Modal>
    );
}

export function BioEditModal(props){
    const [{ showBioEditModal }, dispatch] = useStateValue();
    const setBioEditModalShow = () => {
        dispatch({
            type: "SET_BIO_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showBioEditModal}
            onHide={() => setBioEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <BioEdit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />
            </Modal.Body>
        </Modal>
    );
}

export function RateEditModal(props){
    const [{ showRateEditModal }, dispatch] = useStateValue();
    const setRateEditModalShow = () => {
        dispatch({
            type: "SET_RATE_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showRateEditModal}
            onHide={() => setRateEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <RateEdit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />
            </Modal.Body>
        </Modal>
    );
}

export function OriginEditModal(props){
    const [{ showOriginEditModal }, dispatch] = useStateValue();
    const setOriginEditModalShow = () => {
        dispatch({
            type: "SET_ORIGIN_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showOriginEditModal}
            onHide={() => setOriginEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <OriginEdit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />
            </Modal.Body>
        </Modal>
    );
}

export function PortfolioEditModal(props){
    const [{ showPortfolioEditModal }, dispatch] = useStateValue();
    const setPortfolioEditModalShow = () => {
        dispatch({
            type: "SET_PORTFOLIO_EDIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showPortfolioEditModal}
            onHide={() => setPortfolioEditModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <PortfolioEdit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />
            </Modal.Body>
        </Modal>
    );
}

export function SuccessModal(props){
    const [{ showSuccessModal }, dispatch] = useStateValue();
    const setSuccessModalShow = () => {
        dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: false,
        });
    };
    const navigateTo = useNavigate()
    return (
        <Modal
            {...props}
            show={showSuccessModal}
            onHide={() => {setSuccessModalShow(false); if(props.redirect_to_home === true){navigateTo(props.redirect_url ? props.redirect_url : '/')}}}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <SuccessView text={props.text} description={props.description} redirect_to_home={props.redirect_to_home} redirect_url={props.redirect_url}/>
            </Modal.Body>
        </Modal>
    );
}

export function DeleteModal(props){
    const [{ showDeleteModal }, dispatch] = useStateValue();
    const setDeleteModalShow = () => {
        dispatch({
            type: "SET_DELETE_MODAL_SHOW",
            status: false,
        });
    };
    const navigateTo = useNavigate()
    return (
        <Modal
            {...props}
            show={showDeleteModal}
            onHide={() => {setDeleteModalShow(false); if(props.redirect_to_home === true){navigateTo('/')}}}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <DeleteView text={props.text} callback={props.callback} id={props.id}/>
            </Modal.Body>
        </Modal>
    );
}

export function SubmitWorkModal(props){
    const [{ showSubmitWorkModal }, dispatch] = useStateValue();
    const setSubmitWorkModalShow = () => {
        dispatch({
            type: "SET_SUBMIT_WORK_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showSubmitWorkModal}
            onHide={() => setSubmitWorkModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <SubmitWorkForm job_id={props.job_id} proposal_id={props.proposal_id} setFeedbackJobId={props.setFeedbackJobId}/>
            </Modal.Body>
        </Modal>
    );
}

export function FeedbackModal(props){
    const [{ showFeedbackModal }, dispatch] = useStateValue();
    const setFeedbackModalShow = (status) => {
        dispatch({
            type: "SET_FEED_BACK_MODAL_SHOW",
            status: status,
        });
    };
    const setSuccessModel = () => {
        dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: true,
        });
    }
    return (
        <Modal
            {...props}
            show={showFeedbackModal}
            onHide={() => {setFeedbackModalShow(false); setSuccessModel()}}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <FeedBackForm proposal_id={props.proposal_id} freelancer_id={props.freelancer_id}/>
            </Modal.Body>
        </Modal>
    );
}

export function ProposalModal(props){
    const [{ showProposalModal }, dispatch] = useStateValue();
    const setProposalModalShow = () => {
        dispatch({
            type: "SET_PROPOSAL_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showProposalModal}
            onHide={() => setProposalModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <BrifProposal proposal={props.proposal} callback={props.callback}/>
            </Modal.Body>
        </Modal>
    );
}

export function BankDetailsModal(props){
    const [{ showBankDetailsModal }, dispatch] = useStateValue();
    const setBankDetailsModalShow = () => {
        dispatch({
            type: "SET_BANK_DETAILS_MODAL_SHOW",
            status: false,
        });
    };
    
    return (
        <Modal
            {...props}
            show={showBankDetailsModal}
            onHide={() => setBankDetailsModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <BankDetailsForm bankDetails={props.bankDetails} setBankDetails={props.setBankDetails} />
            </Modal.Body>
        </Modal>
    );
}

export function BillingMethodsModal(props){
    const [{ showBillingMethodsModal }, dispatch] = useStateValue();
    const setBillingMethodsModalShow = () => {
        dispatch({
            type: "SET_BILLING_METHODS_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showBillingMethodsModal}
            onHide={() => setBillingMethodsModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <BillingMethodForm billingMethods={props.billingMethods} setBillingMethods={props.setBillingMethods} />
            </Modal.Body>
        </Modal>
    );
}

export function WorkApproveModal(props){
    const [{ showWorkApproveModal }, dispatch] = useStateValue();
    const setWorkApproveModalShow = () => {
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showWorkApproveModal}
            onHide={() => setWorkApproveModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ApproveWork proposal_details={props.proposal_details} callback={props.callback} giveOptions={props?.giveOptions !== undefined ? props?.giveOptions : true}/>
            </Modal.Body>
        </Modal>
    );
}

export function ReleaseFundModal(props){
    const [{ showReleaseFundModal }, dispatch] = useStateValue();
    const setReleaseFundModalShow = () => {
        dispatch({
            type: "SET_RELEASE_FUND_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
        {...props}
        show={showReleaseFundModal}
        onHide={() => setReleaseFundModalShow(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
            <Modal.Body>
                <ReleaseFunds proposal_id={props.proposal_id} callback={props.callback} />
            </Modal.Body>
        </Modal>
    );
}

export function DepositModal(props){
    const [{ showDepositModal }, dispatch] = useStateValue();
    const setDepositModalShow = () => {
        dispatch({
            type: "SET_DEPOSIT_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showDepositModal}
            onHide={() => setDepositModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <Deposit data={props.data} />
            </Modal.Body>
        </Modal>
    );
}

export function RequirementsReviewModal(props){
    const [{ showRequirementsReviewModal }, dispatch] = useStateValue();
    const setRequirementsReviewModalShow = () => {
        dispatch({
            type: "SET_REQUIREMENTS_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={showRequirementsReviewModal}
            onHide={() => setRequirementsReviewModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <RequirementsReview requirements={props.requirements} />
            </Modal.Body>
        </Modal>
    );
}

export function PurchaseModal(props){
    const [{ purchaseModal }, dispatch] = useStateValue();
    const setPurchaseModalShow = () => {
        dispatch({
            type: "SET_PURCHASE_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={purchaseModal}
            onHide={() => setPurchaseModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <Purchase data={props.data} offer_id={props.offer_id} activeTab={props.activeTab} currency={props.currency} jobDetail={props?.jobDetail}/>
            </Modal.Body>
        </Modal>
    );
}
export function PackagePurchaseModal(props){
    const [{ packagePurchaseModal }, dispatch] = useStateValue();
    const setPackagePurchaseModalShow = () => {
        dispatch({
            type: "SET_PACKAGE_PURCHASE_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={packagePurchaseModal}
            onHide={() => setPackagePurchaseModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <PackagePurchase data={props.data} offer_id={props.offer_id} activeTab={props.activeTab} currency={props.currency}/>
            </Modal.Body>
        </Modal>
    );
}