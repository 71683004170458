import React, { useState } from "react";
import Clock from "../../../../Assets/icons/clock.svg";
import { ReactComponent as WorkIcon } from "../../../../Assets/icons/work-filled.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/edit-white.svg";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/delete.svg";

import "./step2.scss";
import { WorkExperienceModal } from "../../../../Modals";
import { useStateValue } from "../../../../StateProvider";

const Step2 = ({ formData, setFormData, setLoginPageNo }) => {
    const [experience_index, setExperienceIndex] = useState(-1);

    const [, dispatch] = useStateValue();
    const showWorkExperienceModal = () => {
        dispatch({
            type: "SET_WORK_EXPERIENCE_SHOW",
            status: true,
        });
    };

    const handleNext = () => {
        localStorage.setItem('formData', JSON.stringify(formData))
        setLoginPageNo(pre => {
            localStorage.setItem('loginPageNo', pre+1)
            return pre+1;
        })
    }
    return (
        <>
            <div className='step-2-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 2 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>
                            If you have relevant work experience, add it here.{" "}
                        </p>
                    </div>
                    <div className='experience-container'>
                        {formData.work_experience.map((exp, indx) => {
                            return (
                                <div
                                    className='experience'
                                    key={"work-experience-" + indx}>
                                    <div className='details'>
                                        <div className='heading'>
                                            <WorkIcon />
                                            <p>{exp.title}</p>
                                        </div>
                                        <p>
                                            {exp.company} | {exp.start_month}{" "}
                                            {exp.start_year}{" "}
                                            {exp.currently_working ? "" : "-"}{" "}
                                            {exp.end_month} {exp.end_year}
                                        </p>
                                    </div>
                                    <div className='icons'>
                                        <EditIcon
                                            onClick={() => {
                                                setExperienceIndex(indx);
                                                showWorkExperienceModal();
                                            }}
                                        />
                                        <DeleteIcon
                                            onClick={() => {
                                                setFormData((pre) => {
                                                    console.log(
                                                        "HII",
                                                        pre.work_experience,
                                                        indx
                                                    );
                                                    const new_work_experience =
                                                        pre.work_experience.filter(
                                                            (exp, exp_indx) =>
                                                                exp_indx !==
                                                                indx
                                                        );
                                                    console.log(
                                                        new_work_experience
                                                    );
                                                    return {
                                                        ...pre,
                                                        work_experience:
                                                            new_work_experience,
                                                    };
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className='add-experience'
                            onClick={() => {
                                setExperienceIndex(-1);
                                showWorkExperienceModal();
                            }}>
                            <WorkIcon />
                            <p>Add experience</p>
                        </div>
                    </div>
                </div>
                <WorkExperienceModal
                    formData={formData}
                    setFormData={setFormData}
                    experience_index={experience_index}
                />
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={handleNext}>
                    Next: Education
                </button>
            </div>
        </>
    );
};

export default Step2;
