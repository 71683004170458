import React, { useRef, useState } from "react";

import { ReactComponent as UploadIcon } from "../../../../Assets/icons/upload.svg";
import Clock from "../../../../Assets/icons/clock.svg";
import "./step6.scss";
import { domainName } from "../../../../Constants";
import toast from 'react-hot-toast';
import { UpdateUserProfileImage } from "../../../../apiCall";
import { useStateValue } from "../../../../StateProvider";

const Step6 = ({ formData, setFormData, setLoginPageNo }) => {

    const [{countries_list},dispatch] = useStateValue()
    const fileRef = useRef();
    const [userImage, setUserImage] = useState(
        sessionStorage.getItem("profile_img")
    );
    const [errors, setErrors] = useState({});

    const handleNext = () => {
        if (!formData?.gender) {
            setErrors((pre) => {
                return {
                    ...pre,
                    gender: "Gender is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, gender: "" };
            });
        }
        if (!formData?.country) {
            setErrors((pre) => {
                return {
                    ...pre,
                    country: "Country is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, country: "" };
            });
        }
        if (!formData?.language) {
            setErrors((pre) => {
                return {
                    ...pre,
                    language: "Language is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, language: "" };
            });
        }
        localStorage.setItem("formData", JSON.stringify(formData));
        setLoginPageNo((pre) => {
            localStorage.setItem("loginPageNo", pre + 1);
            return pre + 1;
        });
    };

    const changeProfileImg = async (e) => {
        const imgData = new FormData();
        imgData.append("profile_img", e.target.files[0]);

        dispatch({type:"SET_IS_LOADING",status:true})
        const Re = await UpdateUserProfileImage(imgData);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);
        console.log(Re);


        if (Re.data?.status[0].Error === "False") {
            if (sessionStorage.getItem("token")) {
                sessionStorage.setItem(
                    "profile_img",
                    domainName + Re.data.value?.profile_img
                );
                setUserImage(domainName + Re.data.value?.profile_img);
            }
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };
    return (
        <>
            <div className='step-6-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 6 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>Tell us your origin story.</p>
                    </div>
                    <div className='image-input-container'>
                        <input
                            type='file'
                            name=''
                            id=''
                            accept='image/*'
                            ref={fileRef}
                            onChange={(e) => changeProfileImg(e)}
                        />
                        {userImage ? (
                            <img
                                src={userImage}
                                className='user-image'
                                alt=''
                                onClick={() => fileRef.current.click()}
                            />
                        ) : (
                            <span
                                className='pick-image-button'
                                onClick={() => fileRef.current.click()}>
                                <UploadIcon />
                            </span>
                        )}
                        <span className='text-container'>
                            <p className='title' onClick={() => fileRef.current.click()}>Select a file</p>
                            <p>Make sure your file would be below 2 mb</p>
                        </span>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>What gender do you identify as?</p>
                        <select
                            name=''
                            id=''
                            value={formData.gender}
                            onChange={(e) =>
                                setFormData((prev) => {
                                    return {
                                        ...prev,
                                        gender: e.target.value,
                                    };
                                })
                            }>
                            <option value=''>Gender</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Non-binary/Other'>Non-binary/Other</option>
                            <option value='Prefer not to say'>
                                Prefer not to say
                            </option>
                        </select>
                        <p className='form-error'>{errors?.gender}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Which country do you live in</p>
                        {/* <input
                            type='text'
                            placeholder='For Ex: India, USA etc.'
                            value={formData.country}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, country: e.target.value };
                                })
                            }
                        /> */}
                        <select
                        name=''
                        id=''
                        value={formData.country}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return { ...pre, country: e.target.value };
                            })
                        }>
                        <option value=''>Country</option>
                        {countries_list.map((country, indx) => (
                            <option value={country} key={"country" + indx}>
                                {country}
                            </option>
                        ))}
                    </select>
                        <p className='form-error'>{errors?.country}</p>
                    </div>{" "}
                    <div className='input-grp'>
                        <p className='bold'>
                            What are all languages do you can speak?{" "}
                            <span>(Comma seperated)</span>
                        </p>
                        <input
                            type='text'
                            placeholder='Hindi, English, Bangali'
                            value={formData.language}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, language: e.target.value };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.language}</p>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleNext}>
                    Next: Pricing
                </button>
            </div>
        </>
    );
};

export default Step6;
