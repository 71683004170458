import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import toast from 'react-hot-toast';

import "./login.scss"
import SelectRole from "./Components/SelectRole/SelectRole";
import SignUpInForm from "./Components/SignUpI_In_Form/SignUpInForm";
import Introduction from "./Components/Introduction/Introduction";
import Step1 from "./Components/Step1/Step1";
import Step2 from "./Components/Step2/Step2";
import Step3 from "./Components/Step3/Step3";
import Step4 from "./Components/Step4/Step4";
import Step5 from "./Components/Step5/Step5";
import Step6 from "./Components/Step6/Step6";
import Step7 from "./Components/Step7/Step7";
import Step8 from "./Components/Step8/Step8";
import Profile from "../Freelancer/Profile/Profile";
import NamePhoneInput from "./Components/NamePhoneInput/NamePhoneInput";
import { CACHE_TIME } from "../../Constants";
import { GetServiceList, GetSkillsList } from "../../apiCall";
import { useStateValue } from "../../StateProvider";

const Login = () => {
    const [loginPageNo, setLoginPageNo] = useState(0);
    const initialValues = {
        signup_as: "freelancer",
        is_new_registrations: true,
        country_code: "IN +91",
        role: "",
        skills: [],
        work_experience: [],
        education: [],
        service_category: "",
        services: [],
        // user_story: "",
        bio: "",
        gender: "",
        country: "",
        language: "",
        hourly_rate: "",
        service_fee: "",
        portfolio: "",
        yt_video_link: "",
        from_india: true,
    }
    const [formData, setFormData] = useState(localStorage.getItem('formData') ? JSON.parse(localStorage.getItem('formData')) : initialValues)
    const [skillsList, setSkillsList] = useState([])
    const [serviceList, setServiceList] = useState([])
    // const [categories, setCategories] = useState([]);
    const [{categories}] = useStateValue()


    useEffect(() => {
        if(localStorage.getItem('loginPageNo') && (localStorage.getItem('token') || sessionStorage.getItem('token'))){
            setLoginPageNo(parseInt(localStorage.getItem('loginPageNo')))
        }
    }, [])

    useQuery(
        ["get-skills"],
        () => GetSkillsList(),
        {
            cacheTime: CACHE_TIME,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if(data?.data?.status[0].Error === "False"){
                    setSkillsList(data.data.value)
                }
            },
            onError: (err) => {
                console.log(err, "ERROR");
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    useQuery(
        ["get-services"],
        () => GetServiceList(),
        {
            cacheTime: CACHE_TIME,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if(data?.data?.status[0].Error === "False"){
                    setServiceList(data.data.value)
                }
            },
            onError: (err) => {
                console.log(err, "ERROR");
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });
    switch (loginPageNo) {
        case 0:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <SelectRole formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 1:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <SignUpInForm formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 2:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <NamePhoneInput formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 3:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Introduction name={formData.name} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 4:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step1 formData={formData} skillsList={skillsList} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 5:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step2 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 6:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step3 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 7:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step4 formData={formData} serviceList={serviceList} categories={categories} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 8:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step5 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 9:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step6 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 10:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step7 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 11:
            return <div className='login-main'>
                <div className="progress">
                    <span className="bar" style={{width: (loginPageNo - 3)*100/8 + "%"}}></span>
                </div>
                <Step8 formData={formData} setFormData={setFormData} setLoginPageNo={setLoginPageNo}/>
            </div>;
        case 12:
            return <Profile formData={formData} setFormData={setFormData} skillsList={skillsList} is_profile_review={true}/>
    
        default:
            break;
    }
};

export default Login;
