import React, { useRef, useState } from "react";
import { useStateValue } from "../../../../../StateProvider";
import { ReactComponent as Close } from "../../../../../Assets/icons/close.svg";
import { ReactComponent as ExpandIcon } from "../../../../../Assets/icons/expand.svg";
import PdfIcon from "../../../../../Assets/icons/pdf.svg";
import { YtLinkModal } from "../../../../../Modals";
import "./portfolioEdit.scss";
import { UpdateFreelancerPortfolio } from "../../../../../apiCall";
import { domainName } from "../../../../../Constants";
import toast from "react-hot-toast";

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];
const VIDEO_EXTENSIONS = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "mpeg",
    "3gp",
    "ogv",
    "rmvb",
    "m4v",
    "ts",
    "mxf",
    "divx",
];
const PortfolioEdit = ({ formData, setFormData }) => {
    const [, dispatch] = useStateValue();
    const fileRef = useRef();
    const [showVideo, setShowVideo] = useState(
        formData.portfolio || formData.yt_video_link
    );

    // const [uploadedVideo, setUploadedVideo] = useState();
    const setPortfolioEditModalShow = (status) => {
        dispatch({
            type: "SET_PORTFOLIO_EDIT_MODAL_SHOW",
            status: status,
        });
    };
    const showtLinkModal = () => {
        dispatch({
            type: "SET_YT_LINK_MODAL_SHOW",
            status: true,
        });
    };

    function getYouTubeVideoId(url) {
        // Regular expression to match YouTube video ID in various URL formats
        var regExp =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Extract the video ID using the regular expression
        var match = url.match(regExp);

        // If a match is found, return the video ID; otherwise, return null
        return match ? match[1] : null;
    }

    const uploadPortfolio = async (e) => {
        const data = new FormData();
        data.append("portfolio", e.target.files[0]);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const Re = await UpdateFreelancerPortfolio(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            if (sessionStorage.getItem("token")) {
                sessionStorage.setItem(
                    "portfolio",
                    domainName + "/uploads/" + Re.data.value?.portfolio
                );
            }
            setFormData((pre) => {
                return {
                    ...pre,
                    portfolio:
                        domainName + "/uploads/" + Re.data.value?.portfolio,
                    yt_video_link: "",
                };
            });
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };
    return (
        <div className='portfolio-edit-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>
                        Share your work with your portfolio ( Work samples, Case
                        studies etc.)
                    </p>
                    <Close onClick={() => setPortfolioEditModalShow(false)} />
                </div>
                <p className='desc'>
                    You must have add at least one file or video link to your
                    project*
                </p>
                <div className='video-container'>
                    {(formData.yt_video_link !== "" ||
                        formData.portfolio !== "") && (
                        <span
                            className='arrow left-arrow'
                            onClick={() => setShowVideo(false)}>
                            <ExpandIcon />
                        </span>
                    )}
                    {!showVideo ? (
                        <div className='input-container'>
                            <div
                                className='file-input'
                                onClick={() => fileRef.current.click()}>
                                <input
                                    type='file'
                                    name=''
                                    id=''
                                    ref={fileRef}
                                    accept='image/*, video/*, application/pdf'
                                    onChange={(e) => {
                                        uploadPortfolio(e);
                                        setShowVideo(true);
                                    }}
                                />
                                <p>
                                    Drag and drop or <span>browse</span> your
                                    files
                                </p>
                            </div>
                            <span className='hr'></span>
                            <div className='yt-link'>
                                <p>or, embed a video from YouTube or Vimeo.</p>
                                <button
                                    className='btn-white-outline'
                                    onClick={showtLinkModal}>
                                    Add Video Link
                                </button>
                            </div>
                        </div>
                    ) : formData.portfolio === "" ? (
                        <iframe
                            width='560'
                            height='315'
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                                formData.yt_video_link
                            )}?si=KP7ZYISL4EKewGQV&amp;controls=0`}
                            title='YouTube video player'
                            frameborder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            allowfullscreen></iframe>
                    ) : VIDEO_EXTENSIONS.includes(
                          formData?.portfolio?.split(".").at(-1)
                      ) ? (
                        <video width='400' controls>
                            <source src={formData.portfolio} type='video/mp4' />
                            Your browser does not support HTML video.
                        </video>
                    ) : IMAGE_EXTENSIONS.includes(
                          formData?.portfolio?.split(".").at(-1)
                      ) ? (
                        <img src={formData.portfolio} className="portfolio-img" alt='' />
                    ) : (
                        <span
                            className='attachment'
                            onClick={() =>
                                window.open(formData?.portfolio, "_blank")
                            }>
                            <img src={PdfIcon} alt='' />
                            <p>{formData?.portfolio?.split("/").at(-1)}</p>
                        </span>
                    )}
                    {(formData.yt_video_link !== "" ||
                        formData.portfolio !== "") && (
                        <span
                            className='arrow right-arrow'
                            onClick={() => setShowVideo(true)}>
                            <ExpandIcon />
                        </span>
                    )}
                </div>
                {showVideo === false && (
                    <ul className='points'>
                        <li>
                            Images (.jpg, .gif, .png, up to 10 MB, no more than
                            4000 px in any dimension)
                        </li>
                        <li>
                            {
                                "Videos (.mp4, .mov, .webm, .ogm, ogv, up to 100 MB, 2 maximum, < 60 seconds)"
                            }
                        </li>
                        <li>Audio (.mp3, .wav, up to 10 MB, 20 maximum)</li>
                        <li>Document (.pdf, up to 10 MB)</li>
                    </ul>
                )}
            </div>
            <button
                className='btn-blue'
                onClick={() => {
                    setPortfolioEditModalShow(false);
                }}>
                Save
            </button>
            <YtLinkModal
                formData={formData}
                setShowVideo={setShowVideo}
                setFormData={setFormData}
            />
        </div>
    );
};

export default PortfolioEdit;
