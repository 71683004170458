import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../Assets/icons/logo-color.svg";
import { ReactComponent as ChatIcon } from "../Assets/icons/mark_chat_unread.svg";
import { ReactComponent as NotificationIcon } from "../Assets/icons/notifications_active.svg";
import { ReactComponent as MenuIcon } from "../Assets/icons/menu.svg";
import UserImage from "../Assets/icons/user-default.png";
import "./nav.scss";
import { useNavigate } from "react-router-dom";
import NavOffCanvas from "./NavOffCanvas";
import { useStateValue } from "../StateProvider";
import notificationLogo from "../Assets/icons/default_notification.svg";
import { getNotifications, switchUser } from "../apiCall";
import { domainName } from "../Constants";
import toast from "react-hot-toast";

const Nav = ({ activeTab }) => {
    const navigateTo = useNavigate();
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [{ loggedInAs, notifications }, dispatch] = useStateValue();
    const [showOptions, setShowOptions] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();

        dispatch({
            type: "SET_LOGGED_IN_AS",
            status: "",
        });
        navigateTo("/");
    };

    useEffect(() => {
        const getData = async () => {
            const Re = await getNotifications(true);
            if (Re.data?.status[0].Error === "False") {
                dispatch({
                    type: "SET_NOTIFICATIONS",
                    status: Re.data.value,
                });
            }
        };
        if (showNotifications) {
            getData();
        }
    }, [showNotifications, dispatch]);

    const handleSwitchUser = async (user_type) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await switchUser(user_type)
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("SWITCH_USER_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    sessionStorage.setItem("email", re.data.value?.email);
                    sessionStorage.setItem("name", re.data.value?.name);
                    sessionStorage.setItem(
                        "phone_number",
                        re.data.value?.phone_number
                    );
                    if(re.data.value?.have_other_details){
                        dispatch({
                            type: "SET_HAS_OTHER_DETAILS",
                            status: true
                        })
                    }
                    sessionStorage.setItem(
                        "country_code",
                        re.data.value?.country_code
                    );
                    sessionStorage.setItem(
                        "is_indian_user",
                        re.data.value?.is_indian_user
                    );
                    if (re.data.value?.profile_img) {
                        sessionStorage.setItem(
                            "profile_img",
                            domainName +
                                "/uploads/" +
                                re.data.value?.profile_img
                        );
                    }
                    sessionStorage.setItem(
                        "have_other_details",
                        re.data.value?.have_other_details
                    );
                    sessionStorage.setItem(
                        "user_type",
                        re.data.value?.user_type
                    );
                    sessionStorage.setItem(
                        "saved_jobs",
                        JSON.stringify(re.data.value?.saved_jobs)
                    );
                    sessionStorage.setItem(
                        "saved_freelancers",
                        JSON.stringify(re.data.value?.saved_freelancers)
                    );
                    sessionStorage.setItem(
                        "is_premium_user",
                        JSON.stringify(re.data.value?.current_plan === 'free' ? false : true)
                    );

                    dispatch({
                        type: "SET_LOGGED_IN_AS",
                        status: re.data.value?.user_type,
                    });
                    dispatch({
                        type: "SET_SAVED_FREELANCERS",
                        status: re.data.value?.saved_freelancers,
                    });
                    dispatch({
                        type: "SET_CURRENCY_SYBMOL_JOBS",
                        status: re.data.value?.is_indian_user ? "₹" : "$",
                    });

                    if(re.data.value?.have_other_details === false){
                        localStorage.setItem("loginPageNo", 2);
                        navigateTo("/signup")
                    }else{
                        navigateTo("/");
                    }
                }
            }
    }

    return (
        <div className='nav-main'>
            {(showOptions === true || showNotifications === true) && (
                <div
                    className='back-drop'
                    onClick={() => {
                        setShowOptions(false);
                        setShowNotifications(false);
                    }}></div>
            )}
            <div className='nav-items'>
                {loggedInAs !== "" && (
                    <MenuIcon
                        className='menu-icon'
                        onClick={() => setShowOffCanvas(true)}
                    />
                )}
                <Logo className='logo' onClick={() => navigateTo("/")} />
                {loggedInAs === "freelancer" && (
                    <div className='items'>
                        <span className='item' onClick={() => navigateTo("/")}>
                            <p>Find work</p>
                            {activeTab === "find_work" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/my-jobs")}>
                            <p>My jobs</p>
                            {activeTab === "my_jobs" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/create-offer")}>
                            <p>Offer create</p>
                            {activeTab === "create_offer" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/plans")}>
                            <p>Membership plans</p>
                            {activeTab === "membership_plans" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                    </div>
                )}
                {loggedInAs === "client" && (
                    <div className='items'>
                        <span className='item' onClick={() => navigateTo("/")}>
                            <p>Find Freelancers</p>
                            {activeTab === "find_freelancer" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/packages")}>
                            <p>Package Services</p>
                            {activeTab === "package_services" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/my-jobs")}>
                            <p>My Job Posts</p>
                            {activeTab === "my_jobs" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                        <span
                            className='item'
                            onClick={() => navigateTo("/plans")}>
                            <p>Membership plans</p>
                            {activeTab === "membership_plans" && (
                                <span className='under-line'></span>
                            )}
                        </span>
                    </div>
                )}
            </div>
            {loggedInAs !== "" && (
                <div className='icons'>
                    {loggedInAs === "client" && (
                        <button
                            className='primary-btn-blue'
                            onClick={() => navigateTo("/post-new-job")}>
                            Post new job
                        </button>
                    )}
                    <NotificationIcon
                        onClick={() => setShowNotifications(true)}
                    />
                    {showNotifications && (
                        <div className='notifications'>
                            {notifications?.map((noti) => (
                                <div
                                    className='notification'
                                    key={"notification-" + noti.id}>
                                    {noti?.read !== true && (
                                        <span className='unreaded'></span>
                                    )}
                                    <img
                                        src={
                                            noti?.image
                                                ? domainName +
                                                  "/uploads/" +
                                                  noti?.image
                                                : notificationLogo
                                        }
                                        alt=''
                                    />
                                    <div className='noti-details'>
                                        <p className='text'>
                                            {noti?.description}
                                        </p>
                                        <p className='time'>
                                            {new Date(
                                                noti?.create_at
                                            ).toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            ))}
                            
                            {(!notifications || notifications?.length === 0) && (
                                <p className='empty-message'>
                                    You don't have any notifications.
                                </p>
                            )}
                        </div>
                    )}
                    <ChatIcon onClick={() => navigateTo("/chat")} />
                    <img
                        src={sessionStorage.getItem("profile_img") ? sessionStorage.getItem('profile_img') : UserImage}
                        alt=''
                        onClick={() => setShowOptions(true)}
                    />
                    {showOptions && (
                        <div className='options'>
                            <span
                                className='option'
                                onClick={() => {
                                    setShowOptions(false);
                                    navigateTo("/profile");
                                }}>
                                Profile
                            </span>
                            <span
                                className='option'
                                onClick={() => {
                                    setShowOptions(false);
                                    navigateTo("/billing");
                                }}>
                                Billing & Payments
                            </span>
                            {loggedInAs === 'freelancer' && <span
                                className='option'
                                onClick={() => {
                                    setShowOptions(false);
                                    navigateTo("/create-offer");
                                }}>
                                Create offer packages
                            </span>}
                            {loggedInAs === 'freelancer' ? <span className="option" onClick={() => handleSwitchUser('client')}>Switch to buying</span> : <span className='option' onClick={() => handleSwitchUser('freelancer')}>Switch to selling</span>}
                            <span
                                className='option'
                                onClick={() => {
                                    setShowOptions(false);
                                    navigateTo(
                                        loggedInAs === "freelancer"
                                            ? "/offers"
                                            : "/my-packages"
                                    );
                                }}>
                                View offer packages
                            </span>
                            <span className='option' onClick={handleLogout}>
                                Logout
                            </span>
                        </div>
                    )}
                </div>
            )}
            <NavOffCanvas show={showOffCanvas} setShow={setShowOffCanvas} />
        </div>
    );
};

export default Nav;
