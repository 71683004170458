import React from "react";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { useStateValue } from "../../../../StateProvider";
import PdfIcon from "../../../../Assets/icons/pdf.svg";
import { domainName } from "../../../../Constants";

const RequirementsReview = ({ requirements }) => {
    console.log(requirements)
    const [, dispatch] = useStateValue()
    const hideRequirementsModal = () => {
        dispatch({
            type: "SET_REQUIREMENTS_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <div className='requirements-review-main'>
            <div className='approve-work-main'>
                <div className='header'>
                    <p>Requirements</p>
                    <CloseIcon onClick={hideRequirementsModal} />
                </div>
                <div className='content'>
                    <p className='bold'>Requirements</p>

                    <div className='description'>
                        <p>{requirements?.work_description}</p>
                    </div>

                    <div className='grp'>
                        {requirements?.attachments
                            ?.filter((attach) => attach?.link !== "")
                            .map((attach, indx) => (
                                <span
                                    className='attachment'
                                    key={"attachment-" + attach.id}
                                    onClick={() =>
                                        window.open(
                                            domainName +
                                                "/uploads/" +
                                                attach.link,
                                            "_blank"
                                        )
                                    }>
                                    <img src={PdfIcon} alt='' />
                                    <p>{attach.file_name}</p>
                                </span>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequirementsReview;
