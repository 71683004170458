import React from "react";
import PdfIcon from "../../../../Assets/icons/pdf.svg";

import './aboutJob.scss'
import { domainName } from "../../../../Constants";

const AboutJob = ({ JobDetails, setActiveTab }) => {
    return (
        <div className='job-details'>
            <div className='grp'>
                <h1>{JobDetails?.title}</h1>
                <p className='budget'>
                    {JobDetails?.currency_unit === 'INR' ? '₹' : '$'}{JobDetails?.budget_lower_bound} - {JobDetails?.currency_unit === 'INR' ? '₹' : '$'}{JobDetails?.budget_upper_bound}{" "}
                    <span> project budget</span>
                </p>
                <p className='desc'>{JobDetails?.description} </p>
            </div>
            <div className='grp'>
                <p className='title'>Skilled Required</p>
                <div className='skills-container info'>
                    {JobDetails?.skills.map((skill) => (
                        <span
                            className='skill'
                            key={"profile-skill" + skill.id}>
                            {skill.skill}
                        </span>
                    ))}
                </div>
            </div>
            {JobDetails?.responsibilities && <div className='grp'>
                <p className='title'>Responsibilities</p>
                <p className='desc'>{JobDetails?.responsibilities} </p>
            </div>}
            {JobDetails?.attachments?.length > 0 && <div className='grp'>
                <p className='title'>Attachments</p>
                {JobDetails?.attachments.map((attach, indx) => (
                    <span
                        className='attachment'
                        key={"attachment-" + attach.id}
                        onClick={() => window.open(domainName + '/uploads/' + attach.link, "_blank")}>
                        <img src={PdfIcon} alt='' />
                        <p>{attach.file_name}</p>
                    </span>
                ))}
            </div>}
            <div className='button-container'>
                <button className='btn-blue' onClick={() => setActiveTab('bid-now')}>Next: Bid now</button>
            </div>
        </div>
    );
};

export default AboutJob;
