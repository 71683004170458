import React, { useCallback, useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";

import "./myJobs.scss";
import { deleteJob, getClientJobs, openJobForRebid } from "../../../apiCall";
import { toast } from "react-hot-toast";
import { domainName } from "../../../Constants";
import PdfIcon from "../../../Assets/icons/pdf.svg";
import { ReactComponent as ExpandIcon } from "../../../Assets/icons/expand.svg";
import { ReactComponent as MoreIcon } from "../../../Assets/icons/more.svg";
import { ReactComponent as StarReview } from "../../../Assets/icons/star.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/icons/info.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/icons/check-circle.svg";
import { useStateValue } from "../../../StateProvider";
import {
    FeedbackModal,
    ProposalModal,
    ReleaseFundModal,
    WorkApproveModal,
} from "../../../Modals";
import { useNavigate } from "react-router-dom";

const ClientMyJobs = () => {
    const [activeTab, setActiveTab] = useState("all");
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [proposal, setProposal] = useState();
    const [selectedProposal, setSelectedProposal] = useState();
    const [, dispatch] = useStateValue();
    const navigateTo = useNavigate();

    const [currentJobIndx, setCurrentJobIndx] = useState(0);
    const [mobileJobs, setMobileJobs] = useState([]);
    const [hasClientJob, setHasClientJob] = useState(true);
    const [showOptionsForJob, setShowOptionsForJob] = useState();

    const handleProposalModalShow = (status) => {
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: false,
        });
        dispatch({
            type: "SET_PROPOSAL_MODAL_SHOW",
            status: status,
        });
    };
    const handleWorkApproveModalShow = (status) => {
        dispatch({
            type: "SET_PROPOSAL_MODAL_SHOW",
            status: false,
        });
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: status,
        });
    };

    const handleDelete = async (id) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await deleteJob(id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("CLIENT_JOBS_DELETE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                reRenderComp();
            }
        }
    };
    const openForRebid = async (id) => {
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await openJobForRebid(id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("CLIENT_JOBS_DELETE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                toast.success("Job is now open for rebid.");
                reRenderComp();
            }
        }
    };

    const getJobsData = useCallback(
        async (activeTab, page) => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await getClientJobs(activeTab, page);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("CLIENT_JOBS_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    setJobs(re.data.value?.jobs);
                    setTotalPages(re.data.value?.total_pages);
                    if (re.data.value?.jobs?.length === 0) {
                        setHasClientJob(false);
                    }
                    setMobileJobs((pre) => [...pre, ...re.data.value?.jobs]);
                }
            }
        },
        [dispatch]
    );
    const reRenderComp = () => {
        setMobileJobs([]);
        getJobsData(activeTab, 1);
        setCurrentJobIndx(0);
    };

    useEffect(() => {
        if (page && window.innerWidth > 800) {
            getJobsData(activeTab, page);
        }
    }, [activeTab, page, getJobsData]);

    useEffect(() => {
        if (
            currentJobIndx !== -1 &&
            currentJobIndx + 1 >= mobileJobs.length &&
            hasClientJob === true &&
            window.innerWidth <= 800 &&
            (Math.ceil(mobileJobs.length / 2) + 1 === 1 ||
                Math.ceil(mobileJobs.length / 2) + 1 <= totalPages)
        ) {
            getJobsData(activeTab, Math.ceil(mobileJobs.length / 2) + 1);
        }
    }, [
        mobileJobs,
        currentJobIndx,
        activeTab,
        totalPages,
        hasClientJob,
        getJobsData,
    ]);

    // useEffect(() => {
    //     if (!page) {
    //         setPage(1);
    //     }
    // }, [page]);
    // useEffect(() => {
    //     if (currentJobIndx === -1) {
    //         setTotalPages(pre => pre + 1)
    //         setCurrentJobIndx(0);
    //     }
    // }, [currentJobIndx]);
    return (
        <div className='client-my-jobs-main'>
            <div className='content'>
                <h3>My Job Posts</h3>
                <div className='main-container'>
                    <div className='heading'>
                        <span
                            className={`${activeTab === "all" ? "active" : ""}`}
                            onClick={() => {
                                setActiveTab("all");
                                setPage(1);
                                setCurrentJobIndx(0);
                                setMobileJobs([]);
                                setHasClientJob(true);
                            }}>
                            <p>
                                All <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "active_post" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveTab("active_post");
                                setPage(1);
                                setCurrentJobIndx(0);
                                setMobileJobs([]);
                                setHasClientJob(true);
                            }}>
                            <p>
                                Active Post <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "completed_post" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveTab("completed_post");
                                setPage(1);
                                setCurrentJobIndx(0);
                                setMobileJobs([]);
                                setHasClientJob(true);
                            }}>
                            <p>
                                Completed Post <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "offer_letter" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveTab("offer_letter");
                                setPage(1);
                                setCurrentJobIndx(0);
                                setMobileJobs([]);
                                setHasClientJob(true);
                            }}>
                            <p>
                                Released Offer Letter <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                    </div>
                    <div className='my-jobs desktop'>
                        <div className='job-container'>
                            {jobs?.map((job, indx) => (
                                <div
                                    className='job'
                                    key={"job-" + job?.job?.id}>
                                    <div className='job-details-container'>
                                        {showOptionsForJob !== undefined &&
                                            showOptionsForJob ===
                                                job?.job?.id && (
                                                <div
                                                    className='back-drop'
                                                    onClick={() =>
                                                        setShowOptionsForJob(
                                                            undefined
                                                        )
                                                    }></div>
                                            )}
                                        <p className='no'>{indx + 1}.</p>
                                        <div className='details'>
                                            <div className='job-title'>
                                                <span className='job-title-container'>
                                                    <p className='title'>
                                                        {job?.job?.title}
                                                    </p>
                                                    <span className='seperator'></span>
                                                    <p className='budget'>
                                                        From{" "}
                                                        {job?.job
                                                            ?.currency_unit ===
                                                        "INR"
                                                            ? "₹"
                                                            : "$"}
                                                        {job?.job?.budget[0]} -{" "}
                                                        {job?.job?.budget[1]}
                                                    </p>
                                                </span>
                                                {activeTab !==
                                                    "offer_letter" && (
                                                    <MoreIcon
                                                        onClick={() =>
                                                            setShowOptionsForJob(
                                                                job?.job?.id
                                                            )
                                                        }
                                                    />
                                                )}
                                                {showOptionsForJob !==
                                                    undefined &&
                                                    activeTab !==
                                                        "offer_letter" &&
                                                    showOptionsForJob ===
                                                        job?.job?.id && (
                                                        <div className='options'>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    openForRebid(
                                                                        job?.job
                                                                            ?.id
                                                                    )
                                                                }>
                                                                Open for rebid
                                                            </span>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    navigateTo(
                                                                        "/post-new-job?job_id=" +
                                                                            job
                                                                                ?.job
                                                                                ?.id
                                                                    )
                                                                }>
                                                                Update
                                                            </span>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        job?.job
                                                                            ?.id
                                                                    )
                                                                }>
                                                                Delete
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className='about-job'>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Skills required for your
                                                        job
                                                    </p>
                                                    <p className='desc'>
                                                        {job?.job?.skills
                                                            ?.map(
                                                                (skill) =>
                                                                    skill.skill
                                                            )
                                                            .join(", ")}
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Scope
                                                    </p>
                                                    <p className='desc'>
                                                        {job?.job?.duration}
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Description
                                                    </p>
                                                    <p className='desc'>
                                                        {job?.job?.description}
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    {job?.job?.attachments?.map(
                                                        (attach, indx) => (
                                                            <span
                                                                className='attachment'
                                                                key={
                                                                    "attachment-" +
                                                                    attach.id
                                                                }
                                                                onClick={() =>
                                                                    window.open(
                                                                        domainName +
                                                                            "/uploads/" +
                                                                            attach.link,
                                                                        "_blank"
                                                                    )
                                                                }>
                                                                <img
                                                                    src={
                                                                        PdfIcon
                                                                    }
                                                                    alt=''
                                                                />
                                                                <p>
                                                                    {
                                                                        attach.file_name
                                                                    }
                                                                </p>
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`bids ${
                                            job?.bids?.length ||
                                            job?.accepted_bids?.length
                                                ? ""
                                                : "hide"
                                        }`}>
                                        {(job?.bids?.length > 0 ||
                                            job?.accepted_bids?.length ===
                                                0) && (
                                            <p className='bid-title'>
                                                Bid request ({job?.bids?.length}
                                                )
                                            </p>
                                        )}
                                        <div className='bid-list'>
                                            {job?.bids?.map((bid) => (
                                                <div
                                                    className='bid'
                                                    key={
                                                        "job-" +
                                                        job.job.id +
                                                        "-bid-" +
                                                        bid.id
                                                    }>
                                                    <div className='about-freelancer'>
                                                        <div className='about-container'>
                                                            <div
                                                                className='image-container'
                                                                onClick={() =>
                                                                    navigateTo(
                                                                        "/freelancer-profile?freelancer_id=" +
                                                                            bid.freelancer_id +
                                                                            "&job_id=" +
                                                                            job
                                                                                .job
                                                                                .id
                                                                    )
                                                                }>
                                                                <img
                                                                    src={
                                                                        domainName +
                                                                        "/uploads/" +
                                                                        bid?.profile_img
                                                                    }
                                                                    alt=''
                                                                />
                                                            </div>
                                                            <div className='ratings-container'>
                                                                <p
                                                                    className='name'
                                                                    onClick={() =>
                                                                        navigateTo(
                                                                            "/freelancer-profile?freelancer_id=" +
                                                                                bid.freelancer_id +
                                                                                "&job_id=" +
                                                                                job
                                                                                    .job
                                                                                    .id
                                                                        )
                                                                    }>
                                                                    {bid?.name}
                                                                    <span className='seperator'></span>
                                                                    <span className='budget'>
                                                                        {job
                                                                            ?.job
                                                                            ?.currency_unit ===
                                                                        "INR"
                                                                            ? "₹"
                                                                            : "$"}
                                                                        {
                                                                            bid?.amount
                                                                        }
                                                                    </span>
                                                                    {bid?.is_account_verified && (
                                                                        <>
                                                                            <span className='seperator'></span>
                                                                            <span className='account-verified'>
                                                                                <CheckIcon />
                                                                                <p>
                                                                                    Account
                                                                                    Verified
                                                                                </p>
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <span className='ratings'>
                                                                    {Array(5)
                                                                        .fill(0)
                                                                        .map(
                                                                            (
                                                                                ele,
                                                                                indx
                                                                            ) => (
                                                                                <StarReview
                                                                                    key={
                                                                                        "user-review-ratings-" +
                                                                                        bid?.id +
                                                                                        "-" +
                                                                                        indx
                                                                                    }
                                                                                    className={`${
                                                                                        bid?.ratings >
                                                                                        indx
                                                                                            ? "filled"
                                                                                            : ""
                                                                                    }`}
                                                                                />
                                                                            )
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className='primary-btn-white'
                                                            onClick={() => {
                                                                setProposal(
                                                                    bid
                                                                );
                                                                handleProposalModalShow(
                                                                    true
                                                                );
                                                            }}>
                                                            View Bid
                                                        </button>
                                                    </div>
                                                    <div className='bid-details'>
                                                        <p className='desc'>
                                                            {bid?.category}
                                                        </p>
                                                        <p className='bold'>
                                                            {bid?.skills
                                                                ?.map(
                                                                    (skill) =>
                                                                        skill.skill
                                                                )
                                                                .join(", ")}
                                                        </p>
                                                        {activeTab ===
                                                            "offer_letter" && (
                                                            <>
                                                                {" "}
                                                                {bid?.payment_recived_from_client ? (
                                                                    <p className='payment-completed'>
                                                                        {" "}
                                                                        <CheckIcon />{" "}
                                                                        Payment
                                                                        Completed
                                                                    </p>
                                                                ) : (
                                                                    <button
                                                                        className='btn-white-outline payment-btn'
                                                                        onClick={() =>
                                                                            navigateTo(
                                                                                `/freelancer-profile?freelancer_id=${bid?.freelancer_id}&job_id=${job?.job?.id}&hire=true`
                                                                            )
                                                                        }>
                                                                        Complete
                                                                        Payment
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {job?.accepted_bids?.length > 0 && (
                                            <p className='bid-title'>
                                                Freelancers (
                                                {job?.accepted_bids?.length})
                                            </p>
                                        )}
                                        <div className='bid-list'>
                                            {job?.accepted_bids?.map((bid) => (
                                                <div
                                                    className='bid'
                                                    key={
                                                        "job-" +
                                                        job.job.id +
                                                        "-bid-" +
                                                        bid.id
                                                    }>
                                                    <div className='about-freelancer'>
                                                        <div className='about-container'>
                                                            <div
                                                                className='image-container'
                                                                onClick={() =>
                                                                    navigateTo(
                                                                        "/freelancer-profile?freelancer_id=" +
                                                                            bid.freelancer_id +
                                                                            "&job_id=" +
                                                                            job
                                                                                .job
                                                                                .id
                                                                    )
                                                                }>
                                                                <img
                                                                    src={
                                                                        domainName +
                                                                        "/uploads/" +
                                                                        bid?.profile_img
                                                                    }
                                                                    alt=''
                                                                />
                                                            </div>
                                                            <div className='ratings-container'>
                                                                <p
                                                                    className='name'
                                                                    onClick={() =>
                                                                        navigateTo(
                                                                            "/freelancer-profile?freelancer_id=" +
                                                                                bid.freelancer_id +
                                                                                "&job_id=" +
                                                                                job
                                                                                    .job
                                                                                    .id
                                                                        )
                                                                    }>
                                                                    {bid?.name}
                                                                    <span className='seperator'></span>
                                                                    <span className='budget'>
                                                                        {job
                                                                            ?.job
                                                                            ?.currency_unit ===
                                                                        "INR"
                                                                            ? "₹"
                                                                            : "$"}
                                                                        {
                                                                            bid?.amount
                                                                        }
                                                                    </span>
                                                                </p>
                                                                <span className='ratings'>
                                                                    {Array(5)
                                                                        .fill(0)
                                                                        .map(
                                                                            (
                                                                                ele,
                                                                                indx
                                                                            ) => (
                                                                                <StarReview
                                                                                    key={
                                                                                        "user-review-ratings-" +
                                                                                        bid?.id +
                                                                                        "-" +
                                                                                        indx
                                                                                    }
                                                                                    className={`${
                                                                                        bid?.ratings >
                                                                                        indx
                                                                                            ? "filled"
                                                                                            : ""
                                                                                    }`}
                                                                                />
                                                                            )
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className='primary-btn-white'
                                                            onClick={() => {
                                                                setProposal(
                                                                    bid
                                                                );
                                                                handleProposalModalShow(
                                                                    true
                                                                );
                                                            }}>
                                                            View Bid
                                                        </button>
                                                    </div>
                                                    <div className='bid-details'>
                                                        <p className='desc'>
                                                            {bid?.category}
                                                        </p>
                                                        <p className='bold'>
                                                            {bid?.skills
                                                                ?.map(
                                                                    (skill) =>
                                                                        skill.skill
                                                                )
                                                                .join(", ")}
                                                        </p>
                                                        {job?.job
                                                            ?.is_freelancer_hired && (
                                                            <>
                                                                <div className='dates'>
                                                                    <p className='desc'>
                                                                        Start
                                                                        date:{" "}
                                                                        <span className='bold'>
                                                                            {new Date(
                                                                                bid.start_date
                                                                            ).getDate()}{" "}
                                                                            -{" "}
                                                                            {new Date(
                                                                                bid.start_date
                                                                            ).getMonth() +
                                                                                1}
                                                                        </span>
                                                                    </p>
                                                                    <p className='desc'>
                                                                        End
                                                                        date:{" "}
                                                                        <span className='bold date'>
                                                                            {new Date(
                                                                                bid.end_date
                                                                            ).getDate()}{" "}
                                                                            -{" "}
                                                                            {new Date(
                                                                                bid.end_date
                                                                            ).getMonth() +
                                                                                1}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                {bid?.current_iteration ? (
                                                                    <p className='desc'>
                                                                        Current
                                                                        Iteration:{" "}
                                                                        <span className='bold'>
                                                                            {
                                                                                bid?.current_iteration
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                ) : (
                                                                    <p className='desc'>
                                                                        Iteration:{" "}
                                                                        <span className='bold'>
                                                                            {
                                                                                bid?.iteration
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                        {bid?.work_submitted &&
                                                            !bid.payment_completed && (
                                                                <div className='buttons'>
                                                                    <button
                                                                        className='btn-white-outline'
                                                                        onClick={() => {
                                                                            setSelectedProposal(
                                                                                bid
                                                                            );
                                                                            handleWorkApproveModalShow(
                                                                                true
                                                                            );
                                                                        }}>
                                                                        View
                                                                        Work &
                                                                        Approve
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {/* <button
                                                                className='btn-white-outline view-proposal-btn'
                                                                onClick={() => {
                                                                    setProposal(
                                                                        bid
                                                                    );
                                                                    handleProposalModalShow(
                                                                        true
                                                                    );
                                                                }}>
                                                                View Proposal
                                                            </button> */}
                                                        {bid?.iteration > 1 && (
                                                            <div className='revised_due_date'>
                                                                <InfoIcon />{" "}
                                                                <p>
                                                                    Due date got
                                                                    revised for
                                                                    this work.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!jobs?.length && activeTab === "all" ? (
                                <p className='empty-message'>
                                    Oops, you haven't any post job.
                                </p>
                            ) : (
                                <></>
                            )}
                            {!jobs?.length && activeTab === "active_post" ? (
                                <p className='empty-message'>
                                    Oops, you don't have any active job post
                                    yet.
                                </p>
                            ) : (
                                <></>
                            )}
                            {!jobs?.length && activeTab === "completed_post" ? (
                                <p className='empty-message'>
                                    Oops, you didn't complete any job post yet.
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className='my-jobs mobile'>
                        <div className='job-container'>
                            {/* {mobileJobs?.map((job, indx) => ( */}
                            {mobileJobs.length > 0 && (
                                <div className='job'>
                                    <div className='job-details-container'>
                                        {showOptionsForJob !== undefined &&
                                            showOptionsForJob ===
                                                mobileJobs[currentJobIndx]?.job
                                                    ?.id && (
                                                <div
                                                    className='back-drop'
                                                    onClick={() =>
                                                        setShowOptionsForJob(
                                                            undefined
                                                        )
                                                    }></div>
                                            )}
                                        <div className='details'>
                                            <div className='job-title'>
                                                <span className='job-title-container'>
                                                    <p className='no'>
                                                        {currentJobIndx + 1}.
                                                    </p>
                                                    <p className='title'>
                                                        {
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.title
                                                        }
                                                    </p>
                                                    <span className='seperator'></span>
                                                    <p className='budget'>
                                                        From{" "}
                                                        {mobileJobs[
                                                            currentJobIndx
                                                        ]?.job
                                                            ?.currency_unit ===
                                                        "INR"
                                                            ? "₹"
                                                            : "$"}
                                                        {
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.budget[0]
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.budget[1]
                                                        }
                                                    </p>
                                                </span>
                                                <MoreIcon
                                                    MoreIcon
                                                    onClick={() =>
                                                        setShowOptionsForJob(
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.id
                                                        )
                                                    }
                                                />
                                                {showOptionsForJob !==
                                                    undefined &&
                                                    showOptionsForJob ===
                                                        mobileJobs[
                                                            currentJobIndx
                                                        ]?.job?.id && (
                                                        <div className='options'>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    openForRebid(
                                                                        mobileJobs[
                                                                            currentJobIndx
                                                                        ]?.job
                                                                            ?.id
                                                                    )
                                                                }>
                                                                Open for rebid
                                                            </span>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    navigateTo(
                                                                        "/post-new-job?job_id=" +
                                                                            mobileJobs[
                                                                                currentJobIndx
                                                                            ]
                                                                                ?.job
                                                                                ?.id
                                                                    )
                                                                }>
                                                                Update
                                                            </span>
                                                            <span
                                                                className='option'
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        mobileJobs[
                                                                            currentJobIndx
                                                                        ]?.job
                                                                            ?.id
                                                                    )
                                                                }>
                                                                Delete
                                                            </span>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className='about-job'>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Skills required for your
                                                        job
                                                    </p>
                                                    <p className='desc'>
                                                        {mobileJobs[
                                                            currentJobIndx
                                                        ]?.job?.skills
                                                            ?.map(
                                                                (skill) =>
                                                                    skill.skill
                                                            )
                                                            .join(", ")}
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Scope
                                                    </p>
                                                    <p className='desc'>
                                                        {
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.duration
                                                        }
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    <p className='bold'>
                                                        Description
                                                    </p>
                                                    <p className='desc'>
                                                        {
                                                            mobileJobs[
                                                                currentJobIndx
                                                            ]?.job?.description
                                                        }
                                                    </p>
                                                </div>
                                                <div className='grp'>
                                                    {mobileJobs[
                                                        currentJobIndx
                                                    ]?.job?.attachments?.map(
                                                        (attach, indx) => (
                                                            <span
                                                                className='attachment'
                                                                key={
                                                                    "attachment-" +
                                                                    attach.id
                                                                }
                                                                onClick={() =>
                                                                    window.open(
                                                                        domainName +
                                                                            "/uploads/" +
                                                                            attach.link,
                                                                        "_blank"
                                                                    )
                                                                }>
                                                                <img
                                                                    src={
                                                                        PdfIcon
                                                                    }
                                                                    alt=''
                                                                />
                                                                <p>
                                                                    {
                                                                        attach.file_name
                                                                    }
                                                                </p>
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`bids ${
                                            mobileJobs[currentJobIndx]?.bids
                                                ?.length
                                                ? ""
                                                : "hide"
                                        }`}>
                                        {mobileJobs[currentJobIndx]?.job
                                            ?.is_freelancer_hired ? (
                                            <p className='bid-title'>
                                                Freelancer
                                            </p>
                                        ) : (
                                            <p className='bid-title'>
                                                Bid request (
                                                {
                                                    mobileJobs[currentJobIndx]
                                                        ?.bids?.length
                                                }
                                                )
                                            </p>
                                        )}
                                        <div className='bid-list'>
                                            {mobileJobs[
                                                currentJobIndx
                                            ]?.bids?.map((bid) => (
                                                <div
                                                    className='bid'
                                                    key={
                                                        "job-" +
                                                        mobileJobs[
                                                            currentJobIndx
                                                        ]?.job.id +
                                                        "-bid-" +
                                                        bid.id
                                                    }>
                                                    <div className='about-freelancer'>
                                                        <div className='about-container'>
                                                            <div
                                                                className='image-container'
                                                                onClick={() =>
                                                                    navigateTo(
                                                                        "/freelancer-profile?freelancer_id=" +
                                                                            bid.freelancer_id +
                                                                            "&job_id=" +
                                                                            mobileJobs[
                                                                                currentJobIndx
                                                                            ]
                                                                                ?.job
                                                                                .id
                                                                    )
                                                                }>
                                                                <img
                                                                    src={
                                                                        domainName +
                                                                        "/uploads/" +
                                                                        bid?.profile_img
                                                                    }
                                                                    alt=''
                                                                />
                                                            </div>
                                                            <div className='ratings-container'>
                                                                <p
                                                                    className='name'
                                                                    onClick={() =>
                                                                        navigateTo(
                                                                            "/freelancer-profile?freelancer_id=" +
                                                                                bid.freelancer_id +
                                                                                "&job_id=" +
                                                                                mobileJobs[
                                                                                    currentJobIndx
                                                                                ]
                                                                                    ?.job
                                                                                    .id
                                                                        )
                                                                    }>
                                                                    {bid?.name}
                                                                    <span className='seperator'></span>
                                                                    <span className='budget'>
                                                                        {mobileJobs[
                                                                            currentJobIndx
                                                                        ]?.job
                                                                            ?.currency_unit ===
                                                                        "INR"
                                                                            ? "₹"
                                                                            : "$"}
                                                                        {
                                                                            bid?.amount
                                                                        }
                                                                    </span>
                                                                </p>
                                                                <span className='ratings'>
                                                                    {Array(5)
                                                                        .fill(0)
                                                                        .map(
                                                                            (
                                                                                ele,
                                                                                indx
                                                                            ) => (
                                                                                <StarReview
                                                                                    key={
                                                                                        "user-review-ratings-" +
                                                                                        bid?.id +
                                                                                        "-" +
                                                                                        indx
                                                                                    }
                                                                                    className={`${
                                                                                        bid?.ratings >
                                                                                        indx
                                                                                            ? "filled"
                                                                                            : ""
                                                                                    }`}
                                                                                />
                                                                            )
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className='primary-btn-white'
                                                            onClick={() => {
                                                                setProposal(
                                                                    bid
                                                                );
                                                                handleProposalModalShow(
                                                                    true
                                                                );
                                                            }}>
                                                            View Bid
                                                        </button>
                                                    </div>
                                                    <div className='bid-details'>
                                                        <p className='desc'>
                                                            {bid?.category}
                                                        </p>
                                                        <p className='bold'>
                                                            {bid?.skills
                                                                ?.map(
                                                                    (skill) =>
                                                                        skill.skill
                                                                )
                                                                .join(", ")}
                                                        </p>
                                                        {mobileJobs[
                                                            currentJobIndx
                                                        ]?.job
                                                            ?.is_freelancer_hired && (
                                                            <>
                                                                <div className='dates'>
                                                                    <p className='desc'>
                                                                        Start
                                                                        date:{" "}
                                                                        <span className='bold'>
                                                                            {new Date(
                                                                                bid.start_date
                                                                            ).getDate()}{" "}
                                                                            -{" "}
                                                                            {new Date(
                                                                                bid.start_date
                                                                            ).getMonth() +
                                                                                1}
                                                                        </span>
                                                                    </p>
                                                                    <p className='desc'>
                                                                        End
                                                                        date:{" "}
                                                                        <span className='bold date'>
                                                                            {new Date(
                                                                                bid.end_date
                                                                            ).getDate()}{" "}
                                                                            -{" "}
                                                                            {new Date(
                                                                                bid.end_date
                                                                            ).getMonth() +
                                                                                1}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                {bid?.current_iteration ? (
                                                                    <p className='desc'>
                                                                        Current
                                                                        Iteration:{" "}
                                                                        <span className='bold'>
                                                                            {
                                                                                bid?.current_iteration
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                ) : (
                                                                    <p className='desc'>
                                                                        Iteration:{" "}
                                                                        <span className='bold'>
                                                                            {
                                                                                bid?.iteration
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                        {bid?.work_submitted &&
                                                            !bid.payment_completed && (
                                                                <div className='buttons'>
                                                                    <button
                                                                        className='btn-white-outline'
                                                                        onClick={() => {
                                                                            setSelectedProposal(
                                                                                bid
                                                                            );
                                                                            handleWorkApproveModalShow(
                                                                                true
                                                                            );
                                                                        }}>
                                                                        View
                                                                        Work &
                                                                        Approve
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {/* <button
                                                                className='btn-white-outline view-proposal-btn'
                                                                onClick={() => {
                                                                    setProposal(
                                                                        bid
                                                                    );
                                                                    handleProposalModalShow(
                                                                        true
                                                                    );
                                                                }}>
                                                                View Proposal
                                                            </button> */}
                                                        {bid?.iteration > 1 && (
                                                            <div className='revised_due_date'>
                                                                <InfoIcon />{" "}
                                                                <p>
                                                                    Due date got
                                                                    revised for
                                                                    this work.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!mobileJobs?.length && activeTab === "all" ? (
                                <p className='empty-message'>
                                    Oops, you haven't any post job.
                                </p>
                            ) : (
                                <></>
                            )}
                            {!mobileJobs?.length &&
                            activeTab === "active_post" ? (
                                <p className='empty-message'>
                                    Oops, you don't have any active job post
                                    yet.
                                </p>
                            ) : (
                                <></>
                            )}
                            {!mobileJobs?.length &&
                            activeTab === "completed_post" ? (
                                <p className='empty-message'>
                                    Oops, you didn't complete any job post yet.
                                </p>
                            ) : (
                                <></>
                            )}
                            {/* ))} */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pagination'>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </div>
            {mobileJobs?.length > 0 && (
                <>
                    <span
                        className='navigate-job prev'
                        onClick={() =>
                            setCurrentJobIndx((pre) =>
                                pre === 0 ? pre : pre - 1
                            )
                        }>
                        <ExpandIcon />
                    </span>
                    <span
                        className='navigate-job next'
                        onClick={() =>
                            setCurrentJobIndx((pre) =>
                                pre === mobileJobs.length - 1 ? pre : pre + 1
                            )
                        }>
                        <ExpandIcon />
                    </span>
                </>
            )}
            <ProposalModal proposal={proposal} callback={reRenderComp} />
            <WorkApproveModal
                proposal_details={selectedProposal}
                callback={reRenderComp}
            />
            <ReleaseFundModal
                proposal_id={selectedProposal?.id}
                callback={reRenderComp}
            />
            <FeedbackModal
                proposal_id={selectedProposal?.id}
                freelancer_id={selectedProposal?.freelancer_id}
            />
        </div>
    );
};

export default ClientMyJobs;
