import React, { useEffect, useState } from "react";

import "./privacyPolicy.scss";
import { getPrivacyPolicy } from "../../apiCall";
import { CACHE_TIME } from "../../Constants";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const PrivacyPolicy = () => {
    const [activeTab, setActiveTab] = useState();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    const [privacyPolicy, setPrivacyPolicy] = useState();

    useQuery(["get-privacy-policy"], () => getPrivacyPolicy(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.status[0].Error === "False") {
                setPrivacyPolicy(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    return (
        <div className='privacy-policy-main'>
            <h2>Privacy Policy</h2>
            <div className='details-container'>
                <div className='side-bar'>
                    {privacyPolicy?.details?.map((policy, indx) => (
                        policy?.title ? <span
                            key={'indx' + indx + policy?.title}
                            className={activeTab === policy?.title ? "active" : ""}
                            onClick={() => {
                                setActiveTab(policy?.title);
                            }}>
                            <a href={`#${policy?.title}`}>{policy?.title}</a>
                        </span> : <></>
                    ))}
                </div>
                <div className='content'>
                    <div className='head'>
                        <h3>Privacy Policy</h3>
                        {/* <p>Date: {new Date(privacyPolicy?.create_at).toLocaleDateString()}</p> */}
                    </div>
                    <div className='text-container'>
                        {privacyPolicy?.details?.map((policy, indx) =>  <div className='grp' key={'indx-details-' + indx + policy?.title}>
                            <span className='my-spn' id={policy?.title}></span>
                            <h4>{policy?.title}</h4>
                            {policy?.description?.map((desc, indx) => <p key={'policy-desc-'+indx}>{desc}</p>)}
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
