import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as StarIcon } from "../../../Assets/icons/star.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/icons/check-circle.svg";
import { ReactComponent as LocationIcon } from "../../../Assets/icons/location.svg";
import { ReactComponent as CalenderIcon } from "../../../Assets/icons/calender.svg";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit.svg";
import { ReactComponent as WorkIcon } from "../../../Assets/icons/work.svg";
import { ReactComponent as UserIcon } from "../../../Assets/icons/person.svg";

import "./jobDetails.scss";
import AboutJob from "./Components/AboutJob";
import BidForm from "./Components/BidForm";
import { SuccessModal } from "../../../Modals";
import { getBid, getJobDetails } from "../../../apiCall";
import toast from 'react-hot-toast';
import { useStateValue } from "../../../StateProvider";

// const JOB_DETAILS = {
//     id: 5,
//     title: "Banking Mobile App on IOS and Android",
//     desc: "You get an SEO-optimized, mobile responsive, custom-tailored brand home that echoes your brand values with appealing aesthetics. And that’s why Think To Share ranks as the #1 web development agency in India. Our services include Web Development, Web Designing, and Maintaining an impactful web presence for you. You can choose and customize your package. In case, you are not sure which is the perfect service for your brand, you can jump on a no-obligation call with us. We are always there to make your journey easy and hassle-free. You get an SEO-optimized, mobile responsive, custom-tailored brand home that echoes your brand values with appealing aesthetics and that’s why Think To Share ranks as the #1 web development agency in India. Our services include Web Development, Web Designing, and Maintaining an impactful web presence for you. You can choose and customize your package. In case, you are not sure which is the perfect service for your brand, you can jump on a no-obligation call with us. We are always there to make your journey easy and hassle-free.",
//     responsibilities:
//         "You get an SEO-optimized, mobile responsive, custom-tailored brand home that echoes your brand values with appealing aesthetics. And that’s why Think To Share ranks as the #1 web development agency in India. Our services include Web Development, Web Designing, and Maintaining an impactful web presence for you. You can choose and customize your package. In case, you are not sure which is the perfect service for your brand, you can jump on a no-obligation call with us. We are always there to make your journey easy and hassle-free. You get an SEO-optimized, mobile responsive, custom-tailored brand home that echoes your brand values with appealing aesthetics and that’s why Think To Share ranks as the #1 web development agency in India.",
//     attachments: [
//         {
//             id: 2,
//             type: "pdf",
//             name: "Baldor technologies.pdf",
//             link: "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         },
//         {
//             id: 1,
//             type: "pdf",
//             name: "Baldor technologies.pdf",
//             link: "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         },
//     ],
//     budget: [1850, 2200],
//     skills: [
//         {
//             id: 1,
//             name: "Prototyping",
//         },
//         {
//             id: 2,
//             name: "Project Management",
//         },
//         {
//             id: 3,
//             name: "Team Player",
//         },
//         {
//             id: 4,
//             name: "UI Design",
//         },
//         {
//             id: 5,
//             name: "Design Thinking",
//         },
//         {
//             id: 6,
//             name: "User Interviews",
//         },
//         {
//             id: 7,
//             name: "User Research",
//         },
//     ],
//     is_payment_verified: true,
//     ratings: 4,
//     location: "Australia",
//     posted: "Posted 1 month ago",
//     number_of_proposals: 15,
// };
const JobDetails = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("job-details");
    const [jobDetail, setJobDetail] = useState();
  const [, dispatch] = useStateValue();

    const [bidDetails, setBidDetails] = useState({
        bid: "",
        time: "",
        proposal: "",
    });
    const [isEditBid, setIsEditBid] = useState(false);

    console.log(window.location)
    useEffect(() => {

        const getDetails = async () => {
            dispatch({type:"SET_IS_LOADING",status:true})

            const Re = await getJobDetails(id);
            setTimeout(()=>{
                dispatch({ type: "SET_IS_LOADING", status: false });
    
            }, 1000);

            if (Re.data?.status[0].Error === "False") {
                setJobDetail(Re.data.value);
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };

        const getBidDetails = async () => {
            dispatch({type:"SET_IS_LOADING",status:true})

            const Re = await getBid(id);
            setTimeout(()=>{
                dispatch({ type: "SET_IS_LOADING", status: false });
    
            }, 1000);

            if (Re.data?.status[0].Error === "False") {
                setBidDetails({
                    bid: Re.data?.value.amount,
                    time: Re.data?.value.duration,
                    days: Re.data?.value?.duration?.split(" ")[0]
                        ? Re.data?.value?.duration?.split(" ")[0]
                        : "0",
                    months: Re.data?.value?.duration?.split(" ")[2]
                        ? Re.data?.value?.duration?.split(" ")[2]
                        : "0",
                    years: Re.data?.value?.duration?.split(" ")[4]
                        ? Re.data?.value?.duration?.split(" ")[4]
                        : "0",
                    proposal: Re.data?.value.proposal,
                });
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };

        getDetails();
        getBidDetails();
        const searchParams = new URLSearchParams(window.location.search);
        console.log(searchParams);
        const params = {};
        for (const [key, value] of searchParams) {
            params[key] = value;
        }
        if (params["edit_bid"] === "true") {
            setActiveTab("bid-now");
            setIsEditBid(true);
        }
    }, [id, dispatch]);

    return (
        <div className='job-details-main'>
            <div className='job-container'>
                <div className='heading'>
                    <span
                        className={`${
                            activeTab === "job-details" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("job-details")}>
                        <p>Job details</p>
                        <span className='underline'></span>
                    </span>
                    <span
                        className={`${activeTab === "bid-now" ? "active" : ""}`}
                        onClick={() => setActiveTab("bid-now")}>
                        <p>Bid Now</p>
                        <span className='underline'></span>
                    </span>
                </div>
                {activeTab === "job-details" && (
                    <AboutJob
                        JobDetails={jobDetail}
                        setActiveTab={setActiveTab}
                    />
                )}
                {activeTab === "bid-now" && (
                    <BidForm
                        id={id}
                        currency_unit={jobDetail?.currency_unit}
                        bidDetails={bidDetails}
                        setActiveTab={setActiveTab}
                        isEditBid={isEditBid}
                    />
                )}
            </div>
            <div className='about-job-main bid-about-main'>
                {activeTab === "job-details" && (
                    <div className='about-job-head'>
                        <div className='budget'>
                            <h2>
                                {jobDetail?.currency_unit === "INR" ? "₹" : "$"}
                                {jobDetail?.budget_lower_bound} -{" "}
                                {jobDetail?.currency_unit === "INR" ? "₹" : "$"}
                                {jobDetail?.budget_upper_bound}
                            </h2>
                            <p>Project Budget</p>
                        </div>
                        <button
                            className='btn-blue'
                            onClick={(e) => setActiveTab("bid-now")}>
                            Next: View Bid
                        </button>
                        <button className='btn-white-outline'>
                            Save for later
                        </button>
                    </div>
                )}
                <div className='about'>
                    <p className='title'>About Client</p>
                    {activeTab === "bid-now" && <hr />}
                    {activeTab === "bid-now" && (
                        <div className='budget'>
                            <h2>
                                {jobDetail?.currency_unit === "INR" ? "₹" : "$"}
                                {jobDetail?.budget_lower_bound} -{" "}
                                {jobDetail?.currency_unit === "INR" ? "₹" : "$"}
                                {jobDetail?.budget_upper_bound}
                            </h2>
                            <p>Project Budget</p>
                        </div>
                    )}
                    <div className='about-container'>
                        {jobDetail?.is_payment_verified && (
                            <span className='payment-verified item'>
                                <CheckIcon /> <p>Payment verified</p>
                            </span>
                        )}
                        <span className='rating item'>
                            <StarIcon />
                            <p>{jobDetail?.ratings} Rating</p>
                        </span>
                        <span className='item'>
                            <CalenderIcon />
                            <p>Posted {jobDetail?.posted} days ago</p>
                        </span>
                        <span className='fill-none item'>
                            <EditIcon />
                            <p>{jobDetail?.no_of_bids} Proposals</p>
                        </span>
                        <span className='fill-none item'>
                            <LocationIcon />
                            <p>
                                {jobDetail?.city}
                                {jobDetail?.state ? "," : ""} {jobDetail?.state}
                                {jobDetail?.country ? "," : ""}{" "}
                                {jobDetail?.country}
                            </p>
                        </span>
                        <span className='item'>
                            <WorkIcon />
                            <p>
                                {jobDetail?.number_of_jobs} Job posted,{" "}
                                {jobDetail?.hire_rate}% hire rate
                            </p>
                        </span>
                        <span className='item'>
                            <UserIcon />
                            <p>Member since, {jobDetail?.joining_year}</p>
                        </span>
                    </div>
                </div>
            </div>
            <SuccessModal
                text={"Yeah! You have successfully submit your proposal."}
                redirect_to_home={true}
            />
        </div>
    );
};

export default JobDetails;
