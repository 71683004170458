import React, { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Clock from "../../../../Assets/icons/clock.svg";
import { ReactComponent as Close } from "../../../../Assets/icons/close.svg";

import "./step4.scss";

const Step4 = ({
    formData,
    serviceList,
    categories,
    setFormData,
    setLoginPageNo,
}) => {
    const [searchService, setsearchService] = useState("");
    const [services, setService] = useState([]);
    const [servicesSet, setServicesSet] = useState(new Set());
    // const [currentSuggestedServices, setCurrentSuggestedServices] = useState(
    //     []
    // );
    const [suggestedServices, setSuggestedServices] = useState([]);
    const [extraService, setExtraService] = useState("");
    const [errors, setErrors] = useState({});

    const isStillSuggested = (service) => {
        let result = true;
        formData.services.forEach((selectedService) => {
            if (selectedService.id === service.id) {
                result = false;
            }
        });
        return result;
    };

    useEffect(() => {
        setSuggestedServices(
            serviceList.filter((service) => {
                console.log("first check", service.name);
                if (
                    parseInt(service.category) !==
                    parseInt(formData.service_category)
                ) {
                    return false;
                }
                let result = true;
                console.log("2nd check", service.name);
                formData.services.forEach((selectedService) => {
                    if (selectedService?.id === service?.id) {
                        result = false;
                    }
                });
                console.log(" check", service.name);
                return result;
            })
        );
    }, [serviceList, formData.services, formData.service_category]);
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setsearchService(string);
        setExtraService(string);
    };

    const handleNext = () => {
        if (!formData?.service_category) {
            setErrors((pre) => {
                return {
                    ...pre,
                    service_category: "Category of service is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, service_category: "" };
            });
        }
        if (!formData?.services?.length) {
            setErrors((pre) => {
                return { ...pre, services: "Service is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, services: [] };
            });
        }
        localStorage.setItem("formData", JSON.stringify(formData));
        setLoginPageNo((pre) => {
            localStorage.setItem("loginPageNo", pre + 1);
            return pre + 1;
        });
    };

    const handleOnSelect = (item) => {
        if (formData.services.length < 15) {
            setFormData((pre) => {
                const new_services = [...formData.services, item];
                return {
                    ...pre,
                    services: new_services,
                };
            });
            setsearchService("");
            setExtraService("");
        }
    };

    const formatResult = (item) => {
        return (
            <>
                <div style={{ display: "block", textAlign: "left", zIndex: 2 }}>
                    {item.name}
                </div>
            </>
        );
    };

    useEffect(() => {
        const temp_services = []
        // const temp_suggested_services = [];
        // const temp_suggested_services_id = new Set();
        serviceList.forEach((service) => {
            temp_services.push({ id: service.id, name: service.name });
            setServicesSet((pre) => pre.add(service.name));

            // service?.suggested_services.forEach((service) => {
            //     if (!temp_suggested_services_id.has(service.id)) {
            //         temp_suggested_services_id.add(service.id);
            //         temp_suggested_services.push(service);
            //     }
            // });
        });

        setService(temp_services);
        // setCurrentSuggestedServices([...temp_suggested_services]);
    }, [serviceList]);
    return (
        <>
            <div className='step-4-main step-main'>
                {console.log(suggestedServices)}
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 4 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                    </div>
                    <div className='input-grp'>
                        <p className='title'>Category of your services?</p>
                        <select
                            name=''
                            id=''
                            value={formData.service_category}
                            onChange={(e) =>
                                setFormData((prev) => {
                                    return {
                                        ...prev,
                                        service_category: e.target.value,
                                    };
                                })
                            }>
                            <option value=''>Category</option>
                            {categories.map((cat) => (
                                <option
                                    value={cat.id}
                                    key={"category-id-" + cat.id}>
                                    {cat?.name}
                                </option>
                            ))}
                        </select>
                        <p className='form-error'>{errors?.service_category}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='title'>
                            What are the main services you offer?
                        </p>
                        <div className='service-container'>
                            {formData.services.map((service, indx) => (
                                <div
                                    className='selected-service'
                                    key={"selected-service-" + indx}>
                                    {service.name}
                                    <Close
                                        onClick={() =>
                                            setFormData((pre) => {
                                                const new_services =
                                                    formData.services.filter(
                                                        (selectedService) =>
                                                            service.id !==
                                                            selectedService.id
                                                    );
                                                return {
                                                    ...pre,
                                                    services: new_services,
                                                };
                                            })
                                        }
                                    />
                                </div>
                            ))}
                            <ReactSearchAutocomplete
                                items={services
                                    .concat(
                                        servicesSet.has(extraService)
                                            ? []
                                            : [
                                                  {
                                                      id:
                                                          "ry_new_service:" +
                                                          extraService,
                                                      name: extraService,
                                                  },
                                              ]
                                    )
                                    .filter((service) =>
                                        isStillSuggested(service)
                                    )}
                                onSelect={handleOnSelect}
                                fuseOptions={{ keys: ["name"] }}
                                autoFocus
                                inputSearchString={searchService}
                                onSearch={handleOnSearch}
                                formatResult={formatResult}
                                placeholder='Search For Your Service...'
                            />
                        </div>
                        <p className='form-error'>{errors?.services}</p>
                    </div>
                    {suggestedServices?.length > 0 && <div className='input-grp'>
                        <p className='bold'>Suggested Services</p>
                        <div className='suggested-services'>
                            {suggestedServices.slice(0, 5).map((service) => (
                                <span
                                    className='suggested-service'
                                    onClick={() =>
                                        setFormData((pre) => {
                                            const new_services = [
                                                ...formData.services,
                                                service,
                                            ];
                                            return {
                                                ...pre,
                                                services: new_services,
                                            };
                                        })
                                    }
                                    key={"suggested-service" + service.id}>
                                    <Close />
                                    {service.name}
                                </span>
                            ))}
                        </div>
                    </div>}
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleNext}>
                    Next: Bio
                </button>
            </div>
        </>
    );
};

export default Step4;
