import React from "react";

import "./couponApply.scss";
import CheckIcon from "../../../../Assets/icons/check-mark.svg";
import ExpandIcon from "../../../../Assets/icons/expand.svg";

// const coupons = [
//     {
//         id: 1,
//         code: "NEWCUSTOMER01",
//         discount: 100,
//         currency: "INR",
//         description: "Coupon Description",
//     },
//     {
//         id: 2,
//         code: "CUSTOMER02",
//         discount: 150,
//         currency: "INR",
//         description: "Coupon Description 2",
//     },
// ];
const CouponApply = ({ couponDetails, setCouponDetails, setCurrentView, coupons }) => {
    
    return (
        <div className='coupon-apply-main'>
            <p className='bold title'><img src={ExpandIcon} alt="" onClick={() => setCurrentView('purchase-details')}/>APPLY COUPON</p>
            <hr />
            <input
                type='text'
                // value={coupon}
                // onChange={(e) => setCoupon(e.target.value)}
                placeholder='Enter coupon code'
            />
            <div className='coupon-list'>
                {coupons?.map((coupon) => (
                    <div className='coupon' key={"coupon-" + coupon.id}>
                        <span
                            className={`check-box ${couponDetails?.id === coupon?.id ? 'selected' : ''}`}
                            onClick={() => setCouponDetails( coupon)}>
                            {couponDetails?.id === coupon.id && (
                                <img src={CheckIcon} alt='' />
                            )}
                        </span>
                        <div className='details'>
                            <span className="coupon-code"><span>{coupon.code}</span></span>
                            <div>
                                <p className="bold">Save {coupon.discount}% upto {coupon?.maximum_discount_amount}</p>
                                <p className="info">{coupon.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CouponApply;
