import React from "react";
import noDataImg from "../../../Assets/gif/no-data.gif"
import "./EmptyPage.scss"

const EmptyPage = () => {
  return (
    <div className="empty-page-container">
      <img src={noDataImg} alt="" />
      <span>You don't have any conversations.</span>
    </div>
  );
};

export default EmptyPage;
