import React, { useRef, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { ReactComponent as CertificateIcon } from "../../../../Assets/icons/article.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/edit-white.svg";

import "./educationForm.scss";
import { useStateValue } from "../../../../StateProvider";
import { UpdateCertificate } from "../../../../apiCall";
import { domainName } from "../../../../Constants";
import toast from "react-hot-toast";

const initial_data = {
    college: "",
    degree: "",
    otherCertificate: "",
    certificate_url: "",
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
};
const MONTH_MAP = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
};
const DEGREE_NAMES = ["BE", "ME", "BSC", "MSC"];

const EducationForm = ({ formData, setFormData, education_index }) => {
    console.log(formData)
    const [education, setEducation] = useState(
        education_index === -1
            ? initial_data
            : formData.education[education_index]
    );
    const [errors, setErrors] = useState({});
    const [, dispatch] = useStateValue();
    const hideEducationModal = () => {
        dispatch({
            type: "SET_EDUCATION_MODAL_SHOW",
            status: false,
        });
    };
    const fileRef = useRef();
    const uploadCertificate = async (e) => {
        const data = new FormData();
        data.append("certificate", e.target.files[0]);
        dispatch({ type: "SET_IS_LOADING", status: true });

        const Re = await UpdateCertificate(data, education_index === -1 ? formData.education.length : education_index);
        setTimeout(() => {
            dispatch({ type: "SET_IS_LOADING", status: false });
        }, 1000);

        console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            setEducation((pre) => {
                return {
                    ...pre,
                    certificate_url:
                        domainName + "/uploads/" + Re.data.value?.certificate,
                };
            });
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const handleSubmit = () => {
        if (!education?.college) {
            setErrors((pre) => {
                return { ...pre, college: "College is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, college: "" };
            });
        }
        if (!education?.degree) {
            setErrors((pre) => {
                return { ...pre, degree: "Certificate is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, degree: "" };
            });
        }
        if (education?.degree === "Other" && !education?.otherCertificate) {
            setErrors((pre) => {
                return {
                    ...pre,
                    otherCertificate: "Certificate is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, otherCertificate: "" };
            });
        }
        if (!education?.start_month || !education.start_year) {
            setErrors((pre) => {
                return { ...pre, start_date: "Start date is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, start_date: "" };
            });
        }
        if (
            !education?.currently_working &&
            (!education?.end_month || !education.end_year)
        ) {
            setErrors((pre) => {
                return { ...pre, end_date: "End date is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, end_date: "" };
            });
        }
        if (!education?.currently_working) {
            if (education?.end_year < education?.start_year) {
                setErrors((pre) => {
                    return {
                        ...pre,
                        end_date: "End date must be after start date.",
                    };
                });
                return;
            } else if (
                education?.end_year === education?.start_year &&
                MONTH_MAP[education?.end_month] <=
                    MONTH_MAP[education?.start_month]
            ) {
                setErrors((pre) => {
                    return {
                        ...pre,
                        end_date: "End date must be after start date.",
                    };
                });
                return;
            } else {
                setErrors((pre) => {
                    return { ...pre, end_date: "" };
                });
            }
        } else {
            setErrors((pre) => {
                return { ...pre, end_date: "" };
            });
        }
        if (education_index === -1) {
            setFormData((pre) => {
                return {
                    ...pre,
                    education: [...pre.education, education],
                };
            });
        } else {
            setFormData((pre) => {
                const new_education = pre.education;
                new_education[education_index] = education;
                return { ...pre, education: new_education };
            });
        }
        hideEducationModal();
    };

    return (
        <div className='education-form-main'>
            <div className='header'>
                <p>Add Education/Professional Details</p>
                <CloseIcon onClick={hideEducationModal} />
            </div>
            <div className='input-grp'>
                <div className='input-grp'>
                    <p className='bold'>College/Institution name*</p>
                    <input
                        type='text'
                        placeholder='Ex: IIT Bombay'
                        value={education.college}
                        onChange={(e) =>
                            setEducation((pre) => {
                                return { ...pre, college: e.target.value };
                            })
                        }
                    />
                    <p className='form-error'>{errors?.college}</p>
                </div>
                <div className='input-grp'>
                    <p className='bold'>Certifications*</p>
                    <select
                        value={education.degree}
                        onChange={(e) =>
                            setEducation((pre) => {
                                return { ...pre, degree: e.target.value };
                            })
                        }>
                        <option value=''>Certifications</option>
                        {DEGREE_NAMES.map((name) => (
                            <option value={name} key={"degree-" + name}>
                                {name}
                            </option>
                        ))}
                        <option value='Other'>Other</option>
                    </select>
                    <p className='form-error'>{errors?.degree}</p>
                </div>
                {education.degree === "Other" && (
                    <div className='input-grp'>
                        <p className='bold'>Certification name*</p>
                        <input
                            type='text'
                            placeholder='Enter the name of your certification'
                            value={education.otherCertificate}
                            onChange={(e) =>
                                setEducation((pre) => {
                                    return {
                                        ...pre,
                                        otherCertificate: e.target.value,
                                    };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.otherCertificate}</p>
                    </div>
                )}
                <div className='input-grp'>
                    <p className='bold'>Upload Certificate (Image or PDF)</p>
                    <input
                        type='file'
                        name=''
                        className='certificate-input'
                        id=''
                        ref={fileRef}
                        accept='image/*, application/pdf'
                        onChange={(e) => {
                            uploadCertificate(e);
                        }}
                    />
                    {console.log(education)}
                    {!education.certificate_url ? (
                        <div
                            className='file-input'
                            onClick={() => fileRef.current.click()}>
                            <p>
                                <span>Browse</span> your certificate
                            </p>
                        </div>
                    ) : (
                        <div className='file-input'>
                            <span className="container"
                                onClick={() =>
                                    window.open(education?.certificate_url, "_blank")
                                }>
                                <CertificateIcon />
                                <p>Certificate.
                                    {
                                        education?.certificate_url?.split(".")[
                                            education?.certificate_url?.split(
                                                "."
                                            ).length - 1
                                        ]
                                    }
                                </p>
                            </span>
                            <EditIcon onClick={() => fileRef.current.click()} />
                        </div>
                    )}
                </div>
                <div className='input-grp'>
                    <p className='bold'>Start Date*</p>
                    <div className='inputs'>
                        <select
                            value={education.start_month}
                            onChange={(e) =>
                                setEducation((pre) => {
                                    return {
                                        ...pre,
                                        start_month: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Month
                            </option>
                            <option value='January'>January</option>
                            <option value='February'>February</option>
                            <option value='March'>March</option>
                            <option value='April'>April</option>
                            <option value='May'>May</option>
                            <option value='June'>June</option>
                            <option value='July'>July</option>
                            <option value='August'>August</option>
                            <option value='September'>September</option>
                            <option value='October'>October</option>
                            <option value='November'>November</option>
                            <option value='December'>December</option>
                        </select>
                        <select
                            value={education.start_year}
                            onChange={(e) =>
                                setEducation((pre) => {
                                    return {
                                        ...pre,
                                        start_year: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Year
                            </option>
                            {Array(61)
                                .fill(0)
                                .map((num, indx) => (
                                    <option key={"start-year-" + indx}>
                                        {new Date().getFullYear() - indx}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <p className='form-error'>{errors?.start_date}</p>
                </div>
                {!education.currently_working && (
                    <div className='input-grp'>
                        <p className='bold'>End Date*</p>
                        <div className='inputs'>
                            <select
                                value={education.end_month}
                                onChange={(e) =>
                                    setEducation((pre) => {
                                        return {
                                            ...pre,
                                            end_month: e.target.value,
                                        };
                                    })
                                }>
                                <option value=''>Month</option>
                                <option value='January'>January</option>
                                <option value='February'>February</option>
                                <option value='March'>March</option>
                                <option value='April'>April</option>
                                <option value='May'>May</option>
                                <option value='June'>June</option>
                                <option value='July'>July</option>
                                <option value='August'>August</option>
                                <option value='September'>September</option>
                                <option value='October'>October</option>
                                <option value='November'>November</option>
                                <option value='December'>December</option>
                            </select>
                            <select
                                value={education.end_year}
                                onChange={(e) =>
                                    setEducation((pre) => {
                                        return {
                                            ...pre,
                                            end_year: e.target.value,
                                        };
                                    })
                                }>
                                <option value=''>Year</option>
                                {Array(61)
                                    .fill(0)
                                    .map((num, indx) => (
                                        <option key={"end-year-" + indx}>
                                            {new Date().getFullYear() +
                                                4 -
                                                indx}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <p className='form-error'>{errors?.end_date}</p>
                    </div>
                )}
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={hideEducationModal}>
                    Cancel
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EducationForm;
