import React, { useEffect, useState } from "react";

import "./termsAndCondition.scss";
import { getTermsAndConditions } from "../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME } from "../../Constants";

const TermsAndCondition = () => {
  const [activeTab, setActiveTab] = useState("0");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const [termsAndConditions, setTermsAndConditions] = useState();

  useQuery(["get-privacy-policy"], () => getTermsAndConditions(), {
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.data?.status[0].Error === "False") {
        setTermsAndConditions(data.data.value);
      }
    },
    onError: (err) => {
      console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <div className="terms-and-condition-main">
      <h2>Terms and Condition</h2>
      <div className="details-container">
        <div className="side-bar">
          {termsAndConditions?.details?.map((policy, indx) =>
            policy?.title ? (
              <span
                key={"indx" + indx + policy?.title}
                className={activeTab === policy?.title ? "active" : ""}
                onClick={() => {
                  setActiveTab(policy?.title);
                }}
              >
                <a href={`#${policy?.title}`}>
                  <p>{policy?.title}</p>
                </a>
              </span>
            ) : (
              <></>
            )
          )}
        </div>
        <div className="content">
          <div className="head">
            <h3>Terms and Condition</h3>
            {/* <p>
              Date:{" "}
              {new Date(termsAndConditions?.create_at).toLocaleDateString()}
            </p> */}
          </div>
          <div className="text-container">
            {termsAndConditions?.details?.map((policy, indx) => (
              <div className="grp" key={"indx-details-" + indx + policy?.title}>
                <span className="my-spn" id={policy?.title}></span>
                <h4>{policy?.title}</h4>
                {policy?.description?.map((desc, indx) => (
                  <p key={"policy-desc-" + indx}>{desc}</p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
