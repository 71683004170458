import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../../StateProvider";
import { ReactComponent as Close } from "../../../../../Assets/icons/close.svg";

import "./bioEdit.scss";

const BioEdit = ({ formData, setFormData }) => {
    const [, dispatch] = useStateValue();
    const [bio, setBio] = useState("");
    const setBioEditModalShow = (status) => {
        dispatch({
            type: "SET_BIO_EDIT_MODAL_SHOW",
            status: status,
        });
    };
    useEffect(() => {
        setBio(formData.bio);
    }, [formData]);
    return (
        <div className='bio-edit-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>
                        Almost there! How would you like to be intro'd?
                    </p>
                    <Close onClick={() => setBioEditModalShow(false)} />
                </div>
                <div className='input-grp'>
                    <p className='bold'>Everyone has a story, what's yours?</p>
                    <textarea
                        type='text'
                        placeholder='Type here...'
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />
                </div>
                <div className='input-grp'>
                    <p className='bold'>Sample bio</p>
                    <textarea
                        type='text'
                        placeholder='Type here...'
                        value={bio}
                        disabled
                    />
                </div>
            </div>
            <button
                className='btn-blue'
                onClick={() => {
                    setFormData((pre) => {
                        return { ...pre, bio: bio };
                    });
                    setBioEditModalShow(false);
                }}>
                Save
            </button>
        </div>
    );
};

export default BioEdit;
