import React from "react";
import UserOutline from "../../../../Assets/icons/user-outline.svg";
import Work from "../../../../Assets/icons/work.svg";
import AccountBalance from "../../../../Assets/icons/account-balance-wallet.svg";
import Banner from "../../../../Assets/Images/intro-banner.svg"

import "./introduction.scss";

const Introduction = ({ name, setLoginPageNo }) => {
    return (
        <div className='introduction-main'>
            <div className='container'>
                <p className='title'>
                    Hey {name}, Ready for your next big opportunity?
                </p>
                <div className='text-container'>
                    <div className='points'>
                        <span className='point'>
                            <img src={UserOutline} alt='' />
                            <p>
                                Answer a few questions and start building your
                                profile.
                            </p>
                        </span>
                        <hr/>
                        <span className='point'>
                            <img src={Work} alt='' />
                            <p>
                                Apply for open roles or list services for
                                clients to buy.
                            </p>
                        </span>
                        <hr/>
                        <span className='point'>
                            <img src={AccountBalance} alt='' />
                            <p>Get paid safely and know we're there to help.</p>
                        </span>
                    </div>
                    <img src={Banner} alt='' />
                </div>
                <div className='next-action'>
                    <button
                        className='primary-btn-blue'
                        onClick={() => setLoginPageNo((pre) => pre + 1)}>
                        Get Started
                    </button>
                    <p>
                        It will only take 5 -10 minutes and you can edit it
                        later will save as you go.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Introduction;
