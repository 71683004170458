import React, { useEffect, useState } from "react";

import "./bidForm.scss";
import { useStateValue } from "../../../../StateProvider";
import { postBid } from "../../../../apiCall";
import toast from "react-hot-toast";

const BidForm = ({
  id,
  currency_unit,
  bidDetails,
  setActiveTab,
  isEditBid,
}) => {
  const [formData, setFormData] = useState({
    bid: "",
    time: "",
    days: "0",
    months: "0",
    years: "0",
    proposal: "",
  });
  const [errors, setErrors] = useState();
  const [, dispatch] = useStateValue();
  const [isEditMode, setIsEditMode] = useState(isEditBid);

  useEffect(() => {
    setFormData(bidDetails);
    if (!bidDetails?.bid) {
      setIsEditMode(true);
    }
  }, [bidDetails]);
  const handleSubmit = async () => {
    if (!formData?.bid) {
      setErrors((pre) => {
        return { ...pre, bid: "Bid is a required field." };
      });
      return;
    } else {
      setErrors((pre) => {
        return { ...pre, bid: "" };
      });
    }
    if (
      formData?.days === "0" &&
      formData?.months === "0" &&
      formData?.years === "0"
    ) {
      setErrors((pre) => {
        return { ...pre, time: "Duration for project is a required field." };
      });
      return;
    } else {
      setErrors((pre) => {
        return { ...pre, time: "" };
      });
    }
    if (!formData?.proposal) {
      setErrors((pre) => {
        return { ...pre, proposal: "proposal a is required field." };
      });
      return;
    } else {
      setErrors((pre) => {
        return { ...pre, proposal: "" };
      });
    }

    const data = {
      amount: formData?.bid,
      currency_unit: currency_unit,
      duration:
        formData?.days +
        " Days " +
        formData?.months +
        " Months " +
        formData?.years +
        " Years",
      proposal: formData?.proposal,
      job_id: id,
    };

    if (isEditMode) {
      dispatch({ type: "SET_IS_LOADING", status: true });

      const Re = await postBid(data);

      setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

      if (Re.data?.status[0].Error === "False") {
        setActiveTab("job-details");
        dispatch({
          type: "SET_SUCCESS_MODAL_SHOW",
          status: true,
        });
      } else {
        if (Re.data?.status) {
          toast.error(Re.data.status[0].ResponseMessage);
        } else {
          toast.error("Something went wrong, Please try again.");
        }
      }
    }
  };
  return (
    <div className="bid-form-main">
      <p className="bold">
        What is the full amount you'd like to bid for this job?
      </p>
      <div className="input-grp">
        <div className="text">
          <p className="bold">Bid ({currency_unit === "INR" ? "₹" : "$"})</p>
          <p className="desc">
            Total amount the client will see on your proposal
          </p>
          <p className="form-error">{errors?.bid}</p>
        </div>
        <div className="input-container">
          <input
            type="number"
            placeholder="Bid amount"
            value={formData?.bid}
            disabled={!isEditMode}
            onChange={(e) =>
              setFormData((pre) => {
                return {
                  ...pre,
                  bid: e.target.value,
                };
              })
            }
          />
        </div>
      </div>
      <div className="input-grp">
        <div className="text">
          <p className="bold">
            10% Freelancer Service Fee ({currency_unit === "INR" ? "₹" : "$"})
          </p>
        </div>
        <div className="input-container">
          <input
            type="number"
            placeholder="Service Fee"
            value={formData?.bid === "" ? "" : (formData?.bid * 0.1).toFixed(2)}
            disabled
          />
        </div>
      </div>
      <div className="input-grp">
        <div className="text">
          <p className="bold">
            You'll Receive ({currency_unit === "INR" ? "₹" : "$"})
          </p>
          <p className="desc">
            Estimated amount you will receive after service fee
          </p>
        </div>
        <div className="input-container">
          <input
            type="number"
            placeholder="You will recive"
            value={
              formData?.bid === ""
                ? ""
                : formData?.bid - (formData?.bid * 0.1).toFixed(2)
            }
            disabled
          />
        </div>
      </div>
      <hr />
      <div className="input-grp input-grp-2">
        <div className="text">
          <p className="bold">How long this project will take?</p>
        </div>
        <div className="input-container">
          {/* <input
                        type='text'
                        placeholder='Enter'
                        value={formData?.time}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return {
                                    ...pre,
                                    time: e.target.value,
                                };
                            })
                        }
                        
                    /> */}
          <select
            value={formData?.days}
            onChange={(e) =>
              setFormData((pre) => {
                return { ...pre, days: e.target.value };
              })
            }
            disabled={!isEditMode}
          >
            <option value="0">Days</option>
            {new Array(30).fill(0).map((val, indx) => (
              <option key={"day-" + indx} value={indx + 1}>
                {indx + 1} Days
              </option>
            ))}
          </select>
          <select
            value={formData?.months}
            onChange={(e) =>
              setFormData((pre) => {
                return { ...pre, months: e.target.value };
              })
            }
            disabled={!isEditMode}
          >
            <option value="0">Months</option>
            {new Array(12).fill(0).map((val, indx) => (
              <option key={"day-" + indx} value={indx + 1}>
                {indx + 1} Month
              </option>
            ))}
          </select>
          <select
            value={formData?.years}
            onChange={(e) =>
              setFormData((pre) => {
                return { ...pre, years: e.target.value };
              })
            }
            disabled={!isEditMode}
          >
            <option value="0">Years</option>
            {new Array(5).fill(0).map((val, indx) => (
              <option key={"day-" + indx} value={indx + 1}>
                {indx + 1} Year
              </option>
            ))}
          </select>
        </div>
        <p className="form-error">{errors?.time}</p>
      </div>
      <hr />
      <div className="input-grp input-grp-2">
        <div className="text">
          <p className="bold">Write your proposal</p>
        </div>
        <div className="input-container">
          <textarea
            placeholder="Type your proposal"
            value={formData?.proposal}
            onChange={(e) =>
              setFormData((pre) => {
                return {
                  ...pre,
                  proposal: e.target.value,
                };
              })
            }
            disabled={!isEditMode}
          />
        </div>
        <p className="form-error">{errors?.proposal}</p>
      </div>
      {isEditMode && (
        <div className="button-container">
          <button className="btn-blue" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default BidForm;
