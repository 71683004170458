import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { ReactComponent as Close } from "../../../../Assets/icons/close.svg";

import "./skillsForm.scss";
import { useStateValue } from "../../../../StateProvider";
const SkillsForm = ({
    formData,
    skillsList,
    setFormData,
    is_from_edit_profile,
    is_job_post,
}) => {
    const [searchSkill, setsearchSkill] = useState("");
    const [skills, setSkills] = useState([]);
    const [skillsSet, setSkillsSet] = useState(new Set());
    const [currentSuggestedSkills, setCurrentSuggestedSkills] = useState([]);
    const [suggestedSkills, setSuggestedSkills] = useState([]);
    const [extraSkill, setExtraSkill] = useState("");
    const [, dispatch] = useStateValue();

    const [skillsData, setSkillsData] = useState(formData?.skills);

    const setSkillsEditModalShow = (status) => {
        dispatch({
            type: "SET_SKILLS_EDIT_MODAL_SHOW",
            status: status,
        });
    };

    const isStillSuggested = (skill) => {
        let result = true;
        if (is_from_edit_profile) {
            skillsData?.forEach((selectedSkill) => {
                if (selectedSkill?.id === skill?.id) {
                    result = false;
                }
            });
        } else {
            formData?.skills?.forEach((selectedSkill) => {
                if (selectedSkill?.id === skill?.id) {
                    result = false;
                }
            });
        }
        return result;
    };
    useEffect(() => {
        setSuggestedSkills(
            currentSuggestedSkills.filter((skill) => {
                let result = true;

                if (is_from_edit_profile) {
                    skillsData.forEach((selectedSkill) => {
                        if (selectedSkill?.id === skill?.id) {
                            result = false;
                        }
                    });
                } else {
                    formData?.skills?.forEach((selectedSkill) => {
                        if (selectedSkill?.id === skill?.id) {
                            result = false;
                        }
                    });
                }
                return result;
            })
        );
    }, [
        currentSuggestedSkills,
        formData?.skills,
        skillsData,
        is_from_edit_profile,
    ]);

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setsearchSkill(string);
        setExtraSkill(string);
    };

    const handleOnSelect = (item) => {
        console.log(item);
        if (is_from_edit_profile) {
            setSkillsData((pre) => [...pre, item]);
            setsearchSkill("");
            setExtraSkill("");
        } else {
            setFormData((pre) => {
                const new_skills = [...formData?.skills, item];
                return {
                    ...pre,
                    skills: new_skills,
                };
            });
            setsearchSkill("");
            setExtraSkill("");
        }
    };

    const formatResult = (item) => {
        return (
            <>
                <div style={{ display: "block", textAlign: "left", zIndex: 2 }}>
                    {item.name}
                </div>
            </>
        );
    };

    useEffect(() => {
        const temp_skills = [],
            temp_suggested_skills = [];
        const temp_suggested_skills_id = new Set();
        skillsList?.forEach((skill) => {
            temp_skills.push({ id: skill.id, name: skill.name });
            setSkillsSet((pre) => pre.add(skill.name));

            skill?.suggested_skills.forEach((skill) => {
                if (!temp_suggested_skills_id.has(skill.id)) {
                    temp_suggested_skills_id.add(skill.id);
                    temp_suggested_skills.push(skill);
                }
            });
        });

        setSkills(temp_skills);
        setCurrentSuggestedSkills([...temp_suggested_skills]);
    }, [skillsList]);

    return (
        <div
            className={`skills-form-main ${
                is_from_edit_profile ? "skills-form-edit-model" : ""
            }`}>
            {is_from_edit_profile && (
                <div className='text'>
                    <p className='title'>Tell us more about what you do.</p>
                    <Close onClick={() => setSkillsEditModalShow(false)} />
                </div>
            )}
            <div className='input-grp'>
                <p className='bold'>
                    {is_job_post ? "Required" : "Your"} Skills
                </p>
                <div className='skills-container'>
                    {(is_from_edit_profile ? skillsData : formData?.skills).map(
                        (skill, indx) => (
                            <div
                                className='selected-skill'
                                key={"selected-skill-" + indx}>
                                {skill.name}
                                <Close
                                    onClick={() => {
                                        if (is_from_edit_profile) {
                                            setSkillsData((pre) =>
                                                pre.filter(
                                                    (selectedSkill) =>
                                                        skill.id !==
                                                        selectedSkill.id
                                                )
                                            );
                                        } else {
                                            setFormData((pre) => {
                                                const new_skills =
                                                    formData?.skills?.filter(
                                                        (selectedSkill) =>
                                                            skill.id !==
                                                            selectedSkill.id
                                                    );
                                                return {
                                                    ...pre,
                                                    skills: new_skills,
                                                };
                                            });
                                        }
                                    }}
                                />
                            </div>
                        )
                    )}
                    <ReactSearchAutocomplete
                        items={skills
                            .concat(
                                skillsSet.has(extraSkill)
                                    ? []
                                    : [
                                          {
                                              id: "ry_new_skill:" + extraSkill,
                                              name: extraSkill,
                                          },
                                      ]
                            )
                            .filter((skill) => isStillSuggested(skill))}
                        onSelect={handleOnSelect}
                        fuseOptions={{ keys: ["name"] }}
                        autoFocus
                        inputSearchString={searchSkill}
                        onSearch={handleOnSearch}
                        formatResult={formatResult}
                        placeholder='Search For Your Skill...'
                    />
                </div>
                <span className='max-number'>max 15 skills</span>
            </div>
            {suggestedSkills.length > 0 && (
                <div className='input-grp'>
                    <p className='bold'>Suggested Skills</p>
                    <div className='suggested-skills'>
                        {suggestedSkills.slice(0, 5).map((skill) => (
                            <span
                                className='suggested-skill'
                                onClick={() => {
                                    if (is_from_edit_profile) {
                                        if (skillsData.length < 15) {
                                            setSkillsData((pre) => [
                                                ...pre,
                                                skill,
                                            ]);
                                            setsearchSkill("");
                                            setExtraSkill("");
                                        }
                                    } else {
                                        if (formData?.skills?.length < 15) {
                                            setFormData((pre) => {
                                                const new_skills = [
                                                    ...formData?.skills,
                                                    skill,
                                                ];
                                                return {
                                                    ...pre,
                                                    skills: new_skills,
                                                };
                                            });
                                            setsearchSkill("");
                                            setExtraSkill("");
                                        }
                                    }
                                }}
                                key={"suggested-skill" + skill.id}>
                                <Close />
                                {skill.name}
                            </span>
                        ))}
                    </div>
                </div>
            )}
            {is_from_edit_profile && (
                <button
                    className='btn-blue'
                    onClick={() => {
                        setFormData((pre) => {
                            return { ...pre, skills: skillsData };
                        });
                        setSkillsEditModalShow(false);
                    }}>
                    Save
                </button>
            )}
        </div>
    );
};

export default SkillsForm;
