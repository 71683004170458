import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";

import "./myJob.scss";
import {
  FeedbackModal,
  ProposalModal,
  SubmitWorkModal,
  SuccessModal,
} from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import { getFreelancerJobs } from "../../../apiCall";
import toast from "react-hot-toast";

const MyJob = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [proposal, setProposal] = useState();
  const [proposalId, setProposalid] = useState();
  const [jobId, setJobId] = useState();
  const [, dispatch] = useStateValue();

  const showSubmitWorkModal = (proposal_id, job_id) => {
    setProposalid(proposal_id);
    setJobId(job_id);
    dispatch({
      type: "SET_SUBMIT_WORK_MODAL_SHOW",
      status: true,
    });
  };
  const showProposalModal = (id) => {
    dispatch({
      type: "SET_PROPOSAL_MODAL_SHOW",
      status: true,
    });
  };

  useEffect(() => {
    const getData = async () => {
      dispatch({ type: "SET_IS_LOADING", status: true });

      const re = await getFreelancerJobs(activeTab, page);
      setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

      console.log("FREELANCER_JOBS_RESPONSE", re, re.status);
      if (re.status !== 200) {
        toast.error("Something went wrong, Please reload the page.");
      } else {
        if (re.data.status[0].Error === "True") {
          toast.error(re.data.status[0].ResponseMessage);
        } else {
          setJobs(re.data.value?.jobs);
          setTotalPages(re.data.value?.total_pages);
        }
      }
    };

    if (page) {
      getData();
    }
  }, [activeTab, page, dispatch]);

  const reRenderComp = () => {
    setPage(0);
  };
  useEffect(() => {
    if (!page) {
      setPage(1);
    }
  }, [page]);
  return (
    <div className="my-jobs-main">
      <div className="content">
        <h3>My Job</h3>
        <div className="main-container">
          <div className="heading">
            <span
              className={`${activeTab === "all" ? "active" : ""}`}
              onClick={() => setActiveTab("all")}
            >
              <p>
                All <span></span>
              </p>
              <span className="underline"></span>
            </span>
            <span
              className={`${activeTab === "active_jobs" ? "active" : ""}`}
              onClick={() => setActiveTab("active_jobs")}
            >
              <p>
                Active Jobs <span></span>
              </p>
              <span className="underline"></span>
            </span>
            <span
              className={`${activeTab === "awaiting_jobs" ? "active" : ""}`}
              onClick={() => setActiveTab("awaiting_jobs")}
            >
              <p>
                Awaiting Jobs <span></span>
              </p>
              <span className="underline"></span>
            </span>
            <span
              className={`${activeTab === "applied_jobs" ? "active" : ""}`}
              onClick={() => setActiveTab("applied_jobs")}
            >
              <p>
                Applied Jobs <span></span>
              </p>
              <span className="underline"></span>
            </span>
            <span
              className={`${activeTab === "payment_requested" ? "active" : ""}`}
              onClick={() => setActiveTab("payment_requested")}
            >
              <p>
                Payment Requested <span></span>
              </p>
              <span className="underline"></span>
            </span>
            <span
              className={`${activeTab === "completed_jobs" ? "active" : ""}`}
              onClick={() => setActiveTab("completed_jobs")}
            >
              <p>
                Completed Jobs <span></span>
              </p>
              <span className="underline"></span>
            </span>
          </div>
          <div className="job-container-parent">
            <div className="job-container">
              {jobs?.map((job) => (
                <div
                  className={`job ${
                    job?.job?.tag_type ? job?.job?.tag_type : ""
                  }`}
                  key={"job-" + job?.job?.id}
                >
                  {job?.job?.tag && (
                    <span className="job-tag">{job?.job?.tag}</span>
                  )}
                  <span className="id">{job?.job?.id}.</span>
                  <span className="details-container">
                    <div className="details">
                      <p className="name">
                        {job?.job?.title}{" "}
                        {job?.bid?.amount && (
                          <>
                            {" "}
                            <span>|</span>{" "}
                            {job?.bid?.currency_unit === "INR" ? "₹" : "$"}{" "}
                          </>
                        )}
                        {job?.bid?.amount}
                      </p>
                      <p className="other-details">
                        Work: <span>{job?.job?.work}</span>
                      </p>
                      {job?.job?.hired_on && (
                        <p className="other-details">
                          Hired on:{" "}
                          <span>{`${new Date(job?.bid?.hired_on).getDate()} - ${
                            new Date(job?.bid?.hired_on).getMonth() + 1
                          } - ${new Date(
                            job?.bid?.hired_on
                          ).getFullYear()}`}</span>
                        </p>
                      )}
                      {job?.bid?.end_date && (
                        <p className="other-details">
                          Due on:{" "}
                          <span>{`${new Date(job?.bid?.end_date).getDate()} - ${
                            new Date(job?.bid?.end_date).getMonth() + 1
                          } - ${new Date(
                            job?.bid?.end_date
                          ).getFullYear()}`}</span>
                        </p>
                      )}
                    </div>
                    <div className="buttons">
                      {(job?.bid?.work_submitted !== true ||
                        job?.bid?.need_to_reiterate === true) &&
                        job?.bid?.status === "accepted" &&
                        job?.bid?.payment_completed !== true && (
                          <button
                            className="btn-white-outline"
                            onClick={() => {
                              showSubmitWorkModal(job?.bid?.id, job?.job?.id);
                            }}
                          >
                            Submit your work
                          </button>
                        )}
                      <button
                        className="btn-white-outline"
                        onClick={() => {
                          setProposal(job?.bid);
                          showProposalModal();
                        }}
                      >
                        View Proposal
                      </button>
                    </div>
                  </span>
                </div>
              ))}
            </div>
            {!jobs?.length && activeTab === "all" ? (
              <p className="empty-message">Oops, There isn't any job.</p>
            ) : (
              <></>
            )}
            {!jobs?.length && activeTab === "active_jobs" ? (
              <p className="empty-message">
                Oops, You don't have any active job.
              </p>
            ) : (
              <></>
            )}
            {!jobs?.length && activeTab === "awaiting_jobs" ? (
              <p className="empty-message">
                Oops, you haven't any awaiting job.
              </p>
            ) : (
              <></>
            )}
            {!jobs?.length && activeTab === "applied_jobs" ? (
              <p className="empty-message">
                Oops, you haven't applied to any job yet.
              </p>
            ) : (
              <></>
            )}
            {!jobs?.length && activeTab === "payment_requested" ? (
              <p className="empty-message">
                Oops, you don't have any payment request.
              </p>
            ) : (
              <></>
            )}
            {!jobs?.length && activeTab === "completed_jobs" ? (
              <p className="empty-message">
                Oops, you didn't complete any job yet.
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </div>
      <SubmitWorkModal proposal_id={proposalId} job_id={jobId} />
      <FeedbackModal proposal_id={proposalId} callback={reRenderComp} />
      <SuccessModal text={"Yeah! You have successfully submit your work."} />
      <ProposalModal proposal={proposal} callback={reRenderComp} />
    </div>
  );
};

export default MyJob;
