import React from "react";

import "./reviewOffer.scss";
import { SuccessModal } from "../../../../../Modals";
import {
    UpdateOfferCoverImage,
    postOfferPackage,
    updateOfferPackage,
} from "../../../../../apiCall";
import toast from 'react-hot-toast';
import OfferDetails from "../../../../OfferDetails/OfferDetails";
import { useStateValue } from "../../../../../StateProvider";
import { useParams } from "react-router-dom";

const ReviewOffer = ({ formData, setPageNo }) => {
    const { id } = useParams();
    const [, dispatch] = useStateValue();
    const handleSubmit = async () => {
        console.log(formData);
        const data = {
            title: formData?.package_name,
            category: JSON.parse(formData?.category).id,
            basic_service: {
                ...formData?.services.basic,
                extra_service: formData?.has_extra_fast_delivery
                    ? formData?.services.basic.extra_service
                    : null,
            },
            standard_service: {
                ...formData?.services.standard,
                extra_service: formData?.has_extra_fast_delivery
                    ? formData?.services.standard.extra_service
                    : null,
            },
            premium_service: {
                ...formData?.services.premium,
                extra_service: formData?.has_extra_fast_delivery
                    ? formData?.services.premium.extra_service
                    : null,
            },
            description: formData?.about_packaging,
            why_to_hire: formData?.why_should_you_use,
        };

        let re = null;
        dispatch({type:"SET_IS_LOADING",status:true})

        if (id) {
            re = await updateOfferPackage(id, data);
        } else {
            re = await postOfferPackage(data);
        }
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);


        console.log("POST_OFFER_PACKAGE_RESPONSE", re);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                if (formData?.image_file) {
                    const imgData = new FormData();
                    imgData.append("coverpage", formData?.image_file);
                    dispatch({type:"SET_IS_LOADING",status:true})

                    const ImageRe = await UpdateOfferCoverImage(
                        imgData,
                        re.data.value
                    );
                    setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                    console.log("COVER_IMAGE_UPDATE_RESPONSE", ImageRe);

                    if (ImageRe.data?.status[0].Error === "False") {
                        localStorage.clear();
                        dispatch({
                            type: "SET_SUCCESS_MODAL_SHOW",
                            status: true,
                        });
                    } else {
                        if (ImageRe.data?.status) {
                            toast.error(ImageRe.data.status[0].ResponseMessage);
                        } else {
                            toast.error(
                                "Something went wrong, Please try again."
                            );
                        }
                    }
                } else {
                    localStorage.clear();
                    dispatch({
                        type: "SET_SUCCESS_MODAL_SHOW",
                        status: true,
                    });
                }
            }
        }
    };

    return (
        <>
            <OfferDetails data={formData} />
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Publish your offer package
                </button>
            </div>
            <SuccessModal
                text='Yeah! You have successfully complete your Package service.'
                redirect_to_home={true}
            />
        </>
    );
};

export default ReviewOffer;
