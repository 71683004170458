import React, { useEffect, useState } from "react";

import "./faqs.scss";
import { getFaqs } from "../../apiCall";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../Constants";
import toast from "react-hot-toast";

const Faqs = () => {
    const [activeTab, setActiveTab] = useState("0");
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);

    const [faqs, setFaqs] = useState();

    useQuery(["get-privacy-policy"], () => getFaqs(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.status[0].Error === "False") {
                setFaqs(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    return (
        <div className='faqs-main'>
            <h2>Faqs</h2>
            <div className='details-container'>
                <div className='side-bar'>
                    {faqs?.details?.map((policy, indx) =>
                        policy?.title ? (
                            <span
                                key={"indx" + indx + policy?.title}
                                className={
                                    activeTab === policy?.title ? "active" : ""
                                }
                                onClick={() => {
                                    setActiveTab(policy?.title);
                                }}>
                                <a href={`#${policy?.title}`}>
                                    {policy?.title}
                                </a>
                            </span>
                        ) : (
                            <></>
                        )
                    )}
                </div>
                <div className='content'>
                    <div className='head'>
                        <h3>Faqs</h3>
                        <p>
                            Date:{" "}
                            {new Date(faqs?.create_at).toLocaleDateString()}
                        </p>
                    </div>
                    <div className='text-container'>
                        {faqs?.details?.map((policy, indx) => (
                            <div
                                className='grp'
                                key={"indx-details-" + indx + policy?.title}>
                                <span
                                    className='my-spn'
                                    id={policy?.title}></span>
                                <h4>{policy?.title}</h4>
                                {policy?.description?.map((desc, indx) => (
                                    <p key={"policy-desc-" + indx}>{desc}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faqs;
