import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Offcanvas from "react-bootstrap/Offcanvas";

import "./findFreelancer.scss";
import Filters from "../Components/Filters";
import { ReactComponent as FavouritIcon } from "../../../Assets/icons/favorite.svg";
import { ReactComponent as FavouritIconFilled } from "../../../Assets/icons/favorite-filled.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/icons/check-circle.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/icons/filter.svg";
import { ReactComponent as StarReview } from "../../../Assets/icons/star.svg";
import DownArrowIcon from "../../../Assets/icons/arrow-drop-down.svg";
import { useNavigate } from "react-router-dom";
import {
    // GetCategories,
    UpdateSavedfreelancers,
    getFreelancerList,
} from "../../../apiCall";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { domainName } from "../../../Constants";
import { useStateValue } from "../../../StateProvider";

const FindFreelancer = () => {
    const [appliedFilters, setAppliedFilters] = useState({
        category: null,
        // delivery_time: "",
        budget: [0, 9999],
        response_time: "All",
        location: "",
        sort_price_by: "default",
    });
    // const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [showFiltersOffCanvas, setShowFiltersOffCanvas] = useState(false);
    const [freelancers, setFreelancers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [showSortOptions, setShowSortOptions] = useState(false);
    const navigateTo = useNavigate();
    const [{ saved_freelancers , categories}, dispatch] = useStateValue();

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });

    const updateSavedFreelancers = async (freelancers) => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await UpdateSavedfreelancers(freelancers);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("UPDATE_SAVED_FREELANCERS_RESPONSE", re);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            sessionStorage.setItem(
                "saved_freelancers",
                JSON.stringify(re.data.value)
            );

            dispatch({
                type: "SET_SAVED_FREELANCERS",
                status: re.data.value,
            });
        }
    };

    const removeFromSavedFreelancer = (id) => {
        const updated_freelancers = saved_freelancers.filter(
            (freelancer) => freelancer !== id
        );
        sessionStorage.setItem(
            "saved_freelancers",
            JSON.stringify(updated_freelancers)
        );

        dispatch({
            type: "SET_SAVED_FREELANCERS",
            status: updated_freelancers,
        });

        updateSavedFreelancers(updated_freelancers);
    };
    const addInSavedFreelancer = (id) => {
        const updated_freelancers = [...saved_freelancers, id];
        sessionStorage.setItem(
            "saved_freelancers",
            JSON.stringify(updated_freelancers)
        );

        dispatch({
            type: "SET_SAVED_FREELANCERS",
            status: updated_freelancers,
        });
        updateSavedFreelancers(updated_freelancers);
    };

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const Re = await getFreelancerList(page, appliedFilters);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("FREELANCER_DATA", Re);

            if (Re?.data?.status[0].Error === "False") {
                setFreelancers(Re?.data.value?.freelancers);
                setTotalPages(Re?.data.value?.total_pages);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };
        getData();
    }, [appliedFilters, page, dispatch]);
    return (
        <div className='find-freelancer-main'>
            {showSortOptions === true && (
                <div
                    className='back-drop'
                    onClick={() => setShowSortOptions(false)}></div>
            )}
            <div className='filters'>
                <Filters
                    categories={categories}
                    setAppliedFilters={setAppliedFilters}
                />
            </div>
            <div className='find-freelancer-container'>
                <div className='heading-container'>
                    <p className='heading'>Freelancer List</p>
                    <span
                        className='sort-by'
                        onClick={() => setShowSortOptions(true)}>
                        {appliedFilters.sort_price_by === "default" && (
                            <span>Sort by (Default)</span>
                        )}
                        {appliedFilters.sort_price_by === "high" && (
                            <span>Sort by price(High to low)</span>
                        )}
                        {appliedFilters.sort_price_by === "low" && (
                            <span>Sort by price(Low to high)</span>
                        )}
                        <img src={DownArrowIcon} alt='' />
                    </span>
                    {showSortOptions && (
                        <div className='options'>
                            <span
                                className='option'
                                onClick={() => {
                                    setAppliedFilters((pre) => {
                                        return {
                                            ...pre,
                                            sort_price_by: "default",
                                        };
                                    });
                                    setShowSortOptions(false);
                                }}>
                                Sort by Price (Default)
                            </span>
                            <span
                                className='option'
                                onClick={() => {
                                    setAppliedFilters((pre) => {
                                        return {
                                            ...pre,
                                            sort_price_by: "high",
                                        };
                                    });
                                    setShowSortOptions(false);
                                }}>
                                Sort by Price (High to Low)
                            </span>
                            <span
                                className='option'
                                onClick={() => {
                                    setAppliedFilters((pre) => {
                                        return { ...pre, sort_price_by: "low" };
                                    });
                                    setShowSortOptions(false);
                                }}>
                                Sort by Price (Low to High)
                            </span>
                        </div>
                    )}
                    <FilterIcon
                        className='filter-icon'
                        onClick={() => setShowFiltersOffCanvas(true)}
                    />
                </div>
                <div className='freelancer-container'>
                    {freelancers.map((freelancer) => (
                        <div
                            className='freelancer'
                            key={"freelancer-" + freelancer.id}>
                            <div className='about-freelancer'>
                                <div className='about-container'>
                                    <div className='image-container'>
                                        <img
                                            src={
                                                domainName +
                                                "/uploads/" +
                                                freelancer?.img
                                            }
                                            alt=''
                                        />
                                        <span className='online-mark'></span>
                                    </div>
                                    <div className='ratings-container'>
                                        <p className='name'>
                                            {freelancer.name}
                                            {""}
                                            {freelancer.ratings >= 4 ? (
                                                <span className='top-rated'>
                                                    <CheckIcon />{" "}
                                                    <span>Top rated</span>
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {freelancer?.is_account_verified && (
                                                <span className='account-verified'>
                                                    <CheckIcon />{" "}
                                                    <span>Account verified</span>
                                                </span>
                                            )}
                                        </p>
                                        <span className='ratings'>
                                            {Array(5)
                                                .fill(0)
                                                .map((ele, indx) => (
                                                    <StarReview
                                                        key={
                                                            "user-review-ratings-" +
                                                            freelancer.id +
                                                            "-" +
                                                            indx
                                                        }
                                                        className={`${
                                                            freelancer.ratings >
                                                            indx
                                                                ? "filled"
                                                                : ""
                                                        }`}
                                                    />
                                                ))}
                                        </span>
                                    </div>
                                </div>
                                {saved_freelancers?.includes(freelancer.id) ? (
                                    <FavouritIconFilled
                                        className={`fav-icon filled`}
                                        onClick={() =>
                                            removeFromSavedFreelancer(
                                                freelancer.id
                                            )
                                        }
                                    />
                                ) : (
                                    <FavouritIcon
                                        className={`fav-icon`}
                                        onClick={() =>
                                            addInSavedFreelancer(freelancer.id)
                                        }
                                    />
                                )}
                            </div>
                            <div className='freelancer-skills-container'>
                                <div className='skills-container'>
                                    <p className='category'>
                                        {freelancer.service_category}
                                    </p>
                                    <p className='skills'>
                                        {freelancer.skills
                                            .map((skill) => skill.skill)
                                            .join(", ")}
                                    </p>
                                </div>
                                <div className='button-container'>
                                    <button
                                        className='btn-white-outline'
                                        onClick={() =>
                                            navigateTo(
                                                "/freelancer-details/" +
                                                    freelancer.id
                                            )
                                        }>
                                        View Profile
                                    </button>
                                    <p className='price'>
                                        {freelancer.amount_format === "INR"
                                            ? "₹"
                                            : "$"}
                                        {freelancer.hourly_rate}/hr
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='pagination'>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                    />
                </div>
            </div>
            <Offcanvas
                show={showFiltersOffCanvas}
                className='nav-offcanvas-main'
                onHide={() => setShowFiltersOffCanvas(false)}>
                <Offcanvas.Body>
                    <Filters
                        categories={categories}
                        setAppliedFilters={setAppliedFilters}
                        setShowFiltersOffCanvas={setShowFiltersOffCanvas}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default FindFreelancer;
