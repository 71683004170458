import React, { useCallback, useEffect, useRef, useState } from "react";

import MoreIcon from "../../../Assets/icons/more.svg";
import { ReactComponent as EmojiIcon } from "../../../Assets/icons/emoji.svg";
// import { ReactComponent as AddImageIcon } from "../../../Assets/icons/add-photo.svg";
import { ReactComponent as AttachFileIcon } from "../../../Assets/icons/attach-file.svg";
import { ReactComponent as ExpandIcon } from "../../../Assets/icons/expand.svg";
import PdfIcon from "../../../Assets/icons/pdf.svg";
import "./chatWindow.scss";
import { domainName } from "../../../Constants";
// import ChatImg from "../../../Assets/Images/chat-img.png";
import { SendChatDocument, getChatMessages } from "../../../apiCall";
import toast from 'react-hot-toast';
import EmojiPicker from "emoji-picker-react";
import { useStateValue } from "../../../StateProvider";
import { useNavigate } from "react-router-dom";

// const messages = [
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
//     {
//         id: 1,
//         message: "Hello, how are you doing?",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:15AM",
//     },
//     {
//         id: 2,
//         message: "I am doing well. Thank you! How can I help you today?",
//         message_type: "text",
//         sent_by: "opp",
//         time: "08:16AM",
//     },
//     {
//         id: 3,
//         message:
//             "I have a question about the return policy for a product I purchased.",
//         message_type: "text",
//         sent_by: "me",
//         time: "08:17 AM",
//     },
//     {
//         id: 4,
//         message_type: "img",
//         img_link: ChatImg,
//         sent_by: "opp",
//         time: "08:19AM",
//     },
//     {
//         id: 5,
//         pdf_name: "Baldor technologies.pdf",
//         pdf_link:
//             "https://cdn2.hubspot.net/hub/53/file-13204607-pdf/docs/introduction-to-seo-ebook.pdf",
//         message_type: "pdf",
//         sent_by: "me",
//         time: "Just Now",
//     },
// ];

const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
const ChatWindow = ({
    activeUserGroup,
    setCurrentView,
    showUserOptions,
    setShowUserOptions,
    pendingMessageCounter,
    setPendingMessageCounter,
    newMessage,
    setNewMessage,
    pendingMessages,
    setPendingMessages,
    messages,
    setMessages,
    sendJsonMessage,
}) => {
    const messageContainerRef = useRef();

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const fileRef = useRef();

    const [{ loggedInAs }] = useStateValue();
    const navigateTo = useNavigate();

    const sendMessage = useCallback(() => {
        const new_msg_obj = {
            type: "NEW_MESSAGE",
            id: "pending-" + pendingMessageCounter,
            message: newMessage,
            sender: loggedInAs,
            message_type: "text",
            group_id: activeUserGroup.id,
            IS_READ_ACK: "false",
        };
        setPendingMessages((pre) => pre.concat(new_msg_obj));
        setPendingMessageCounter((pre) => pre + 1);
        sendJsonMessage(new_msg_obj);
        setNewMessage("");
    }, [
        activeUserGroup.id,
        newMessage,
        pendingMessageCounter,
        sendJsonMessage,
        loggedInAs,
        setNewMessage,
        setPendingMessageCounter,
        setPendingMessages,
    ]);

    const sendDocument = (document_id, pending_counter_id) => {
        const new_msg_obj = {
            type: "NEW_MESSAGE",
            id: "pending-" + pending_counter_id,
            message: "",
            document_id: document_id,
            sender: loggedInAs,
            message_type: "file",
            group_id: activeUserGroup.id,
            IS_READ_ACK: "false",
        };
        sendJsonMessage(new_msg_obj);
    };

    const sendFile = async (e) => {
        const data = new FormData();
        data.append("data", e.target.files[0]);

        const pending_counter_id = pendingMessageCounter;
        const new_msg_obj = {
            type: "NEW_MESSAGE",
            id: "pending-" + pending_counter_id,
            message: "",
            document_name: e.target.files[0].name,
            sender: loggedInAs,
            message_type: "file",
            group_id: activeUserGroup.id,
        };
        setPendingMessages((pre) => pre.concat(new_msg_obj));
        setPendingMessageCounter((pre) => pre + 1);

        const Re = await SendChatDocument(data, activeUserGroup.id);
        // console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            sendDocument(Re.data.value, pending_counter_id);
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    useEffect(() => {
        const keyPress = (e) => {
            if (e.key === "Enter" && newMessage) {
                sendMessage();
            }
        };

        window.addEventListener("keypress", keyPress);

        return () => window.removeEventListener("keypress", keyPress);
    }, [sendMessage, newMessage]);

    useEffect(() => {
        const getData = async () => {
            const re = await getChatMessages(activeUserGroup.id);
            console.log("GET_CHAT_MESSAGES_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    setMessages(re.data.value);
                }
            }
        };
        getData();
    }, [activeUserGroup.id, setMessages]);

    useEffect(() => {
        const element_id =
            "message-" + activeUserGroup?.id + "-" + activeUserGroup?.first_unseen_message;

        const element = document.getElementById(element_id);
        console.log(activeUserGroup?.first_unseen_message, element_id, element);
        if (element) {
            console.log("IN ELEMENT")
            // element.scrollIntoView();
            const yOffset = messageContainerRef.current.scrollHeight - element.getBoundingClientRect().top - messageContainerRef.current.offsetHeight;
            console.log(yOffset, messageContainerRef.current.scrollHeight, - element.getBoundingClientRect().top - messageContainerRef.current.offsetHeight)
            messageContainerRef.current.scrollTo(0, yOffset - 500);
        } else {
            console.log("NOT")
            messageContainerRef.current.scrollTo(
                0,
                messageContainerRef.current.scrollHeight
            );
        }
    }, [messages, activeUserGroup?.first_unseen_message, activeUserGroup?.id, pendingMessageCounter]);

    const handleScroll = (event) => {
        if (
            messageContainerRef.current.scrollTop +
                messageContainerRef.current.offsetHeight ===
            messageContainerRef.current.scrollHeight
        ) {
            const new_msg_obj = {
                sender: loggedInAs,
                group_id: activeUserGroup.id,
                IS_READ_ACK: "true",
            };
            sendJsonMessage(new_msg_obj);
        }
    };

    return (
        <>
            {showEmojiPicker && (
                <div
                    className='emoji-back-drop'
                    onClick={() => setShowEmojiPicker(false)}></div>
            )}
            <div className='chat-window-main'>
                {showUserOptions === true && (
                    <div
                        className='back-drop'
                        onClick={() => setShowUserOptions(false)}></div>
                )}
                <div className='about'>
                    <div
                        className={`${
                            loggedInAs === "client"
                                ? "about-user freelancer-about"
                                : "about-user"
                        }`}>
                        <ExpandIcon
                            onClick={() => setCurrentView("user-list")}
                        />
                        <div
                            className='image-container'
                            onClick={() => {
                                if (loggedInAs === "client") {
                                    navigateTo(
                                        "/freelancer-details/" +
                                            activeUserGroup.user_id
                                    );
                                }
                            }}>
                            <img
                                src={
                                    domainName +
                                    "/uploads/" +
                                    activeUserGroup?.profile_img
                                }
                                alt=''
                            />
                            {activeUserGroup?.is_online && (
                                <span className='online-mark'></span>
                            )}
                        </div>
                        <span
                            className='name-container'
                            onClick={() => {
                                if (loggedInAs === "client") {
                                    navigateTo(
                                        "/freelancer-details/" +
                                            activeUserGroup.user_id
                                    );
                                }
                            }}>
                            <p className='name'>{activeUserGroup?.name}</p>
                            <p className='status'>{activeUserGroup?.status}</p>
                        </span>
                    </div>
                    <img
                        src={MoreIcon}
                        alt=''
                        onClick={() => setShowUserOptions(true)}
                    />
                    {showUserOptions && (
                        <div className='user-options'>
                            {loggedInAs === "client" && (
                                <>
                                    <span
                                        className='option'
                                        onClick={() =>
                                            navigateTo(
                                                (loggedInAs === "client"
                                                    ? "/freelancer-details/"
                                                    : "/client-details/") +
                                                    activeUserGroup.user_id
                                            )
                                        }>
                                        Profile
                                    </span>
                                    <hr />
                                </>
                            )}
                            <span className='option'>Mute Notificatons</span>
                        </div>
                    )}
                </div>
                <div
                    className='message-container'
                    onScroll={handleScroll}
                    ref={messageContainerRef}>
                    {messages.map((message) => (
                        <div
                            className={`message ${
                                message?.sender === loggedInAs
                                    ? "sent"
                                    : "recived"
                            }`}
                            id={
                                "message-" +
                                activeUserGroup?.id +
                                "-" +
                                message?.id
                            }
                            key={
                                "message-" +
                                activeUserGroup?.id +
                                "-" +
                                message?.id
                            }>
                            {message?.sender !== loggedInAs && (
                                <img
                                    src={
                                        domainName +
                                        "/uploads/" +
                                        activeUserGroup?.profile_img
                                    }
                                    className='user-image'
                                    alt=''
                                />
                            )}
                            <span>
                                {message?.sender !== loggedInAs && (
                                    <p className='name'>
                                        {activeUserGroup?.name}
                                    </p>
                                )}
                                {message.message_type === "text" && (
                                    <p className='message-text'>
                                        {message?.message}
                                    </p>
                                )}
                                {message.message_type === "file" &&
                                    imageExtensions.includes(
                                        message.document.split(".").slice(-1)[0]
                                    ) && (
                                        <img
                                            className='message-text message-img'
                                            src={
                                                domainName +
                                                "/uploads/" +
                                                message.document
                                            }
                                            alt=''
                                        />
                                    )}
                                {message.message_type === "file" &&
                                    !imageExtensions.includes(
                                        message.document.split(".").slice(-1)[0]
                                    ) && (
                                        <span
                                            className='attachment'
                                            onClick={() =>
                                                window.open(
                                                    domainName +
                                                        "/uploads/" +
                                                        message.document,
                                                    "_blank"
                                                )
                                            }>
                                            <img src={PdfIcon} alt='' />
                                            <p>
                                                {
                                                    message.document
                                                        .split("/")
                                                        .slice(-1)[0]
                                                }
                                            </p>
                                        </span>
                                    )}
                                <p className={`time`}>
                                    {new Date().toLocaleDateString() ===
                                    new Date(
                                        message?.create_at
                                    ).toLocaleDateString()
                                        ? new Date(message?.create_at)
                                              .toLocaleTimeString()
                                              .slice(0, -3)
                                        : new Date(message?.create_at)
                                              .toLocaleString()
                                              .slice(0, -3)}
                                </p>
                            </span>
                        </div>
                    ))}
                    {pendingMessages.map((message) => (
                        <div
                            className={`message ${
                                message?.sender === loggedInAs
                                    ? "sent"
                                    : "recived"
                            }`}
                            key={
                                "message-" +
                                activeUserGroup?.id +
                                "-" +
                                message?.id
                            }>
                            {message?.sender !== loggedInAs && (
                                <img
                                    src={
                                        domainName +
                                        "/uploads/" +
                                        activeUserGroup?.profile_img
                                    }
                                    className='user-image'
                                    alt=''
                                />
                            )}
                            <span>
                                {message?.sender !== loggedInAs && (
                                    <p className='name'>
                                        {activeUserGroup?.name}
                                    </p>
                                )}
                                {message.message_type === "text" && (
                                    <p className='message-text'>
                                        {message?.message}
                                    </p>
                                )}
                                {/* {(message.message_type === "file" && imageExtensions.includes(message.img_link.split(".")[-1])) && (
                                <img
                                    className='message-text'
                                    src={message.img_link}
                                    alt=''
                                />
                            )} */}
                                {message.message_type === "file" && (
                                    <span className='attachment'>
                                        {/* <img src={PdfIcon} alt='' /> */}
                                        <p>{message.document_name}</p>
                                    </span>
                                )}
                                <p className={`time`}>Sending</p>
                            </span>
                        </div>
                    ))}
                </div>
                <div className='input-container'>
                    <EmojiIcon
                        onClick={() => setShowEmojiPicker((pre) => !pre)}
                    />
                    {showEmojiPicker && (
                        <div className='emoji-picker'>
                            <EmojiPicker
                                onEmojiClick={(e) =>
                                    setNewMessage((pre) => pre + e.emoji)
                                }
                            />
                        </div>
                    )}
                    {/* input:emoj */}
                    <AttachFileIcon onClick={() => fileRef.current.click()} />
                    <input type='file' onChange={sendFile} ref={fileRef} />
                    <input
                        type='text'
                        placeholder='Reply...'
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <div className='send-btn' onClick={sendMessage}>
                        <ExpandIcon />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatWindow;
