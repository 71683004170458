import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { useStateValue } from "../../../../StateProvider";
import './ytLinkInput.scss'

const YtLinkInput = ({ formData, setFormData, setShowVideo }) => {
    const [, dispatch] = useStateValue();
    const [error, setError] = useState("")
    function getYouTubeVideoId(url) {
        // Regular expression to match YouTube video ID in various URL formats
        var regExp =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        // Extract the video ID using the regular expression
        var match = url.match(regExp);

        // If a match is found, return the video ID; otherwise, return null
        return match ? match[1] : null;
    }
    const hideYtLinkModal = () => {
        dispatch({
            type: "SET_YT_LINK_MODAL_SHOW",
            status: false,
        });
    };
    const handleSubmit = () => {
        if(formData.yt_video_link !== "" && getYouTubeVideoId(formData.yt_video_link)){
            setError("")
            setFormData(pre => {return {...pre, portfolio: ""}})
            setShowVideo(true)
            hideYtLinkModal();
        }else{
            setError("Invalid youtube video url.")
        }
    };
    return (
        <div className='yt-link-main'>
            <div className='header'>
                <p>Add Portfolio</p>
                <CloseIcon onClick={hideYtLinkModal}/>
            </div>
            <div className='input-grp'>
                <p className='bold'>
                    Paste the link to your Youtube or Vimeo video here
                </p>
                <input
                    type='text'
                    placeholder='Youtube video link...'
                    className='role-input'
                    value={formData.yt_video_link}
                    onChange={(e) =>
                        setFormData((pre) => {
                            return { ...pre, yt_video_link: e.target.value };
                        })
                    }
                />
                <p className="form-error">{error}</p>
            </div>
            <div className='buttons'>
                <button className='btn-white-outline' onClick={hideYtLinkModal}>
                    Cancel
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default YtLinkInput;
