const saved_jobs =
  sessionStorage.getItem("saved_jobs") || localStorage.getItem("saved_jobs");
const saved_freelancers =
  sessionStorage.getItem("saved_freelancers") ||
  localStorage.getItem("saved_freelancers");
export const initialState = {
  loggedInAs:
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? localStorage.getItem("user_type") || sessionStorage.getItem("user_type")
        ? localStorage.getItem("user_type") ||
          sessionStorage.getItem("user_type")
        : ""
      : "",
  showForgotPasswordModal: false,
  hasOtherDetails:
    sessionStorage.getItem("have_other_details") === "true" ? true : false,
  showWorkExperienceModal: false,
  categories: [],
  showEducationModal: false,
  showYtLinkModal: false,
  showRoleEditModal: false,
  showSkillsEditModal: false,
  showBioEditModal: false,
  showRateEditModal: false,
  showOriginEditModal: false,
  showPortfolioEditModal: false,
  showSuccessModal: false,
  showDeleteModal: false,
  showSubmitWorkModal: false,
  showFeedbackModal: false,
  showProposalModal: false,
  showBankDetailsModal: false,
  showBillingMethodsModal: false,
  showWorkApproveModal: false,
  showReleaseFundModal: false,
  showDepositModal: false,
  showRequirementsReviewModal: false,
  packagePurchaseModal: false,
  purchaseModal: false,
  saved_jobs:
    saved_jobs && saved_jobs !== "undefined" ? JSON.parse(saved_jobs) : [],
  saved_freelancers:
    saved_freelancers && saved_freelancers !== "undefined"
      ? JSON.parse(saved_freelancers)
      : [],
  currency_symbol:
    sessionStorage.getItem("is_indian_user") === "true" ||
    localStorage.getItem("is_indian_user") === "true"
      ? "₹"
      : "$",
  countries_list: [],
  notifications: [],
  isLoading: false,
  tax: 18,
  commission_for_free_client: 10,
  thresold_for_commission: 1000000,
  commission_for_member_client: 10,
  commission_for_member_client_with_cost_greater_than_thresold: 5,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_IS_LOADING":
      if (action.status) {
        document.body.style.overflow = "hidden";
        window.scrollTo(0, 0);
      } else {
        document.body.style.overflow = "auto";
      }
      return {
        ...state,
        isLoading: action.status,
      };
    case "SET_LOGGED_IN_AS":
      return {
        ...state,
        loggedInAs: action.status,
      };
    case "SET_FORGOT_PASSWORD_MODAL":
      return {
        ...state,
        showForgotPasswordModal: action.status,
      };
    case "SET_HAS_OTHER_DETAILS":
      return {
        ...state,
        hasOtherDetails: action.status,
      };
    case "SET_SAVED_JOBS":
      return {
        ...state,
        saved_jobs: action.status,
      };
    case "SET_SAVED_FREELANCERS":
      return {
        ...state,
        saved_freelancers: action.status,
      };
    case "SET_CURRENCY_SYBMOL_JOBS":
      return {
        ...state,
        currency_symbol: action.status,
      };
    case "SET_WORK_EXPERIENCE_SHOW":
      return {
        ...state,
        showWorkExperienceModal: action.status,
      };
    case "SET_EDUCATION_MODAL_SHOW":
      return {
        ...state,
        showEducationModal: action.status,
      };
    case "SET_YT_LINK_MODAL_SHOW":
      return {
        ...state,
        showYtLinkModal: action.status,
      };
    case "SET_SKILLS_EDIT_MODAL_SHOW":
      return {
        ...state,
        showSkillsEditModal: action.status,
      };
    case "SET_ROLE_EDIT_MODAL_SHOW":
      return {
        ...state,
        showRoleEditModal: action.status,
      };
    case "SET_BIO_EDIT_MODAL_SHOW":
      return {
        ...state,
        showBioEditModal: action.status,
      };
    case "SET_RATE_EDIT_MODAL_SHOW":
      return {
        ...state,
        showRateEditModal: action.status,
      };
    case "SET_ORIGIN_EDIT_MODAL_SHOW":
      return {
        ...state,
        showOriginEditModal: action.status,
      };
    case "SET_PORTFOLIO_EDIT_MODAL_SHOW":
      return {
        ...state,
        showPortfolioEditModal: action.status,
      };
    case "SET_SUCCESS_MODAL_SHOW":
      return {
        ...state,
        showSuccessModal: action.status,
      };
    case "SET_DELETE_MODAL_SHOW":
      return {
        ...state,
        showDeleteModal: action.status,
      };
    case "SET_SUBMIT_WORK_MODAL_SHOW":
      return {
        ...state,
        showSubmitWorkModal: action.status,
      };
    case "SET_FEED_BACK_MODAL_SHOW":
      return {
        ...state,
        showFeedbackModal: action.status,
      };
    case "SET_PROPOSAL_MODAL_SHOW":
      return {
        ...state,
        showProposalModal: action.status,
      };
    case "SET_BANK_DETAILS_MODAL_SHOW":
      return {
        ...state,
        showBankDetailsModal: action.status,
      };
    case "SET_BILLING_METHODS_MODAL_SHOW":
      return {
        ...state,
        showBillingMethodsModal: action.status,
      };
    case "SET_WORK_APPROVE_MODAL_SHOW":
      return {
        ...state,
        showWorkApproveModal: action.status,
      };
    case "SET_RELEASE_FUND_MODAL_SHOW":
      return {
        ...state,
        showReleaseFundModal: action.status,
      };
    case "SET_DEPOSIT_MODAL_SHOW":
      return {
        ...state,
        showDepositModal: action.status,
      };
    case "SET_REQUIREMENTS_MODAL_SHOW":
      return {
        ...state,
        showRequirementsReviewModal: action.status,
      };
    case "SET_PURCHASE_MODAL_SHOW":
      return {
        ...state,
        purchaseModal: action.status,
      };
    case "SET_PACKAGE_PURCHASE_MODAL_SHOW":
      return {
        ...state,
        packagePurchaseModal: action.status,
      };
    case "SET_COUNTRIES_LIST":
      const countries = action.status?.filter(country => !['India', 'United Arab Emirates', 'United Kingdom', 'United States'].includes(country))
      return {
        ...state,
        countries_list: countries ? ['India', 'United Arab Emirates', 'United Kingdom', 'United States', ""].concat(countries?.toSorted()) : [],
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.status,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.status,
      };
    case "SET_TAX":
      return {
        ...state,
        tax: action.status,
      };
    case "SET_COMMISSION_FOR_FREE_CLIENT":
      return {
        ...state,
        commission_for_free_client: action.status,
      };
    case "SET_THRESOLD_FOR_COMMISSION":
      return {
        ...state,
        thresold_for_commission: action.status,
      };
    case "SET_COMMISSION_FOR_MEMBER_CLIENT":
      return {
        ...state,
        commission_for_member_client: action.status,
      };
    case "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD":
      return {
        ...state,
        commission_for_member_client_with_cost_greater_than_thresold:
          action.status,
      };

    default:
      return state;
  }
}

export default reducer;
