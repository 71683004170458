import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../Assets/icons/close.svg";
import { useStateValue } from "../../../StateProvider";
import "./bankDetailsForm.scss";
import { savePayoutMethod } from "../../../apiCall";
import toast from "react-hot-toast";

const BankDetailsForm = ({ bankDetails, setBankDetails }) => {
  const [details, setDetails] = useState();
  const [, dispatch] = useStateValue();
  const hideBankDetailsModal = () => {
    dispatch({
      type: "SET_BANK_DETAILS_MODAL_SHOW",
      status: false,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (bankDetails.accountNumber !== details.accountNumber) {
      dispatch({
        type: "SET_IS_LOADING",
        status: true,
      });
      const re = await savePayoutMethod(details);

      if (re?.data?.status) {
        if (re.data.status[0].Response === 200) {
          setBankDetails(details);
          hideBankDetailsModal();
          setDetails({});
          toast.success("bank account added");
        } else {
          toast.error(re.data.status[0].ResponseMessage);
        }
      } else {
        toast.error("something went wrong.");
      }
      setTimeout(()=>{
        dispatch({ type: "SET_IS_LOADING", status: false });

    }, 1000);
    }
    else{
        toast.error("please change account number.")
    }
  };

  useEffect(() => {
    setDetails(bankDetails);
  }, [bankDetails]);
  return (
    <form className="bank-details-form-main" onSubmit={handleSubmit}>
      <div className="header">
        <p>Bank Details</p>
        <CloseIcon onClick={hideBankDetailsModal} />
      </div>
      <div className="input-grp">
        <p className="bold">Account number</p>
        <input
          type="number"
          placeholder="0000 0000 0000 0000"
          value={details?.accountNumber}
          onChange={(e) =>
            setDetails((pre) => {
              return { ...pre, accountNumber: e.target.value };
            })
          }
          required
        />
      </div>
      <div className="input-grp">
        <p className="bold">Name</p>
        <input
          type="text"
          placeholder="Add name"
          value={details?.name}
          onChange={(e) =>
            setDetails((pre) => {
              return { ...pre, name: e.target.value };
            })
          }
          required
        />
      </div>
      <div className="input-grp">
        <p className="bold">IFSC code</p>
        <input
          type="text"
          placeholder="add IFSC code"
          value={details?.ifscCode}
          onChange={(e) =>
            setDetails((pre) => {
              return { ...pre, ifscCode: e.target.value.toUpperCase() };
            })
          }
          required
          pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
          title="enter valide ifsc code."
        />
      </div>
      <div className="buttons">
        <button className="btn-blue">Submit Bank Details</button>
      </div>
    </form>
  );
};

export default BankDetailsForm;
