import React, { useState } from "react";

import "./step3.scss";

const Step3 = ({ formData, setFormData, setPageNo }) => {
    const [error, setError] = useState({'about': "", 'why': ""})
    const handleNext = () => {
        if(!formData?.about_packaging){
            setError(pre => {return {... pre, about: "About offer packaging is required."}})
            return;
        }
        setError(pre => {return {... pre, about: ""}})
        if(!formData?.why_should_you_use){
            setError(pre => {return {...pre, why: "This question is required."}})
            return;    
        }
        setError(pre => {return {... pre, why: ""}})
        localStorage.setItem("create_offer", JSON.stringify(formData));
        setPageNo((pre) => {
            localStorage.setItem("offer_create_page_no", pre + 1);
            return pre + 1;
        });
    };
    return (
        <>
            <div className='step-3-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 3 of 4</p>
                        </span>
                        <p className='title'>Service description</p>
                        <p className='desc'>
                            Your title is the most important place to include
                            keywords that buyers would likely use to search for
                            a service like yours.
                        </p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>About offer packaging</p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.about_packaging}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        about_packaging: e.target.value,
                                    };
                                })
                            }
                        />
                        <p className="form-error">{error?.about}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>
                            Why should you use this service of mine
                        </p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.why_should_you_use}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        why_should_you_use: e.target.value,
                                    };
                                })
                            }
                        />
                        <p className="form-error">{error['why']}</p>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={handleNext}>
                    Next: Cover Image
                </button>
            </div>
        </>
    );
};

export default Step3;
