import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import "./navOffCanvas.scss";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProvider";

const NavOffCanvas = ({ show, setShow, activeTab }) => {
    const navigateTo = useNavigate()
    const [{ loggedInAs }] = useStateValue();
    return (
        <Offcanvas
            show={show}
            className='nav-offcanvas-main'
            onHide={() => setShow(false)}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {loggedInAs === "freelancer" && <div className='items'>
                    <span className='item' onClick={() => {setShow(false); navigateTo('/')}}>
                        <p>Find work</p>
                        {activeTab === "find_work" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/my-jobs')}}>
                        <p>My jobs</p>
                        {activeTab === "my_jobs" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/create-offer')}}>
                        <p>Offer create</p>
                        {activeTab === "create_offer" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/plans')}}>
                        <p>Membership plans</p>
                        {activeTab === "membership_plans" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                </div>}
                {loggedInAs === "client" && <div className='items'>
                    <span className='item' onClick={() => {setShow(false); navigateTo('/')}}>
                        <p>Find Freelancers</p>
                        {activeTab === "find_freelancer" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/packages')}}>
                        <p>Package Services</p>
                        {activeTab === "package_services" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/my-jobs')}}>
                        <p>My Job Posts</p>
                        {activeTab === "my_jobs" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <span className='item' onClick={() => {setShow(false);navigateTo('/plans')}}>
                        <p>Membership plans</p>
                        {activeTab === "membership_plans" && (
                            <span className='under-line'></span>
                        )}
                    </span>
                    <button className="primary-btn-blue" onClick={() => {setShow(false);navigateTo('/post-new-job')}}>Post new job</button>
                </div>}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default NavOffCanvas;
