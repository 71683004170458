import React, { useEffect, useState } from "react";

import "./ShippingPolicy.scss";
import { getTermsAndConditions } from "../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME } from "../../Constants";

const ShippingPolicy = () => {
  const [activeTab, setActiveTab] = useState("0");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const [shippingPolicy, setShippingPolicy] = useState([{"title":"Last updated on Mar 11th 2024","description":["Shipping is not applicable for business."]}]);

  

  return (
    <div className="terms-and-condition-main">
      <h2>Shipping & Delivery Policy</h2>
      <div className="details-container">
        <div className="side-bar">
          {shippingPolicy?.map((policy, indx) =>
            policy?.title ? (
              <span
                key={"indx" + indx + policy?.title}
                className={activeTab === policy?.title ? "active" : ""}
                onClick={() => {
                  setActiveTab(policy?.title);
                }}
              >
                <a href={`#${policy?.title}`}>
                  <p>{policy?.title}</p>
                </a>
              </span>
            ) : (
              <></>
            )
          )}
        </div>
        <div className="content">
          <div className="head">
            <h3>Shipping & Delivery Policy</h3>
            
          </div>
          <div className="text-container">
            {shippingPolicy?.map((policy, indx) => (
              <div className="grp" key={"indx-details-" + indx + policy?.title}>
                <span className="my-spn" id={policy?.title}></span>
                <h4>{policy?.title}</h4>
                {policy?.description?.map((desc, indx) => (
                  <p key={"policy-desc-" + indx}>{desc}</p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
