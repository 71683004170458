import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";

import "./offers.scss";
import { ReactComponent as StarReview } from "../../../Assets/icons/star.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/icons/check-circle.svg";
import { deleteOfferPackage, getMyOffers } from "../../../apiCall";
import toast from 'react-hot-toast';
import { domainName } from "../../../Constants";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";

const Offers = () => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [offers, setOffers] = useState([]);
    const navigateTo = useNavigate();
    const [deleteId, setDeleteId] = useState();
    const [, dispatch] = useStateValue();
    useEffect(() => {
        const getData = async () => {
            dispatch({type:"SET_IS_LOADING",status:true})

            const Re = await getMyOffers(page);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("OFFERS_DATA", Re);

            if (Re?.data?.status[0].Error === "False") {
                setOffers(Re?.data.value?.offers);
                setTotalPages(Re?.data.value?.total_pages);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };
        getData();
    }, [page, dispatch]);

    const handleDelete = async (id) => {
        dispatch({type:"SET_IS_LOADING",status:true})

        const Re = await deleteOfferPackage(id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("DELETE_OFFERS", Re);

        if (Re?.data?.status[0].Error === "False") {
            setOffers((pre) => pre.filter((offer) => offer.id !== id));
            dispatch({
                type: "SET_DELETE_MODAL_SHOW",
                status: false,
            });
        } else {
            if (Re?.data?.status) {
                toast.error(Re?.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const handleOnDeleteClick = (id) => {
        setDeleteId(id);
        dispatch({
            type: "SET_DELETE_MODAL_SHOW",
            status: true,
        });
    };

    return (
        <div className='offers-main'>
            <h3>Job offering</h3>
            <div className='offers-list'>
                {offers.map((offer) => (
                    <div className='offer' key={"offer-" + offer.id}>
                        <div className='image-container'>
                            <img
                                src={domainName + "/uploads/" + offer.coverpage}
                                alt=''
                                onClick={() => navigateTo("/offer/" + offer.id)}
                            />
                        </div>
                        <div className='details-container'>
                            <div className='about-person'>
                                <img
                                    src={
                                        localStorage.getItem("profile_img") ||
                                        sessionStorage.getItem("profile_img")
                                    }
                                    alt=''
                                />
                                <div className='name'>
                                    <div className='rating-container'>
                                        <p>
                                            {localStorage.getItem("name") ||
                                                sessionStorage.getItem("name")}
                                        </p>
                                        <span className='ratings'>
                                            {Array(5)
                                                .fill(0)
                                                .map((ele, indx) => (
                                                    <StarReview
                                                        key={
                                                            "user-review-ratings-" +
                                                            offer.id +
                                                            "-" +
                                                            indx
                                                        }
                                                        className={`${
                                                            offer.ratings > indx
                                                                ? "filled"
                                                                : ""
                                                        }`}
                                                    />
                                                ))}
                                        </span>
                                    </div>
                                    <span className='tag'>
                                        <CheckIcon />
                                        <p>Top rated</p>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <p className='category'>{offer.category}</p>
                            <p className='desc'>{offer.description}</p>
                            <button
                                className='btn-white-outline'
                                onClick={() => {
                                    localStorage.removeItem(
                                        "offer_create_page_no"
                                    );
                                    navigateTo("/update-offer/" + offer.id);
                                }}>
                                Update
                            </button>
                            <button
                                className='btn-white-outline delete-btn'
                                onClick={() => handleOnDeleteClick(offer.id)}>
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className='pagination'>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </div>
            <DeleteModal
                text='Are you sure you want to delete this Offering?'
                callback={handleDelete}
                id={deleteId}
            />
        </div>
    );
};

export default Offers;
