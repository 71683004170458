import React, { useState } from "react";

import "./step1.scss";
import SkillsForm from "../../../../Login/Components/Step1/SkillsForm";

const Step1 = ({
    formData,
    categories,
    skillsList,
    setFormData,
    setPageNo,
}) => {
    const [errors, setErrors] = useState({});
    const handleNext = () => {
        if (!formData?.title) {
            setErrors((pre) => {
                return { ...pre, title: "Title is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, title: "" };
            });
        }
        if (!formData?.category) {
            setErrors((pre) => {
                return { ...pre, category: "Category is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, category: "" };
            });
        }
        if (!formData?.skills?.length) {
            setErrors((pre) => {
                return { ...pre, skills: "Skills is required field." };
            });
            return;
        }else{
            setErrors((pre) => {
                return { ...pre, skills: [] };
            });
        }

        setPageNo((pre) => pre + 1)
    };

    return (
        <>
            <div className='job-post-step-1-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 1 of 3</p>
                            <span>Job Post</span>
                        </span>
                        <p className='title'>
                            Tell us about what you want to get done.
                        </p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>
                            Write a title of your job post
                            <span className='astrict'>*</span>
                        </p>
                        <input
                            type='text'
                            placeholder='For Ex: Voice artist, UX designer'
                            className='role-input'
                            value={formData.title}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, title: e.target.value };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.title}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='title'>Category of your services?</p>
                        <select
                            name=''
                            id=''
                            value={formData.category}
                            onChange={(e) =>
                                setFormData((prev) => {
                                    return {
                                        ...prev,
                                        category: e.target.value,
                                    };
                                })
                            }>
                            <option value=''>Category</option>
                            {categories?.map((cat) => (
                                <option value={cat.id}>{cat?.name}</option>
                                ))}
                        </select>
                                <p className='form-error'>{errors?.category}</p>
                    </div>
                    <SkillsForm
                        formData={formData}
                        skillsList={skillsList}
                        setFormData={setFormData}
                        is_job_post={true}
                    />
                                <p className='form-error'>{errors?.skills}</p>
                </div>
            </div>
            <div className='buttons'>
                {/* <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button> */}
                <span></span>
                <button
                    className='btn-blue'
                    onClick={() => handleNext()}>
                    Next: Scope
                </button>
            </div>
        </>
    );
};

export default Step1;
