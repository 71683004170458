import React, { useState } from "react";
import Clock from "../../../../Assets/icons/clock.svg";
import { ReactComponent as EducationIcon } from "../../../../Assets/icons/educationIcon.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/icons/edit-white.svg";
import { ReactComponent as DeleteIcon } from "../../../../Assets/icons/delete.svg";

import "./step3.scss";
import { EducationModal } from "../../../../Modals";
import { useStateValue } from "../../../../StateProvider";

const Step3 = ({ formData, setFormData, setLoginPageNo }) => {
    const [education_index, setEducationIndex] = useState(-1);

    const [, dispatch] = useStateValue();
    const showEducationModal = () => {
        dispatch({
            type: "SET_EDUCATION_MODAL_SHOW",
            status: true,
        });
    };

    const handleNext = () => {
        localStorage.setItem('formData', JSON.stringify(formData))
        setLoginPageNo(pre => {
            localStorage.setItem('loginPageNo', pre+1)
            return pre+1;
        })
    }
    return (
        <>
            <div className='step-3-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 3 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>Add your education/professional background.</p>
                    </div>
                    <div className='education-container'>
                        {formData.education.map((exp, indx) => {
                            return (
                                <div
                                    className='education'
                                    key={"education-" + indx}>
                                    <div className='details'>
                                        <div className='heading'>
                                            <EducationIcon />
                                            <p>{exp.college}</p>
                                        </div>
                                        <p>
                                            {exp.degree === 'Other' ? exp?.otherCertificate : exp.degree} {exp.start_year} -{" "}
                                            {exp.end_year}
                                        </p>
                                    </div>
                                    <div className='icons'>
                                        <EditIcon
                                            onClick={() => {
                                                setEducationIndex(indx);
                                                showEducationModal();
                                            }}
                                        />
                                        <DeleteIcon
                                            onClick={() => {
                                                setFormData((pre) => {
                                                    console.log(
                                                        "HII",
                                                        pre.education,
                                                        indx
                                                    );
                                                    const new_education =
                                                        pre.education.filter(
                                                            (exp, exp_indx) =>
                                                                exp_indx !==
                                                                indx
                                                        );
                                                    console.log(new_education);
                                                    return {
                                                        ...pre,
                                                        education:
                                                            new_education,
                                                    };
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className='add-education'
                            onClick={() => {
                                setEducationIndex(-1);
                                showEducationModal();
                            }}>
                                <span>

                            <EducationIcon />
                                </span>
                            <p>Add education/ professional details</p>
                        </div>
                    </div>
                </div>
                <EducationModal
                    formData={formData}
                    setFormData={setFormData}
                    education_index={education_index}
                />
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={handleNext}>
                    Next: Service
                </button>
            </div>
        </>
    );
};

export default Step3;
