import React, { useEffect, useState } from "react";

import "./postNewJob.scss";
import Step1 from "./Components/Step1/Step1";
import Step2 from "./Components/Step2/Step2";
import Step3 from "./Components/Step3/Step3";
import { GetSkillsList, getCountries, getJobDetails } from "../../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME } from "../../../Constants";
import JobReview from "./Components/JobReview/JobReview";
import { useStateValue } from "../../../StateProvider";

const PostNewJob = () => {
    const [{categories}] = useStateValue()
    const [pageNo, setPageNo] = useState(0);
    const [skillsList, setSkillsList] = useState([]);
    // const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [, dispatch] = useStateValue()
    const [jobId, setJobId] = useState()
    const [formData, setFormData] = useState({
        skills: [],
        duration: "More than 9 months",
        hiring_mode: "remote",
        title: "",
        category: "",
        description: "",
        responsibilities: "",
        budget_lower_bound: "",
        budget_upper_bound: "",
        files: [],
        city: "",
        state: "",
        country: "",
    });

    useQuery(["get-skills"], () => GetSkillsList(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.status[0].Error === "False") {
                setSkillsList(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });

    useQuery(["get-countries"], () => getCountries(), {
        // staleTime: STALE_TIME,
        refetchOnWindowFocus: false,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            console.log(data);
            setCountries(data.map((country) => country.name.common));
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = {};
        for (const [key, value] of searchParams) {
            params[key] = value;
        }

        const getDetails = async (job_id) => {
            dispatch({type:"SET_IS_LOADING",status:true})

            const Re = await getJobDetails(job_id);
            setTimeout(()=>{
                dispatch({ type: "SET_IS_LOADING", status: false });
    
            }, 1000);
         

            if (Re.data?.status[0].Error === "False") {
                // setJobDetail(Re.data.value);
                console.log(Re.data.value)
                setFormData({
                    skills: Re.data.value.skills.map(skill => {return {id: skill.id, name: skill.skill}}),
                    duration: Re.data.value.duration,
                    hiring_mode: Re.data.value.hiring_mode,
                    title: Re.data.value.title,
                    category: Re.data.value.category_id,
                    description: Re.data.value.description,
                    responsibilities: Re.data.value.responsibilities,
                    budget_lower_bound: Re.data.value.budget_lower_bound,
                    budget_upper_bound: Re.data.value.budget_upper_bound,
                    files: [],
                    city: Re.data.value.city,
                    state: Re.data.value.state,
                    country: Re.data.value.country,
                })
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };

        if (params["job_id"]) {
            getDetails(params["job_id"])
            setJobId(params["job_id"])
        }
    }, [dispatch])

    switch (pageNo) {
        case 0:
            return (
                <div className='post-new-job-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 3 + "%",
                            }}></span>
                    </div>
                    <Step1
                        formData={formData}
                        categories={categories}
                        skillsList={skillsList}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                    />
                </div>
            );

        case 1:
            return (
                <div className='post-new-job-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 3 + "%",
                            }}></span>
                    </div>
                    <Step2
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                        countries={countries}
                    />
                </div>
            );
        case 2:
            return (
                <div className='post-new-job-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 3 + "%",
                            }}></span>
                    </div>
                    <Step3
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                    />
                </div>
            );

        case 3:
            return (
                <div className='post-new-job-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 3 + "%",
                            }}></span>
                    </div>
                    <JobReview
                        formData={formData}
                        setPageNo={setPageNo}
                        categories={categories}
                        jobId={jobId}
                    />
                </div>
            );

        default:
            break;
    }
    return <div className='post-new-job-main'></div>;
};

export default PostNewJob;
