import React, { useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import { useForm } from "react-hook-form";

import "./contactUs.scss";
import { postContactQuery } from "../../apiCall";
import toast from "react-hot-toast";

const ContactUs = () => {
  const [{ loggedInAs }, dispatch] = useStateValue();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleFormSubmit = async (data) => {
    dispatch({ type: "SET_IS_LOADING", status: true });
    const re = await postContactQuery(data);
    setTimeout(() => {
      dispatch({ type: "SET_IS_LOADING", status: false });
    }, 1000);

    console.log("CONTACT_RESPONSE", re, re.status);
    if (re.status !== 200) {
      toast.error("Something went wrong, Please reload the page.");
    } else {
      if (re.data.status[0].Error === "True") {
        if (
          re.data.status[0].ResponseMessage === "Unacceptable Parameter Value"
        ) {
          const errorObj = re.data.status[0].Message;

          Object.keys(errorObj).forEach((key) => {
            setError(key, {
              type: "pattern",
              message: errorObj[key],
            });
          });
        } else {
          toast.error(re.data.status[0].ResponseMessage);
        }
      } else {
        toast.success(
          "Your contact request submited. Someone will contact you soon."
        );
      }
    }
  };
  return (
    <div className="contact-us-main">
      <h2>Contact us</h2>
      <div className="contact-us-container">
        <div className="header">
          <h3>GOOD DAY!</h3>
          <p>We are happy to assist you.</p>
        </div>
        <p className="title">
          Please fill out the form, we will call you back within 24 hours.
        </p>
        <form>
          <div className="input-grp">
            <label htmlFor="name">
              Name<span>*</span>
            </label>
            <input
              type="text"
              placeholder="Please enter your name"
              {...register("name", {
                required: "required",
                maxLength: 100,
              })}
            />
            {errors?.name?.type === "required" && (
              <p className="form-error">name is required.</p>
            )}
            {errors?.name?.type === "maxLength" && (
              <p className="form-error">
                Length of name can not be more than 320
              </p>
            )}
            {errors?.name?.type === "pattern" && (
              <p className="form-error">{errors?.name?.message}</p>
            )}
          </div>
          <div className="input-grp">
            <label htmlFor="email">
              Email<span>*</span>
            </label>
            <input
              type="email"
              placeholder="Please enter your email"
              {...register("email", {
                required: "required",
              })}
            />
            {errors?.email?.type === "required" && (
              <p className="form-error">Email is required.</p>
            )}
            {errors?.email?.type === "pattern" && (
              <p className="form-error">{errors?.email?.message}</p>
            )}
          </div>
          <div className="input-grp">
            <label htmlFor="subject">
              Subject<span>*</span>
            </label>
            <input
              type="text"
              placeholder="Please enter subject."
              {...register("subject", {
                required: "required",
                maxLength: 100,
              })}
            />
            {errors?.subject?.type === "required" && (
              <p className="form-error">Subject is required.</p>
            )}
            {errors?.subject?.type === "maxLength" && (
              <p className="form-error">
                Length of subject can not be more than 320
              </p>
            )}
            {errors?.subject?.type === "pattern" && (
              <p className="form-error">{errors?.subject?.message}</p>
            )}
          </div>
          {loggedInAs === "client" && (
            <>
              <div className="input-grp">
                <label htmlFor="query_type">Contact query type</label>
                <select
                  {...register("query_type", {
                    required: "required",
                  })}
                >
                  <option value="general">General Query</option>
                  <option value="refund">Refund Query</option>
                </select>
                {errors?.query_type?.type === "required" && (
                  <p className="form-error">Query type is required.</p>
                )}
                {errors?.query_type?.type === "pattern" && (
                  <p className="form-error">{errors?.query_type?.message}</p>
                )}
              </div>
              <div className="input-grp">
                <label htmlFor="proposal-id">Proposal Id</label>
                <input
                  type="number"
                  placeholder="Please enter unique proposal id."
                  {...register("proposal_id")}
                />
                {errors?.proposal_id?.type === "pattern" && (
                  <p className="form-error">{errors?.proposal_id?.message}</p>
                )}
              </div>
            </>
          )}
          <div className="input-grp">
            <label htmlFor="problem">
              Describe your problem/solution<span>*</span>
            </label>
            <textarea
              id=""
              placeholder="Type here..."
              {...register("message", {
                required: "required",
              })}
            ></textarea>
            {errors?.message?.type === "required" && (
              <p className="form-error">Message is required.</p>
            )}
            {errors?.message?.type === "pattern" && (
              <p className="form-error">{errors?.message?.message}</p>
            )}
          </div>
          <button
            className="primary-btn-blue"
            onClick={handleSubmit(handleFormSubmit)}
          >
            Submit
          </button>
        </form>
      </div>

      <div className="contact-detail-wrapper">
        <h2>You may contact us using the information below:</h2>

        <div className="contact-detail d-flex flex-column ">
          <span>
            <strong> Merchant Legal entity name:</strong>
            RYSOVATE EXPERIENCES PRIVATE LIMITED
          </span>
          <span>
            <strong>Registered Address:</strong>
            Malad West, Mumbai Mumbai Suburban MAHARASHTRA 400064
          </span>
          {/* <span>
            <strong>Telephone No:</strong>
            9819774232
          </span> */}
          <span>
            <strong>E-Mail ID:</strong>
            support@rysovate.com
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
