import React, { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";

// import UserImage from "../../Assets/icons/user-default.png";
import "./chat.scss";
import UserListView from "./Components/UserListView";
import ChatWindow from "./Components/ChatWindow";
import { useSearchParams } from "react-router-dom";
import { getChatGroups } from "../../apiCall";
import toast from "react-hot-toast";
import { webSocketDomain } from "../../Constants";
import { useStateValue } from "../../StateProvider";
import EmptyPage from "./Components/EmptyPage";

// const userList = [
//     {
//         id: 1,
//         img: UserImage,
//         name: "Prit Manvar 1",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 2,
//         img: UserImage,
//         name: "Prit Manvar 2",
//         is_online: true,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 3,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: true,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 4,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 5,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 6,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 7,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 8,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 9,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 10,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 11,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 12,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 13,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 14,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 15,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
//     {
//         id: 16,
//         img: UserImage,
//         name: "Prit Manvar",
//         is_online: false,
//         status: "Online",
//         time: "9:36 AM",
//         last_message:
//             "Nice. I don't know why people get all worked up about hawaiian pizza. I don't know why people get all worked up about hawaiian pizza.",
//     },
// ];

const Chat = () => {
  const [activeUserGroup, setActiveUserGroup] = useState();
  const [currentView, setCurrentView] = useState("user-list");
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [chatGroups, setChatGroups] = useState([]);

  const [{ loggedInAs }, dispatch] = useStateValue();

  const [searchParams] = useSearchParams();
  const group_id = searchParams.get("chat_group");

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [pendingMessages, setPendingMessages] = useState([]);
  const [pendingMessageCounter, setPendingMessageCounter] = useState(0);
  const { sendJsonMessage } = useWebSocket(
    webSocketDomain + "/" + activeUserGroup?.id
  );
  useWebSocket(webSocketDomain + "/" + activeUserGroup?.id, {
    onOpen: () => {
      // sendJsonMessage({type: "NEW_CONNECTION", group_id: activeUserGroup.id})
      console.log("Connected!");
    },
    onClose: () => {
      console.log("Disconnected!");
    },
    // New onMessage handler
    onMessage: (e) => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case "welcome_message":
          break;
        default:
          console.log("NEW_MESSAGE", data);
          if (data?.message_type === "READ_ACK") {
            if (data.sender === loggedInAs) {
              setChatGroups((pre) =>
                pre.map((grp) => {
                  if (grp.id === activeUserGroup?.id) {
                    const updated_grp = { ...grp };
                    updated_grp.last_message_details[
                      `seen_by_${loggedInAs}`
                    ] = true;
                    updated_grp.first_unseen_message = -1;
                    return updated_grp;
                  } else {
                    return grp;
                  }
                })
              );
              setActiveUserGroup((pre) => {
                return {
                  ...pre,
                  seen_by_freelancer: true,
                  seen_by_client: true,
                  first_unseen_message: data?.first_unseen_message_id,
                };
              });
            }
          } else {
            setPendingMessages((pre) =>
              pre.filter((msg) => msg.id !== data.pending_id)
            );
            setMessages((pre) => pre.concat(data));
            setChatGroups((pre) =>
              pre.map((grp) => {
                if (grp.id === activeUserGroup?.id) {
                  const updated_grp = { ...grp };
                  updated_grp.last_message_details = data;
                  updated_grp.first_unseen_message =
                    loggedInAs !== data["sender"] &&
                    (updated_grp?.first_unseen_message === undefined ||
                      updated_grp?.first_unseen_message === -1)
                      ? data.id
                      : updated_grp?.first_unseen_message;
                  return updated_grp;
                } else {
                  return grp;
                }
              })
            );
          }
          break;
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      dispatch({ type: "SET_IS_LOADING", status: true });

      const re = await getChatGroups();
      setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

      console.log("GET_CHAT_GROUPS_RESPONSE", re, re.status);
      if (re.status !== 200) {
        toast.error("Something went wrong, Please reload the page.");
      } else {
        if (re.data.status[0].Error === "True") {
          toast.error(re.data.status[0].ResponseMessage);
        } else {
          setChatGroups(re.data.value);

          if (group_id) {
            setActiveUserGroup(
              re.data.value?.filter((grp) => grp.id === parseInt(group_id))[0]
            );
          } else {
            setActiveUserGroup(re.data.value[0]);
          }
        }
      }
    };
    getData();
  }, [group_id, dispatch]);
  return (
    <>
      <div className="chat-main desktop-chat-main">
        <UserListView
          chatGroups={chatGroups}
          activeUserGroup={activeUserGroup}
          setActiveUserGroup={setActiveUserGroup}
          setCurrentView={setCurrentView}
          setShowUserOptions={setShowUserOptions}
        />
        {activeUserGroup ? (
          <ChatWindow
            activeUserGroup={activeUserGroup}
            setCurrentView={setCurrentView}
            setShowUserOptions={setShowUserOptions}
            showUserOptions={showUserOptions}
            pendingMessageCounter={pendingMessageCounter}
            setPendingMessageCounter={setPendingMessageCounter}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            pendingMessages={pendingMessages}
            setPendingMessages={setPendingMessages}
            messages={messages}
            setMessages={setMessages}
            sendJsonMessage={sendJsonMessage}
          />
        ) : (
          <EmptyPage />
        )}
      </div>
      <div className="chat-main mobile-chat-main">
        {currentView === "user-list" && (
          <UserListView
            chatGroups={chatGroups}
            activeUserGroup={activeUserGroup}
            setActiveUserGroup={setActiveUserGroup}
            setCurrentView={setCurrentView}
            setShowUserOptions={setShowUserOptions}
          />
        )}
        {currentView === "chat" && (
          <ChatWindow
            activeUserGroup={activeUserGroup}
            setCurrentView={setCurrentView}
            setShowUserOptions={setShowUserOptions}
            showUserOptions={showUserOptions}
            pendingMessageCounter={pendingMessageCounter}
            setPendingMessageCounter={setPendingMessageCounter}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            pendingMessages={pendingMessages}
            setPendingMessages={setPendingMessages}
            messages={messages}
            setMessages={setMessages}
            sendJsonMessage={sendJsonMessage}
          />
        )}
      </div>
    </>
  );
};

export default Chat;
