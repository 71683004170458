import React, { useState } from "react";

import "./packages.scss";
import Filters from "../Components/Filters";
import PackageList from "./Components/PackageList";
// import { GetCategories } from "../../../apiCall";
// import { useQuery } from "react-query";
// import { CACHE_TIME } from "../../../Constants";
// import toast from 'react-hot-toast';
import { useStateValue } from "../../../StateProvider";

const Packages = () => {
    const [appliedFilters, setAppliedFilters] = useState({
        category: null,
        // delivery_time: "",
        budget: [0, 9999],
        response_time: "All",
        location: "",
    });
    // const [categories, setCategories] = useState([]);
    const [{categories}] = useStateValue()

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });
    
    return (
        <div className='packages-main'>
            <div className='filters'>
                <Filters categories={categories} appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters}/>
            </div>
            <PackageList categories={categories} appliedFilters={appliedFilters}/>
        </div>
    );
};

export default Packages;
