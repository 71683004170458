import React, { useEffect, useState } from "react";

import "./purchase.scss";
import { getCouponCodesList, sendProposalToFreelancer } from "../../../../apiCall";
import toast from 'react-hot-toast';
import { useStateValue } from "../../../../StateProvider";
import { displayRazorpay } from "../../../../utils/Razorpay";
import PurchaseDetails from "./PurchaseDetails";
import CouponApply from "../../Packages/Components/CouponApply";

const Purchase = (props) => {
    const [appliedCoupon, setAppliedCoupon] = useState("");
    const [currentView, setCurrentView] = useState("purchase-details");
    const [coupons, setCoupons] = useState();
    // const navigateTo = useNavigate()
    const [, dispatch] = useStateValue()

    console.log(props)

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await getCouponCodesList();
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);


            console.log("COUPON_CODE_LIST_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    setCoupons(re.data.value);
                }
            }
        };
        getData();
    }, [dispatch]);

    const handleCouponCodeApply = () => {
        // setAppliedCoupon(couponDetails)
        setCurrentView("purchase-details");
    };
    const handlePayment = async () => {
        const data = {...props.data,
            coupon: appliedCoupon?.id,
        };
        
        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await sendProposalToFreelancer(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);


        console.log("POST_FREELANCER_PROPOSAL_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                // navigateTo('/packages-requirements/' + props?.offer_id)
                const discount_amount = appliedCoupon?.discount ? Math.min(appliedCoupon?.maximum_discount_amount, props?.data?.price * appliedCoupon?.discount / 100) : 0
                displayRazorpay(props?.data?.price, 'JOB_POST', appliedCoupon?.id, discount_amount, re.data.value, null, dispatch)
            }
        }
    };
    return (
        <div className='purchase-main'>
            {currentView === "purchase-details" ? (
                <>
                    <PurchaseDetails
                        {...props}
                        appliedCoupon={appliedCoupon}
                        setCurrentView={setCurrentView}
                        setAppliedCoupon={setAppliedCoupon}
                    />
                    <button
                        className='primary-btn-blue'
                        onClick={handlePayment}>
                        COUNTINUE WITH PAYMENT
                    </button>
                </>
            ) : (
                <>
                    <CouponApply
                        couponDetails={appliedCoupon}
                        setCouponDetails={setAppliedCoupon}
                        setCurrentView={setCurrentView}
                        coupons={coupons}
                    />
                    <button
                        className='primary-btn-blue'
                        onClick={handleCouponCodeApply}>
                        APPLY
                    </button>
                </>
            )}
        </div>
    );
};

export default Purchase;
