import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Home from "./views/Home/Home";
import HomeNav from "./views/Home/Components/HomeNav";
import HomeFooter from "./views/Home/Components/HomeFooter";
import Nav from "./Components/Nav";
import Login from "./views/Login/Login";
import Profile from "./views/Freelancer/Profile/Profile";
import { useStateValue } from "./StateProvider";
import FindWork from "./views/Freelancer/FindWork/FindWork";
import JobDetails from "./views/Freelancer/JobDetails/JobDetails";
import Chat from "./views/Chat/Chat";
import MyJob from "./views/Freelancer/MyJob/MyJob";
import MemberShipPlans from "./views/MemberShipPlans/MemberShipPlans";
import Billing from "./views/Billing/Billing";
import OfferCreate from "./views/Freelancer/OfferCreate/OfferCreate";
import FindFreelancer from "./views/Client/FindFreelancer/FindFreelancer";
import FreelancerDetails from "./views/Client/FreelancerDetails/FreelancerDetails";
import PostNewJob from "./views/Client/PostNewJob/PostNewJob";
import Offers from "./views/Freelancer/Offers/Offers";
import OfferDetails from "./views/OfferDetails/OfferDetails";
import ClientProfile from "./views/Client/Profile/ClientProfile";
import ClientMyJobs from "./views/Client/MyJobs/MyJobs";
import HireFreelancer from "./views/Client/HireFreelancer/HireFreelancer";
import Packages from "./views/Client/Packages/Packages";
import { GetCategories, getCountries, getNotifications } from "./apiCall";

import { useQuery } from "react-query";
import { Toaster } from "react-hot-toast";
import { CACHE_TIME, googleClinetId } from "./Constants";
import ProtectedRoute from "./ProtectedRoutes";
import Loader from "./Components/Loader/Loader";
import PurchasedPackages from "./views/Client/PurchasedPackages/PurchasedPackages";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ContactUs from "./views/ContactUs/ContactUs";
import AboutUs from "./views/AboutUs/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./views/TermsAndCondition/TermsAndCondition";
import Faqs from "./views/Faqs/Faqs";
import RefundPolicy from "./views/RefundPolicy/RefundPolicy";
import ShippingPolicy from "./views/ShippingPolicy/ShippingPolicy";

function App() {
    const [{ loggedInAs, isLoading }, dispatch] = useStateValue();
    useQuery(["get-countries"], () => getCountries(), {
        // staleTime: STALE_TIME,
        refetchOnWindowFocus: false,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            dispatch({
                type: "SET_COUNTRIES_LIST",
                status: data.map((country) => country.name.common),
            });
        },
        onError: (err) => {
            console.log(err, "ERROR");
            // if (err.message) {
            //     toast.error(err.message);
            // } else {
            //     toast.error("Something went wrong while fetching countries");
            // }
        },
    });

    useQuery(["get-notifications"], () => getNotifications(false), {
        // staleTime: STALE_TIME,
        refetchOnWindowFocus: false,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            console.log(data);
            dispatch({
                type: "SET_NOTIFICATIONS",
                status: data?.data?.value,
            });
        },
        onError: (err) => {
            console.log(err, "ERROR");
            // if (err.message) {
            //     toast.error(err.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        },
    });

    useQuery(["get-categories"], () => GetCategories(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            console.log(data);
            if (data?.data?.status[0].Error === "False") {
                dispatch({
                    type: "SET_CATEGORIES",
                    status: data?.data?.value,
                });
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            // if (err.message) {
            //     toast.error(err.message);
            // } else {
            //     toast.error("Something went wrong");
            // }
        },
    });

    return (
        <div className='App'>
            {isLoading && <Loader />}
            <GoogleOAuthProvider clientId={googleClinetId}>
                <BrowserRouter>
                    <ProtectedRoute>
                        <Routes>
                            <Route
                                path='/'
                                element={
                                    <>
                                        {loggedInAs === "" && (
                                            <>
                                                <HomeNav />
                                                <Home />
                                                <HomeFooter />
                                            </>
                                        )}
                                        {loggedInAs === "freelancer" && (
                                            <>
                                                <Nav activeTab='find_work' />
                                                <FindWork />
                                                <HomeFooter />
                                            </>
                                        )}
                                        {loggedInAs === "client" && (
                                            <>
                                                <Nav activeTab='find_freelancer' />
                                                <FindFreelancer />
                                                <HomeFooter />
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <Route
                                path='/job-details/:id'
                                element={
                                    <>
                                        <Nav activeTab={"find_work"} />
                                        <JobDetails />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/update-bid/:id'
                                element={
                                    <>
                                        <Nav activeTab={"find_work"} />
                                        <JobDetails />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/my-jobs'
                                element={
                                    <>
                                        {loggedInAs === "freelancer" && (
                                            <>
                                                <Nav activeTab={"my_jobs"} />
                                                <MyJob />
                                                <HomeFooter />
                                            </>
                                        )}
                                        {loggedInAs === "client" && (
                                            <>
                                                <Nav activeTab={"my_jobs"} />
                                                <ClientMyJobs />
                                                <HomeFooter />
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <Route
                                path='/freelancer-details/:id'
                                element={
                                    <>
                                        <Nav activeTab={"find_freelancer"} />
                                        <FreelancerDetails />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/post-new-job'
                                element={
                                    <>
                                        <Nav />
                                        <PostNewJob />
                                        <HomeFooter />
                                    </>
                                }
                            />

                            <Route
                                path='/chat'
                                element={
                                    <>
                                        <Nav />
                                        <Chat />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/plans'
                                element={
                                    <>
                                        <Nav activeTab={"membership_plans"} />
                                        <MemberShipPlans />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/billing'
                                element={
                                    <>
                                        <Nav />
                                        <Billing />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/create-offer'
                                element={
                                    <>
                                        <Nav activeTab={"create_offer"} />
                                        <OfferCreate />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/update-offer/:id'
                                element={
                                    <>
                                        <Nav activeTab={"create_offer"} />
                                        <OfferCreate />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/offers'
                                element={
                                    <>
                                        <Nav />
                                        <Offers activeTab={"create_offer"} />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/offer/:id'
                                element={
                                    <>
                                        <Nav activeTab={"package_services"} />
                                        <div style={{ paddingTop: "80px" }}>
                                            <OfferDetails />
                                        </div>
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/packages'
                                element={
                                    <>
                                        <Nav activeTab={"package_services"} />
                                        <Packages />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/my-packages'
                                element={
                                    <>
                                        <Nav />
                                        <PurchasedPackages />
                                        <HomeFooter />
                                    </>
                                }
                            />

                            <Route
                                path='/signup'
                                element={
                                    <>
                                        <Nav />
                                        <Login />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/profile'
                                element={
                                    <>
                                        {loggedInAs === "freelancer" && (
                                            <>
                                                <Nav />
                                                <Profile />
                                                <HomeFooter />
                                            </>
                                        )}
                                        {loggedInAs === "client" && (
                                            <>
                                                <Nav />
                                                <ClientProfile />
                                                <HomeFooter />
                                            </>
                                        )}
                                    </>
                                }
                            />
                            <Route
                                path='/freelancer-profile/'
                                element={
                                    <>
                                        <Nav />
                                        <HireFreelancer />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/contact-us/'
                                element={
                                    <>
                                        <Nav />
                                        <ContactUs />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/about-us/'
                                element={
                                    <>
                                        <Nav />
                                        <AboutUs />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/privacy-policy/'
                                element={
                                    <>
                                        <Nav />
                                        <PrivacyPolicy />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/refund-policy/'
                                element={
                                    <>
                                        <Nav />
                                        <RefundPolicy />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/shipping-policy/'
                                element={
                                    <>
                                        <Nav />
                                        <ShippingPolicy />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/terms-and-conditions/'
                                element={
                                    <>
                                        <Nav />
                                        <TermsAndCondition />
                                        <HomeFooter />
                                    </>
                                }
                            />
                            <Route
                                path='/faqs/'
                                element={
                                    <>
                                        <Nav />
                                        <Faqs />
                                        <HomeFooter />
                                    </>
                                }
                            />
                        </Routes>
                    </ProtectedRoute>
                </BrowserRouter>
            </GoogleOAuthProvider>
            <Toaster />
        </div>
    );
}

export default App;
