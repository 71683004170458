import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../../StateProvider";
import { ReactComponent as Close } from "../../../../../Assets/icons/close.svg";

import "./rateEdit.scss";

const RateEdit = ({ formData, setFormData }) => {
    const [, dispatch] = useStateValue();
    const [hourlyRate, setHourlyRate] = useState();
    const setRateEditModalShow = (status) => {
        dispatch({
            type: "SET_RATE_EDIT_MODAL_SHOW",
            status: status,
        });
    };
    useEffect(() => {
        setHourlyRate(formData?.hourly_rate);
    }, [formData]);
    return (
        <div className='rate-edit-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>
                    Now, Let’s set your hourly rate.
                    </p>
                    <Close onClick={() => setRateEditModalShow(false)} />
                </div>
                <p className='desc'>
                    Client will see this rate on your profile and in search
                    results once you publish your profile. You can adjust your
                    rate every time your submit a proposal.
                </p>
                <div className='input-grp'>
                    <div className='text'>
                        <p className='bold'>Hourly rate*</p>
                        <p>Total amount the client will see on your profile</p>
                    </div>
                    <div className='input-container'>
                        <input
                            type='number'
                            placeholder='add'
                            value={hourlyRate}
                            onChange={(e) =>
                                setHourlyRate(e.target.value)
                            }
                        />
                        <span>$/hr</span>
                    </div>
                </div>
                <div className='input-grp'>
                    <div className='text'>
                        <p className='bold'>Platform fee*</p>
                        <p>
                            This helps us run the platform and provide services
                            like payment protection and customer support.
                        </p>
                    </div>
                    <div className='input-container'>
                        <input
                            type='number'
                            placeholder='add'
                            value={hourlyRate * 0.1}
                            disabled
                        />
                        <span>$/hr</span>
                    </div>
                </div>
                <div className='input-grp'>
                    <div className='text'>
                        <p className='bold'>What you'll get*</p>
                        <p>
                            This is final amount what you will get after all
                            deductions
                        </p>
                    </div>
                    <div className='input-container'>
                        <input
                            type='number'
                            placeholder='add'
                            value={
                                hourlyRate -
                                hourlyRate * 0.1
                            }
                            disabled
                        />
                        <span>$/hr</span>
                    </div>
                </div>
            </div>
            <button
                className='btn-blue'
                onClick={() => {
                    setFormData((pre) => {
                        return { ...pre, hourly_rate: hourlyRate };
                    });
                    setRateEditModalShow(false);
                }}>
                Save
            </button>
        </div>
    );
};

export default RateEdit;
