import React, { useEffect, useState } from "react";

import { ReactComponent as SideImage } from "../../Assets/Images/Left Side Image.svg";
import Star from "../../Assets/icons/star-1.svg";
import Shild from "../../Assets/icons/shild.svg";
import ThumbsUp from "../../Assets/icons/thumbs-up.svg";
import Expand from "../../Assets/icons/expand.svg";
import handImage from "../../Assets/Images/hands.svg";
import { ReactComponent as UserIcon } from "../../Assets/icons/User-Icon.svg";
import { ReactComponent as StarIcon } from "../../Assets/icons/Star-Icon.svg";
import { ReactComponent as DocIcon } from "../../Assets/icons/Doc-Icon.svg";
import { ReactComponent as CheckIcon } from "../../Assets/icons/Check-Icon.svg";
import "./aboutUs.scss";
import { useNavigate } from "react-router-dom";
import { getAboutUs } from "../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME } from "../../Constants";

const AccordianData = [
    {
        icon: Star,
        title: "Reliable Dealings",
        details:
            "Data secure text refers to taking measures to protect the confidentiality, integrity, and availability of text data.",
    },
    {
        icon: Shild,
        title: "Data Secured",
        details:
            "Data secure text refers to taking measures to protect the confidentiality, integrity, and availability of text data.",
    },
    {
        icon: ThumbsUp,
        title: "On Time Payment",
        details:
            "Data secure text refers to taking measures to protect the confidentiality, integrity, and availability of text data.",
    },
];
const AboutUs = () => {
    const [expandedAccordion, setexpandedAccordion] = useState(-1);
    const [aboutUs, setAboutUs] = useState();
    const navigateTo = useNavigate();

    useQuery(["get-aboutus"], () => getAboutUs(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            console.log(data);
            if (data?.data?.status[0].Error === "False") {
                setAboutUs(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);
    return (
        <div className='about-us-main'>
            <h2>About Us</h2>
            <div className='about-us-container'>
                <div className='header'>
                    <div className='backface'></div>
                    <p className='heading'>
                        Crafting freelance success together
                    </p>
                </div>
                <div className='section-1'>
                    <div className='title'>
                        <div className='my-hr'></div>
                        <p>WHO WE ARE?</p>
                    </div>
                    <div className='desc'>
                        {aboutUs?.who_we_are.map((text, indx) => (
                            <p key={"who_we_are" + indx}>{text}</p>
                        ))}
                    </div>
                </div>
                <div className='section-2'>
                    <img src={handImage} alt='' />
                    <div className='desc'>
                        <div className='title'>
                            <div className='my-hr'></div>
                            <p>How our journey began...</p>
                        </div>
                        {aboutUs?.how_our_journey_begins.map((text, indx) => (
                            <p
                                className='text'
                                key={"how_our_journey_begins" + indx}>
                                {text}
                            </p>
                        ))}
                    </div>
                </div>
                <div className='section-3'>
                    <div className='details'>
                        <div className='title'>
                            <div className='my-hr'></div>
                            <p>WHERE WE ARE!</p>
                        </div>
                        <div className='desc'>
                            {aboutUs?.where_we_are.map((text, indx) => (
                                <p key={"where_we_are" + indx}>{text}</p>
                            ))}
                        </div>
                    </div>
                    <div className='images'>
                        <div className='image first'>
                            <UserIcon />
                            <div className='text'>
                                <p className='bold'>857M</p>
                                <p className='desc'>Total Freelancer</p>
                            </div>
                        </div>
                        <div className='image second'>
                            <StarIcon />
                            <div className='text'>
                                <p className='bold'>627M</p>
                                <p className='desc'>Positive Reviews</p>
                            </div>
                        </div>
                        <div className='image third'>
                            <DocIcon />
                            <div className='text'>
                                <p className='bold'>87M</p>
                                <p className='desc'>Order Received</p>
                            </div>
                        </div>
                        <div className='image fourth'>
                            <CheckIcon />
                            <div className='text'>
                                <p className='bold'>727M</p>
                                <p className='desc'>Project Completed</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-4'>
                    <SideImage className='side-image' />
                    <div className='section-4-content'>
                        <div className='text'>
                            <div className='my-hr'></div>
                            <h3>OUR VALUES</h3>
                        </div>
                        <div className='accordion'>
                            {AccordianData.map((item, indx) => (
                                <div
                                    className='item'
                                    key={"accordian-" + indx}
                                    onMouseEnter={() =>
                                        setexpandedAccordion(indx)
                                    }
                                    onMouseLeave={() =>
                                        setexpandedAccordion(-1)
                                    }>
                                    <img
                                        src={item.icon}
                                        alt=''
                                        className='accordian-icon'
                                    />
                                    <div className='details'>
                                        <p className='title'>{item.title}</p>
                                        <p
                                            className={`data ${
                                                expandedAccordion === indx
                                                    ? "expanded"
                                                    : ""
                                            }`}>
                                            {item.details}
                                        </p>
                                    </div>
                                    <img
                                        src={Expand}
                                        alt=''
                                        className={`arrow ${
                                            expandedAccordion === indx
                                                ? "rotate"
                                                : ""
                                        }`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='section-5'>
                    <div className='text'>
                        <h1>
                            JOIN <br />
                            WORLD'S BEST <br />
                            <span>MARKETPLACE</span>
                        </h1>
                        <p>
                            Connect to freelancers with proven business
                            experience
                        </p>
                        <button
                            className='primary-btn-white'
                            onClick={() => navigateTo("/signup")}>
                            Started now{" "}
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'>
                                <path
                                    d='M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z'
                                    fill='#5D01DB'
                                />
                            </svg>
                        </button>
                    </div>
                    <span className='client-rate right-circles'>
                        <h3>4.5/5</h3>
                        <p className='text'>Clients rate on Rysovate</p>
                    </span>
                    <span className='satisfied-customer right-circles'>
                        <h3>98%</h3>
                        <p className='text'>Satisfied customers</p>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
