import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../../StateProvider";
import { ReactComponent as Close } from "../../../../../Assets/icons/close.svg";

import "./originEdit.scss";

const OriginEdit = ({ formData, setFormData }) => {
    const [, dispatch] = useStateValue();
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [language, setLanguage] = useState("");

    const setOriginEditModalShow = (status) => {
        dispatch({
            type: "SET_ORIGIN_EDIT_MODAL_SHOW",
            status: status,
        });
    };
    useEffect(() => {
        setGender(formData?.gender);
        setCountry(formData?.country);
        setLanguage(formData?.language);
    }, [formData]);
    return (
        <div className='origin-edit-main'>
            <div className='container'>
                <div className='text'>
                    <p className='title'>
                        Almost there! How would you like to be intro'd?
                    </p>
                    <Close onClick={() => setOriginEditModalShow(false)} />
                </div>
                <div className='input-grp'>
                    <p className='bold'>What gender do you identify as?</p>
                    <select
                        name=''
                        id=''
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}>
                        <option value=''>Gender</option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                        <option value='Non-binary/Other'>Non-binary/Other</option>
                        <option value='Prefer not to say'>
                            Prefer not to say
                        </option>
                    </select>
                </div>
                <div className='input-grp'>
                    <p className='bold'>Which country do you live in</p>
                    <input
                        type='text'
                        placeholder='For Ex: India, USA etc.'
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                </div>{" "}
                <div className='input-grp'>
                    <p className='bold'>
                        What are all languages do you can speak?
                    </p>
                    <input
                        type='text'
                        placeholder='Hindi, English, Bangali'
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                    />
                </div>
            </div>
            <button
                className='btn-blue'
                onClick={() => {
                    setFormData((pre) => {
                        return {
                            ...pre,
                            gender: gender,
                            country: country,
                            language: language,
                        };
                    });
                    setOriginEditModalShow(false);
                }}>
                Save
            </button>
        </div>
    );
};

export default OriginEdit;
