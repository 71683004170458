import React from "react";

import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { useStateValue } from "../../../../StateProvider";
import "./brifProposal.scss";
import { updateProposalStatus } from "../../../../apiCall";
import toast from 'react-hot-toast';

const BrifProposal = ({ proposal, callback }) => {
    const [{ loggedInAs }, dispatch] = useStateValue();
    const hideProposalModal = () => {
        dispatch({
            type: "SET_PROPOSAL_MODAL_SHOW",
            status: false,
        });
    };
    const handleSubmit = async (status) => {
        dispatch({type:"SET_IS_LOADING",status:true})

        const Re = await updateProposalStatus(proposal?.id, status);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("PROPOSAL_UPDATE_DATA", Re);

        if (Re?.data?.status[0].Error === "False") {
            callback();
            hideProposalModal();
        } else {
            if (Re?.data?.status) {
                toast.error(Re?.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };
    const handleDownload = () => {

    }
    console.log(proposal);
    return (
        <div className='brif-proposal-main'>
            <div className='header'>
                <p>Brief Proposal</p>
                <CloseIcon onClick={hideProposalModal} />
            </div>
            <div className='content'>
                <p className='text'>{proposal.proposal}</p>
                {proposal?.end_date && (
                    <p className='timing'>
                        Start date:{" "}
                        <span>{`${new Date(proposal?.start_date).getDate()} - ${
                            new Date(proposal?.start_date).getMonth() + 1
                        } - ${new Date(
                            proposal?.start_date
                        ).getFullYear()}`}</span>{" "}
                        - End date:{" "}
                        <span>{`${new Date(proposal?.end_date).getDate()} - ${
                            new Date(proposal?.end_date).getMonth() + 1
                        } - ${new Date(
                            proposal?.end_date
                        ).getFullYear()}`}</span>
                    </p>
                )}
                <p className='timing'>
                    {proposal?.iteration && (
                        <>
                            Iteration: <span>{proposal?.iteration}</span>
                        </>
                    )}
                </p>
                <p className='cost'>
                    Post will cost: <span>₹{proposal?.amount}</span> and
                    completes in:{" "}
                    {proposal?.end_date ? (
                        <span>
                            {Math.round(
                                (new Date(proposal?.end_date) -
                                    new Date(proposal?.start_date)) /
                                    (1000 * 60 * 60 * 24)
                            )}{" "}
                            Days
                        </span>
                    ) : (
                        <span>{proposal?.duration}</span>
                    )}
                </p>
            </div>
            {(proposal?.status === "pending" &&
            proposal?.sent_by === 'client' && loggedInAs === 'freelancer') ? (
                <div className='buttons'>
                    <button
                        className='btn-blue'
                        onClick={() => handleSubmit("accepted")}>
                        Accept Proposal
                    </button>
                    <button
                        className='btn-white-outline'
                        onClick={() => handleSubmit("rejected")}>
                        Reject Proposal
                    </button>
                </div>
            ) : (
                <div className='buttons'>
                    <button
                        className='btn-blue'
                        onClick={() => handleDownload()}>
                        Download Proposal
                    </button>
                </div>
            )}
        </div>
    );
};

export default BrifProposal;
