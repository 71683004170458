import React from "react";

import "./packagePurchaseDetails.scss";
import HistoryIcon from "../../../../Assets/icons/history.svg";
import RevisionIcon from "../../../../Assets/icons/revision.svg";
import BoxIcon from "../../../../Assets/icons/box.svg";
import PlusIcon from "../../../../Assets/icons/plus-sign.svg";
import MinusIcon from "../../../../Assets/icons/minus-sign.svg";
import CheckIcon from "../../../../Assets/icons/check.svg";
import ExpandIcon from "../../../../Assets/icons/expand.svg";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";

const PackagePurchaseDetails = ({
    data,
    activeTab,
    currency,
    appliedCoupon,
    setCurrentView,
    setAppliedCoupon,
    formData,
    setFormData,
    total,
}) => {
    return (
        <div className='package-purchase-details-main'>
            <div className='details'>
                <div className='description'>
                    <div className='text'>
                        <p className='gray-bold'>{activeTab}</p>
                        <p className='desc'>{data?.description}</p>
                    </div>
                    <p className='price'>
                        {currency}
                        {data?.price}
                    </p>
                </div>
                <div className='includes'>
                    <div className='item'>
                        <img src={BoxIcon} alt='' />
                        <p>Basic Package</p>
                    </div>
                    <div className='item'>
                        <img src={HistoryIcon} alt='' />
                        <p>{data?.delivery_time} Days Delivery</p>
                    </div>
                    <div className='item'>
                        <img src={RevisionIcon} alt='' />
                        <p>{data?.number_of_revisions} Revisions</p>
                    </div>
                    {/* <div className='item'>
                        <img src={ArticleIcon} alt='' />
                        <p>Source file</p>
                    </div> */}
                </div>
                <hr />
                <div className='count-manage'>
                    <p className='bold'>How often do you need this order?</p>
                    <div className='item-container'>
                        <div className='single-price'>
                            <p className='black-bold'>Single Order</p>
                            <p className='price'>
                                {currency}
                                {data?.price}
                            </p>
                        </div>
                        <div className='quantity'>
                            <p>Quantity</p>
                            <span className='handle-input'>
                                <img
                                    src={MinusIcon}
                                    alt=''
                                    onClick={() =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                quantity:
                                                    pre.quantity > 1
                                                        ? pre.quantity - 1
                                                        : pre.quantity,
                                            };
                                        })
                                    }
                                />
                                <span className='count'>
                                    {formData?.quantity}
                                </span>
                                <img
                                    src={PlusIcon}
                                    alt=''
                                    onClick={() =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                quantity: pre.quantity + 1,
                                            };
                                        })
                                    }
                                />
                            </span>
                        </div>
                    </div>
                    {/* <div className='item-container'>
                        <div className='single-price'>
                            <p className='price'>
                                {currency}
                                {data?.source_file_charge}
                            </p>
                        </div>
                        <div className='source-file'>
                            <span
                                className='check-box'
                                onClick={() =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            includeSourceFile:
                                                !pre.includeSourceFile,
                                        };
                                    })
                                }>
                                {formData?.includeSourceFile && (
                                    <img src={CheckIcon} alt='' />
                                )}
                            </span>
                            <span className='text'>
                                <p className='black-bold'>Source file</p>
                                <p className='info'>
                                    The seller will include the source file of
                                    the delivery, so you can make further edits
                                    to the design.
                                </p>
                            </span>
                        </div>
                    </div> */}
                    <div className='item-container'>
                        <div className='single-price'>
                            <p className='price'>
                                {currency}
                                {data?.extra_service?.extra_charge}
                            </p>
                        </div>
                        <div className='source-file'>
                            <span
                                className='check-box'
                                onClick={() =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            take_fast_delivery:
                                                !pre.take_fast_delivery,
                                        };
                                    })
                                }>
                                {formData?.take_fast_delivery && (
                                    <img src={CheckIcon} alt='' />
                                )}
                            </span>
                            <span className='text'>
                                <p className='black-bold'>Fast delivery</p>
                                <p className='info'>
                                    Get delivery in just {data?.extra_service?.special_delivery_time} days
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className='item-container'>
                        <div className='coupon-code-container'>
                            <span className='text'>
                                <p className='bold'>Apply coupons</p>
                                {appliedCoupon?.code ? (
                                    <p className='info'>
                                        <span className='code'>
                                            {appliedCoupon?.code}
                                        </span>{" "}
                                        Applied which saves you {currency}
                                        {Math.min(appliedCoupon?.maximum_discount_amount,total*appliedCoupon?.discount/100)}
                                    </p>
                                ) : (
                                    <p className='info'>
                                        Use coupons to get offers.
                                    </p>
                                )}
                            </span>
                            {appliedCoupon ? (
                                <span
                                    className='remove-coupon icon-container'
                                    onClick={() => setAppliedCoupon(undefined)}>
                                    <p>REMOVE</p>
                                    <CloseIcon />
                                </span>
                            ) : (
                                <span
                                    className='expand-icon-container icon-container'
                                    onClick={() =>
                                        setCurrentView("select-coupon")
                                    }>
                                    <img src={ExpandIcon} alt='' />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='payment'>
                        <p className='bold'>Payment details</p>
                        <div className='calculation'>
                            <span>
                                <p className='title'>Total MRP</p>
                                <p className='value'>
                                    {formData?.quantity} X {currency}
                                    {data?.price}
                                </p>
                            </span>
                            {/* {formData?.includeSourceFile && (
                                <span>
                                    <p className='title'>Source File</p>
                                    <p className='value'>
                                        {currency}
                                        {data?.source_file_charge}
                                    </p>
                                </span>
                            )} */}
                            {formData?.take_fast_delivery && (
                                <span>
                                    <p className='title'>Fast Delivery</p>
                                    <p className='value'>
                                        {currency}
                                        {data?.extra_service?.extra_charge}
                                    </p>
                                </span>
                            )}
                            {appliedCoupon && (
                                <span>
                                    <p className='title'>Coupon Discount</p>
                                    <p className='value'>
                                        -{currency}{Math.min(appliedCoupon?.maximum_discount_amount,total*appliedCoupon?.discount/100)}
                                    </p>
                                </span>
                            )}
                            <span>
                                <p className='title'>Taxes</p>
                                <p className='value'>
                                    {currency}
                                    {(
                                        (total *
                                            formData?.taxPercentage) /
                                        100
                                    ).toFixed(2)}
                                </p>
                            </span>
                            <span>
                                <p className='title'>Rysovate Service Charges</p>
                                <p className='value'>
                                    {currency}
                                    {(
                                        (total *
                                            formData?.commission) /
                                        100
                                    ).toFixed(2)}
                                </p>
                            </span>
                        </div>
                        <hr />
                        <span>
                            <p className='title'>Total MRP</p>
                            <p className='value'>
                                {currency}
                                {total -
                                    (appliedCoupon?.discount
                                        ? Math.min(appliedCoupon?.maximum_discount_amount, total * appliedCoupon?.discount / 100)
                                        : 0) +
                                    (total * formData?.taxPercentage) / 100 + (total * formData?.commission) / 100}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackagePurchaseDetails;
