import React from "react";
import { ReactComponent as StarReview } from "../../../Assets/icons/star.svg";
import HistoryIcon from "../../../Assets/icons/history.svg";
import RevisionIcon from "../../../Assets/icons/revision.svg";
import CheckIcon from "../../../Assets/icons/check.svg";

import './aboutOffer.scss'
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";
import { domainName } from "../../../Constants";

const AboutOffer = ({offerDetails, activeTab, setActiveTab, currency, setShowRequirementsForm }) => {
    const [{ loggedInAs }] = useStateValue();
    const navigateTo = useNavigate()

    return (
        <div className='about-main'>
            <div className='about-container'>
                <div className='offer-name'>
                    <p className='type'>
                        {
                            JSON.parse(
                                offerDetails?.category
                                    ? offerDetails?.category
                                    : "{}"
                            )?.name
                        }
                    </p>
                    <p className='title'>{offerDetails?.package_name}</p>
                </div>
                <img
                    className='review-img'
                    src={offerDetails?.cover_image}
                    alt=''
                />
                <div className='text-container'>
                    <p className='bold'>Description</p>
                    <p className='desc'>{offerDetails?.about_packaging}</p>
                </div>
                <div className='text-container'>
                    <p className='bold'>Why you should hire me?</p>
                    <p className='desc'>{offerDetails?.why_should_you_use}</p>
                </div>
                <div className='mobile-offer-details'>
                    <div className='heading'>
                        <span
                            className={`${
                                activeTab === "basic" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("basic")}>
                            <p>Basic</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "standard" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("standard")}>
                            <p>Standard</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "premium" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("premium")}>
                            <p>Premium</p>
                            <span className='underline'></span>
                        </span>
                    </div>
                    <div className='details'>
                        <p className='price'>
                            {currency}
                            {offerDetails?.services[activeTab].price}
                        </p>
                        <p className='desc'>
                            {offerDetails?.services[activeTab].description}
                        </p>
                        <div className='points'>
                            <span>
                                <img src={HistoryIcon} alt='' />{" "}
                                <p className='bold'>
                                    {
                                        offerDetails?.services[activeTab]
                                            .delivery_time
                                    }{" "}
                                    Days Delivery
                                </p>
                            </span>
                            <span>
                                <img src={RevisionIcon} alt='' />{" "}
                                <p className='bold'>
                                    {
                                        offerDetails?.services[activeTab]
                                            .number_of_revisions
                                    }{" "}
                                    Revisions
                                </p>
                            </span>
                            {offerDetails?.services[activeTab]?.extra_service?.special_delivery_time && <span>
                                <img src={CheckIcon} alt='' />{" "}
                                <p>{offerDetails?.services[activeTab]?.extra_service?.special_delivery_time} Days delivery for extra {currency}{offerDetails?.services[activeTab]?.extra_service?.extra_charge}</p>
                            </span>}
                        </div>
                        {loggedInAs === "client" && (
                            <button
                                className='btn-white-outline'
                                onClick={() => setShowRequirementsForm(true)}>
                                Continue
                            </button>
                        )}
                    </div>
                    {loggedInAs === 'client' && <div className='details-container'>
                        <div className='about-person'>
                            <img
                                src={
                                    domainName +
                                    "/uploads/" +
                                    offerDetails?.freelancer?.profile_img
                                }
                                alt=''
                            />
                            <div className='name'>
                                <div className='rating-container'>
                                    <p>{offerDetails?.freelancer?.name}</p>
                                    <span className='ratings'>
                                        {Array(5)
                                            .fill(0)
                                            .map((ele, indx) => (
                                                <StarReview
                                                    key={
                                                        "user-review-ratings-" +
                                                        offerDetails?.id +
                                                        "-" +
                                                        indx
                                                    }
                                                    className={`${
                                                        offerDetails?.freelancer
                                                            ?.ratings > indx
                                                            ? "filled"
                                                            : ""
                                                    }`}
                                                />
                                            ))}
                                    </span>
                                </div>
                                <span className='tag'>
                                    <p
                                        onClick={() =>
                                            navigateTo(
                                                "/freelancer-details/" +
                                                    offerDetails?.freelancer?.id
                                            )
                                        }>
                                        View Profile
                                    </p>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div className='grp'>
                            <p className='category'>
                                {offerDetails?.freelancer?.service_category}
                            </p>
                            <p className='bold'>
                                {offerDetails?.freelancer?.role}
                            </p>
                        </div>
                        <div className='grp'>
                            <p className='category'>Skills</p>
                            <div className='skills-container'>
                                {offerDetails?.freelancer?.skills.map(
                                    (skill) => (
                                        <span
                                            className='skill'
                                            key={"freelancer-skill" + skill.id}>
                                            {skill.skill}
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                    </div>}
                </div>
                {offerDetails?.reviews?.length > 0 && (
                    <div className='text-container'>
                        <p className='bold'>
                            Seller's Recent History (
                            {offerDetails?.reviews?.length})
                        </p>
                        {offerDetails?.reviews.map((review) => (
                            <div
                                className='user-review'
                                key={"user-review-" + review.id}>
                                <div className='ratings-container'>
                                    <p className='name'>{review.given_for}</p>
                                    <span className='ratings'>
                                        {Array(5)
                                            .fill(0)
                                            .map((ele, indx) => (
                                                <StarReview
                                                    key={
                                                        "user-review-ratings-" +
                                                        review.id +
                                                        "-" +
                                                        indx
                                                    }
                                                    className={`${
                                                        review.ratings > indx
                                                            ? "filled"
                                                            : ""
                                                    }`}
                                                />
                                            ))}
                                    </span>
                                </div>
                                <p className='review'>{review.review}</p>
                                <p className='client-name'>
                                    From Client:{" "}
                                    <span>{review.given_from__name}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className='side-div'>
                <div className='offer-details offer-details-desktop'>
                    <div className='heading'>
                        <span
                            className={`${
                                activeTab === "basic" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("basic")}>
                            <p>Basic</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "standard" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("standard")}>
                            <p>Standard</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "premium" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("premium")}>
                            <p>Premium</p>
                            <span className='underline'></span>
                        </span>
                    </div>
                    <div className='details'>
                        <p className='price'>
                            {currency}
                            {offerDetails?.services[activeTab].price}
                        </p>
                        <p className='desc'>
                            {offerDetails?.services[activeTab].description}
                        </p>
                        <div className='points'>
                            <span>
                                <img src={HistoryIcon} alt='' />{" "}
                                <p className='bold'>
                                    {
                                        offerDetails?.services[activeTab]
                                            .delivery_time
                                    }{" "}
                                    Days Delivery
                                </p>
                            </span>
                            <span>
                                <img src={RevisionIcon} alt='' />{" "}
                                <p className='bold'>
                                    {
                                        offerDetails?.services[activeTab]
                                            .number_of_revisions
                                    }{" "}
                                    Revisions
                                </p>
                            </span>
                            {offerDetails?.services[activeTab]?.extra_service?.special_delivery_time && <span>
                                <img src={CheckIcon} alt='' />{" "}
                                <p>{offerDetails?.services[activeTab]?.extra_service?.special_delivery_time} Days delivery for extra {currency}{offerDetails?.services[activeTab]?.extra_service?.extra_charge}</p>
                            </span>}
                        </div>
                        {loggedInAs === "client" && (
                            <button
                                className='btn-white-outline'
                                onClick={() => setShowRequirementsForm(true)}>
                                Continue
                            </button>
                        )}
                    </div>
                </div>
                {loggedInAs === 'client' && <div className='details-container'>
                    <div className='about-person'>
                        <img
                            src={
                                domainName +
                                "/uploads/" +
                                offerDetails?.freelancer?.profile_img
                            }
                            alt=''
                        />
                        <div className='name'>
                            <div className='rating-container'>
                                <p>{offerDetails?.freelancer?.name}</p>
                                <span className='ratings'>
                                    {Array(5)
                                        .fill(0)
                                        .map((ele, indx) => (
                                            <StarReview
                                                key={
                                                    "user-review-ratings-" +
                                                    offerDetails?.id +
                                                    "-" +
                                                    indx
                                                }
                                                className={`${
                                                    offerDetails?.freelancer
                                                        ?.ratings > indx
                                                        ? "filled"
                                                        : ""
                                                }`}
                                            />
                                        ))}
                                </span>
                            </div>
                            <span className='tag'>
                                <p
                                    onClick={() =>
                                        navigateTo(
                                            "/freelancer-details/" +
                                                offerDetails?.freelancer?.id
                                        )
                                    }>
                                    View Profile
                                </p>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className='grp'>
                        <p className='category'>
                            {offerDetails?.freelancer?.service_category}
                        </p>
                        <p className='bold'>{offerDetails?.freelancer?.role}</p>
                    </div>
                    <div className='grp'>
                        <p className='category'>Skills</p>
                        <div className='skills-container'>
                            {offerDetails?.freelancer?.skills.map((skill) => (
                                <span
                                    className='skill'
                                    key={"freelancer-skill" + skill.id}>
                                    {skill.skill}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default AboutOffer;
