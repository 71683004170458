import toast from "react-hot-toast";
import {
  InitiatePayment,
  CompletePayment,
  InitiateSubscriptionPayment,
  SubscriptionPaymentStatus,
} from "../apiCall";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(
  original_amount,
  reason,
  coupon_code = null,
  total_discount = null,
  proposal_id = null,
  setShowLoader = null,
  dispatch = null
) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  if (setShowLoader) {
    setShowLoader(true);
  }
  const initialize_payment_data = {
    original_amount: original_amount,
    reason: reason,
  };
  if (total_discount) {
    initialize_payment_data.total_discount = total_discount;
  }
  if (coupon_code) {
    initialize_payment_data.coupon_code = coupon_code;
  }
  if (proposal_id) {
    initialize_payment_data.proposal_id = proposal_id;
  }
  const result = await InitiatePayment(initialize_payment_data);
  if (setShowLoader) {
    setShowLoader(false);
  }
  const order = result?.data["order"];

  if (result?.data?.status[0].Error === "True") {
    toast.error(result.data.status[0].Message);
    return;
  }

  const options = {
    key: order["razorpay_merchant_key"], // Enter the Key ID generated from the Dashboard
    amount: order["razorpay_amount"],
    currency: order["currency"],
    name: "Rysovate",
    description: "Test Transaction",
    order_id: order["razorpay_order_id"],
    handler: async function (response) {
      console.log(response);
      const data = {
        orderCreationId: order["razorpay_order_id"],
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };

      const orderRe = await CompletePayment({
        order_id: data.razorpayOrderId,
        payment_id: data.razorpayPaymentId,
      });
      console.log(orderRe);

      if (orderRe?.data["status"][0]["Error"] === "True") {
        toast.error(orderRe?.data["status"][0]["ResponseMessage"]);
        return;
      } else {
        if (orderRe?.data?.order?.reason === "JOB_POST") {
          dispatch({
            type: "SET_PACKAGE_PURCHASE_MODAL_SHOW",
            status: false,
          });
          dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: true,
          });
        } else {
          toast.success(orderRe?.data?.order?.message);
        }
      }
    },
    prefill: {
      name: "Rysovate",
      email: "rysovate@gmail.com",
      contact: "9999999999",
    },
    notes: {
      address: "Rysovate Corporate Office",
    },
    theme: {
      color: "#5D01DB",
    },
  };

  console.log(options);
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
export async function displayRazorpaySubcription(
  plan_id,
  setShowLoader = null,
  dispatch = null
) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  console.log(
    "***********************************************************************************"
  );
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  if (setShowLoader) {
    setShowLoader(true);
  }
  const initialize_payment_data = {
    plan_id,
  };

  const result = await InitiateSubscriptionPayment(initialize_payment_data);
  if (setShowLoader) {
    setShowLoader(false);
  }
  const order = result?.data["order"];

  if (result?.data?.status[0].Error === "True") {
    toast.error(result.data.status[0].ResponseMessage);
    return;
  }

  const options = {
    key: order.razorpay_merchant_key,
    subscription_id: order.subscription_id,
    name: order.company_name,
    description: order.description,
    // image: "/your_logo.jpg",
    handler: async function (response) {
      const re = await SubscriptionPaymentStatus(response);
      console.log(re);

      if (re?.data["status"][0]["Error"] === "True") {
        toast.error(re?.data["status"][0]["ResponseMessage"]);
        return;
      } else {
        toast.success(re?.data["status"][0]["ResponseMessage"]);
      }
    },
    prefill: {
      name: order.user_name,
      email: order.user_email,
      contact: order.user_number,
    },
    // notes: {
    //   note_key_1: "Tea. Earl Grey. Hot",
    //   note_key_2: "Make it so.",
    // },
    theme: {
      color: "#5D01DB",
    },
  };

  console.log(options);
  const subscriptionPaymentObject = new window.Razorpay(options);
  subscriptionPaymentObject.open();
}
