import React, { useState } from "react";

import Clock from "../../../../Assets/icons/clock.svg";
import "./step7.scss";

const Step7 = ({ formData, setFormData, setLoginPageNo }) => {
    const [errors, setErrors] = useState({})
    const handleNext = () => {
        if (!formData?.hourly_rate) {
            setErrors((pre) => {
                return {
                    ...pre,
                    hourly_rate: "Hourly rate is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, hourly_rate: "" };
            });
        }
        localStorage.setItem('formData', JSON.stringify(formData))
        setLoginPageNo(pre => {
            localStorage.setItem('loginPageNo', pre+1)
            return pre+1;
        })
    }

    const is_indian_user = (localStorage.getItem('is_indian_user') || sessionStorage.getItem('is_indian_user')) === 'true' ? true : false;
    return (
        <>
            <div className='step-7-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 7 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>Now, Let’s set your hourly rate.</p>
                        <p className='desc'>
                            Client will see this rate on your profile and in
                            search results once you publish your profile. You
                            can adjust your rate every time your submit a
                            proposal.
                        </p>
                    </div>
                    <div className='input-grp'>
                        <div className='text'>
                            <p className='bold'>Hourly rate*</p>
                            <p>
                                Total amount the client will see on your profile
                            </p>
                            <p className="form-error">{errors?.hourly_rate}</p>
                        </div>
                        <div className='input-container'>
                            <input
                                type='number'
                                placeholder='Hourly rate'
                                value={formData.hourly_rate}
                                min={1}
                                onChange={(e) =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            hourly_rate: e.target.value,
                                        };
                                    })
                                }
                            />
                            <span>{is_indian_user ? "₹" : "$"}/hr</span>
                        </div>
                    </div>
                    <div className='input-grp'>
                        <div className='text'>
                            <p className='bold'>Platform fee*</p>
                            <p>
                                This helps us run the platform and provide
                                services like payment protection and customer
                                support.
                            </p>
                        </div>
                        <div className='input-container'>
                            <input
                                type='number'
                                placeholder='add'
                                value={(formData.hourly_rate * 0.1).toFixed(2)}
                                disabled
                            />
                            <span>{is_indian_user ? "₹" : "$"}/hr</span>
                        </div>
                    </div>
                    <div className='input-grp'>
                        <div className='text'>
                            <p className='bold'>What you'll get*</p>
                            <p>
                            This is final amount what you will get after all deductions
                            </p>
                        </div>
                        <div className='input-container'>
                            <input
                                type='number'
                                placeholder='add'
                                value={formData.hourly_rate - formData.hourly_rate*0.1}
                                disabled
                            />
                            <span>{is_indian_user ? "₹" : "$"}/hr</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={handleNext}>
                    Next: Portfolio
                </button>
            </div>
        </>
    );
};

export default Step7;
