import { api } from "./Constants";
import { request } from "./utils/axios-utils";

// ***************************************************  Create User  ****************************************************************
export async function SignUp(data) {
    return request({ url: api.signup, method: "post", data: data });
}

// ***************************************************  send otp  ****************************************************************
export async function sendOTP(data) {
    return request({ url: api.send_otp, method: "post", data: data });
}

// ***************************************************  verify otp  ****************************************************************
export async function verifyOTP(data) {
    return request({ url: api.verify_otp, method: "post", data: data });
}

// ***************************************************  verify otp  ****************************************************************
export async function forgotPassword(data) {
    return request({ url: api.forgot_password, method: "post", data: data });
}

// ***************************************************  Login  ****************************************************************
export async function SignIn(data) {
    return request({ url: api.login, method: "post", data: data });
}
export const googleLogin = (googleToken) => {
    return request({
        url: api.google_login,
        method: "post",
        data: {
            access_token: googleToken,
        },
    });
};

// ***************************************************  Add basic user details  ****************************************************************
export async function AddBasicDetails(data) {
    return request({ url: api.add_basic_details, method: "patch", data: data });
}

// ***************************************************  Add client details  ****************************************************************
export async function updateClientDetails(data) {
    return request({ url: api.update_client_details, method: "patch", data: data });
}

// ***************************************************  Get skills list  ****************************************************************
export async function GetSkillsList() {
    return request({ url: api.get_skills, method: "get" });
}

// ***************************************************  Get skills list  ****************************************************************
export async function GetServiceList() {
    return request({ url: api.get_service, method: "get" });
}

// ***************************************************  Update Freelancer Data  ****************************************************************
export async function UpdateFreelancerData(data) {
    return request({ url: api.freelancer_data, method: "post", data: data });
}

// ***************************************************  Update Freelancer Data  ****************************************************************
export async function GetFreelancerData() {
    return request({ url: api.freelancer_data, method: "get" });
}

// ***************************************************  Update Freelancer Data  ****************************************************************
export async function GetFreelancerDataById(id, job_id) {
    console.log(id, job_id)
    return request({ url: api.freelancer_data + '/' + id + '/' + job_id, method: "get" });
}

// ***************************************************  update user image  ****************************************************************
export async function UpdateUserProfileImage(data) {
    return request({
        url: api.update_profile_img,
        method: "patch",
        data: data,
    });
}

// ***************************************************  update user image  ****************************************************************
export async function UpdateFreelancerPortfolio(data) {
    return request({
        url: api.update_portfolio,
        method: "patch",
        data: data,
    });
}

// ***************************************************  upload user certificate  ****************************************************************
export async function UpdateCertificate(data, education_index) {
    return request({
        url: api.update_certificate + "/" + education_index,
        method: "patch",
        data: data,
    });
}

// ***************************************************  get freelancer list  ****************************************************************
export async function getFreelancerList(page, filters) {
    let queryParams = "?"
    Object.keys(filters).forEach(key => {
            if(filters[key] && key === "budget"){
                const currency_unit = (localStorage.getItem('is_indian_user') || sessionStorage.getItem('is_indian_user'))
                queryParams += "&" + "budget_lower_bound" + "=" + filters[key][0] + "&" + "budget_upper_bound" + "=" + filters[key][1] + "&" + "currency" + "=" + (currency_unit === 'true' ? 'INR' : 'USD')
            }else if(filters[key]){
                queryParams += "&" + key + "=" + filters[key]
            }
    });
    return request({ url: api.freelancer_list + `/${page+queryParams}`, method: "get" });
}

// ***************************************************  get freelancer's details by id  ****************************************************************
export async function getFreelancerDetailsById(id) {
    return request({ url: api.freelancer_data + `/${id}`, method: "get" });
}

// ***************************************************  Get categories  ****************************************************************
export async function GetCategories() {
    return request({ url: api.get_categories, method: "get" });
}

// ***************************************************  Get countries  ****************************************************************
export async function getCountries() {
    return fetch("https://restcountries.com/v3.1/all?fields=name")
        .then((data) => data.json())
        .catch((err) => err.json());
}

// ***************************************************  post job details  ****************************************************************
export async function postJobDetails(data) {
    return request({ url: api.job + "/post-job", method: "post", data: data });
}

// ***************************************************  update job details  ****************************************************************
export async function updateJobDetails(data, id) {
    return request({ url: api.job + "/update-job/"+id, method: "patch", data: data });
}

// ***************************************************  update work documents  ****************************************************************
export async function uploadJobAttachments(data, id) {
    return request({
        url: api.job + "/attachments/" + id,
        method: "post",
        data: data,
    });
}

// ***************************************************  Delete job  ****************************************************************
export async function deleteJob(id) {
    return request({ url: api.job + "/job/" + id, method: "delete"});
}

// ***************************************************  Open job for rebid  ****************************************************************
export async function openJobForRebid(id) {
    return request({ url: api.job + "/open-for-rebid/" + id, method: "patch"});
}

// ***************************************************  Get jobs  ****************************************************************
export async function GetJobs(page, filters, active_tab) {
    let queryParams = "?" + 'job_type' + "=" + active_tab
    Object.keys(filters).forEach(key => {
            if(filters[key] && key === "budget"){
                const currency_unit = (localStorage.getItem('is_indian_user') || sessionStorage.getItem('is_indian_user'))
                queryParams += "&" + "budget_lower_bound" + "=" + filters[key][0] + "&" + "budget_upper_bound" + "=" + filters[key][1] + "&" + "currency" + "=" + (currency_unit === 'true' ? 'INR' : 'USD')
            }else if(filters[key]){
                queryParams += "&" + key + "=" + filters[key]
            }
    });
    return request({ url: api.job + `/${page+queryParams}`, method: "get" });
}

// ***************************************************  Get saved jobs  ****************************************************************
export async function GetSavedJobs(page) {
    return request({ url: api.get_saved_jobs + `/${page}`, method: "get" });
}

// ***************************************************  Get saved jobs  ****************************************************************
export async function GetSavedFreelancers(page) {
    return request({ url: api.get_saved_freelancers + `/${page}`, method: "get" });
}

// ***************************************************  Get past jobs  ****************************************************************
export async function GetPastJobs(page) {
    return request({ url: api.get_past_jobs + `/${page}`, method: "get" });
}

// ***************************************************  Update saved jobs  ****************************************************************
export async function UpdateSavedJobs(data) {
    return request({ url: api.update_saved_jobs, method: "patch", data: data });
}

// ***************************************************  Update saved freelancers  ****************************************************************
export async function UpdateSavedfreelancers(data) {
    return request({ url: api.update_saved_freelancers, method: "patch", data: data });
}

// ***************************************************  get job details  ****************************************************************
export async function getJobDetails(id) {
    return request({ url: api.job_details+ "/" + id, method: "get" });
}

// ***************************************************  get freelancer jobs  ****************************************************************
export async function getFreelancerJobs(category, page) {
    return request({ url: api.get_freelancer_jobs + "/" + category + "/" + page, method: "get" });
}

// ***************************************************  get client jobs  ****************************************************************
export async function getClientJobs(category, page) {
    return request({ url: api.get_client_jobs + "/" + category + "/" + page, method: "get" });
}

// ***************************************************  get client packages  ****************************************************************
export async function getClientPackages(category, page) {
    return request({ url: api.get_client_packages + "/" + category + "/" + page, method: "get" });
}

// ***************************************************  post bid details  ****************************************************************
export async function postBid(data) {
    return request({ url: api.bid, method: "post", data: data });
}

// ***************************************************  get bid details  ****************************************************************
export async function getBid(job_id) {
    return request({ url: api.bid + "/" + job_id, method: "get" });
}

// ***************************************************  delete bid  ****************************************************************
export async function deleteBid(delete_id) {
    return request({ url: api.bid + "/" + delete_id, method: "delete" });
}

// ***************************************************  post package proposal  ****************************************************************
export async function postPackageProposal(data) {
    return request({ url: api.package_proposal, method: "post", data: data });
}
// ***************************************************  post package proposal  ****************************************************************
export async function postPackageRequirements(proposal, id) {
    return request({ url: api.job + "/package-requirements/" + id, method: "post", data: {proposal} });
}
// ***************************************************  update work documents  ****************************************************************
export async function uploadProposalAttachments(data, id) {
    console.log(data, id)
    return request({
        url: api.job + "/proposal-attachments/" + id,
        method: "post",
        data: data,
    });
}

// ***************************************************  update package coverimage  ****************************************************************
export async function UpdateOfferCoverImage(data, id) {
    return request({
        url: api.update_cover_img + "/" + id,
        method: "patch",
        data: data,
    });
}

// ***************************************************  get user recent 3 reviews  ****************************************************************
export async function getReviews(email) {
    return request({ url: api.reviews + '/' + email, method: "get" });
}

// ***************************************************  create offer package.  ****************************************************************
export async function postOfferPackage(data) {
    return request({ url: api.offerpackage, method: "post", data: data });
}

// ***************************************************  get freelancer's offers  ****************************************************************
export async function getMyOffers(page) {
    return request({ url: api.my_offers + `/${page}`, method: "get" });
}

// ***************************************************  get freelancer's offers  ****************************************************************
export async function getOfferDetails(page) {
    return request({ url: api.offerpackage + `${page}`, method: "get" });
}

// ***************************************************  update offer package  ****************************************************************
export async function updateOfferPackage(id, data) {
    return request({ url: api.offerpackage + "update/" + id, method: "patch", data: data });
}

// ***************************************************  update offer package  ****************************************************************
export async function deleteOfferPackage(id) {
    return request({ url: api.offerpackage + "delete/" + id, method: "delete" });
}

// ***************************************************  update work documents  ****************************************************************
export async function uploadWorkDocuments(data, id) {
    return request({
        url: api.work_documents + "/" + id,
        method: "post",
        data: data,
    });
}

// ***************************************************  upload work details.  ****************************************************************
export async function uploadWorkDetails(data, id) {
    return request({ url: api.work_description + "/" + id, method: "post", data: data });
}

// ***************************************************  upload job feedback.  ****************************************************************
export async function postJobFeedback(data, id) {
    return request({ url: api.job_feedback + "/" + id, method: "post", data: data });
}

// ***************************************************  upload freelancer feedback.  ****************************************************************
export async function postFreelancerFeedback(data, proposal_id) {
    return request({ url: api.freelancer_feedback + "/" + proposal_id, method: "post", data: data });
}

// ***************************************************  reiterate job.  ****************************************************************
export async function reiterateJob(job_id, data) {
    return request({ url: api.reiterate_job + "/" + job_id, method: "patch" , data: data});
}

// ***************************************************  reiterate job.  ****************************************************************
export async function markJobCompleted(job_id) {
    return request({ url: api.mark_job_completed + "/" + job_id, method: "patch"});
}

// ***************************************************  reiterate job.  ****************************************************************
// export async function hireFreelancer(data) {
//     return request({ url: api.hire_freelancer, method: "patch" , data: data});
// }
// ***************************************************  send proposal to freelancer.  ****************************************************************
export async function sendProposalToFreelancer(data) {
    return request({ url: api.send_proposal_to_freelancer, method: "post" , data: data});
}

// ***************************************************  get freelancer's offers  ****************************************************************
export async function getOffers(page) {
    return request({ url: api.offers + `/${page}`, method: "get" });
}

// ***************************************************  get coupon codes list  ****************************************************************
export async function getCouponCodesList() {
    return request({ url: api.coupon_codes_list, method: "get" });
}

// ***************************************************  updated proposal status  ****************************************************************
export async function updateProposalStatus(id, status) {
    return request({ url: api.update_proposal_status + '/' + id + '/' + status, method: "patch" });
}

// ***************************************************  updated proposal status  ****************************************************************
export async function addEmailSubscriber(email) {
    return request({ url: '/api/add-to-email-list/' + email, method: "post" });
}

// ***************************************************  create chat group  ****************************************************************
export async function createChatGroup(freelancer_id) {
    return request({ url: api.create_chat_group + "/" + freelancer_id, method: "post" });
}

// ***************************************************  get chat group  ****************************************************************
export async function getChatGroups() {
    return request({ url: api.get_chat_group, method: "get" });
}

// ***************************************************  get chat group  ****************************************************************
export async function getChatMessages(group_id) {
    return request({ url: api.get_chat_messages + '/' + group_id, method: "get" });
}

// ***************************************************  send chat document  ****************************************************************
export async function SendChatDocument(data, group_id) {
    return request({
        url: api.send_chat_document + "/" + group_id,
        method: "post",
        data: data,
    });
}

// ***************************************************  initiate payment  ****************************************************************
export async function InitiatePayment(data) {
    return request({ url: api.payment, method: "post", data: data });
}
// ***************************************************  initiate subscription payment  ****************************************************************
export async function InitiateSubscriptionPayment(data) {
    return request({ url: api.subscriptionPayment, method: "post", data: data });
}
// ***************************************************   subscription payment status  ****************************************************************
export async function SubscriptionPaymentStatus(data) {
    return request({ url: api.subscriptionPaymentStatus, method: "post", data: data });
}

// ***************************************************  complete payment  ****************************************************************
export async function CompletePayment(data) {
    return request({ url: api.complete_payment, method: "post", data: data });
}

// *************************************************** google login  ****************************************************************

export async function GoogleLogin(data) {
    return request({ url: api.google_login, method: 'post', data: data })
}
// ***************************************************  get notifications  ****************************************************************
export async function getNotifications(mark_read) {
    return request({ url: api.notifications + '/' + mark_read, method: "get" });
}
// ***************************************************  post contact details  ****************************************************************
export async function postContactQuery(data) {
    return request({ url: api.contact, method: "post", data:data });
}
// ***************************************************  get privacy policy  ****************************************************************
export async function getPrivacyPolicy() {
    return request({ url: api.privacy_policy, method: "get" });
}
// ***************************************************  get terms and conditions  ****************************************************************
export async function getTermsAndConditions() {
    return request({ url: api.terms_and_conditions, method: "get" });
}
// ***************************************************  get faqs  ****************************************************************
export async function getFaqs() {
    return request({ url: api.faqs, method: "get" });
}
// ***************************************************  switch user  ****************************************************************
export async function switchUser(user_type) {
    return request({ url: api.switch_user + "/" + user_type, method: "patch" });
}
// ***************************************************  get payout method  ****************************************************************
export async function getPayoutMethod() {
    return request({ url: api.payoutMethod, method: "get" });
}
// ***************************************************  add and update payout method ****************************************************************
export async function savePayoutMethod(data) {
    return request({ url: api.payoutMethod , method: "post",data:data });
}

// ***************************************************  get membership plans  ****************************************************************
export async function getMembershipPlans(user_type) {
    return request({ url: api.membership_plans + "/" + user_type, method: "get" });
}

// ***************************************************  get user stories  ****************************************************************
export async function getUserStories() {
    return request({ url: api.user_stories, method: "get" });
}

// ***************************************************  get about us  ****************************************************************
export async function getAboutUs() {
    return request({ url: api.aboutus, method: "get" });
}
