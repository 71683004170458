import React, { useState } from "react";

// import VisaIcon from "../../../Assets/icons/visa.svg";
// import MasterCardIcon from "../../../Assets/icons/masterCard.svg";
// import CalenderIcon from "../../../Assets/icons/calender.svg";
import "./PayoutInfo.scss";
// import { BankDetailsModal, BillingMethodsModal } from "../../../Modals";
import { BankDetailsModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import { useQuery } from "react-query";
import { getPayoutMethod } from "../../../apiCall";
import { CACHE_TIME } from "../../../Constants";
import toast from "react-hot-toast";

const PayoutInfo = () => {
  // const [billingMethods, setBillingMethods] = useState({});
  const [, dispatch] = useStateValue();
  const showBankDetailsModal = () => {
    dispatch({
      type: "SET_BANK_DETAILS_MODAL_SHOW",
      status: true,
    });
  };
  useQuery(["get-payout-method"], () => getPayoutMethod(), {
    // staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.log(data);
      if (data?.data?.status[0].Response === 200) {
        console.log("asdasd")
        setBankDetails(data?.data?.value)
      } else if (data?.data?.status[0].Response === 500) {
        toast.error("Something went wrong");
      }
    },
    onError: (err) => {
      console.log(err, "ERROR");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  const [bankDetails, setBankDetails] = useState({});

  return (
    <div className="payout-info-main">
      {/* <div className='details'>
                {console.log(Object.keys(billingMethods)?.length)}
                <div className='text'>
                    <p className='bold'>Billing method</p>
                    {Object.keys(billingMethods)?.length !== 0 ? (
                        <div
                            className='billing-info'>
                            <div className='billing-head'>
                                <p>{`${billingMethods?.first_name} ${billingMethods?.last_name}`}</p>
                                <div className='icons'>
                                    <img src={VisaIcon} alt='' />
                                    <img src={MasterCardIcon} alt='' />
                                </div>
                            </div>
                            <div className='billing-body'>
                                <div className='data-grp'>
                                    <div className='billing-data-container'>
                                        <p>Card number</p>
                                        <div>
                                            <p>{billingMethods?.card_number}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='data-grp'>
                                    <div className='billing-data-container'>
                                        <p>Expiration Date</p>
                                        <div>
                                            <p>{billingMethods?.expiration_date}</p>
                                            <img src={CalenderIcon} alt='' />
                                        </div>
                                    </div>
                                    <div className='billing-data-container'>
                                        <p>Security Code</p>
                                        <div>
                                            <p>{billingMethods?.security_code}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className='desc'>
                            You haven’t set up any billing methods yet. Your
                            billing method will be charged only when your
                            available balance from Upwork earnings is not
                            sufficient to pay for your monthly membership and/or
                            Connects.
                        </p>
                    )}
                </div>
                {Object.keys(billingMethods)?.length !== 0  ? <button
                    className='btn-white-outline'
                    onClick={showBillingMethodsModal}>
                    Change a billing method
                </button> : <button
                    className='btn-white-outline'
                    onClick={showBillingMethodsModal}>
                    Add a billing method
                </button>}
            </div> */}
      <div className="details">
        <div className="text">
          <p className="bold">
            Bank details{" "}
            {bankDetails?.accountNumber ? (
              <span onClick={showBankDetailsModal}>Change</span>
            ):""}
          </p>
          {!bankDetails?.accountNumber ? (
            <p className="desc">
              You haven’t set up any billing methods yet. Your billing method
              will be charged only when your available balance from Upwork
              earnings is not sufficient to pay for your monthly membership
              and/or Connects.
            </p>
          ):""}
          {bankDetails?.accountNumber ? (
            <div className="bank-info">
              <p>
                Account number: <span>{bankDetails.accountNumber}</span>
              </p>
              <p>
                Name: <span>{bankDetails.name}</span>
              </p>
              <p>
                IFSC code: <span>{bankDetails.ifscCode}</span>
              </p>
            </div>
          ):""}
        </div>
        {!bankDetails?.accountNumber ? (
          <button className="btn-white-outline" onClick={showBankDetailsModal}>
            Add account number
          </button>
        ):""}
      </div>
      <BankDetailsModal
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
      />
      {/* <BillingMethodsModal
                billingMethods={billingMethods}
                setBillingMethods={setBillingMethods}
            /> */}
    </div>
  );
};

export default PayoutInfo;
