import React, { useState } from "react";
import "./step1.scss";

const Step1 = ({ formData, setFormData, setPageNo, categories }) => {
    const [errors, setErrors] = useState({});

    const handleNext = () => {
        if (!formData?.package_name) {
            setErrors((pre) => {
                return {
                    ...pre,
                    package_name: "Package name is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, package_name: "" };
            });
        }
        if (!formData?.category) {
            setErrors((pre) => {
                return {
                    ...pre,
                    category: "Category is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, category: "" };
            });
        }
        localStorage.setItem("create_offer", JSON.stringify(formData));
        setPageNo((pre) => {
            localStorage.setItem("offer_create_page_no", pre + 1);
            return pre + 1;
        });
    };

    return (
        <>
            <div className='step-1-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 1 of 4</p>
                        </span>
                        <p className='title'>
                            Let us know title of your package offering
                        </p>
                        <p className='desc'>
                            Your title is the most important place to include
                            keywords that buyers would likely use to search for
                            a service like yours.
                        </p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>
                            Package title <span>*</span>
                        </p>
                        <input
                            type='text'
                            placeholder='For Ex: Web design for E- commerce'
                            className='my-input'
                            value={formData.package_name}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        package_name: e.target.value,
                                    };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.package_name}</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Category of your services?</p>
                        <select
                            name=''
                            id=''
                            className='my-input'
                            value={formData.category}
                            onChange={(e) =>
                                setFormData((prev) => {
                                    return {
                                        ...prev,
                                        category: e.target.value,
                                    };
                                })
                            }>
                            <option value=''>Category</option>
                            {categories.map((category) => (
                                <option
                                key={category.title + category.id}
                                value={JSON.stringify({
                                    id: category.id,
                                    name: category.title,
                                })}>
                                    {category?.name}
                                </option>
                            ))}
                        </select>
                            <p className='form-error'>{errors?.category}</p>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                {/* <button className='btn-white-outline'>Skip</button> */}
                <span></span>
                <button className='btn-blue' onClick={handleNext}>
                    Next: Package Pricing
                </button>
            </div>
        </>
    );
};

export default Step1;
