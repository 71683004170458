import React from "react";

import "./billingHistory.scss";

const BillingHistory = () => {
    return (
        <div className='billing-history-main'>
            <div className='head'>
                <div>
                    <p>Paid to date</p>
                    <p className='data'>₹860</p>
                </div>
                <div>
                    <p>Escrow account</p>
                    <p className='data'>₹860</p>
                </div>
                <div>
                    <p>Refunded</p>
                    <p className='data'>₹860</p>
                </div>
                <div className='last'>
                    <p>Any data</p>
                    <p className='data'>₹860</p>
                </div>
            </div>
            <div className='table'>
                <div className='table-head'>
                    <span>Order ID</span>
                    <span>Amount</span>
                    <span>Date</span>
                    <span>From</span>
                </div>
                <div className='table-body'>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                    <div className='table-row'>
                        <span>1234</span>
                        <span>₹860</span>
                        <span>24 Jun'2023</span>
                        <span>Michel</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingHistory;
