import React, { useState } from "react";
import Slider from "@mui/material/Slider";

import { ReactComponent as CheckedIcon } from "../../../Assets/icons/check-mark.svg";
import "./filters.scss";
import { getCountries } from "../../../apiCall";
import { useQuery } from "react-query";
import toast from 'react-hot-toast';
import { CACHE_TIME } from "../../../Constants";

const Filters = ({ categories, setAppliedFilters, setShowFiltersOffCanvas }) => {
    const [showMoreCategories, setShowMoreCategories] = useState(false);
    const [countries, setCountries] = useState([]);

    const initialFilters = {
        category: null,
        // delivery_time: "",
        budget: [0, 9999],
        response_time: "All",
        location: "",
    };
    const [filters, setFilters] = useState(initialFilters);

    const handleChange = (e) => {
        setFilters((pre) => {
            return { ...pre, budget: e.target.value };
        });
    };

    useQuery(["get-countries"], () => getCountries(), {
        // staleTime: STALE_TIME,
        refetchOnWindowFocus: false,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            console.log(data);
            setCountries(data.map((country) => country.name.common));
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    return (
        <div className='freelancer-filters-main'>
            <p
                className='clear'
                onClick={() => {
                    setAppliedFilters({ ...initialFilters, budget: null });
                    setFilters(initialFilters);
                    if (setShowFiltersOffCanvas) {
                        setShowFiltersOffCanvas(false);
                    }
                }}>
                Clear all
            </p>
            <div className='filters-body'>
                <div className='categories filter-category'>
                    <p className='title'>Categories</p>
                    {categories
                        ?.slice(
                            0,
                            showMoreCategories
                                ? categories?.length
                                : Math.min(5, categories?.length)
                        )
                        .map((category) => (
                            <div
                                className='category-item'
                                key={"category-" + category.id}
                                onClick={() =>
                                    setFilters((pre) => {
                                        return {
                                            ...pre,
                                            category: category.id,
                                        };
                                    })
                                }>
                                <span
                                    className={`check-box ${
                                        category.id === filters.category
                                            ? "checked"
                                            : ""
                                    }`}>
                                    <CheckedIcon />
                                </span>
                                <p>{category?.name}</p>
                            </div>
                        ))}
                    {categories?.length > 5 && showMoreCategories === false && (
                        <p
                            className='show-more'
                            onClick={() => setShowMoreCategories(true)}>
                            + Show more
                        </p>
                    )}
                </div>
                {/* <div className='delivery-time filter-category'>
                    <p className='title'>Delivery Time</p>
                    <select
                        name=''
                        id=''
                        value={filters.delivery_time}
                        onChange={(e) =>
                            setFilters((pre) => {
                                return {
                                    ...pre,
                                    delivery_time: e.target.value,
                                };
                            })
                        }>
                        <option value=''>Delivery Time</option>
                        <option value='Less than 3 months'>
                            Less than 3 months
                        </option>
                        <option value='Between 3 to 6 months'>
                            Between 3 to 6 months
                        </option>
                        <option value='Between 6 to 9 months'>
                            Between 6 to 9 months
                        </option>
                        <option value='More than 9 months'>
                            More than 9 months
                        </option>
                    </select>
                </div> */}
                <div className='budget filter-category'>
                    <p className='title'>Hourly Rate</p>
                    <Slider
                        getAriaLabel={() => "hourly rate"}
                        value={filters.budget}
                        onChange={handleChange}
                        valueLabelDisplay='on'
                        valueLabelFormat={(value) =>
                            (localStorage.getItem("is_indian_user") ||
                                sessionStorage.getItem("is_indian_user")) ===
                            "true"
                                ? "₹" + value
                                : "$" + value
                        }
                        min={0}
                        step={1}
                        max={9999}
                    />
                    <div className='values'>
                        <span className='value'>{filters?.budget[0]}</span>
                        <span className='value'>{filters?.budget[1]}</span>
                    </div>
                </div>
                <div className='response-time filter-category'>
                    <p className='title'>Response Time</p>
                    <select
                        name=''
                        id=''
                        value={filters.response_time}
                        onChange={(e) =>
                            setFilters((pre) => {
                                return {
                                    ...pre,
                                    response_time: e.target.value,
                                };
                            })
                        }>
                        <option value='All'>Response Time</option>
                        <option value='Fast'>Fast</option>
                        <option value='Faster'>Faster</option>
                        <option value='Fastest'>Fastest</option>
                    </select>
                </div>
                <div className='location filter-category'>
                    <p className='title'>Location</p>
                    <select
                        name=''
                        id=''
                        value={filters.location}
                        onChange={(e) =>
                            setFilters((pre) => {
                                return { ...pre, location: e.target.value };
                            })
                        }>
                        <option value=''>Location</option>
                        {countries.map((country, indx) => (
                            <option value={country} key={"country" + indx}>
                                {country}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    className='primary-btn-blue'
                    onClick={() => {
                        setAppliedFilters(filters);
                        if (setShowFiltersOffCanvas) {
                            setShowFiltersOffCanvas(false);
                        }
                    }}>
                    FILTER RESULT
                </button>
            </div>
        </div>
    );
};

export default Filters;
