import React, { useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import "./userListView.scss";
import { domainName } from "../../../Constants";
import { useStateValue } from "../../../StateProvider";

const UserListView = ({
  chatGroups,
  activeUserGroup,
  setActiveUserGroup,
  setCurrentView,
  setShowUserOptions,
}) => {
  const handleOnSelect = (person) => {
    setActiveUserGroup(person);
  };
  const [{ loggedInAs }] = useStateValue();

  const formatResult = (person) => {
    return (
      <>
        <div style={{ display: "block", textAlign: "left", zIndex: 2 }}>
          {person.name}
        </div>
      </>
    );
  };

  useEffect(() => {}, []);
  return (
    <div className="user-list-view-main">
      <h4>All Messages</h4>
      <ReactSearchAutocomplete
        items={chatGroups}
        onSelect={handleOnSelect}
        fuseOptions={{ keys: ["name"] }}
        autoFocus
        formatResult={formatResult}
        placeholder="Search"
      />
      <div className="user-list">
        {chatGroups.length > 0 ? (
          <>
            {chatGroups?.map((user) => (
              <div
                className={`user-chat ${
                  activeUserGroup?.id === user?.id ? "active-chat" : ""
                }`}
                onClick={() => {
                  setActiveUserGroup(user);
                  setCurrentView("chat");
                  setShowUserOptions(false);
                }}
                key={"chat-person-" + user?.id}
              >
                <div className="about">
                  <div className="about-user">
                    <div className="image-container">
                      <img
                        src={domainName + "/uploads/" + user?.profile_img}
                        alt=""
                      />
                      {user?.status === "online" && (
                        <span className="online-mark"></span>
                      )}
                    </div>
                    <span>{user?.name}</span>
                  </div>
                  {user?.last_message_details && (
                    <p className="time">
                      {new Date().toLocaleDateString() ===
                      new Date(
                        user?.last_message_details?.create_at
                      ).toLocaleDateString()
                        ? new Date(
                            user?.last_message_details?.create_at
                          ).toLocaleTimeString()
                        : new Date(
                            user?.last_message_details?.create_at
                          ).toLocaleDateString()}
                    </p>
                  )}
                </div>
                <p className="last-message">
                  {user?.last_message_details?.message ||
                    user?.last_message_details?.document?.split("/").slice(-1)}
                </p>
                {user?.last_message_details
                  ? user?.last_message_details[`seen_by_${loggedInAs}`] ===
                      false && <span className="unread_message"></span>
                  : ""}
              </div>
            ))}
          </>
        ) : (
          <div className="empty-list">
            <span>You don't have any conversations.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserListView;
