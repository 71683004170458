import React from "react";

import "./releaseFunds.scss";
import { useStateValue } from "../../../../StateProvider";
import { markJobCompleted } from "../../../../apiCall";
import toast from 'react-hot-toast';

const ReleaseFunds = ({ proposal_id, callback }) => {
    console.log(proposal_id)
    const [, dispatch] = useStateValue();
    const hideReleaseFundsModal = () => {
        dispatch({
            type: "SET_RELEASE_FUND_MODAL_SHOW",
            status: false,
        });
    };
    const handleNotReally = () => {
        hideReleaseFundsModal();
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: true,
        });
    };
    const handleSubmit = async () => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await markJobCompleted(proposal_id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("MARK_JOB_COMPLETED_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                hideReleaseFundsModal();
                dispatch({
                    type: "SET_FEED_BACK_MODAL_SHOW",
                    status: true,
                });
                callback();
            }
        }
    };
    return (
        <div className='realese-work-main'>
            <p className='description'>
                We sincerely hope that the work submitted meets your
                expectations. Kindly note that once approval is granted, further
                iterations will not be possible.
            </p>
            <div className='buttons'>
                <button className='primary-btn-white' onClick={handleNotReally}>
                    Not really
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Yes, Relese the funds
                </button>
            </div>
        </div>
    );
};

export default ReleaseFunds;
