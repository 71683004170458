import React, { useRef, useState } from "react";

import "./step4.scss";

const Step4 = ({ formData, setFormData, setPageNo }) => {
    const fileRef = useRef();
    const [image, setImage] = useState(formData.cover_image);

    const handleNext = () => {
        // localStorage.setItem("create_offer", JSON.stringify(formData));
        setPageNo((pre) => {
            // localStorage.setItem("offer_create_page_no", pre + 1);
            return pre + 1;
        });
    };

    const changeCoverpageImage = async (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            setImage(reader.result);
            setFormData(pre => {return {...pre, cover_image:reader.result, image_file: file}})
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        // const Re = await UpdateOfferCoverImage(imgData);
        // console.log(Re);

        // if (Re.data?.status[0].Error === "False") {
        //     setFormData(pre => {return {...pre, cover_image: domainName + "/uploads/" + Re.data.value?.coverpage}})
        // } else {
        //     if (Re.data?.status) {
        //         toast.error(Re.data.status[0].ResponseMessage);
        //     } else {
        //         toast.error("Something went wrong, Please try again.");
        //     }
        // }
    };
    return (
        <>
            <div className='step-4-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 4 of 4</p>
                        </span>
                        <p className='title'>Share your cover page</p>
                        <p className='desc'>
                            You must have add at one image file to your project*
                        </p>
                    </div>
                    <div className='video-container'>
                        <div className='input-container'>
                            <div
                                className='file-input'
                                onClick={() => fileRef.current.click()}>
                                <input
                                    type='file'
                                    name=''
                                    id=''
                                    ref={fileRef}
                                    accept='image/*'
                                    onChange={(e) => changeCoverpageImage(e)}
                                />
                                <p>
                                    Drag and drop or <span>browse</span> your
                                    file
                                </p>
                            </div>
                        </div>
                    </div>
                    {image && <img src={image} alt='Cover' />}
                    <ul className='points'>
                        <li>
                            Images (.jpg, .gif, .png, up to 10 MB, no more than
                            4000 px in any dimension)
                        </li>
                    </ul>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleNext}>
                    Review your offer package
                </button>
            </div>
        </>
    );
};

export default Step4;
