import React, { useEffect, useState } from "react";

import "./hireForm.scss";
import { useStateValue } from "../../../../StateProvider";
import { DepositModal, PurchaseModal, SuccessModal } from "../../../../Modals";
import { getJobDetails } from "../../../../apiCall";
import toast from "react-hot-toast";

const HireForm = ({
    job_id,
    freelancer_id,
    currency_unit,
    setShowUserProfile,
}) => {
    const [formData, setFormData] = useState({
        bid: "",
        iteration: "",
        responsibility: "",
        start_date: "",
        start_month: "",
        start_year: "",
        end_date: "",
        end_month: "",
        end_year: "",
    });
    const [
        {
            commission_for_free_client,
            thresold_for_commission,
            commission_for_member_client,
            commission_for_member_client_with_cost_greater_than_thresold,
        },
        dispatch,
    ] = useStateValue();
    const [commission, setCommission] = useState(10);
    const [myData, setMyData] = useState();
    const [errors, setErrors] = useState({});

    const [jobDetail, setJobDetail] = useState();

    useEffect(() => {
        setCommission(
            sessionStorage.getItem("is_premium_user") === "true"
                ? formData?.bid > thresold_for_commission
                    ? commission_for_member_client_with_cost_greater_than_thresold
                    : commission_for_member_client
                : commission_for_free_client
        );
    }, [
        commission_for_free_client,
        thresold_for_commission,
        commission_for_member_client,
        commission_for_member_client_with_cost_greater_than_thresold,
        formData?.bid,
    ]);
    useEffect(() => {
        const getDetails = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const Re = await getJobDetails(job_id);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            if (Re.data?.status[0].Error === "False") {
                setJobDetail(Re.data.value);
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };

        getDetails();
    }, [job_id, dispatch]);

    const handleSubmit = async () => {
        if (!formData?.bid) {
            setErrors((pre) => {
                return { ...pre, bid: "Bid is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, bid: "" };
            });
        }
        if (!formData?.iteration) {
            setErrors((pre) => {
                return { ...pre, iteration: "Iteration is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, iteration: "" };
            });
        }
        if (
            !formData?.start_date ||
            !formData?.start_month ||
            !formData?.start_year
        ) {
            setErrors((pre) => {
                return {
                    ...pre,
                    start_date: "Start date is a required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, start_date: "" };
            });
        }
        if (
            !formData?.end_date ||
            !formData?.end_month ||
            !formData?.end_year
        ) {
            setErrors((pre) => {
                return { ...pre, end_date: "End date is a required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, end_date: "" };
            });
        }
        if (!formData?.responsibility) {
            setErrors((pre) => {
                return {
                    ...pre,
                    responsibility: "Responsibility is required field.",
                };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, responsibility: "" };
            });
        }

        const data = {
            amount: formData?.bid,
            currency_unit: currency_unit,
            responsibility: formData?.responsibility,
            job_id: job_id,
            freelancer_id: freelancer_id,
            start_date: formData.start_date,
            start_month: formData.start_month,
            start_year: formData.start_year,
            end_date: formData.end_date,
            end_month: formData.end_month,
            end_year: formData.end_year,
            iteration: formData.iteration,
        };
        setMyData(data);

        dispatch({
            type: "SET_PURCHASE_MODAL_SHOW",
            status: true,
        });
    };

    return (
        <div className='hire-form-main'>
            <p className='bold'>
                What is the full amount you'd like to bid for this job?
            </p>
            <div className='input-grp'>
                <div className='text'>
                    <p className='bold'>
                        Paid amount ({currency_unit === "INR" ? "₹" : "$"})
                    </p>
                    <p className='desc'>
                        Total amount the Freelancer will see on your proposal
                    </p>
                    <p className='form-error'>{errors.bid}</p>
                </div>
                <div className='input-container'>
                    <input
                        type='number'
                        placeholder='add'
                        value={formData?.bid}
                        min={0}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return {
                                    ...pre,
                                    bid: e.target.value,
                                };
                            })
                        }
                    />
                </div>
            </div>
            <div className='input-grp'>
                <div className='text'>
                    <p className='bold'>
                        {commission}% Service Fee ({currency_unit === "INR" ? "₹" : "$"})
                    </p>
                </div>
                <div className='input-container'>
                    <input
                        type='number'
                        placeholder='add'
                        value={
                            formData?.bid === ""
                                ? ""
                                : (formData?.bid * commission/100).toFixed(2)
                        }
                        disabled
                    />
                </div>
            </div>
            <div className='input-grp'>
                <div className='text'>
                    <p className='bold'>
                        Total amount you are paying (
                        {currency_unit === "INR" ? "₹" : "$"})
                    </p>
                    {/* <p className='desc'>
                        Estimated amount you will receive after service fee
                    </p> */}
                </div>
                <div className='input-container'>
                    <input
                        type='number'
                        placeholder='add'
                        value={
                            formData?.bid === ""
                                ? ""
                                : parseInt(formData?.bid) +
                                  parseFloat((formData?.bid * 0.1).toFixed(2))
                        }
                        disabled
                    />
                </div>
            </div>
            <hr />
            <div className='input-grp input-grp-2'>
                <div className='text'>
                    <p className='bold'>How many iteration?</p>
                </div>
                <div className='input-container'>
                    <input
                        type='number'
                        placeholder='Enter'
                        value={formData?.iteration}
                        min={0}
                        max={50}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return {
                                    ...pre,
                                    iteration: e.target.value,
                                };
                            })
                        }
                    />
                </div>
                <p className='form-error'>{errors.iteration}</p>
            </div>
            <hr />
            <div className='dates-container'>
                <div className='input-grp input-grp-2'>
                    <div className='text'>
                        <p className='bold'>Start Date*</p>
                    </div>
                    <div className='input-container'>
                        <select
                            value={formData.start_date}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        start_date: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Date
                            </option>
                            {new Array(31).fill(0).map((ele, indx) => (
                                <option value={indx + 1} key={"date-" + indx}>
                                    {indx + 1}
                                </option>
                            ))}
                        </select>
                        <select
                            value={formData.start_month}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        start_month: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Month
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                        <select
                            value={formData.start_year}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        start_year: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Year
                            </option>
                            {Array(3)
                                .fill(0)
                                .map((num, indx) => (
                                    <option key={"start-year-" + indx}>
                                        {new Date().getFullYear() + indx}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <p className='form-error'>{errors.start_date}</p>
                </div>
                <div className='input-grp input-grp-2'>
                    <div className='text'>
                        <p className='bold'>End Date*</p>
                    </div>
                    <div className='input-container'>
                        <select
                            value={formData.end_date}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, end_date: e.target.value };
                                })
                            }>
                            <option value='' disabled>
                                Date
                            </option>
                            {new Array(31).fill(0).map((ele, indx) => (
                                <option value={indx + 1} key={"date-" + indx}>
                                    {indx + 1}
                                </option>
                            ))}
                        </select>
                        <select
                            value={formData.end_month}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        end_month: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Month
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                        <select
                            value={formData.end_year}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, end_year: e.target.value };
                                })
                            }>
                            <option value='' disabled>
                                Year
                            </option>
                            {Array(3)
                                .fill(0)
                                .map((num, indx) => (
                                    <option key={"start-year-" + indx}>
                                        {new Date().getFullYear() + indx}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <p className='form-error'>{errors.end_date}</p>
                </div>
            </div>
            <hr />
            <div className='input-grp input-grp-2'>
                <div className='text'>
                    <p className='bold'>Write your responsibilities</p>
                </div>
                <div className='input-container'>
                    <textarea
                        placeholder='Add responsibilities which freelancers need to cover during work. '
                        value={formData?.responsibility}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return {
                                    ...pre,
                                    responsibility: e.target.value,
                                };
                            })
                        }
                    />
                </div>
                <p className='form-error'>{errors.responsibility}</p>
            </div>
            <div className='buttons'>
                <button
                    className='primary-btn-white'
                    onClick={() => setShowUserProfile(true)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Submit Proposal
                </button>
            </div>
            <PurchaseModal
                data={myData}
                currency={currency_unit === "INR" ? "₹" : "$"}
                jobDetail={jobDetail}
            />
            <DepositModal data={myData} />
            <SuccessModal
                text='Yeah! You have successfully complete your payment.'
                redirect_to_home={true}
            />
        </div>
    );
};

export default HireForm;
