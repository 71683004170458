import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";

import "./purchasedPackages.scss";
// import { toast } from "react-toastify";
import { getClientPackages } from "../../../apiCall";
import { ReactComponent as BoxIcon } from "../../../Assets/icons/box.svg";
import { ReactComponent as HistoryIcon } from "../../../Assets/icons/history.svg";
import { ReactComponent as RevisionsIcon } from "../../../Assets/icons/revision.svg";
import { ReactComponent as ArticleIcon } from "../../../Assets/icons/article.svg";
import { domainName } from "../../../Constants";
import { FeedbackModal, ReleaseFundModal, RequirementsReviewModal, WorkApproveModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import toast from "react-hot-toast";

const PurchasedPackages = () => {
    const [activeTab, setActiveTab] = useState("all");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [packages, setPackages] = useState([]);
    const [selectedProposal, setSelectedProposal] = useState()
    const [selectedRequirements, setSelectedRequirements] = useState()
    const [giveOptions, setGiveOptions] = useState(true)
    const [, dispatch] = useStateValue()

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await getClientPackages(activeTab, page);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("CLIENT_PACKAGES_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0]?.Error === "True") {
                    toast.error(re.data.status[0]?.ResponseMessage);
                } else {
                    setPackages(re.data.value?.packages);
                    setTotalPages(re.data.value?.total_pages);
                }
            }
        };
        if(page){
            getData();
        }
    }, [activeTab, page, dispatch]);
    const handleWorkApproveModalShow = (status) => {
        dispatch({
            type: "SET_PROPOSAL_MODAL_SHOW",
            status: false,
        });
        dispatch({
            type: "SET_WORK_APPROVE_MODAL_SHOW",
            status: status,
        });
    };
    const showRequirementsModal = () => {
        dispatch({
            type: "SET_REQUIREMENTS_MODAL_SHOW",
            status: true,
        });
    };
    const reRenderComp = () => {
        setPage(0);
    };
    return (
        <div className='purchase-packages-main'>
            <div className='content'>
                <h3>Offer package details</h3>
                <div className='main-container'>
                    <div className='heading'>
                        <span
                            className={`${activeTab === "all" ? "active" : ""}`}
                            onClick={() => {
                                setActiveTab("all");
                                setPage(1);
                            }}>
                            <p>
                                All <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "inprogress" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveTab("inprogress");
                                setPage(1);
                            }}>
                            <p>
                                Inprogress <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "completed" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveTab("completed");
                                setPage(1);
                            }}>
                            <p>
                                Completed <span></span>
                            </p>
                            <span className='underline'></span>
                        </span>
                    </div>
                    <div className='my-packages'>
                        <div className='packages-container'>
                            {packages.map((pac, indx) => (
                                <div
                                    className='package'
                                    key={"package-" + pac.package_id}>
                                    <img
                                        src={
                                            domainName +
                                            "/uploads/" +
                                            pac?.coverpage
                                        }
                                        alt=''
                                    />
                                    <div className='text-container'>
                                        <p className='desc'>
                                            {pac.description}
                                        </p>
                                        <div className='icons'>
                                            <span>
                                                <BoxIcon />{" "}
                                                <p>
                                                    {pac?.service_type} Package
                                                </p>
                                            </span>
                                            <span>
                                                <HistoryIcon />{" "}
                                                <p>
                                                    {
                                                        pac[
                                                            pac?.service_type +
                                                                "_service"
                                                        ]?.delivery_time
                                                    }{" "}
                                                    Days Delivery
                                                </p>
                                            </span>
                                            <span>
                                                <RevisionsIcon />{" "}
                                                <p>
                                                    {
                                                        pac[
                                                            pac?.service_type +
                                                                "_service"
                                                        ]?.number_of_revisions
                                                    }{" "}
                                                    Revisions
                                                </p>
                                            </span>
                                            <span>
                                                <ArticleIcon />{" "}
                                                <p>Source File</p>
                                            </span>
                                        </div>
                                        <hr />
                                        <div className='times'>
                                            <p className='time'>
                                                Started on:{" "}
                                                {pac?.start_date && <span>
                                                    {new Date(
                                                        pac?.start_date
                                                    ).toLocaleDateString()}
                                                </span>}
                                            </p>
                                            <p className='time'>
                                                Delivered on:{" "}
                                                {pac?.start_date && <span>
                                                    {new Date(
                                                        new Date(
                                                            pac?.start_date
                                                        ).setDate(
                                                            new Date(
                                                                pac?.start_date
                                                            ).getDate() +
                                                                pac[
                                                                    pac?.service_type +
                                                                        "_service"
                                                                ]?.delivery_time
                                                        )
                                                    ).toLocaleDateString()}
                                                </span>}
                                            </p>
                                            <p className="time">Order number: <span>{pac.proposal_id}</span></p>
                                        </div>
                                        <hr />
                                        <div className="tracker">
                                            <div className="track">
                                                <div className={`circle-container ${pac?.status['1']?.step_completed === true ? 'completed' : ''}`}>
                                                    <span className="my-vr unvisible"></span>
                                                    <span className="circle">1</span>
                                                    <span className="my-vr"></span>
                                                </div>
                                                <span className={`details ${pac?.status['1']?.step_completed === true ? 'clicable' : ''}`} onClick={() => {setSelectedRequirements(pac?.status['1']?.requirements);showRequirementsModal()}}>
                                                    <p className="bold">Requirement submitted</p>
                                                    <p className="note">View requirements</p>
                                                </span>
                                            </div>
                                            <div className="track">
                                                <div className={`circle-container ${pac?.status['2']?.step_completed === true ? 'completed' : ''}`}>
                                                    <span className="my-vr"></span>
                                                    <span className="circle">2</span>
                                                    <span className="my-vr"></span>
                                                </div>
                                                <span className="details">
                                                    <p className="bold">Freelancer's response</p>
                                                    {pac?.status['2']?.step_completed === true && <p className="note">Freelancer has {pac?.status['2']?.status} your proposal.</p>}
                                                </span>
                                            </div>
                                            <div className="track">
                                                <div className={`circle-container ${pac?.status['3']?.step_completed === true ? 'completed' : ''}`}>
                                                    <span className="my-vr"></span>
                                                    <span className="circle">3</span>
                                                    <span className="my-vr"></span>
                                                </div>
                                                <span className="details">
                                                    <p className="bold">Work in progress</p>
                                                    {/* <p className="note">test</p> */}
                                                </span>
                                            </div>
                                            <div className="track">
                                                <div className={`circle-container ${(pac?.status['4']?.step_completed === true && pac?.status['4']?.need_to_reiterate !== true) ? 'completed' : ''}`}>
                                                    <span className="my-vr"></span>
                                                    <span className="circle">4</span>
                                                    <span className="my-vr"></span>
                                                </div>
                                                <span className={`details ${pac?.status['4']?.step_completed === true  ? 'clicable' : ''}`} onClick={() => {setSelectedProposal({id: pac.proposal_id, work_description: pac?.status['4']?.work_details?.work_description, attachments: pac?.status['4']?.work_details?.work_documents, freelancer_id: pac?.freelancer_id}); setGiveOptions(pac?.status['5']?.step_completed !== true); handleWorkApproveModalShow(true)}}>
                                                    <p className="bold">Work submitted</p>
                                                    {pac?.status['4']?.step_completed === true && <p className="note">View work</p>}
                                                </span>
                                            </div>
                                            <div className="track">
                                                <div className={`circle-container ${pac?.status['5']?.step_completed === true ? 'completed' : ''}`}>
                                                    <span className="my-vr"></span>
                                                    <span className="circle">5</span>
                                                    <span className="my-vr unvisible"></span>
                                                </div>
                                                <span className="details">
                                                    <p className="bold">Completed</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pagination'>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </div>
            <WorkApproveModal
                proposal_details={selectedProposal}
                callback={reRenderComp}
                giveOptions={giveOptions}
            />
            <ReleaseFundModal
                proposal_id={selectedProposal?.id}
                callback={reRenderComp}
            />
            <FeedbackModal
                proposal_id={selectedProposal?.id}
                freelancer_id={selectedProposal?.freelancer_id}
            />
            <RequirementsReviewModal requirements={selectedRequirements}/>
        </div>
    );
};

export default PurchasedPackages;
