import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Pagination from "@mui/material/Pagination";
import Offcanvas from "react-bootstrap/Offcanvas";

import "./findWork.scss";
import Filters from "../Components/Filters";
import { ReactComponent as FavouritIcon } from "../../../Assets/icons/favorite.svg";
import { ReactComponent as FavouritIconFilled } from "../../../Assets/icons/favorite-filled.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/icons/check-circle.svg";
import { ReactComponent as StarIcon } from "../../../Assets/icons/star.svg";
import { ReactComponent as LocationIcon } from "../../../Assets/icons/location.svg";
import { ReactComponent as FilterIcon } from "../../../Assets/icons/filter.svg";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit-gray.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/icons/delete-gray.svg";
import { useNavigate } from "react-router-dom";
import {
    // GetCategories,
    GetJobs,
    GetPastJobs,
    GetSavedJobs,
    UpdateSavedJobs,
    deleteBid,
} from "../../../apiCall";
// import { CACHE_TIME } from "../../../Constants";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { useStateValue } from "../../../StateProvider";
import { DeleteModal } from "../../../Modals";

const FindWork = () => {
    const [appliedFilters, setAppliedFilters] = useState({
        category: null,
        delivery_time: "",
        budget: null,
        response_time: "All",
        location: "",
    });
    // const [categories, setCategories] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [activeTab, setActiveTab] = useState("most_recent");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showFiltersOffCanvas, setShowFiltersOffCanvas] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [deleteId, setDeleteId] = useState();
    const [{ saved_jobs, categories }, dispatch] = useStateValue();

    const navigateTo = useNavigate();

    useEffect(() => {
        const getData = async () => {
            let Re = {};
            dispatch({ type: "SET_IS_LOADING", status: true });

            if (activeTab === "best_match" || activeTab === "most_recent") {
                Re = await GetJobs(page, appliedFilters, activeTab);
            } else if (activeTab === "saved_jobs") {
                Re = await GetSavedJobs(page);
            } else {
                Re = await GetPastJobs(page);
            }
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("JOBS_DATA", Re);

            if (Re?.data?.status[0].Error === "False") {
                setJobs(Re?.data.value?.jobs);
                setTotalPages(Re?.data.value?.total_pages);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };
        getData();
    }, [activeTab, appliedFilters, page, saved_jobs, dispatch]);

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setSearchString(string);
    };

    const handleOnSelect = (item) => {
        setSearchString("");
        navigateTo(`/job-details/${item?.id}`)
    };

    const formatResult = (item) => {
        return (
            <>
                <div style={{ display: "block", textAlign: "left", zIndex: 2 }}>
                    {item.name}
                </div>
            </>
        );
    };

    const updateSavedJobs = async (jobs) => {
        const old_jobs = saved_jobs;
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await UpdateSavedJobs(jobs);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("UPDATE_JOBS_RESPONSE", re);
        if (re.status !== 200) {
            sessionStorage.setItem("saved_jobs", old_jobs);

            dispatch({
                type: "SET_SAVED_JOBS",
                status: old_jobs,
            });
            toast.error("Something went wrong, Please reload the page.");
        } else {
            sessionStorage.setItem("saved_jobs", JSON.stringify(re.data.value));

            dispatch({
                type: "SET_SAVED_JOBS",
                status: re.data.value,
            });
        }
    };

    const removeFromSavedJobs = (id) => {
        const updated_jobs = saved_jobs.filter((job) => job !== id);
        sessionStorage.setItem("saved_jobs", JSON.stringify(updated_jobs));

        dispatch({
            type: "SET_SAVED_JOBS",
            status: updated_jobs,
        });

        updateSavedJobs(updated_jobs);
    };
    const addInSavedJobs = (id) => {
        const updated_jobs = [...saved_jobs, id];
        sessionStorage.setItem("saved_jobs", JSON.stringify(updated_jobs));

        dispatch({
            type: "SET_SAVED_JOBS",
            status: updated_jobs,
        });
        updateSavedJobs(updated_jobs);
    };

    const handleDelete = async (id) => {
        dispatch({ type: "SET_IS_LOADING", status: true });

        const Re = await deleteBid(id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("DELETE_BID", Re);

        if (Re?.data?.status[0].Error === "False") {
            setJobs((pre) => pre.filter((job) => job.bid_id !== id));
            dispatch({
                type: "SET_DELETE_MODAL_SHOW",
                status: false,
            });
        } else {
            if (Re?.data?.status) {
                toast.error(Re?.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const handleOnDeleteClick = (id) => {
        setDeleteId(id);
        dispatch({
            type: "SET_DELETE_MODAL_SHOW",
            status: true,
        });
    };

    return (
        <div className='find-work-main'>
            {(activeTab === "best_match" || activeTab === "most_recent") && (
                <div className='filters'>
                    <Filters
                        categories={categories}
                        setAppliedFilters={setAppliedFilters}
                    />
                </div>
            )}
            <div className='find-work-container'>
                <div className='search-box-container'>
                    <ReactSearchAutocomplete
                        items={jobs.map((job) => {
                            return { id: job.id, name: job.title };
                        })}
                        onSelect={handleOnSelect}
                        fuseOptions={{ keys: ["name"] }}
                        autoFocus
                        inputSearchString={searchString}
                        onSearch={handleOnSearch}
                        formatResult={formatResult}
                        placeholder='Search'
                    />
                    <button className='btn-blue'>Search</button>
                </div>
                <div className='jobs-container'>
                    <div className='filter-icon-container'>
                        <p>Job Post List</p>
                        {(activeTab === "best_match" ||
                            activeTab === "most_recent") && (
                            <FilterIcon
                                onClick={() => setShowFiltersOffCanvas(true)}
                            />
                        )}
                    </div>
                    <div className='heading'>
                        <span
                            className={`${
                                activeTab === "most_recent" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("most_recent")}>
                            <p>Most Recent</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "best_match" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("best_match")}>
                            <p>Best matches</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "saved_jobs" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("saved_jobs")}>
                            <p>Saved Jobs</p>
                            <span className='underline'></span>
                        </span>
                        <span
                            className={`${
                                activeTab === "past_jobs" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("past_jobs")}>
                            <p>Past Bid Proposals</p>
                            <span className='underline'></span>
                        </span>
                    </div>

                    {jobs?.map((job) => (
                        <div className='job-container' key={"job-id-" + job.id}>
                            {saved_jobs?.includes(job.id) ? (
                                <FavouritIconFilled
                                    className={`fav-icon filled`}
                                    onClick={() => removeFromSavedJobs(job.id)}
                                />
                            ) : (
                                <FavouritIcon
                                    className={`fav-icon`}
                                    onClick={() => addInSavedJobs(job.id)}
                                />
                            )}
                            <div className='job' key={"job-" + job?.id}>
                                {job?.job_status === "open for rebid" && (
                                    <span className='job-tag'>
                                        {"Open for rebid"}
                                    </span>
                                )}
                                {job?.job_status === "closed" && (
                                    <span className='job-tag'>{"closed"}</span>
                                )}
                                <div
                                    className='job-head'
                                    onClick={() =>
                                        navigateTo(`/job-details/${job?.id}`)
                                    }>
                                    <span>
                                        <p className='title'>{job?.title}</p>
                                    </span>
                                    <p className='desc'>{job?.description}</p>
                                </div>
                                <hr />
                                <p className='price'>
                                    {job?.currency_unit === "INR" ? "₹" : "$"}
                                    {job?.budget_lower_bound} -{" "}
                                    {job?.budget_upper_bound}
                                </p>
                                <div className='skills-container info'>
                                    {job?.skills.map((skill) => (
                                        <span
                                            className='skill'
                                            key={"profile-skill" + skill.id}>
                                            {skill.skill}
                                        </span>
                                    ))}
                                </div>
                                <div className='ratings'>
                                    {/* {job?.payment_verified && ( */}
                                    <>
                                        <span className='payment-verified item'>
                                            <CheckIcon />{" "}
                                            <p>Payment verified</p>
                                        </span>
                                        <span className='hr'></span>
                                    </>
                                    {/* )} */}

                                    {job?.is_account_verified && <span className='account-verified item'>
                                        <CheckIcon /> <p>Account verified</p>
                                    </span>}
                                    {job?.is_account_verified && <span className='hr'></span>}
                                    <span className='rating item'>
                                        <StarIcon />
                                        <p>{job?.ratings} Rating</p>
                                    </span>
                                    <span className='hr'></span>
                                    <span className='location item'>
                                        <LocationIcon />
                                        <p>
                                            {job?.city}
                                            {job?.state && job?.city
                                                ? ", "
                                                : ""}
                                            {job?.state}
                                            {(job?.state || job?.city) &&
                                            job?.country
                                                ? ", "
                                                : ""}
                                            {job?.country}
                                        </p>
                                    </span>
                                </div>
                                {activeTab === "past_jobs" &&
                                    job.status !== "accepted" && (
                                        <span className='edit-bids'>
                                            <EditIcon
                                                onClick={() =>
                                                    navigateTo(
                                                        `/job-details/${job?.id}?edit_bid=true`
                                                    )
                                                }
                                            />
                                            <DeleteIcon
                                                onClick={() =>
                                                    handleOnDeleteClick(
                                                        job?.bid_id
                                                    )
                                                }
                                            />
                                        </span>
                                    )}
                            </div>
                        </div>
                    ))}
                    {!jobs?.length && activeTab === "most_recent" ? (
                        <p className='empty-message'>
                            Oops, There isn't any recent job.
                        </p>
                    ) : (
                        <></>
                    )}
                    {!jobs?.length && activeTab === "best_match" ? (
                        <p className='empty-message'>
                            Oops, We couldn't find any best match job for you.
                        </p>
                    ) : (
                        <></>
                    )}
                    {!jobs?.length && activeTab === "saved_jobs" ? (
                        <p className='empty-message'>
                            Oops, you haven't saved any job yet.
                        </p>
                    ) : (
                        <></>
                    )}
                    {!jobs?.length && activeTab === "past_jobs" ? (
                        <p className='empty-message'>
                            Oops, you haven't applied to any job yet.
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
                <div className='pagination'>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                    />
                </div>
            </div>
            <Offcanvas
                show={showFiltersOffCanvas}
                className='nav-offcanvas-main'
                onHide={() => setShowFiltersOffCanvas(false)}>
                <Offcanvas.Body>
                    <Filters
                        categories={categories}
                        setAppliedFilters={setAppliedFilters}
                        setShowFiltersOffCanvas={setShowFiltersOffCanvas}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            <DeleteModal
                text='Are you sure you want to delete this bid?'
                callback={handleDelete}
                id={deleteId}
            />
        </div>
    );
};

export default FindWork;
