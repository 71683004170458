import React, { useEffect, useState } from "react";

import InfoIcon from "../../Assets/icons/info.svg";
import { ReactComponent as CheckMarkIcon } from "../../Assets/icons/check-mark.svg";
import "./memberShipPlans.scss";
import { useStateValue } from "../../StateProvider";
import { getMembershipPlans } from "../../apiCall";
import toast from "react-hot-toast";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { displayRazorpaySubcription } from "../../utils/Razorpay";

const MemberShipPlans = () => {
    const [currentPlan, setCurrentPlan] = useState(2);
    const [plans, setPlans] = useState([]);
    const [currencyUnit, setCurrencyUnit] = useState("₹");
    const [{ loggedInAs }, dispatch] = useStateValue();

    useEffect(() => {
        const getDetails = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const Re = await getMembershipPlans(loggedInAs);
            dispatch({ type: "SET_IS_LOADING", status: false });

            if (Re.data?.status[0].Error === "False") {
                setPlans(Re.data.value);
                if (Re.data.value[0]?.currency_unit === "USD") {
                    setCurrencyUnit("$");
                } else {
                    setCurrencyUnit("₹");
                }
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };

        getDetails();
    }, [dispatch, loggedInAs]);

    
    return (
        <div className='membership-plans-main'>
            <div className='note'>
                <img src={InfoIcon} alt='' />
                <p>Currently you are using our freemium model </p>
            </div>
            <div className='desktop-plans-container plans-container'>
                {plans.map((plan) => (
                    <span className={`plan`} key={"plan-" + plan?.id}>
                        <div className='price'>
                            <h3>
                                {currencyUnit}
                                {plan?.price}/
                            </h3>
                            <p>month</p>
                        </div>
                        <span className='basic-details'>
                            <p className='title'>{plan?.plan_name} Plan</p>
                            <p className='desc'>{plan?.description}</p>
                        </span>
                        <div className='features-container'>
                            <p className='bold'>
                                Features of {plan?.plan_name} Plan
                            </p>
                            <div className='features'>
                                {plan?.features.map((feature, indx) => (
                                    <div
                                        className='feature'
                                        key={
                                            "feature-" +
                                            plan?.plan_name +
                                            "-" +
                                            indx
                                        }>
                                        <CheckMarkIcon /> {feature}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className='btn-white-outline' onClick={()=>displayRazorpaySubcription(plan.razorpay_plan_id)}>
                            GET STARTED
                        </button>
                    </span>
                ))}
            </div>
            <div className={`plans-mobile-container plans-container`}>
                <Swiper
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className='mySwiper'>
                    {plans.map((plan) => (
                        <SwiperSlide key={"plan-" + plan?.id}>
                            <span className={`plan`}>
                                <div className='price'>
                                    <h3>
                                        {currencyUnit}
                                        {plan?.price}/
                                    </h3>
                                    <p>month</p>
                                </div>
                                <span className='basic-details'>
                                    <p className='title'>
                                        {plan?.plan_name} Plan
                                    </p>
                                    <p className='desc'>{plan?.description}</p>
                                </span>
                                <div className='features-container'>
                                    <p className='bold'>
                                        Features of {plan?.plan_name} Plan
                                    </p>
                                    <div className='features'>
                                        {plan?.features.map((feature, indx) => (
                                            <div
                                                className='feature'
                                                key={
                                                    "feature-" +
                                                    plan?.plan_name +
                                                    "-" +
                                                    indx
                                                }>
                                                <CheckMarkIcon /> {feature}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <button className='btn-white-outline' onClick={()=>displayRazorpaySubcription(plan.razorpay_plan_id)} >
                                    GET STARTED
                                </button>
                            </span>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* <div className={`plans current-${currentPlan}`}>
                    {currentPlan > 0 && (
                        <span
                            className={`plan plan-${currentPlan - 1}`}
                            key={"plan-mobile-" + plans[currentPlan - 1]?.id}>
                            {console.log(
                                "Current",
                                currentPlan - 1,
                                plans[currentPlan - 1]?.plan_name
                            )}
                            <div className='price'>
                                <h3>
                                    {currencyUnit}
                                    {plans[currentPlan - 1]?.price}/
                                </h3>
                                <p>month</p>
                            </div>
                            <span className='basic-details'>
                                <p className='title'>
                                    {plans[currentPlan - 1]?.plan_name} Plan
                                </p>
                                <p className='desc'>
                                    {plans[currentPlan - 1]?.description}
                                </p>
                            </span>
                            <div className='features-container'>
                                <p className='bold'>
                                    Features of{" "}
                                    {plans[currentPlan - 1]?.plan_name} Plan
                                </p>
                                <div className='features'>
                                    {plans[currentPlan - 1]?.features.map(
                                        (feature, indx) => (
                                            <div
                                                className='feature'
                                                key={
                                                    "feature-mobile-" +
                                                    plans[currentPlan - 1]
                                                        ?.plan_name +
                                                    "-" +
                                                    indx
                                                }>
                                                <CheckMarkIcon /> {feature}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <button className='btn-white-outline'>
                                GET STARTED
                            </button>
                        </span>
                    )}
                    <span
                        className={`plan current-plan plan-${currentPlan}`}
                        key={"plan-mobile-" + plans[currentPlan]?.id}>
                        {console.log(
                            "Current w",
                            currentPlan,
                            plans[currentPlan],
                            plans[currentPlan]?.plan_name
                        )}
                        <div className='price'>
                            {console.log(
                                currentPlan,
                                plans[currentPlan]?.price
                            )}
                            <h3>
                                {currencyUnit}
                                {plans[currentPlan]?.price}/
                            </h3>
                            <p>month</p>
                        </div>
                        <span className='basic-details'>
                            <p className='title'>
                                {plans[currentPlan]?.plan_name} Plan
                            </p>
                            <p className='desc'>
                                {plans[currentPlan]?.description}
                            </p>
                        </span>
                        <div className='features-container'>
                            <p className='bold'>
                                Features of {plans[currentPlan]?.plan_name} Plan
                            </p>
                            <div className='features'>
                                {plans[currentPlan]?.features.map(
                                    (feature, indx) => (
                                        <div
                                            className='feature'
                                            key={
                                                "feature-mobile-" +
                                                plans[currentPlan]?.plan_name +
                                                "-" +
                                                indx
                                            }>
                                            <CheckMarkIcon /> {feature}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        <button className='btn-white-outline'>
                            GET STARTED
                        </button>
                    </span>
                    {currentPlan < 2 && (
                        <span
                            className={`plan plan-${currentPlan + 1}`}
                            key={"plan-mobile-" + plans[currentPlan + 1]?.id}>
                            {console.log(
                                "Current l",
                                currentPlan + 1,
                                plans[currentPlan + 1]?.plan_name
                            )}
                            <div className='price'>
                                <h3>
                                    {currencyUnit}
                                    {plans[currentPlan + 1]?.price}/
                                </h3>
                                <p>month</p>
                            </div>
                            <span className='basic-details'>
                                <p className='title'>
                                    {plans[currentPlan + 1]?.plan_name} Plan
                                </p>
                                <p className='desc'>
                                    {plans[currentPlan + 1]?.description}
                                </p>
                            </span>
                            <div className='features-container'>
                                <p className='bold'>
                                    Features of{" "}
                                    {plans[currentPlan + 1]?.plan_name} Plan
                                </p>
                                <div className='features'>
                                    {plans[currentPlan + 1]?.features.map(
                                        (feature, indx) => (
                                            <div
                                                className='feature'
                                                key={
                                                    "feature-mobile-" +
                                                    plans[currentPlan + 1]
                                                        ?.plan_name +
                                                    "-" +
                                                    indx
                                                }>
                                                <CheckMarkIcon /> {feature}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <button className='btn-white-outline'>
                                GET STARTED
                            </button>
                        </span>
                    )}
                </div> */}

                <div className='paginations'>
                    <span
                        className={`${currentPlan === 0 ? "active" : ""}`}
                        onClick={() => setCurrentPlan(0)}></span>
                    <span
                        className={`${currentPlan === 1 ? "active" : ""}`}
                        onClick={() => setCurrentPlan(1)}></span>
                    <span
                        className={`${currentPlan === 2 ? "active" : ""}`}
                        onClick={() => setCurrentPlan(2)}></span>
                </div>
            </div>
        </div>
    );
};

export default MemberShipPlans;
