import React from "react";

import {ReactComponent as SubmitedIcon} from "../Assets/icons/submited.svg";
import "./successView.scss";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProvider";

const SuccessView = ({ text, description, redirect_to_home, redirect_url }) => {
  const [, dispatch] = useStateValue()
    const navigateTo = useNavigate();
    const hideSuccessModal = () => {
      dispatch({
          type: "SET_SUCCESS_MODAL_SHOW",
          status: false,
      });
      navigateTo(redirect_url ? redirect_url : "/")
  };
    return (
        <div className='bid-submited'>
            {/* <img src={SubmitedIcon} alt='' /> */}
            <SubmitedIcon />
            <p>{text}</p>
            {description && <p className='description'>{description}</p>}
            {redirect_to_home && (
                <button
                    className='primary-btn-blue'
                    onClick={hideSuccessModal}>
                    Continue
                </button>
            )}
        </div>
    );
};

export default SuccessView;
