import React, { useState } from "react";
import "./homeFooter.scss";
import { ReactComponent as Logo } from "../../../Assets/icons/logo.svg";
import { ReactComponent as Facebook } from "../../../Assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../../Assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "../../../Assets/icons/twitter.svg";
import { addEmailSubscriber } from "../../../apiCall";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";

const HomeFooter = () => {
    const [email, setEmail] = useState("");
    const navigateTo = useNavigate()
    const [{categories}] = useStateValue()
    const handleSubmit = async () => {
        if (!email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
            toast.error("Invalid email");
            return;
        }

        const Re = await addEmailSubscriber(email);
        
        console.log(Re)
        if (Re.data?.status[0].Error === "False") {
            toast.success("Your email address is added to our email list.")
        } else {
            if (Re.data?.status) {
                toast.error(Re.data.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };
    return (
        <footer className='home-footer-main'>
            <div className='footer-part footer-part-1'>
                <div className='rysovate'>
                    <Logo />
                    <p>
                        We have expertise in a variety of fields, including
                        writing, design, programming, marketing, and more.{" "}
                    </p>
                </div>
                <hr />
                <div className='socials'>
                    <p className='bold'>Follow us</p>
                    <div className='social-medias'>
                        <Facebook />
                        <Instagram />
                        <Twitter />
                    </div>
                </div>
            </div>
            <div className='part-container'>
                <div className='footer-part footer-part-2'>
                    <p className='bold'>Categories</p>
                    <div className='items'>
                        {categories.map((cat, indx) => <p key={cat?.name + indx}>{cat?.name}</p>)}
                    </div>
                </div>
                <hr />
                <div className='footer-part footer-part-3'>
                    <p className='bold'>About Us</p>
                    <div className='items'>
                        <p onClick={() => navigateTo('/about-us')}>About Us</p>
                        <p onClick={() => navigateTo('/terms-and-conditions')}>Terms & Conditions</p>
                        <p onClick={() => navigateTo('/privacy-policy')}>Privacy Policy</p>
                        <p onClick={() => navigateTo('/shipping-policy')}>Shipping & Delivery Policy</p>
                        <p onClick={() => navigateTo('/refund-policy')}>Cancellation & Refund Policy</p>
                        <p onClick={() => navigateTo('/faqs')}>FAQs</p>
                        <p onClick={() => navigateTo('/plans')}>Pricing</p>
                        <p onClick={() => navigateTo('/contact-us')}>Contact Us</p>
                    </div>
                </div>
            </div>
            <div className='footer-part footer-part-4'>
                <p className='bold'>Subscribe</p>
                <div className='input-container'>
                    <input
                        type='email'
                        placeholder='Enter Your Email ID'
                        name=''
                        id=''
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={handleSubmit}>Subscribe</button>
                </div>
                <hr />
                <div className='socials'>
                    <p className='bold'>Follow us</p>
                    <div className='social-medias'>
                        <Facebook />
                        <Instagram />
                        <Twitter />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default HomeFooter;
