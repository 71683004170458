import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { ReactComponent as StarIcon } from "../../../../Assets/icons/star.svg";
import { useStateValue } from "../../../../StateProvider";
import "./feedBackForm.scss";
import { postFreelancerFeedback, postJobFeedback } from "../../../../apiCall";
import toast from 'react-hot-toast';

const FeedBackForm = ({ proposal_id, freelancer_id }) => {
    const [, dispatch] = useStateValue();
    const [feedback, setFeedback] = useState();
    const [ratings, setRatings] = useState(0);
    const setSuccessModalShow = () => {
        dispatch({
            type: "SET_SUCCESS_MODAL_SHOW",
            status: true,
        });
    };
    const hideFeedbackModal = () => {
        dispatch({
            type: "SET_FEED_BACK_MODAL_SHOW",
            status: false,
        });
        setSuccessModalShow();
    };
    const handleSubmit = async () => {
        if(!ratings || !feedback){
            toast.error("Please provide review and ratings.")
            return;
        }
        if (freelancer_id) {
            dispatch({type:"SET_IS_LOADING",status:true})

            const re = await postFreelancerFeedback(
                { ratings: ratings, review: feedback },
                proposal_id,
            );
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("POST_FREELANCER_FEEDBACK_RESPONSE", re);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    hideFeedbackModal();
                }
            }
        } else {
            dispatch({type:"SET_IS_LOADING",status:true})

            const re = await postJobFeedback(
                { ratings: ratings, review: feedback },
                proposal_id
            );
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("POST_JOB_FEEDBACK_RESPONSE", re);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    hideFeedbackModal();
                }
            }
        }
    };
    return (
        <div className='feedback-form-main'>
            <div className='header'>
                <p>Feedback form</p>
                <CloseIcon onClick={hideFeedbackModal} />
            </div>
            <div className='input-grp'>
                <p className='bold'>
                    How much would you like to rate your client?*
                </p>
                <div className='star-group'>
                    <StarIcon
                        className={`${ratings > 0 ? "active" : ""}`}
                        onClick={() => setRatings(1)}
                    />
                    <StarIcon
                        className={`${ratings > 1 ? "active" : ""}`}
                        onClick={() => setRatings(2)}
                    />
                    <StarIcon
                        className={`${ratings > 2 ? "active" : ""}`}
                        onClick={() => setRatings(3)}
                    />
                    <StarIcon
                        className={`${ratings > 3 ? "active" : ""}`}
                        onClick={() => setRatings(4)}
                    />
                    <StarIcon
                        className={`${ratings > 4 ? "active" : ""}`}
                        onClick={() => setRatings(5)}
                    />
                </div>
            </div>
            <div className='input-grp'>
                <p className='bold'>Share your valuable feedback.*</p>
                <textarea
                    type='text'
                    placeholder='Describe you delivery work in brief.'
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={hideFeedbackModal}>
                    Draft
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Submit work
                </button>
            </div>
        </div>
    );
};

export default FeedBackForm;
