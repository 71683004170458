import React from "react";
import pdfIcon from "../../../../../Assets/icons/pdf.svg";

import "./jobReview.scss";
import { useStateValue } from "../../../../../StateProvider";
import { postJobDetails, updateJobDetails, uploadJobAttachments } from "../../../../../apiCall";
import toast from 'react-hot-toast';
import { SuccessModal } from "../../../../../Modals";

const JobReview = ({ formData, setPageNo, categories, jobId }) => {
    const [{ currency_symbol }, dispatch] = useStateValue();
    const handleSubmit = async () => {
        const data = {
            skills: formData.skills,
            duration: formData.duration,
            hiring_mode: formData.hiring_mode,
            title: formData.title,
            description: formData.description,
            category: formData.category,
            responsibilities: formData.responsibilities,
            currency_unit:
                sessionStorage.getItem("is_indian_user") ||
                localStorage.getItem("is_indian_user")
                    ? "INR"
                    : "USD",
            budget_lower_bound: formData.budget_lower_bound,
            budget_upper_bound: formData.budget_upper_bound,
            city: formData.city,
            state: formData.state,
            country: formData.country,
        };
        dispatch({ type: "SET_IS_LOADING", status: true });

        let re = undefined
        if(jobId){
            re = await updateJobDetails(data, jobId);
        }else{
            re = await postJobDetails(data);
        }
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("POST_JOB_DETAILS_RESPONSE", re);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                if (
                    re.data.status[0].ResponseMessage ===
                    "Unacceptable Parameter Value"
                ) {
                    toast.error(re.data.status[0].Message);
                } else {
                    toast.error(re.data.status[0].ResponseMessage);
                }
            } else {
                if (formData?.files.length > 0) {
                    let filesData = new FormData();
                    formData?.files?.forEach((file, index) => {
                        filesData.append(`files`, file); // Append each file with a unique key
                    });

                    if (formData?.files?.length === 0) {
                        filesData = [];
                    }
                    dispatch({ type: "SET_IS_LOADING", status: true });

                    const ImageRe = await uploadJobAttachments(
                        filesData,
                        re.data.value
                    );
                    setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                    console.log("POST_JOB_ATTACHMENTS_RESPONSE", ImageRe);

                    if (ImageRe.data?.status[0].Error === "False") {
                        localStorage.clear();
                        dispatch({
                            type: "SET_SUCCESS_MODAL_SHOW",
                            status: true,
                        });
                    } else {
                        if (ImageRe.data?.status) {
                            toast.error(ImageRe.data.status[0].ResponseMessage);
                        } else {
                            toast.error(
                                "Something went wrong, Please try again."
                            );
                        }
                    }
                } else {
                    localStorage.clear();
                    dispatch({
                        type: "SET_SUCCESS_MODAL_SHOW",
                        status: true,
                    });
                }
            }
        }

        console.log(data, formData);
    };
    return (
        <>
            <div className='job-post-step-4-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <p className='title'>Job details</p>
                    </div>
                    <div className='content'>
                        <div className='head'>
                            <p>{formData.title}</p>
                        </div>
                        <div className='body'>
                            <div className='grp'>
                                <p className='bold'>Category</p>
                                <p className='text-content'>
                                    {
                                        categories.filter(
                                            (category) =>
                                                String(category.id) ===
                                                formData.category
                                        )[0]?.name
                                    }
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>
                                    Skills required for your job
                                </p>
                                <p className='text-content'>
                                    {formData.skills
                                        .map((skill) => skill.name)
                                        .join(", ")}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Scope</p>
                                <p className='text-content'>
                                    {formData.duration}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Hiring Mode</p>
                                <p className='text-content capitalize'>
                                    {formData.hiring_mode}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Location</p>
                                <p className='text-content capitalize'>
                                    {formData.city}
                                    {formData.state && formData.city
                                        ? ", "
                                        : ""}
                                    {formData.state}
                                    {(formData.state || formData.city) &&
                                    formData.country
                                        ? ", "
                                        : ""}
                                    {formData.country}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Budget</p>
                                <p className='text-content'>
                                    {currency_symbol}
                                    {formData.budget_lower_bound} -{" "}
                                    {currency_symbol}
                                    {formData.budget_upper_bound}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Description</p>
                                <p className='text-content'>
                                    {formData.description}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Responsibilities</p>
                                <p className='text-content'>
                                    {formData.responsibilities}
                                </p>
                            </div>
                            <div className='grp'>
                                <p className='bold'>Attachments</p>
                                {formData?.files?.map((file, indx) => (
                                    <div
                                        className='my-file'
                                        key={"uploaded-file-" + indx}>
                                        <span className='attachment'>
                                            <span>
                                                <img src={pdfIcon} alt='' />
                                                <p>{file?.name}</p>
                                            </span>
                                        </span>
                                        {/* <div
                                    className='plus'
                                    onClick={() => fileRef.current.click()}>
                                    <CloseIcon />
                                </div> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Post Job
                </button>
            </div>
            <SuccessModal
                text='Yeah! You have successfully post your job'
                description="You‘ll receive proposals to  your job. No charges until you hire."
                redirect_to_home={true}
                redirect_url = "/my-jobs"
            />
        </>
    );
};

export default JobReview;
