import React, { useState } from "react";

import "./forgotPassword.scss";
import { useStateValue } from "../../../../StateProvider";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { forgotPassword, sendOTP, verifyOTP } from "../../../../apiCall";
import toast from "react-hot-toast";

const ForgotPassword = () => {
    const [, dispatch] = useStateValue();
    const [step, setStep] = useState("email");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [token, setToken] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const hideForgotPassword = () => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL",
            status: false,
        });
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError("Email is required for forgot password.");
            return;
        }

        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await sendOTP({
            email: email,
            reason: "Forgot password",
        });
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("SEND_OTP_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                setEmailError(re.data.status[0].ResponseMessage);
            } else {
                setStep("otp");
            }
        }
    };

    const handleOtpVerification = async (e) => {
        e.preventDefault();

        if (!otp) {
            setOtpError("OTP is required for verification.");
            return;
        }

        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await verifyOTP({
            email: email,
            otp: otp,
        });
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("VERIFY_OTP_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                setOtpError(re.data.status[0].ResponseMessage);
            } else {
                setStep("password");
                setToken(re.data.access_token);
            }
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        if (!password || password !== confirmPassword) {
            setPasswordError(
                "Password is required and password and forgot password must be same."
            );
            return;
        }

        dispatch({ type: "SET_IS_LOADING", status: true });
        const re = await forgotPassword({
            email: email,
            newPassword: password,
            token: token,
        });
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("FORGOT_PASSWORD_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                setPasswordError(re.data.status[0].ResponseMessage);
            } else {
                toast.success("Password changed...");
                hideForgotPassword();
            }
        }
    };

    return (
        <div className='forgot-password-main'>
            <div className='header'>
                <p>Forgot Password</p>
                <CloseIcon onClick={hideForgotPassword} />
            </div>
            {step === "email" && (
                <form
                    className='input-container'
                    onSubmit={handleSendOtp}
                    onError={(e) => console.log(e)}>
                    <p className='desc'>
                        Please enter email associated with your account.
                    </p>
                    <div className='input-grp'>
                        {/* <p className='bold'>Email</p> */}
                        <input
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <p className='form-error'>{emailError}</p>
                    </div>
                    <button className='btn-blue' type='submit'>
                        Send OTP
                    </button>
                </form>
            )}
            {step === "otp" && (
                <form
                    className='input-container'
                    onSubmit={handleOtpVerification}
                    onError={(e) => console.log(e)}>
                    <p className='desc'>Please enter OTP</p>
                    <div className='input-grp'>
                        {/* <p className='bold'>Email</p> */}
                        <input
                            type='text'
                            placeholder='OTP'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <p className='form-error'>{otpError}</p>
                    </div>
                    <button className='btn-blue' type='submit'>
                        Verify OTP
                    </button>
                </form>
            )}
            {step === "password" && (
                <form
                    className='input-container'
                    onSubmit={handleForgotPassword}
                    onError={(e) => console.log(e)}>
                    <div className='input-grp'>
                        <p className='bold'>Password</p>
                        <input
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Confirm Password</p>
                        <input
                            type='password'
                            placeholder='Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <p className='form-error'>{passwordError}</p>
                    </div>
                    <button className='btn-blue' type='submit'>
                        Change Password
                    </button>
                </form>
            )}
        </div>
    );
};

export default ForgotPassword;
