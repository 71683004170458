import React, { useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

import { ReactComponent as GoogleIcon } from "../../../../Assets/icons/google-icon.svg";
// import { ReactComponent as FacebookIcon } from "../../../../Assets/icons/facebook-icon.svg";
import { ReactComponent as Eye } from "../../../../Assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "../../../../Assets/icons/eye-off.svg";
import "./signUpInForm.scss";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, SignIn, SignUp } from "../../../../apiCall";
import { domainName } from "../../../../Constants";
import { useGoogleLogin } from "@react-oauth/google";
import { ForgotPasswordModal } from "../../../../Modals";

const SignUpInForm = ({ formData, setFormData, setLoginPageNo }) => {
    const navigateTo = useNavigate();
    const [, dispatch] = useStateValue();

    const [showPassword, setShowPassword] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm();
    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => verifyUser(tokenResponse),
        onError: (errorResponse) => toast.error(errorResponse),
    });
    //   const re = await GoogleLogin({ access_token: data?.access_token });

    const showForgotPassword = () => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL",
            status: true,
        });
    }
    const verifyUser = async (data) => {
        try {
            if (formData.is_new_registrations) {
                if (formData.signup_as === "client") {
                    dispatch({ type: "SET_IS_LOADING", status: true });

                    const re = await GoogleLogin({
                        access_token: data?.access_token,
                        user_type: "client",
                    });
                    setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                    console.log("SIGN_UP_RESPONSE", re);
                    if (re.status !== 200) {
                        toast.error(
                            JSON.parse(re.request.response).status[0].Message
                        );
                    } else {
                        if (re.data.status[0].Error === "True") {
                            if (
                                re.data.status[0].ResponseMessage ===
                                "Unacceptable Parameter Value"
                            ) {
                                const errorObj = re.data.status[0].Message;

                                Object.keys(errorObj).forEach((key) => {
                                    setError(key, {
                                        type: "pattern",
                                        message: errorObj[key],
                                    });
                                });
                            } else {
                                toast.error(re.data.status[0].ResponseMessage);
                            }
                        } else {
                            sessionStorage.setItem(
                                "token",
                                re.data.access_token
                            );
                            sessionStorage.setItem(
                                "email",
                                re.data.value?.email
                            );
                            sessionStorage.setItem(
                                "is_premium_user",
                                JSON.stringify(
                                    re.data.value?.current_plan === "free"
                                        ? false
                                        : true
                                )
                            );
                            sessionStorage.setItem(
                                "user_type",
                                re.data.value?.user_type
                            );
                            sessionStorage.setItem("tax", re.data.value?.tax);
                            sessionStorage.setItem(
                                "commission_for_free_client",
                                re.data.value?.commission_for_free_client
                            );
                            sessionStorage.setItem(
                                "thresold_for_commission",
                                re.data.value?.thresold_for_commission
                            );
                            sessionStorage.setItem(
                                "commission_for_member_client",
                                re.data.value?.commission_for_member_client
                            );
                            sessionStorage.setItem(
                                "commission_for_member_client_with_cost_greater_than_thresold",
                                re.data.value
                                    ?.commission_for_member_client_with_cost_greater_than_thresold
                            );
                            dispatch({
                                type: "SET_TAX",
                                status: re.data.value?.tax,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_FREE_CLIENT",
                                status: re.data.value
                                    ?.commission_for_free_client,
                            });
                            dispatch({
                                type: "SET_THRESOLD_FOR_COMMISSION",
                                status: re.data.value?.thresold_for_commission,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                                status: re.data.value
                                    ?.commission_for_member_client,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                                status: re.data.value
                                    ?.commission_for_member_client_with_cost_greater_than_thresold,
                            });

                            dispatch({
                                type: "SET_LOGGED_IN_AS",
                                status: re.data.value?.user_type,
                            });
                            setLoginPageNo((pre) => {
                                localStorage.setItem("loginPageNo", pre + 1);
                                return pre + 1;
                            });
                        }
                    }
                } else {
                    dispatch({ type: "SET_IS_LOADING", status: true });
                    const re = await GoogleLogin({
                        access_token: data?.access_token,
                        user_type: "freelancer",
                    });

                    setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                    console.log("SIGN_UP_RESPONSE", re, re.status);
                    if (re.status !== 200) {
                        toast.error(
                            JSON.parse(re.request.response).status[0].Message
                        );
                    } else {
                        if (re.data.status[0].Error === "True") {
                            if (
                                re.data.status[0].ResponseMessage ===
                                "Unacceptable Parameter Value"
                            ) {
                                const errorObj = re.data.status[0].Message;

                                Object.keys(errorObj).forEach((key) => {
                                    setError(key, {
                                        type: "pattern",
                                        message: errorObj[key],
                                    });
                                });
                            } else {
                                toast.error(re.data.status[0].ResponseMessage);
                            }
                        } else {
                            sessionStorage.setItem(
                                "token",
                                re.data.access_token
                            );
                            sessionStorage.setItem(
                                "email",
                                re.data.value?.email
                            );
                            sessionStorage.setItem(
                                "is_premium_user",
                                JSON.stringify(
                                    re.data.value?.current_plan === "free"
                                        ? false
                                        : true
                                )
                            );
                            sessionStorage.setItem(
                                "have_other_details",
                                re.data.value?.have_other_details
                            );
                            sessionStorage.setItem(
                                "user_type",
                                re.data.value?.user_type
                            );

                            sessionStorage.setItem("tax", re.data.value?.tax);
                            sessionStorage.setItem(
                                "commission_for_free_client",
                                re.data.value?.commission_for_free_client
                            );
                            sessionStorage.setItem(
                                "thresold_for_commission",
                                re.data.value?.thresold_for_commission
                            );
                            sessionStorage.setItem(
                                "commission_for_member_client",
                                re.data.value?.commission_for_member_client
                            );
                            sessionStorage.setItem(
                                "commission_for_member_client_with_cost_greater_than_thresold",
                                re.data.value
                                    ?.commission_for_member_client_with_cost_greater_than_thresold
                            );
                            dispatch({
                                type: "SET_TAX",
                                status: re.data.value?.tax,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_FREE_CLIENT",
                                status: re.data.value
                                    ?.commission_for_free_client,
                            });
                            dispatch({
                                type: "SET_THRESOLD_FOR_COMMISSION",
                                status: re.data.value?.thresold_for_commission,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                                status: re.data.value
                                    ?.commission_for_member_client,
                            });
                            dispatch({
                                type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                                status: re.data.value
                                    ?.commission_for_member_client_with_cost_greater_than_thresold,
                            });

                            dispatch({
                                type: "SET_LOGGED_IN_AS",
                                status: re.data.value?.user_type,
                            });
                            setLoginPageNo((pre) => {
                                localStorage.setItem("loginPageNo", pre + 1);
                                return pre + 1;
                            });
                        }
                    }
                }
            } else {
                dispatch({ type: "SET_IS_LOADING", status: true });
                const re = await GoogleLogin({
                    access_token: data?.access_token,
                    user_type: "",
                });

                setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                console.log("SIGN_IN_RESPONSE", re);
                if (re.status !== 200) {
                    console.log(
                        "SIGN_IN_RESPONSE",
                        JSON.parse(re.request.response).status[0].Message
                    );
                    toast.error(
                        JSON.parse(re.request.response).status[0].Message
                    );
                } else {
                    if (re.data.status[0].Error === "True") {
                        if (
                            re.data.status[0].ResponseMessage ===
                            "Unacceptable Parameter Value"
                        ) {
                            const errorObj = re.data.status[0].Message;

                            Object.keys(errorObj).forEach((key) => {
                                setError(key, {
                                    type: "pattern",
                                    message: errorObj[key],
                                });
                            });
                        } else {
                            toast.error(re.data.status[0].ResponseMessage);
                        }
                    } else {
                        sessionStorage.setItem("token", re.data.access_token);
                        sessionStorage.setItem("email", re.data.value?.email);
                        sessionStorage.setItem("name", re.data.value?.name);
                        sessionStorage.setItem(
                            "phone_number",
                            re.data.value?.phone_number
                        );
                        if (re.data.value?.have_other_details) {
                            dispatch({
                                type: "SET_HAS_OTHER_DETAILS",
                                status: true,
                            });
                        }
                        sessionStorage.setItem(
                            "country_code",
                            re.data.value?.country_code
                        );
                        sessionStorage.setItem(
                            "is_indian_user",
                            re.data.value?.is_indian_user
                        );
                        if (re.data.value?.profile_img) {
                            sessionStorage.setItem(
                                "profile_img",
                                domainName +
                                    "/uploads/" +
                                    re.data.value?.profile_img
                            );
                        }
                        sessionStorage.setItem(
                            "have_other_details",
                            re.data.value?.have_other_details
                        );
                        sessionStorage.setItem(
                            "user_type",
                            re.data.value?.user_type
                        );
                        sessionStorage.setItem(
                            "saved_jobs",
                            JSON.stringify(re.data.value?.saved_jobs)
                        );
                        sessionStorage.setItem(
                            "saved_freelancers",
                            JSON.stringify(re.data.value?.saved_freelancers)
                        );
                        sessionStorage.setItem(
                            "is_premium_user",
                            JSON.stringify(
                                re.data.value?.current_plan === "free"
                                    ? false
                                    : true
                            )
                        );

                        sessionStorage.setItem("tax", re.data.value?.tax);
                        sessionStorage.setItem(
                            "commission_for_free_client",
                            re.data.value?.commission_for_free_client
                        );
                        sessionStorage.setItem(
                            "thresold_for_commission",
                            re.data.value?.thresold_for_commission
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client",
                            re.data.value?.commission_for_member_client
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client_with_cost_greater_than_thresold",
                            re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold
                        );
                        dispatch({
                            type: "SET_TAX",
                            status: re.data.value?.tax,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_FREE_CLIENT",
                            status: re.data.value?.commission_for_free_client,
                        });
                        dispatch({
                            type: "SET_THRESOLD_FOR_COMMISSION",
                            status: re.data.value?.thresold_for_commission,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                            status: re.data.value?.commission_for_member_client,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                            status: re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold,
                        });

                        dispatch({
                            type: "SET_LOGGED_IN_AS",
                            status: re.data.value?.user_type,
                        });
                        dispatch({
                            type: "SET_SAVED_FREELANCERS",
                            status: re.data.value?.saved_freelancers,
                        });
                        dispatch({
                            type: "SET_CURRENCY_SYBMOL_JOBS",
                            status: re.data.value?.is_indian_user ? "₹" : "$",
                        });

                        console.log(re.data.value?.name !== null);
                        if (
                            re.data.value?.have_other_details === true &&
                            re.data.value?.user_type === "freelancer"
                        ) {
                            navigateTo("/");
                        } else if (re.data.value?.name !== null) {
                            if (re.data.value?.user_type === "client") {
                                navigateTo("/");
                            }
                            setLoginPageNo((pre) => {
                                localStorage.setItem("loginPageNo", pre + 2);
                                return pre + 2;
                            });
                        } else {
                            console.log("YESSSS");
                            setLoginPageNo((pre) => {
                                localStorage.setItem("loginPageNo", pre + 1);
                                return pre + 1;
                            });
                        }
                    }
                }
            }
        } catch {}
    };

    const handleFormSubmit = async (data) => {
        if (formData.is_new_registrations) {
            if (formData.signup_as === "client") {
                dispatch({ type: "SET_IS_LOADING", status: true });

                const re = await SignUp({
                    email: data.email,
                    password: data.password,
                    user_type: "client",
                });
                setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                console.log("SIGN_UP_RESPONSE", re);
                if (re.status !== 200) {
                    toast.error(
                        "Something went wrong, Please reload the page."
                    );
                } else {
                    if (re.data.status[0].Error === "True") {
                        if (
                            re.data.status[0].ResponseMessage ===
                            "Unacceptable Parameter Value"
                        ) {
                            const errorObj = re.data.status[0].Message;

                            Object.keys(errorObj).forEach((key) => {
                                setError(key, {
                                    type: "pattern",
                                    message: errorObj[key],
                                });
                            });
                        } else {
                            toast.error(re.data.status[0].ResponseMessage);
                        }
                    } else {
                        sessionStorage.setItem("token", re.data.access_token);
                        sessionStorage.setItem("email", re.data.value?.email);
                        sessionStorage.setItem(
                            "is_premium_user",
                            JSON.stringify(
                                re.data.value?.current_plan === "free"
                                    ? false
                                    : true
                            )
                        );
                        sessionStorage.setItem(
                            "user_type",
                            re.data.value?.user_type
                        );

                        sessionStorage.setItem("tax", re.data.value?.tax);
                        sessionStorage.setItem(
                            "commission_for_free_client",
                            re.data.value?.commission_for_free_client
                        );
                        sessionStorage.setItem(
                            "thresold_for_commission",
                            re.data.value?.thresold_for_commission
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client",
                            re.data.value?.commission_for_member_client
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client_with_cost_greater_than_thresold",
                            re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold
                        );
                        dispatch({
                            type: "SET_TAX",
                            status: re.data.value?.tax,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_FREE_CLIENT",
                            status: re.data.value?.commission_for_free_client,
                        });
                        dispatch({
                            type: "SET_THRESOLD_FOR_COMMISSION",
                            status: re.data.value?.thresold_for_commission,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                            status: re.data.value?.commission_for_member_client,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                            status: re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold,
                        });

                        dispatch({
                            type: "SET_LOGGED_IN_AS",
                            status: re.data.value?.user_type,
                        });
                        setLoginPageNo((pre) => {
                            localStorage.setItem("loginPageNo", pre + 1);
                            return pre + 1;
                        });
                    }
                }
            } else {
                dispatch({ type: "SET_IS_LOADING", status: true });

                const re = await SignUp({
                    email: data.email,
                    password: data.password,
                    user_type: "freelancer",
                });
                setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                console.log("SIGN_UP_RESPONSE", re, re.status);
                if (re.status !== 200) {
                    toast.error(
                        "Something went wrong, Please reload the page."
                    );
                } else {
                    if (re.data.status[0].Error === "True") {
                        if (
                            re.data.status[0].ResponseMessage ===
                            "Unacceptable Parameter Value"
                        ) {
                            const errorObj = re.data.status[0].Message;

                            Object.keys(errorObj).forEach((key) => {
                                setError(key, {
                                    type: "pattern",
                                    message: errorObj[key],
                                });
                            });
                        } else {
                            toast.error(re.data.status[0].ResponseMessage);
                        }
                    } else {
                        sessionStorage.setItem("token", re.data.access_token);
                        sessionStorage.setItem("email", re.data.value?.email);
                        sessionStorage.setItem(
                            "is_premium_user",
                            JSON.stringify(
                                re.data.value?.current_plan === "free"
                                    ? false
                                    : true
                            )
                        );
                        sessionStorage.setItem(
                            "have_other_details",
                            re.data.value?.have_other_details
                        );
                        sessionStorage.setItem(
                            "user_type",
                            re.data.value?.user_type
                        );

                        sessionStorage.setItem("tax", re.data.value?.tax);
                        sessionStorage.setItem(
                            "commission_for_free_client",
                            re.data.value?.commission_for_free_client
                        );
                        sessionStorage.setItem(
                            "thresold_for_commission",
                            re.data.value?.thresold_for_commission
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client",
                            re.data.value?.commission_for_member_client
                        );
                        sessionStorage.setItem(
                            "commission_for_member_client_with_cost_greater_than_thresold",
                            re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold
                        );
                        dispatch({
                            type: "SET_TAX",
                            status: re.data.value?.tax,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_FREE_CLIENT",
                            status: re.data.value?.commission_for_free_client,
                        });
                        dispatch({
                            type: "SET_THRESOLD_FOR_COMMISSION",
                            status: re.data.value?.thresold_for_commission,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                            status: re.data.value?.commission_for_member_client,
                        });
                        dispatch({
                            type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                            status: re.data.value
                                ?.commission_for_member_client_with_cost_greater_than_thresold,
                        });

                        dispatch({
                            type: "SET_LOGGED_IN_AS",
                            status: re.data.value?.user_type,
                        });
                        setLoginPageNo((pre) => {
                            localStorage.setItem("loginPageNo", pre + 1);
                            return pre + 1;
                        });
                    }
                }
            }
        } else {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await SignIn({
                email: data.email,
                password: data.password,
            });
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("SIGN_IN_RESPONSE", re);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    if (
                        re.data.status[0].ResponseMessage ===
                        "Unacceptable Parameter Value"
                    ) {
                        const errorObj = re.data.status[0].Message;

                        Object.keys(errorObj).forEach((key) => {
                            setError(key, {
                                type: "pattern",
                                message: errorObj[key],
                            });
                        });
                    } else {
                        toast.error(re.data.status[0].ResponseMessage);
                    }
                } else {
                    sessionStorage.setItem("token", re.data.access_token);
                    sessionStorage.setItem("email", re.data.value?.email);
                    sessionStorage.setItem("name", re.data.value?.name);
                    sessionStorage.setItem(
                        "phone_number",
                        re.data.value?.phone_number
                    );
                    if (re.data.value?.have_other_details) {
                        dispatch({
                            type: "SET_HAS_OTHER_DETAILS",
                            status: true,
                        });
                    }
                    sessionStorage.setItem(
                        "country_code",
                        re.data.value?.country_code
                    );
                    sessionStorage.setItem(
                        "is_indian_user",
                        re.data.value?.is_indian_user
                    );
                    if (re.data.value?.profile_img) {
                        sessionStorage.setItem(
                            "profile_img",
                            domainName +
                                "/uploads/" +
                                re.data.value?.profile_img
                        );
                    }
                    sessionStorage.setItem(
                        "have_other_details",
                        re.data.value?.have_other_details
                    );
                    sessionStorage.setItem(
                        "user_type",
                        re.data.value?.user_type
                    );
                    sessionStorage.setItem(
                        "saved_jobs",
                        JSON.stringify(re.data.value?.saved_jobs)
                    );
                    sessionStorage.setItem(
                        "saved_freelancers",
                        JSON.stringify(re.data.value?.saved_freelancers)
                    );
                    sessionStorage.setItem(
                        "is_premium_user",
                        JSON.stringify(
                            re.data.value?.current_plan === "free"
                                ? false
                                : true
                        )
                    );

                    sessionStorage.setItem("tax", re.data.value?.tax);
                    sessionStorage.setItem(
                        "commission_for_free_client",
                        re.data.value?.commission_for_free_client
                    );
                    sessionStorage.setItem(
                        "thresold_for_commission",
                        re.data.value?.thresold_for_commission
                    );
                    sessionStorage.setItem(
                        "commission_for_member_client",
                        re.data.value?.commission_for_member_client
                    );
                    sessionStorage.setItem(
                        "commission_for_member_client_with_cost_greater_than_thresold",
                        re.data.value
                            ?.commission_for_member_client_with_cost_greater_than_thresold
                    );
                    dispatch({
                        type: "SET_TAX",
                        status: re.data.value?.tax,
                    });
                    dispatch({
                        type: "SET_COMMISSION_FOR_FREE_CLIENT",
                        status: re.data.value?.commission_for_free_client,
                    });
                    dispatch({
                        type: "SET_THRESOLD_FOR_COMMISSION",
                        status: re.data.value?.thresold_for_commission,
                    });
                    dispatch({
                        type: "SET_COMMISSION_FOR_MEMBER_CLIENT",
                        status: re.data.value?.commission_for_member_client,
                    });
                    dispatch({
                        type: "SET_COMMISSION_FOR_MEMBER_CLIENT_WITH_COST_GREATER_THAN_THRESOLD",
                        status: re.data.value
                            ?.commission_for_member_client_with_cost_greater_than_thresold,
                    });

                    dispatch({
                        type: "SET_LOGGED_IN_AS",
                        status: re.data.value?.user_type,
                    });
                    dispatch({
                        type: "SET_SAVED_FREELANCERS",
                        status: re.data.value?.saved_freelancers,
                    });
                    dispatch({
                        type: "SET_CURRENCY_SYBMOL_JOBS",
                        status: re.data.value?.is_indian_user ? "₹" : "$",
                    });

                    console.log(re.data.value?.name !== null);
                    if (
                        re.data.value?.have_other_details === true &&
                        re.data.value?.user_type === "freelancer"
                    ) {
                        navigateTo("/");
                    } else if (re.data.value?.name !== null) {
                        if (re.data.value?.user_type === "client") {
                            navigateTo("/");
                        }
                        setLoginPageNo((pre) => {
                            localStorage.setItem("loginPageNo", pre + 2);
                            return pre + 2;
                        });
                    } else {
                        console.log("YESSSS");
                        setLoginPageNo((pre) => {
                            localStorage.setItem("loginPageNo", pre + 1);
                            return pre + 1;
                        });
                    }
                }
            }
        }
    };
    return (
        <div className='signup-form-main'>
            <div className='container'>
                {formData.is_new_registrations && (
                    <p className='title'>
                        Sign up to{" "}
                        {formData.signup_as === "freelancer"
                            ? "work for clients"
                            : "hire freelancers"}
                    </p>
                )}
                {!formData.is_new_registrations && (
                    <p className='title'>
                        Login as{" "}
                        {formData.signup_as === "freelancer"
                            ? "freelancer"
                            : "client"}
                    </p>
                )}
                <button
                    className='google-login login-btn'
                    onClick={() => googleLogin()}>
                    <GoogleIcon />
                    Continue With Google
                </button>
                {/* <button className='facebook-login login-btn'>
                    <FacebookIcon />
                    Continue With Facebook
                </button> */}
                <span className='hr'>
                    <hr />
                    <p>OR</p>
                    <hr />
                </span>
                <div className={`form-container login-form`}>
                    <div className='input-grp'>
                        <input
                            type='email'
                            placeholder='Email address'
                            {...register("email", {
                                required: "required",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Invalid email format.",
                                },
                            })}
                        />
                        {errors?.email?.type === "required" && (
                            <p className='form-error'>Email is required.</p>
                        )}
                        {errors?.email?.type === "pattern" && (
                            <p className='form-error'>
                                {errors?.email?.message}
                            </p>
                        )}
                    </div>
                    <div className='input-grp'>
                        <span className='password'>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder='Password'
                                {...register("password", {
                                    required: true,
                                    pattern:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                })}
                            />
                            <span
                                onClick={() => setShowPassword((pre) => !pre)}>
                                {showPassword ? <Eye /> : <EyeOff />}
                            </span>
                        </span>
                        {errors.password?.type === "required" && (
                            <p className='form-error'>Password is required.</p>
                        )}
                        {errors.password?.type === "pattern" && (
                            <p className='form-error'>
                                Must Be Contains Uppercase, Lowercase Letters,
                                Numbers, Special Characters And Length Is
                                Greater Than 8 Character And Less Then 16
                                Character.
                            </p>
                        )}
                        <p className='forgot-password' onClick={showForgotPassword}>Forgot password?</p>
                    </div>
                </div>
                <div className='next-action'>
                    <button
                        className='primary-btn-blue'
                        onClick={handleSubmit(handleFormSubmit)}>
                        {formData.is_new_registrations
                            ? "Create my account"
                            : "Log in"}
                    </button>
                    {formData.is_new_registrations ? (
                        <p>
                            Already have an account?{" "}
                            <span
                                onClick={() => {
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            is_new_registrations: false,
                                        };
                                    });
                                }}>
                                Log in
                            </span>
                        </p>
                    ) : (
                        <p>
                            Don't have an account?{" "}
                            <span
                                onClick={() => {
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            is_new_registrations: true,
                                        };
                                    });
                                    setLoginPageNo((pre) => {
                                        localStorage.setItem("loginPageNo", 0);
                                        return 0;
                                    });
                                }}>
                                Sign Up
                            </span>
                        </p>
                    )}
                </div>
            </div>
            <ForgotPasswordModal /> 
        </div>
    );
};

export default SignUpInForm;
