import React from "react";
import ReactFlagsSelect from "react-flags-select";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm } from "react-hook-form";
import CountryCodes from "../../../../CountryCodes.json";

import "./namePhoneInput.scss";
import { AddBasicDetails } from "../../../../apiCall";

const NamePhoneInput = ({ formData, setFormData, setLoginPageNo }) => {
    const [{ loggedInAs }, dispatch] = useStateValue();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            name: sessionStorage.getItem('name') || localStorage.getItem('name'),
            country_code: sessionStorage.getItem('country_code') || localStorage.getItem('country_code'),
            phone_number: sessionStorage.getItem('phone_number') || localStorage.getItem('phone_number'),
            annual_revenue: sessionStorage.getItem('annual_revenue') || localStorage.getItem('annual_revenue'),
        }
      });
    const navigateTo = useNavigate();

    const handleFormSubmit = async (data) => {
        dispatch({type:"SET_IS_LOADING",status:true})
        const re = await AddBasicDetails({
            name: data.name,
            country_code: formData.country_code,
            phone_number: data.phone_number,
            is_indian_user: formData.from_india,
            annual_revenue: data.annual_revenue ? data.annual_revenue : "",
            user_type: loggedInAs,
        });
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("ADD_USER_DETAILS_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                if (
                    re.data.status[0].ResponseMessage ===
                    "Unacceptable Parameter Value"
                ) {
                    const errorObj = re.data.status[0].Message;

                    Object.keys(errorObj).forEach((key) => {
                        setError(key, {
                            type: "pattern",
                            message: errorObj[key],
                        });
                    });
                } else {
                    toast.error(re.data.status[0].ResponseMessage);
                }
            } else {
                sessionStorage.setItem("name", data.name);
                sessionStorage.setItem(
                    "phone_number",
                    data.phone_number
                );
                sessionStorage.setItem(
                    "country_code",
                    formData.country_code
                );
                sessionStorage.setItem(
                    "is_indian_user",
                    formData.from_india
                );
                if (loggedInAs === "client") {
                    navigateTo("/");
                    sessionStorage.setItem("have_other_details", true);
                    dispatch({
                        type: "SET_HAS_OTHER_DETAILS",
                        status: true
                    })
                } else {
                    const have_other_details =
                        localStorage.getItem("have_other_details") ||
                        sessionStorage.getItem("have_other_details");

                    if (have_other_details !== "true") {
                        setLoginPageNo((pre) => {
                            localStorage.setItem("loginPageNo", pre + 1);
                            return pre + 1;
                        });
                    } else {
                        navigateTo("/");
                        sessionStorage.setItem("have_other_details", true);
                        dispatch({
                            type: "SET_HAS_OTHER_DETAILS",
                            status: true
                        })
                    }
                }
            }
        }
    };
    return (
        <div className='name-phone-input-main'>
            <div className='container'>
                <p className='title'>Please provide below details.</p>
                <div className='form-container login-form'>
                    <div className='input-grp'>
                        <p className='bold'>Enter your name*</p>
                        <input
                            type='text'
                            placeholder='Name'
                            {...register("name", {
                                required: "required",
                                maxLength: 320,
                                pattern: {
                                    message: "Invalid name format.",
                                },
                            })}
                        />
                        {errors?.name?.type === "required" && (
                            <p className='form-error'>name is required.</p>
                        )}
                        {errors?.name?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of name can not be more than 320
                            </p>
                        )}
                        {errors?.name?.type === "pattern" && (
                            <p className='form-error'>
                                {errors?.name?.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className='form-container login-form'>
                    <div className='input-grp'>
                        <p className='bold'>Phone Number*</p>

                        <span className='phone'>
                            <ReactFlagsSelect
                                countries={Object.keys(CountryCodes)}
                                customLabels={CountryCodes}
                                placeholder=''
                                selected={formData.country_code.split(" ")[0]}
                                onSelect={(code) =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            country_code:
                                                code + " " + CountryCodes[code],
                                        };
                                    })
                                }
                                searchable
                            />
                            <input
                                className='phone_input'
                                type='number'
                                placeholder='Phone number'
                                {...register("phone_number", {
                                    required: "required",
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: "Invalid phone number.",
                                    },
                                })}
                            />
                        </span>
                        {errors?.phone_number?.type === "required" && (
                            <p className='form-error'>
                                Phone number is required.
                            </p>
                        )}
                        {errors?.phone_number?.type === "maxLength" && (
                            <p className='form-error'>
                                Length of name can not be more than 320
                            </p>
                        )}
                        {errors?.phone_number?.type === "pattern" && (
                            <p className='form-error'>
                                {errors?.phone_number?.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className='form-container login-form'>
                    <div className='input-grp'>
                        <p className='bold'>Are you from India?</p>
                        <div className='radio-container'>
                            <div
                                className='radio-grp'
                                onClick={() =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            from_india: true,
                                        };
                                    })
                                }>
                                <span
                                    className={`my-radio ${
                                        formData.from_india === true
                                            ? "selected"
                                            : ""
                                    }`}>
                                    <span className='select-mark'></span>
                                </span>
                                <p>Yes</p>
                            </div>
                            <div
                                className='radio-grp'
                                onClick={() =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            from_india: false,
                                        };
                                    })
                                }>
                                <span
                                    className={`my-radio ${
                                        formData.from_india === false
                                            ? "selected"
                                            : ""
                                    }`}>
                                    <span className='select-mark'></span>
                                </span>
                                <p>No</p>
                            </div>
                        </div>
                    </div>
                </div>
                {loggedInAs === "client" && (
                    <>
                        <div className='form-container login-form'>
                            <div className='input-grp'>
                                <p className='bold'>
                                    Enter Your Annual Revenue
                                </p>
                                <input
                                    type='text'
                                    placeholder='Annual Revenue'
                                    {...register("annual_revenue")}
                                />
                                <p className='note'>
                                    To improve your experience
                                </p>
                            </div>
                        </div>
                    </>
                )}
                <div className='next-action'>
                    <button
                        className='primary-btn-blue'
                        onClick={handleSubmit(handleFormSubmit)}>
                        {formData.signup_as === "client" ? "Submit" : "Next"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NamePhoneInput;
