import React, { useRef, useState } from "react";
import { ReactComponent as CheckMark } from "../../Assets/icons/check-mark.svg";
import UploadIcon from "../../Assets/icons/upload.svg";
import pdfIcon from "../../Assets/icons/pdf.svg";
import DeleteIcon from "../../Assets/icons/delete-1.svg";

import "./packageRequirements.scss";
import { postPackageRequirements, uploadProposalAttachments } from "../../apiCall";
import toast from 'react-hot-toast';
import { useStateValue } from "../../StateProvider";

const PackageRequirements = ({ id, setShowRequirementsForm }) => {
    const [formData, setFormData] = useState({
        requirements: "",
        files: [],
        checkmark: false,
    });
    const [errors, setErrors] = useState({});
    const fileRef = useRef();
    const [, dispatch] = useStateValue()

    const handleSubmit = async () => {
        if(!formData?.requirements){
            setErrors(pre => {return {...pre, requirements: "Requirements are required."}})
            return;
        }
        if(!formData?.checkmark){
            setErrors(pre => {return {...pre, checkmark: "Please confirm your requirements."}})
            return;
        }
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await postPackageRequirements(formData.requirements, id);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("POST_JOB_DETAILS_RESPONSE", re);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                if (
                    re.data.status[0].ResponseMessage ===
                    "Unacceptable Parameter Value"
                ) {
                    setErrors(pre => {return {...pre, requirements: re.data.status[0].Message}})
                } else {
                    toast.error(re.data.status[0].ResponseMessage);
                }
            } else {
                if (formData?.files.length > 0) {
                    let filesData = new FormData();
                    formData?.files?.forEach((file, index) => {
                        filesData.append(`files`, file); // Append each file with a unique key
                    });

                    if (formData?.files?.length === 0) {
                        filesData = [];
                    }
                    console.log(re.data.value)
                    dispatch({ type: "SET_IS_LOADING", status: true });

                    const ImageRe = await uploadProposalAttachments(
                        filesData,
                        re.data.value
                    );
                    setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                    console.log("POST_PROPOSAL_ATTACHMENTS_RESPONSE", ImageRe);

                    if (ImageRe.data?.status[0].Error === "False") {
                        dispatch({
                            type: "SET_PACKAGE_PURCHASE_MODAL_SHOW",
                            status: true,
                        });
                    } else {
                        if (ImageRe.data?.status) {
                            toast.error(ImageRe.data.status[0].ResponseMessage);
                        } else {
                            toast.error(
                                "Something went wrong, Please try again."
                            );
                        }
                    }
                } else {
                    dispatch({
                        type: "SET_PACKAGE_PURCHASE_MODAL_SHOW",
                        status: true,
                    });
                }
            }
        }
    };
    return (
        <div className='package-requirements-main'>
            <div className='details-container'>
                {/* <div className='thanks'>
                    <span className='check-mark'>
                        <CheckMark />
                    </span>
                    <p>Thank you for your purchase</p>
                </div> */}
                <h2>Submit requirements to start your order</h2>
                <div className='input-grp'>
                    <p className='bold'>
                        Do you have an idea of you want? or should I surprise
                        you?
                    </p>
                    <textarea
                        type='text'
                        placeholder='Add responsibilities which freelancers need to cover during work. '
                        value={formData.requirements}
                        onChange={(e) =>
                            setFormData((pre) => {
                                return {
                                    ...pre,
                                    requirements: e.target.value,
                                };
                            })
                        }
                    />
                    <p className='form-error'>{errors?.requirements}</p>
                </div>
                <div className='files-container'>
                    {formData?.files?.map((file, indx) => (
                        <div className='my-file' key={"uploaded-file-" + indx}>
                            <span className='attachment'>
                                <span>
                                    <img src={pdfIcon} alt='' />
                                    <p>{file?.name}</p>
                                </span>
                                <img
                                    src={DeleteIcon}
                                    className='delete-icon'
                                    alt=''
                                    onClick={() =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                files: pre.files.filter(
                                                    (file, fileIndx) =>
                                                    indx !== fileIndx
                                                    ),
                                                };
                                            })
                                        }
                                />
                            </span>
                            {/* <div
                                    className='plus'
                                    onClick={() => fileRef.current.click()}>
                                    <CloseIcon />
                                </div> */}
                        </div>
                    ))}
                    <div className='file-upload'>
                        <span
                            className='attachment'
                            onClick={() => fileRef.current.click()}>
                            <span>
                                <img src={UploadIcon} alt='' />
                                <p>Attach file</p>
                            </span>
                            <input
                                type='file'
                                name=''
                                id=''
                                onChange={(e) =>
                                    setFormData((pre) => {
                                        return {
                                            ...pre,
                                            files: [
                                                ...pre.files,
                                                e.target.files[0],
                                            ],
                                        };
                                    })
                                }
                                ref={fileRef}
                                // accept='.pdf, .docx, .jpg, .jpeg'
                            />
                        </span>
                        {/* <span className='instructions'>
                                <p>PDF/DOCX/JPG/JPEG format supported</p>
                                <p>Max file size: 100MB</p>
                            </span> */}
                    </div>
                </div>
                <div
                    className='check'
                    onClick={() =>
                        setFormData((pre) => {
                            return { ...pre, checkmark: !pre.checkmark };
                        })
                    }>
                    <span
                        className={`check-box ${
                            formData?.checkmark ? "checked" : ""
                        }`}>
                        {formData?.checkmark && <CheckMark />}
                    </span>
                    <p className='desc'>
                        The information I provided is accurate and complete. Any
                        changes will require the seller’s approval and may be
                        subject to additional cost.
                    </p>
                </div>
                            <p className='form-error'>{errors?.checkmark}</p>
                <div className='buttons'>
                    <button
                        className='primary-btn-white'
                        onClick={() => setShowRequirementsForm(false)}>
                        CANCEL
                    </button>
                    <button className='primary-btn-blue' onClick={handleSubmit}>START ORDER</button>
                </div>
            </div>
        </div>
    );
};

export default PackageRequirements;
