import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import { ReactComponent as CheckMark } from "../../../../Assets/icons/check-mark.svg";
import "./workExperienceForm.scss";
import { useStateValue } from "../../../../StateProvider";

const initial_data = {
    title: "",
    company: "",
    currently_working: false,
    start_month: "",
    start_year: "",
    end_month: "",
    end_year: "",
};

const MONTH_MAP = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
};

const WorkExperienceForm = ({ formData, setFormData, experience_index }) => {
    const [experience, setExperience] = useState(
        experience_index === -1
            ? initial_data
            : formData.work_experience[experience_index]
    );
    const [errors, setErrors] = useState({});
    const [, dispatch] = useStateValue();
    const hideWorkExperienceModal = () => {
        dispatch({
            type: "SET_WORK_EXPERIENCE_SHOW",
            status: false,
        });
    };
    const handleSubmit = () => {
        if (!experience?.title) {
            setErrors((pre) => {
                return { ...pre, title: "Title is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, title: "" };
            });
        }
        if (!experience?.company) {
            setErrors((pre) => {
                return { ...pre, company: "Company is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, company: "" };
            });
        }
        if (!experience?.start_month || !experience.start_year) {
            setErrors((pre) => {
                return { ...pre, start_date: "Start date is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, start_date: "" };
            });
        }
        if (
            !experience?.currently_working &&
            (!experience?.end_month || !experience.end_year)
        ) {
            setErrors((pre) => {
                return { ...pre, end_date: "End date is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, end_date: "" };
            });
        }
        if (!experience?.currently_working) {
            if (experience?.end_year < experience?.start_year) {
                setErrors((pre) => {
                    return {
                        ...pre,
                        end_date: "End date must be after start date.",
                    };
                });
                return;
            } else if (
                experience?.end_year === experience?.start_year &&
                MONTH_MAP[experience?.end_month] <=
                    MONTH_MAP[experience?.start_month]
            ) {
                setErrors((pre) => {
                    return {
                        ...pre,
                        end_date: "End date must be after start date.",
                    };
                });
                return;
            } else {
                setErrors((pre) => {
                    return { ...pre, end_date: "" };
                });
            }
        } else {
            setErrors((pre) => {
                return { ...pre, end_date: "" };
            });
        }
        if (experience_index === -1) {
            setFormData((pre) => {
                return {
                    ...pre,
                    work_experience: [...pre.work_experience, experience],
                };
            });
        } else {
            setFormData((pre) => {
                const new_work_experience = pre.work_experience;
                new_work_experience[experience_index] = experience;
                return { ...pre, work_experience: new_work_experience };
            });
        }
        hideWorkExperienceModal();
    };

    return (
        <div className='work-experience-form-main'>
            <div className='header'>
                <p>Add Work Experience</p>
                <CloseIcon onClick={hideWorkExperienceModal} />
            </div>
            <div className='input-grp'>
                <div className='input-grp'>
                    <p className='bold'>Title*</p>
                    <input
                        type='text'
                        placeholder='Ex: Software engineer'
                        value={experience.title}
                        onChange={(e) =>
                            setExperience((pre) => {
                                return { ...pre, title: e.target.value };
                            })
                        }
                    />
                    <p className='form-error'>{errors?.title}</p>
                </div>
                <div className='input-grp'>
                    <p className='bold'>Company*</p>
                    <input
                        type='text'
                        placeholder='Ex: Microsoft'
                        value={experience.company}
                        onChange={(e) =>
                            setExperience((pre) => {
                                return { ...pre, company: e.target.value };
                            })
                        }
                    />
                    <p className='form-error'>{errors?.company}</p>
                </div>
                <div className='check-box-grp'>
                    <span
                        className='check-box'
                        onClick={() =>
                            setExperience((pre) => {
                                return {
                                    ...pre,
                                    currently_working: !pre.currently_working,
                                    end_month: "",
                                    end_year: "",
                                };
                            })
                        }>
                        {experience.currently_working && <CheckMark />}
                    </span>
                    <p>I am currently working in this role.</p>
                </div>
                <div className='input-grp'>
                    <p className='bold'>Start Date*</p>
                    <div className='inputs'>
                        <select
                            value={experience.start_month}
                            onChange={(e) =>
                                setExperience((pre) => {
                                    return {
                                        ...pre,
                                        start_month: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Month
                            </option>
                            <option value='January'>January</option>
                            <option value='February'>February</option>
                            <option value='March'>March</option>
                            <option value='April'>April</option>
                            <option value='May'>May</option>
                            <option value='June'>June</option>
                            <option value='July'>July</option>
                            <option value='August'>August</option>
                            <option value='September'>September</option>
                            <option value='October'>October</option>
                            <option value='November'>November</option>
                            <option value='December'>December</option>
                        </select>
                        <select
                            value={experience.start_year}
                            onChange={(e) =>
                                setExperience((pre) => {
                                    return {
                                        ...pre,
                                        start_year: e.target.value,
                                    };
                                })
                            }>
                            <option value='' disabled>
                                Year
                            </option>
                            {Array(61)
                                .fill(0)
                                .map((num, indx) => (
                                    <option key={"start-year-" + indx}>
                                        {new Date().getFullYear() - indx}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <p className='form-error'>{errors?.start_date}</p>
                </div>
                {!experience.currently_working && (
                    <div className='input-grp'>
                        <p className='bold'>End Date*</p>
                        <div className='inputs'>
                            <select
                                value={experience.end_month}
                                onChange={(e) =>
                                    setExperience((pre) => {
                                        return {
                                            ...pre,
                                            end_month: e.target.value,
                                        };
                                    })
                                }>
                                <option value=''>Month</option>
                                <option value='January'>January</option>
                                <option value='February'>February</option>
                                <option value='March'>March</option>
                                <option value='April'>April</option>
                                <option value='May'>May</option>
                                <option value='June'>June</option>
                                <option value='July'>July</option>
                                <option value='August'>August</option>
                                <option value='September'>September</option>
                                <option value='October'>October</option>
                                <option value='November'>November</option>
                                <option value='December'>December</option>
                            </select>
                            <select
                                value={experience.end_year}
                                onChange={(e) =>
                                    setExperience((pre) => {
                                        return {
                                            ...pre,
                                            end_year: e.target.value,
                                        };
                                    })
                                }>
                                <option value=''>Year</option>
                                {Array(61)
                                    .fill(0)
                                    .map((num, indx) => (
                                        <option key={"end-year-" + indx}>
                                            {new Date().getFullYear() - indx}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <p className='form-error'>{errors?.end_date}</p>
                    </div>
                )}
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={hideWorkExperienceModal}>
                    Cancel
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default WorkExperienceForm;
