import React, { useRef } from "react";
import UploadIcon from "../../../../../Assets/icons/upload.svg";
import pdfIcon from "../../../../../Assets/icons/pdf.svg";
import DeleteIcon from "../../../../../Assets/icons/delete-1.svg";
import "./step3.scss";

const Step3 = ({ formData, setFormData, setPageNo }) => {
    const fileRef = useRef();
    return (
        <>
            <div className='job-post-step-3-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 3 of 3</p>
                            <span>Job Post</span>
                        </span>
                        <p className='title'>Start the conversation.</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Describe what you need</p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.description}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        description: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>Responsibilities</p>
                        <textarea
                            type='text'
                            placeholder='Type here...'
                            value={formData.responsibilities}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return {
                                        ...pre,
                                        responsibilities: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>
                    <div className='files-container'>
                        {formData?.files?.map((file, indx) => (
                            <div
                                className='my-file'
                                key={"uploaded-file-" + indx}>
                                <span className='attachment'>
                                    <span>
                                        <img src={pdfIcon} alt='' />
                                        <p>{file?.name}</p>
                                    </span>
                                    <img
                                        src={DeleteIcon}
                                        className='delete-icon'
                                        alt=''
                                        onClick={() =>
                                            setFormData((pre) => {
                                                return {
                                                    ...pre,
                                                    files: pre.files.filter(
                                                        (file, fileIndx) =>
                                                            indx !== fileIndx
                                                    ),
                                                };
                                            })
                                        }
                                    />
                                </span>
                                {/* <div
                                    className='plus'
                                    onClick={() => fileRef.current.click()}>
                                    <CloseIcon />
                                </div> */}
                            </div>
                        ))}
                        <div className='file-upload'>
                            <span
                                className='attachment'
                                onClick={() => fileRef.current.click()}>
                                <span>
                                    <img src={UploadIcon} alt='' />
                                    <p>Attach file</p>
                                </span>
                                <input
                                    type='file'
                                    name=''
                                    id=''
                                    onChange={(e) =>
                                        setFormData((pre) => {
                                            return {
                                                ...pre,
                                                files: [
                                                    ...pre.files,
                                                    e.target.files[0],
                                                ],
                                            };
                                        })
                                    }
                                    ref={fileRef}
                                    accept='.pdf, .docx, .jpg, .jpeg'
                                />
                            </span>
                            <span className='instructions'>
                                <p>PDF/DOCX/JPG/JPEG format supported</p>
                                <p>Max file size: 100MB</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={() => setPageNo((pre) => pre + 1)}>
                    Review job post
                </button>
            </div>
        </>
    );
};

export default Step3;
