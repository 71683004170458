import React, { useEffect, useState } from "react";
import PackagePurchaseDetails from "./PackagePurchaseDetails";

import "./packagePurchase.scss";
import CouponApply from "./CouponApply";
import { getCouponCodesList, postPackageProposal } from "../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../StateProvider";
import { displayRazorpay } from "../../../../utils/Razorpay";

const PackagePurchase = (props) => {
    const [appliedCoupon, setAppliedCoupon] = useState("");
    const [currentView, setCurrentView] = useState("purchase-details");
    const [coupons, setCoupons] = useState();
    // const navigateTo = useNavigate()
    const [
        {
            tax,
            commission_for_free_client,
            thresold_for_commission,
            commission_for_member_client,
            commission_for_member_client_with_cost_greater_than_thresold,
        },
        dispatch,
    ] = useStateValue();

    const [formData, setFormData] = useState({
        quantity: 1,
        // includeSourceFile: false,
        taxPercentage: 18,
        commission: 10,
        take_fast_delivery: false,
    });
    const [total, setTotal] = useState();
    useEffect(() => {
        setTotal(
            formData?.quantity * props?.data?.price +
                (formData?.take_fast_delivery &&
                props?.data?.extra_service?.extra_charge
                    ? parseFloat(props?.data?.extra_service?.extra_charge)
                    : 0)
        );
    }, [formData, props?.data]);

    useEffect(() => {
        setFormData((pre) => {
            return {
                ...pre,
                taxPercentage: tax,
                commission:
                    sessionStorage.getItem("is_premium_user") === "true"
                        ? total > thresold_for_commission
                            ? commission_for_member_client_with_cost_greater_than_thresold
                            : commission_for_member_client
                        : commission_for_free_client,
            };
        });
    }, [
        tax,
        commission_for_free_client,
        thresold_for_commission,
        commission_for_member_client,
        commission_for_member_client_with_cost_greater_than_thresold,
        total,
    ]);
    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await getCouponCodesList();
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("COUPON_CODE_LIST_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    setCoupons(re.data.value);
                }
            }
        };
        getData();
    }, [dispatch]);

    const handleCouponCodeApply = () => {
        // setAppliedCoupon(couponDetails)
        setCurrentView("purchase-details");
    };
    const handlePayment = async () => {
        const data = {
            package: props?.offer_id,
            service_type: props?.activeTab,
            quantity: formData?.quantity,
            purchased_extra_service: formData?.take_fast_delivery,
            coupon: appliedCoupon?.id,
        };
        dispatch({ type: "SET_IS_LOADING", status: true });

        const re = await postPackageProposal(data);
        setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        console.log("POST_PACKAGE_PROPOSAL_RESPONSE", re, re.status);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                // navigateTo('/packages-requirements/' + props?.offer_id)
                const discount_amount = appliedCoupon?.discount
                    ? Math.min(
                          appliedCoupon?.maximum_discount_amount,
                          (total * appliedCoupon?.discount) / 100
                      )
                    : 0;
                displayRazorpay(
                    total,
                    "JOB_POST",
                    appliedCoupon?.id,
                    discount_amount,
                    re.data.value,
                    null,
                    dispatch
                );
            }
        }
    };
    return (
        <div className='package-purchase-main'>
            {currentView === "purchase-details" ? (
                <>
                    <PackagePurchaseDetails
                        {...props}
                        appliedCoupon={appliedCoupon}
                        setCurrentView={setCurrentView}
                        setAppliedCoupon={setAppliedCoupon}
                        formData={formData}
                        setFormData={setFormData}
                        total={total}
                    />
                    <button
                        className='primary-btn-blue'
                        onClick={handlePayment}>
                        COUNTINUE WITH PAYMENT
                    </button>
                </>
            ) : (
                <>
                    <CouponApply
                        couponDetails={appliedCoupon}
                        setCouponDetails={setAppliedCoupon}
                        setCurrentView={setCurrentView}
                        coupons={coupons}
                    />
                    <button
                        className='primary-btn-blue'
                        onClick={handleCouponCodeApply}>
                        APPLY
                    </button>
                </>
            )}
        </div>
    );
};

export default PackagePurchase;
