import React from "react";
import { ReactComponent as Logo } from "../../../Assets/icons/logo.svg"; 
import "./homeNav.scss"
import { useNavigate } from "react-router-dom";

const HomeNav = () => {
    const navigateTo = useNavigate()
    return (
        <div className='home-nav'>
            <Logo />
            <button className='primary-btn-white' onClick={() => navigateTo('/signup')}>Login</button>
        </div>
    );
};

export default HomeNav;
