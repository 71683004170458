import React, { useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../Assets/icons/close.svg";
import UploadIcon from "../../../../Assets/icons/upload.svg";
import PdfIcon from "../../../../Assets/icons/pdf.svg";

import "./submitWorkForm.scss";
import { useStateValue } from "../../../../StateProvider";
import { uploadWorkDetails, uploadWorkDocuments } from "../../../../apiCall";
import toast from 'react-hot-toast';

const SubmitWorkForm = ({ job_id, proposal_id }) => {
    console.log(job_id, proposal_id)
    const fileRef = useRef();
    const [, dispatch] = useStateValue();
    const hideSubmitWorkModal = () => {
        dispatch({
            type: "SET_SUBMIT_WORK_MODAL_SHOW",
            status: false,
        });
    };
    const showFeedbackModal = () => {
        dispatch({
            type: "SET_FEED_BACK_MODAL_SHOW",
            status: true,
        });
    };
    const [description, setDescription] = useState();
    const [files, setFiles] = useState([]);

    const handleSubmit = async () => {
        let filesData = new FormData();
        files.forEach((file, index) => {
            filesData.append(`files`, file); // Append each file with a unique key
        });

        if (files.length === 0) {
            filesData = [];
        }

        let re = null;
        if (files.length !== 0) {
            dispatch({type:"SET_IS_LOADING",status:true})

            re = await uploadWorkDocuments(filesData, proposal_id);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

        } else {
            re = { data: { status: [{ Error: "False" }] }, status: 200 };
        }
        console.log("POST_WORK_DOCUMENTS_RESPONSE", re);
        if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page.");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                dispatch({type:"SET_IS_LOADING",status:true})

                const DetailsRe = await uploadWorkDetails(
                    { work_description: description },
                    proposal_id
                );
                setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

                console.log("JOB_DETAILD_UPDATE_RESPONSE", DetailsRe);

                if (DetailsRe.data?.status[0].Error === "False") {
                    hideSubmitWorkModal();
                    showFeedbackModal();
                } else {
                    if (DetailsRe.data?.status) {
                        toast.error(DetailsRe.data.status[0].ResponseMessage);
                    } else {
                        toast.error("Something went wrong, Please try again.");
                    }
                }
            }
        }
    };

    return (
        <div className='submit-form-main'>
            <div className='header'>
                <p>Submit your work</p>
                <CloseIcon onClick={hideSubmitWorkModal} />
            </div>
            <div className='inputs'>
                <div className='input-grp'>
                    <p className='bold'>
                        Describe you delivery work in brief.*
                    </p>
                    <textarea
                        type='text'
                        placeholder='Ex: Microsoft'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className='file-upload'>
                    {files?.map((file, indx) => (
                        <span key={"upload-file-" + indx}>
                            <img src={PdfIcon} alt='' />
                            <p>{file.name}</p>
                        </span>
                    ))}

                    <span
                        className='attachment'
                        onClick={() => fileRef.current.click()}>
                        <img src={UploadIcon} alt='' />
                        <p>Upload work</p>
                        <input
                            type='file'
                            name=''
                            id=''
                            ref={fileRef}
                            onChange={(e) =>
                                setFiles((pre) => [...pre, e.target.files[0]])
                            }
                        />
                    </span>
                    {/* <div
                        className='plus'
                        onClick={() => fileRef.current.click()}>
                        <CloseIcon />
                    </div> */}
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={hideSubmitWorkModal}>
                    Cancel
                </button>
                <button className='btn-blue' onClick={handleSubmit}>
                    Submit work
                </button>
            </div>
        </div>
    );
};

export default SubmitWorkForm;
