import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as StarReview } from "../../../../Assets/icons/star.svg";
import { ReactComponent as CheckIcon } from "../../../../Assets/icons/check-circle.svg";
import DownArrowIcon from "../../../../Assets/icons/arrow-drop-down.svg";
import { ReactComponent as FilterIcon } from "../../../../Assets/icons/filter.svg";

import "./packageList.scss";
import { domainName } from "../../../../Constants";
import { useNavigate } from "react-router-dom";
import { getOffers } from "../../../../apiCall";
import toast from 'react-hot-toast';
import Filters from "../../Components/Filters";
import { useStateValue } from "../../../../StateProvider";

const PackageList = ({ categories, setAppliedFilters }) => {
    const [page, setPage] = useState(1);
    const [packages, setPackages] = useState([]);
    const [showFiltersOffCanvas, setShowFiltersOffCanvas] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const navigateTo = useNavigate();
    const [, dispatch] = useStateValue();


    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const Re = await getOffers(page);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("OFFERS_DATA", Re);

            if (Re?.data?.status[0].Error === "False") {
                setPackages(Re?.data.value?.offers);
                setTotalPages(Re?.data.value?.total_pages);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try again.");
                }
            }
        };
        getData();
    }, [page, dispatch]);
    return (
        <div className='package-list-main'>
            <div className='heading-container'>
                <p className='heading'>Service Packages</p>
                <span>
                    <span>Sort by (Default)</span>
                    <img src={DownArrowIcon} alt='' />
                </span>
                <FilterIcon
                    className='filter-icon'
                    onClick={() => setShowFiltersOffCanvas(true)}
                />
            </div>
            <div className='package-list'>
                {packages?.map((offer) => (
                    <div className='package' key={"offer-" + offer.id}>
                        <div className='image-container'>
                            <img
                                src={domainName + "/uploads/" + offer.coverpage}
                                alt=''
                                onClick={() => navigateTo("/offer/" + offer.id)}
                            />
                        </div>
                        <div className='details-container'>
                            <div className='about-person'>
                                <img
                                    src={domainName + '/uploads/' + offer.freelancer_image}
                                    alt=''
                                />
                                <div className='name'>
                                    <div className='rating-container'>
                                        <p>
                                            {offer.freelancer_name}
                                        </p>
                                        <span className='ratings'>
                                            {Array(5)
                                                .fill(0)
                                                .map((ele, indx) => (
                                                    <StarReview
                                                        key={
                                                            "user-review-ratings-" +
                                                            offer.id +
                                                            "-" +
                                                            indx
                                                        }
                                                        className={`${
                                                            offer.ratings > indx
                                                                ? "filled"
                                                                : ""
                                                        }`}
                                                    />
                                                ))}
                                        </span>
                                    </div>
                                    <div className="tag-grp">
                                    {offer?.ratings >= 4 && <span className='tag'>
                                        <CheckIcon />
                                        <p>Top rated</p>
                                    </span>}
                                    {offer?.is_account_verified && <span className='account-verified'>
                                        <CheckIcon />
                                        <p>Account Verified</p>
                                    </span>}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <p className='category'>{offer.category}</p>
                            <p className='desc'>{offer.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className='pagination'>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                />
            </div>
            <Offcanvas
                show={showFiltersOffCanvas}
                className='nav-offcanvas-main'
                onHide={() => setShowFiltersOffCanvas(false)}>
                <Offcanvas.Body>
                    <Filters
                        categories={categories}
                        setAppliedFilters={setAppliedFilters}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default PackageList;
