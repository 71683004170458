import React, { useState } from "react";
import DesktopTable from "./DesktopTable";

import "./step2.scss";

const Step2 = ({ formData, setFormData, setPageNo }) => {
    const [error, setError] = useState("")
    const handleNext = () => {
        const {basic, standard, premium} = formData?.services
        
        if(!basic?.description || !basic?.delivery_time || !basic?.number_of_revisions || !basic?.price){
            setError("Please enter all details of basic plan")
            return;
        }
        if(!standard?.description || !standard?.delivery_time || !standard?.number_of_revisions || !standard?.price){
            setError("Please enter all details of standard plan")
            return;
        }
        if(!premium?.description || !premium?.delivery_time || !premium?.number_of_revisions || !premium?.price){
            setError("Please enter all details of premium plan")
            return;
        }
        
        setError("")
        localStorage.setItem("create_offer", JSON.stringify(formData));
        setPageNo((pre) => {
            localStorage.setItem("offer_create_page_no", pre + 1);
            return pre + 1;
        });
    };
    const [currentMobileStep, setCurrentMobileStep] = useState("basic");
    return (
        <>
            <div className='step-2-main step-main'>
                <div className='container' id='offer-container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 2 of 4</p>
                        </span>
                        <p className='title'>
                            Let us know pricing of your package
                        </p>
                        <p className='desc'>
                            Your title is the most important place to include
                            keywords that buyers would likely use to search for
                            a service like yours.
                        </p>
                    </div>
                    <div className='desktop-data-table-container'>
                        <DesktopTable
                            className='desktop-table'
                            formData={formData}
                            setFormData={setFormData}
                            showColumn={"all"}
                            error={error}
                            />
                    </div>
                    <div className='mobile-data-table-container'>
                        <DesktopTable
                            className='mobile-table'
                            formData={formData}
                            setFormData={setFormData}
                            showColumn={currentMobileStep}
                            error={error}
                        />
                    </div>
                </div>
            </div>
            <div className='buttons desktop-button'>
                <button
                    className='btn-white-outline'
                    onClick={() => setPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={handleNext}>
                    Next: Description
                </button>
            </div>
            <div className='buttons mobile-button'>
                <button
                    className='btn-white-outline'
                    onClick={() => {
                        if (currentMobileStep === "basic") {
                            setPageNo((pre) => pre - 1);
                            window.scrollTo(0, 0);
                        } else if (currentMobileStep === "standard") {
                            setCurrentMobileStep("basic");
                            window.scrollTo(0, 0);
                        } else {
                            setCurrentMobileStep("standard");
                            window.scrollTo(0, 0);
                        }
                    }}>
                    Back
                </button>
                <button
                    className='btn-blue'
                    onClick={() => {
                        if (currentMobileStep === "basic") {
                            if(!formData?.services?.basic?.description || !formData?.services?.basic?.delivery_time || !formData?.services?.basic?.number_of_revisions || !formData?.services?.basic?.price){
                                setError("Please enter all details of basic plan")
                                return;
                            }
                            setError("")
                            setCurrentMobileStep("standard");
                            window.scrollTo(0, 0);
                        } else if (currentMobileStep === "standard") {
                            if(!formData?.services?.standard?.description || !formData?.services?.standard?.delivery_time || !formData?.services?.standard?.number_of_revisions || !formData?.services?.standard?.price){
                                setError("Please enter all details of standard plan")
                                return;
                            }
                            setError("")
                            setCurrentMobileStep("premium");
                            window.scrollTo(0, 0);
                        } else {
                            if(!formData?.services?.premium?.description || !formData?.services?.premium?.delivery_time || !formData?.services?.premium?.number_of_revisions || !formData?.services?.premium?.price){
                                setError("Please enter all details of premium plan")
                                return;
                            }
                            setError("")
                            handleNext()
                            window.scrollTo(0, 0);
                        }
                    }}>
                    Next:{" "}
                    {currentMobileStep === "basic"
                        ? "Standard"
                        : currentMobileStep === "standard"
                        ? "Premium"
                        : "Description"}
                </button>
            </div>
        </>
    );
};

export default Step2;
