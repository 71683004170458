import React, { useState } from "react";

import Clock from "../../../../Assets/icons/clock.svg";
import "./step1.scss";
import SkillsForm from "./SkillsForm";

const Step1 = ({ formData, skillsList, setFormData, setLoginPageNo }) => {
    const [errors, setErrors] = useState({});
    const handleNext = () => {
        if (!formData?.role) {
            setErrors((pre) => {
                return { ...pre, role: "Role is required field." };
            });
            return;
        } else {
            setErrors((pre) => {
                return { ...pre, role: "" };
            });
        }
        if (!formData?.skills?.length) {
            setErrors((pre) => {
                return { ...pre, skills: "Skills is required field." };
            });
            return;
        }else{
            setErrors((pre) => {
                return { ...pre, skills: [] };
            });
        }
        localStorage.setItem("formData", JSON.stringify(formData));
        setLoginPageNo((pre) => {
            localStorage.setItem("loginPageNo", pre + 1);
            return pre + 1;
        });
    };

    return (
        <>
            <div className='step-1-main step-main'>
                <div className='container'>
                    <div className='text'>
                        <span className='step'>
                            <p>STEP 1 of 8</p>
                            <img src={Clock} alt='' />
                            <span>5 - 10min</span>
                        </span>
                        <p className='title'>Tell us more about what you do.</p>
                    </div>
                    <div className='input-grp'>
                        <p className='bold'>
                            Your title <span> (Your professional role)</span>
                            <span className='astrict'>*</span>
                        </p>
                        <input
                            type='text'
                            placeholder='For Ex: Voice artist, UX designer'
                            className='role-input'
                            value={formData.role}
                            onChange={(e) =>
                                setFormData((pre) => {
                                    return { ...pre, role: e.target.value };
                                })
                            }
                        />
                        <p className='form-error'>{errors?.role}</p>
                    </div>
                    <div style={{width:"100%"}}>
                        <SkillsForm
                            formData={formData}
                            skillsList={skillsList}
                            setFormData={setFormData}
                        />
                        <p className='form-error' style={{marginTop:"8px"}}>{errors?.skills}</p>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    className='btn-white-outline'
                    onClick={() => setLoginPageNo((pre) => pre - 1)}>
                    Back
                </button>
                <button className='btn-blue' onClick={handleNext}>
                    Next: Experience
                </button>
            </div>
        </>
    );
};

export default Step1;
