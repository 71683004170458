import React, { useState, useEffect } from "react";

import "./offerCreate.scss";
import Step1 from "./Components/Step1/Step1";
import Step2 from "./Components/Step2/Step2";
import Step3 from "./Components/Step3/Step3";
import Step4 from "./Components/Step4/Step4";
import ReviewOffer from "./Components/ReviewOffer/ReviewOffer";
import { getOfferDetails } from "../../../apiCall";
// import { useQuery } from "react-query";
import { domainName } from "../../../Constants";
import toast from 'react-hot-toast';
import { useParams } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";

const OfferCreate = () => {
    const [pageNo, setPageNo] = useState(0);
    // const [categories, setCategories] = useState([]);
    const [{categories}, dispatch] = useStateValue();

    const {id} = useParams()

    const initialState = {
        package_name: "",
        category: "",
        services: {
            basic: {
                // title: "",
                description: "",
                delivery_time: "",
                number_of_revisions: "",
                price: "",
                currency_unit: (sessionStorage.getItem('is_indian_user') === 'true' || localStorage.getItem("is_indian_user")  === 'true') ? 'INR' : 'USD',
                extra_service: {
                    special_delivery_time: "",
                    extra_charge: ""
                }
            },
            standard: {
                // title: "",
                description: "",
                delivery_time: "",
                number_of_revisions: "",
                price: "",
                currency_unit: (sessionStorage.getItem('is_indian_user') === 'true' || localStorage.getItem("is_indian_user")  === 'true') ? 'INR' : 'USD',
                extra_service: {
                    special_delivery_time: "",
                    extra_charge: ""
                }
            },
            premium: {
                // title: "",
                description: "",
                delivery_time: "",
                number_of_revisions: "",
                price: "",
                currency_unit: (sessionStorage.getItem('is_indian_user') === 'true' || localStorage.getItem("is_indian_user")  === 'true') ? 'INR' : 'USD',
                extra_service: {
                    special_delivery_time: "",
                    extra_charge: ""
                }
            },
        },
        has_extra_fast_delivery: false,
        about_packaging: "",
        why_should_you_use: "",
        cover_image: "",
        image_file: "",
        email: (localStorage.getItem('email') || sessionStorage.getItem('email')),
    }
    const [formData, setFormData] = useState(localStorage.getItem('create_offer') ? JSON.parse(localStorage.getItem('create_offer')) : initialState);

    useEffect(() => {
        if (
            localStorage.getItem("offer_create_page_no") &&
            (localStorage.getItem("token") || sessionStorage.getItem("token"))
        ) {
            setPageNo(parseInt(localStorage.getItem("offer_create_page_no")));
        }

        const getData = async () => {
            dispatch({type:"SET_IS_LOADING",status:true})

            const re = await getOfferDetails(id);
            setTimeout(()=>{
                dispatch({ type: "SET_IS_LOADING", status: false });
    
            }, 1000);

            console.log("OFFER_DETAILS_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    const value = re.data.value;
                    setFormData({...value, 'cover_image': domainName + '/uploads/' + value.cover_image, has_extra_fast_delivery: value.services.basic.extra_service ? value.services.basic.extra_service : false, category: JSON.stringify(JSON.parse(value.category))});
                    localStorage.clear()
                    // setCurrency(re.data.value?.services?.basic?.currency_unit === 'INR' ? '₹' : '$')
                }
            }
        };
        
        if(id){
            getData();
        }
    }, [id, dispatch]);

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });

    switch (pageNo) {
        case 0:
            return (
                <div className='offer-create-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 4 + "%",
                            }}></span>
                    </div>
                    <Step1
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                        categories={categories}
                    />
                </div>
            );
        case 1:
            return (
                <div className='offer-create-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 4 + "%",
                            }}></span>
                    </div>
                    <Step2
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                    />
                </div>
            );
        case 2:
            return (
                <div className='offer-create-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 4 + "%",
                            }}></span>
                    </div>
                    <Step3
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                    />
                </div>
            );
        case 3:
            return (
                <div className='offer-create-main'>
                    <div className='progress'>
                        <span
                            className='bar'
                            style={{
                                width: ((pageNo + 1) * 100) / 4 + "%",
                            }}></span>
                    </div>
                    <Step4
                        formData={formData}
                        setFormData={setFormData}
                        setPageNo={setPageNo}
                    />
                </div>
            );
        case 4:
            return (
                <div className='offer-create-main'>
                    <ReviewOffer formData={formData} setPageNo={setPageNo} />
                </div>
            );
        default:
            break;
    }
};

export default OfferCreate;
