import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SideImage } from "../../Assets/Images/Left Side Image.svg";
import Star from "../../Assets/icons/star-1.svg";
import Shild from "../../Assets/icons/shild.svg";
import ThumbsUp from "../../Assets/icons/thumbs-up.svg";
import Expand from "../../Assets/icons/expand.svg";
import { ReactComponent as JobIcon } from "../../Assets/icons/layers.svg";
import { ReactComponent as User } from "../../Assets/icons/user-fill.svg";
import { ReactComponent as Safety } from "../../Assets/icons/safe-icon.svg";
import { ReactComponent as HelpCircle } from "../../Assets/icons/help-circle.svg";
// import ServiceImage from "../../Assets/Images/service-img.png";
import { ReactComponent as Arrow } from "../../Assets/icons/arrow.svg";
import UserIcon from "../../Assets/icons/User-Icon.svg";
import StarIcon from "../../Assets/icons/Star-Icon.svg";
import DocIcon from "../../Assets/icons/Doc-Icon.svg";
import CheckIcon from "../../Assets/icons/Check-Icon.svg";
import ArrowOutline from "../../Assets/icons/arrow-outline.svg";
// import UserImg from "../../Assets/icons/user-default.png";
import InvertedComa from "../../Assets/icons/inverted-coma.svg";
import { ReactComponent as StarReview } from "../../Assets/icons/star.svg";
import "./home.scss";
import { useNavigate } from "react-router-dom";
import { getUserStories } from "../../apiCall";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { CACHE_TIME, domainName } from "../../Constants";
import { useStateValue } from "../../StateProvider";

const AccordianData = [
    {
        icon: Star,
        title: "Reliable Dealing",
        details: "Experience seamless transactions with guaranteed reliability every time.",
    },
    {
        icon: Shild,
        title: "Data Secured",
        details: "Your privacy is our priority—secured data for peace of mind.",
    },
    {
        icon: ThumbsUp,
        title: "On Time Payment",
        details: "Timely payments, every project, every time.",
    },
];
// const Services = [
//     "Design & Creative",
//     "Development & IT",
//     "Digital Marketing",
//     "Content Writing",
//     "Music & Audio",
//     "Finance & Accounting",
//     "Design & Creative",
//     "Development & IT",
//     "Digital Marketing",
//     "Content Writing",
//     "Music & Audio",
//     "Finance & Accounting",
// ];
const Achivements = [
    {
        icon: UserIcon,
        title: "857M",
        text: "Total Freelancer",
    },
    {
        icon: StarIcon,
        title: "627M",
        text: "Positive Review",
    },
    {
        icon: DocIcon,
        title: "87M",
        text: "Order Recived",
    },
    {
        icon: CheckIcon,
        title: "727M",
        text: "Project Completed",
    },
];
// const userReviews = [
//     {
//         name: "Jenny Hudson",
//         role: "Product Manager, Dell",
//         title: "Great Service!",
//         details:
//             "The AI software we implemented in our business has significantly improved our productivity and accuracy. It has allowed us to streamline our operations and focus on more important tasks.",
//         ratings: 4,
//         img: UserImg,
//         next_users: [UserImg, UserImg, UserImg, UserImg, UserImg],
//         next_user_number: "120+",
//     },
//     {
//         name: "Jenny Hudson",
//         role: "Product Manager, Dell",
//         title: "Great Service! slfajla",
//         details:
//             "The AI software we implemented in our business has significantly improved our productivity and accuracy. It has allowed us to streamline our operations and focus on more important tasks.",
//         ratings: 4,
//         img: UserImg,
//         next_users: [UserImg, UserImg, UserImg, UserImg, UserImg],
//         next_user_number: "120+",
//     },
// ];
const Home = () => {
    const [expandedAccordion, setexpandedAccordion] = useState(-1);
    const [currentUserReview, setcurrentUserReview] = useState(0);
    // const [categories, setCategories] = useState([])
    const [userStories, setUserStories] = useState([])
    const navigateTo = useNavigate()
    const servicesRef = useRef();
    const [{categories}] = useStateValue()
    const scrollRight = () => {
        servicesRef.current.scrollLeft = Math.min(
            servicesRef.current.offsetWidth,
            servicesRef.current.scrollLeft + 300
        );
    };
    const scrollLeft = () => {
        servicesRef.current.scrollLeft = Math.max(
            0,
            servicesRef.current.scrollLeft - 300
        );
    };

    // useQuery(["get-categories"], () => GetCategories(), {
    //     // staleTime: STALE_TIME,
    //     cacheTime: CACHE_TIME,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data) => {
    //         console.log(data);
    //         if (data?.data?.status[0].Error === "False") {
    //             setCategories(data.data.value);
    //         }
    //     },
    //     onError: (err) => {
    //         console.log(err, "ERROR");
    //         if (err.message) {
    //             toast.error(err.message);
    //         } else {
    //             toast.error("Something went wrong");
    //         }
    //     },
    // });
    useQuery(["get-userstories"], () => getUserStories(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            console.log(data);
            if (data?.data?.status[0].Error === "False") {
                setUserStories(data.data.value);
            }
        },
        onError: (err) => {
            console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const prevVal = servicesRef.current.scrollLeft
            let new_val = prevVal;
            if(prevVal + 300 <= servicesRef.current.offsetWidth){
                new_val = prevVal + 300;
            }else{
                new_val = 0;
            }
            if(prevVal === new_val && prevVal !== 0){
                new_val = 0;
            }

            servicesRef.current.scrollLeft = new_val
        }, 3000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {   
            if(userStories?.length){
                setcurrentUserReview(pre => (pre + 1)%userStories.length)
            }else{
                setcurrentUserReview(0)
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [userStories?.length]);

    const userReviewRef = useRef();

    useEffect(() => {
        const handleScroll = () => {
            if (userReviewRef.current) {
                userReviewRef.current.scrollLeft =
                    currentUserReview *
                    (userReviewRef?.current?.offsetWidth
                        ? userReviewRef.current?.offsetWidth
                        : 1);
            }
        };
        handleScroll();
    }, [currentUserReview]);
    return (
        <div className='home-main'>
            <div className='banner-container'>
                <div className='banner'>
                    <h1 className='line-1'>
                     Need professional <span>service? </span>
                    </h1>
                    <h1 className='line-2'>Hire the best freelancer here!</h1>
                    <p className='goal'>
                        FOCUS YOUR <span>GOAL</span>
                    </p>
                </div>
                <p className='line'>
                    Work with talented people at the most affordable price.
                </p>
            </div>
            <div className='section-1'>
                <SideImage className='side-image' />
                <div className='section-1-content'>
                    <div className='text'>
                        <p>How it works</p>
                        <h3>Start find a job with us easily</h3>
                    </div>
                    <div className='accordion'>
                        {AccordianData.map((item, indx) => (
                            <div
                                className='item'
                                key={"accordian-" + indx}
                                onMouseEnter={() => setexpandedAccordion(indx)}
                                onMouseLeave={() => setexpandedAccordion(-1)}>
                                <img
                                    src={item.icon}
                                    alt=''
                                    className='accordian-icon'
                                />
                                <div className='details'>
                                    <p className='title'>{item.title}</p>
                                    <p
                                        className={`data ${
                                            expandedAccordion === indx
                                                ? "expanded"
                                                : ""
                                        }`}>
                                        {item.details}
                                    </p>
                                </div>
                                <img
                                    src={Expand}
                                    alt=''
                                    className={`arrow ${
                                        expandedAccordion === indx
                                            ? "rotate"
                                            : ""
                                    }`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='section-2'>
                <div className='text'>
                    <p className='title'>Need something done?</p>
                    <p className='details'>
                    Kickstart your project with a simple click—tell us what you need!
                    </p>
                </div>
                <div className='tiles'>
                    <div className='tile job'>
                        <JobIcon />
                        <div className='tile-text'>
                            <p className='bold'>Post a job</p>
                            <p>Easily post your project and start receiving bids today.</p>
                        </div>
                    </div>
                    <div className='tile user'>
                        <User />
                        <div className='tile-text'>
                            <p className='bold'>Choose freelancer</p>
                            <p>Select from a pool of top-rated freelancers to find your perfect match.</p>
                        </div>
                    </div>
                    <div className='tile safety'>
                        <Safety />
                        <div className='tile-text'>
                            <p className='bold'>Pay Safely</p>
                            <p>Transact with confidence through our secure payment system.</p>
                        </div>
                    </div>
                    <div className='tile help-circle'>
                        <HelpCircle />
                        <div className='tile-text'>
                            <p className='bold'>We are here to help</p>
                            <p>Dedicated support available whenever you need assistance.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-3'>
                <div className='heading'>
                    <div className='text'>
                        <p className='title'>Trending</p>
                        <p className='details'>Discover cutting-edge services shaping the future of freelancing, all in one place!</p>
                    </div>
                    <div className='arrows'>
                        <Arrow
                            onClick={scrollLeft}
                            className={`${
                                servicesRef?.current?.scrollLeft === 0
                                    ? "disalbe"
                                    : ""
                            }`}
                        />
                        <Arrow
                            className={`${
                                servicesRef?.current?.scrollLeft ===
                                servicesRef?.current?.offsetWidth
                                    ? "disalbe rotate"
                                    : "rotate"
                            }`}
                            onClick={scrollRight}
                        />
                    </div>
                </div>
                <div className='service-container'>
                    <img
                        src={ArrowOutline}
                        alt=''
                        onClick={scrollLeft}
                        className={`${
                            servicesRef?.current?.scrollLeft === 0
                                ? "disalbe arrow"
                                : "arrow"
                        }`}
                    />
                    <div className='services' ref={servicesRef}>
                        {categories?.map((service, indx) => (
                            <div className='service' key={"service-" + indx}>
                                <img src={domainName + '/uploads/' + service?.image} alt='' />
                                <span>{service?.name}</span>
                            </div>
                        ))}
                    </div>
                    <img
                        src={ArrowOutline}
                        alt=''
                        className={`${
                            servicesRef?.current?.scrollLeft ===
                            servicesRef?.current?.offsetWidth
                                ? "disalbe arrow rotate"
                                : "arrow rotate"
                        }`}
                        onClick={scrollRight}
                    />
                </div>
            </div>
            <div className='section-4'>
                <div className='text'>
                    <p className='title'>Achivement We Have Earned</p>
                    <p className='details'>
                        At Freelancer, we believe that talent is borderless and
                        opportunity should be too.
                    </p>
                </div>
                <div className='achivements'>
                    {Achivements.map((achivement, indx) => (
                        <div className='achivement' key={"achivement-" + indx}>
                            <img src={achivement.icon} alt='' />
                            <p className='title'>{achivement.title}</p>
                            <p>{achivement.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='section-5'>
                <div className='text'>
                    <h1>
                        JOIN <br />
                        WORLD'S BEST <br />
                        <span>MARKETPLACE</span>
                    </h1>
                    <p>
                        Connect to freelancers with proven business experience
                    </p>
                    <button className='primary-btn-white' onClick={() => navigateTo('/signup')}>
                        Start now{" "}
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'>
                            <path
                                d='M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z'
                                fill='#5D01DB'
                            />
                        </svg>
                    </button>
                </div>
                <span className='client-rate right-circles'>
                    <h3>4.5/5</h3>
                    <p className='text'>Clients rate on Rysovate</p>
                </span>
                <span className='satisfied-customer right-circles'>
                    <h3>98%</h3>
                    <p className='text'>Satisfied customers</p>
                </span>
            </div>
            {userStories?.length > 0 && <div className='section-6'>
                <div className='text'>
                    <p className='title'>User Story</p>
                    <p className='details'>
                        Freelancing refers to working as an independent
                        contractor, often in a self-employed capacity, for
                        multiple clients or companies rather than being employed
                        by a single employer.
                    </p>
                </div>
                <div className='user-cards-container' ref={userReviewRef}>
                    {userStories.map((review, indx) => (
                        <div className='user-card' key={"user-review-" + indx}>
                            <div className='user'>
                                <div className='image-container'>
                                    <img
                                        src={InvertedComa}
                                        className='inverted-coma'
                                        alt=''
                                    />
                                    <span className='user-image'>
                                        <img
                                            src={domainName + '/uploads/' + review.user_image}
                                            alt=''
                                            className='user-image'
                                        />
                                    </span>
                                </div>
                                <div className='review-container'>
                                    <div className='review-details'>
                                        <div className='review'>
                                            <span className='head'>
                                                <p className='title'>
                                                    {review.title}
                                                </p>
                                                <span className='ratings'>
                                                    {Array(5)
                                                        .fill(0)
                                                        .map((ele, indx) => (
                                                            <StarReview
                                                                key={
                                                                    "user-review-ratings-" +
                                                                    review.id +
                                                                    "-" +
                                                                    indx
                                                                }
                                                                className={`${
                                                                    review.ratings >
                                                                    indx
                                                                        ? "filled"
                                                                        : ""
                                                                }`}
                                                            />
                                                        ))}
                                                    <p>4.0</p>
                                                </span>
                                            </span>
                                            <p className='review-desc'>
                                                {review.description}
                                            </p>
                                        </div>
                                        <div className='user-info'>
                                            <p className='user-name'>
                                                {review.user_name}
                                            </p>
                                            <p className='user-role'>
                                                {review.designation}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='navigation'>
                                        <div className='arrows'>
                                            <Arrow
                                                onClick={() =>
                                                    setcurrentUserReview(
                                                        (prev) =>
                                                            Math.max(
                                                                0,
                                                                prev - 1
                                                            )
                                                    )
                                                }
                                                className={`${
                                                    servicesRef?.current
                                                        ?.scrollLeft === 0
                                                        ? "disalbe"
                                                        : ""
                                                }`}
                                            />
                                            <Arrow
                                                className={`${
                                                    servicesRef?.current
                                                        ?.scrollLeft ===
                                                    servicesRef?.current
                                                        ?.offsetWidth
                                                        ? "disalbe rotate"
                                                        : "rotate"
                                                }`}
                                                onClick={() =>
                                                    setcurrentUserReview(
                                                        (prev) =>
                                                            Math.min(
                                                                userStories.length -
                                                                    1,
                                                                prev + 1
                                                            )
                                                    )
                                                }
                                            />
                                        </div>
                                        {/* <div
                                            className='next-users'
                                            style={{
                                                width:
                                                    review.next_users.length *
                                                        30 +
                                                    30 +
                                                    "px",
                                            }}>
                                            {review.next_users.map(
                                                (user, indx) => (
                                                    <img
                                                        src={user}
                                                        alt=''
                                                        key={
                                                            "next-user-img-" +
                                                            indx
                                                        }
                                                        style={{
                                                            left:
                                                                indx * 30 +
                                                                "px",
                                                        }}
                                                    />
                                                )
                                            )}
                                            <span className='number'>
                                                {review.next_user_number}
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
};

export default Home;
