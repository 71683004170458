import React, { useEffect, useState } from "react";

import "./offerDetails.scss";
import { useStateValue } from "../../StateProvider";
import { getOfferDetails } from "../../apiCall";
import toast from 'react-hot-toast';
import { useParams } from "react-router-dom";
import { domainName } from "../../Constants";
import { PackagePurchaseModal, SuccessModal } from "../../Modals";
import AboutOffer from "./Components/AboutOffer";
import PackageRequirements from "./PackageRequirements";

const OfferDetails = ({ data }) => {
    const [activeTab, setActiveTab] = useState("basic");
    const [{ currency_symbol }] = useStateValue();
    const [currency, setCurrency] = useState(currency_symbol);
    const [offerDetails, setOfferDetails] = useState();
    const [showRequirementsForm, setShowRequirementsForm] = useState(0);
    const { id } = useParams();
    const [, dispatch] = useStateValue();


    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "SET_IS_LOADING", status: true });

            const re = await getOfferDetails(id);
            setTimeout(()=>{
            dispatch({ type: "SET_IS_LOADING", status: false });

        }, 1000);

            console.log("OFFER_DETAILS_RESPONSE", re, re.status);
            if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page.");
            } else {
                if (re.data.status[0].Error === "True") {
                    toast.error(re.data.status[0].ResponseMessage);
                } else {
                    setOfferDetails({
                        ...re.data.value,
                        cover_image:
                            domainName +
                            "/uploads/" +
                            re.data.value.cover_image,
                    });
                    setCurrency(
                        re.data.value?.services?.basic?.currency_unit === "INR"
                            ? "₹"
                            : "$"
                    );
                }
            }
        };

        if (data) {
            setOfferDetails(data);
        } else {
            getData();
        }
    }, [data, id, dispatch]);
    return (
        <>
            {!showRequirementsForm ? (
                <AboutOffer
                    offerDetails={offerDetails}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    currency={currency}
                    setShowRequirementsForm={setShowRequirementsForm}
                />
            ) : (
                <PackageRequirements id={id} setShowRequirementsForm={setShowRequirementsForm}/>
            )}

            <PackagePurchaseModal
                data={offerDetails?.services[activeTab]}
                offer_id={id}
                activeTab={activeTab}
                currency={currency}
            />
            <SuccessModal text={"Package purchased successfully."} redirect_to_home={true} />
        </>
    );
};

export default OfferDetails;
